import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import { API } from '../../../services/Api'
import { Link as RouterLink } from 'react-router-dom'
import Attachments from '../../Attachments/'
import UsageDataStatus from './UsageDataStatus'

import {
  Grid,
  GridItem,
  Link,
  Text,
  Tag,
  Box
} from '@chakra-ui/react'

const WorkOrderListItemCell = ({ children, ...props }) => {
  const borderProps = {
    borderBottom: 'solid 1px',
    borderBottomColor: 'providerDashboard.grayBorder'
  }

  return (
    <GridItem
      py={7}
      display='flex'
      height='100%'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='center'
      {...borderProps}
      {...props}
    >
      {children}
    </GridItem>
  )
}

WorkOrderListItemCell.propTypes = {
  children: PropTypes.node.isRequired
}

const StatusTag = ({ displayStatus }) => {
  let backgroundColor, textColor

  switch (displayStatus) {
    case 'Awaiting Patient':
      backgroundColor = 'gray.200'
      textColor = 'gray.600'
      break
    case 'Delivered':
      backgroundColor = 'rgba(21, 163, 54, 0.1)'
      textColor = 'green.800'
      break
    case 'Supplier Processing':
      backgroundColor = 'yellow.200'
      textColor = 'yellow.800'
      break
    case 'Out For Delivery':
      backgroundColor = 'yellow.200'
      textColor = 'yellow.800'
      break
  }

  return (
    <Tag borderRadius={2} backgroundColor={backgroundColor}>
      <Text casing='uppercase' fontWeight='700' fontSize='xs' color={textColor}>
        {displayStatus}
      </Text>
    </Tag>
  )
}

StatusTag.propTypes = {
  displayStatus: PropTypes.string.isRequired
}

const StatusTagPriorAuth = ({ consultation }) => {
  const {
    priorAuthStatus: status,
    priorAuthPayerResponse
  } = consultation

  let backgroundColor, textColor

  if (status === 'Submitted' || status === 'Approved') {
    backgroundColor = 'rgba(21, 163, 54, 0.1)'
    textColor = 'green.800'
  } else if (status === 'Rejected' || status === 'Cancelled') {
    backgroundColor = 'rgba(226, 97, 91, 0.1)'
    textColor = 'red.800'
  } else {
    backgroundColor = 'gray.200'
    textColor = 'gray.600'
  }

  const downloadUrlMutation = useMutation(
    params => API.attachments.getDownloadUrl(params),
    {
      onSuccess: (data) => {
        window.open(data.data.downloadUrl, '_blank')
      }
    }
  )

  const handleTagClick = () => (
    downloadUrlMutation.mutate({ uuid: priorAuthPayerResponse.uuid })
  )

  return (
    <Tag borderRadius={2} backgroundColor={backgroundColor}>
      <Text casing='uppercase' fontWeight='700' fontSize='xs' color={textColor}>
        {priorAuthPayerResponse
          ? (
            <Link color={textColor} onClick={handleTagClick}>{status}</Link>
            )
          : status}
      </Text>
    </Tag>
  )
}

StatusTagPriorAuth.propTypes = {
  consultation: PropTypes.object.isRequired
}

const InnerCell = ({ children, ...props }) => {
  const borderProps = {
    borderBottom: 'solid 1px',
    borderBottomColor: 'providerDashboard.grayBorder'
  }

  return (
    <Box
      py={7}
      height='80px'
      width='100%'
      {...borderProps}
      _last={{ borderBottom: 'none' }}
      {...props}
    >
      {children}
    </Box>
  )
}

InnerCell.propTypes = {
  children: PropTypes.node.isRequired
}

const WorkOrderListItem = ({
  consultation,
  firstInGroup,
  lastInGroup,
  ...props
}) => {
  const {
    createdAt,
    fulfillmentsForDataUsageRequests,
    referralCode,
    patientPacketTypes
  } = consultation

  const fulfillmentForDataUsageRequests = fulfillmentsForDataUsageRequests[0]

  const formattedCreatedAt = (
    dayjs(createdAt).format('MM/DD/YYYY')
  )

  const formattedReferralCode = (
    referralCode.replace(/(\S{3})(\S{3})/, '$1-$2')
  )

  return (
    <Grid
      data-test='work-order-list-item'
      data-referral-code={formattedReferralCode}
      key={consultation.uuid}
      templateColumns='repeat(12, 1fr)'
      fontSize={14}
      alignItems='center'
      {...props}
    >
      <WorkOrderListItemCell
        colSpan={{ base: 2 }}
        pl={6}
        borderBottom={lastInGroup ? 'solid 1px' : 'none'}
      >
        {firstInGroup && (
          <>
            <Link
              as={RouterLink}
              fontWeight='600'
              color='gray.700'
              fontSize='md'
              marginBottom={1}
              wordBreak='break-word'
              to={`/work_orders/${consultation.uuid}`}
              data-test='work-order-patient-name'
            >
              {consultation.patientName || consultation.patientPrimaryContact}
            </Link>
            {consultation.patientDob && (
              <Text
                color='gray.500'
                fontSize='sm'
                wordBreak='break-word'
              >
                {consultation.patientDob}
              </Text>
            )}
          </>
        )}
      </WorkOrderListItemCell>
      <WorkOrderListItemCell alignItems='center' colSpan={{ base: 1 }} py={7}>
        <Link
          as={RouterLink}
          fontWeight='600'
          color='green.600'
          fontSize='md'
          to={`/work_orders/${consultation.uuid}`}
          data-test='work-order-detail'
        >
          View
        </Link>
      </WorkOrderListItemCell>
      <WorkOrderListItemCell colSpan={{ base: 1 }} py={7}>
        {formattedCreatedAt}
      </WorkOrderListItemCell>
      <WorkOrderListItemCell colSpan={{ base: 2 }} py={7} pl={4}>
        <StatusTag displayStatus={consultation.displayStatus} />
      </WorkOrderListItemCell>
      <WorkOrderListItemCell colSpan={{ base: 2 }}>
        <StatusTagPriorAuth consultation={consultation} />
      </WorkOrderListItemCell>
      <WorkOrderListItemCell
        colSpan={{ base: 2 }}
        py={0}
      >
        {patientPacketTypes.map(({ name }) => (
          <InnerCell key={name}>
            <Text>{name}</Text>
          </InnerCell>)
        )}

        {fulfillmentForDataUsageRequests && (
          <InnerCell key='request'>
            CPAP Usage Data
          </InnerCell>
        )}

      </WorkOrderListItemCell>
      <WorkOrderListItemCell
        colSpan={{ base: 2 }}
        py={0}
      >
        {patientPacketTypes.map((packetType) => (
          <InnerCell key={packetType.type}>
            <Attachments
              consultation={consultation}
              packetType={packetType}
            />
          </InnerCell>)
        )}

        {fulfillmentForDataUsageRequests && (
          <InnerCell key='request'>
            <UsageDataStatus fulfillment={fulfillmentForDataUsageRequests} />
          </InnerCell>
        )}
      </WorkOrderListItemCell>
    </Grid>
  )
}

WorkOrderListItem.propTypes = {
  consultation: PropTypes.object.isRequired,
  firstInGroup: PropTypes.bool.isRequired,
  lastInGroup: PropTypes.bool.isRequired
}

const WorkOrderListGroup = ({ group }) => {
  return (
    <>
      {group && group.map((consultation, index) => {
        return (
          <WorkOrderListItem
            key={consultation.uuid}
            consultation={consultation}
            firstInGroup={index === 0}
            lastInGroup={index === (group.length - 1)}
          />
        )
      }
      )}
    </>
  )
}

WorkOrderListGroup.propTypes = {
  group: PropTypes.array.isRequired
}

export default WorkOrderListGroup
