/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import useRouteMatching from '../components/common/useRouteMatching'
import Mixpanel from './Mixpanel'

const WIDGET_ENABLED = process.env.ZENDESK_WIDGET_KEY != null

export const identify = ({ name, email, resourceType, uuid }) => {
  if (!WIDGET_ENABLED) return

  window.zE('webWidget', 'identify', {
    name: name || resourceType || 'Patient',
    email: email || `${uuid}@doorbellhealth.com`
  })

  prefillContactForm({ name, email })
}

export const openWidget = () => {
  if (!WIDGET_ENABLED) return

  window.zE('webWidget', 'open')
}

// This resets the widget state (form data), note that it doesn't reset
// the state when this function is called while the widget is open
// Reloading the browser's tab is equivalent to calling this function
export const resetWidget = () => {
  if (!WIDGET_ENABLED) return

  window.zE('webWidget', 'reset')
}

export const trackWidgetOpen = () => {
  if (!WIDGET_ENABLED) return

  window.zE('webWidget:on', 'open', function () {
    Mixpanel.trackEvent('Click Chat Icon')
  })
}

export const updateWidgetSettings = (settings) => {
  if (!WIDGET_ENABLED) return

  window.zE('webWidget', 'updateSettings', {
    webWidget: settings
  })
}

// Available keys to prefill: "name", "email", "subject", "description"
export const prefillContactForm = (keyValueFields) => {
  const fields = []

  Object.entries(keyValueFields)
    .forEach(([key, value]) => {
      if (value !== undefined) {
        fields.push(
          { id: key, prefill: { '*': value } }
        )
      }
    })

  updateWidgetSettings(
    { contactForm: { fields } }
  )
}

export const useSetWidgetPosition = () => {
  if (!WIDGET_ENABLED) return

  const mobileBreakpointValue = '(max-width: 62em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })
  const { isShopRoute, isProductRoute } = useRouteMatching()

  useEffect(() => {
    let offset

    if (isMobile) {
      offset = {
        vertical: '5px',
        horizontal: '5px',
        mobile: {
          horizontal: '5px',
          vertical: '5px'
        }
      }

      if (isShopRoute || isProductRoute) {
        offset = {
          vertical: '70px',
          horizontal: '-5px',
          mobile: {
            horizontal: '-5px',
            vertical: '70px'
          }
        }
      }
    } else {
      offset = {
        vertical: '20px',
        horizontal: '52px'
      }

      if (isShopRoute || isProductRoute) {
        offset = {
          vertical: '120px',
          horizontal: '52px'
        }
      }
    }

    updateWidgetSettings({ offset })
  }, [isMobile, isShopRoute, isProductRoute])
}
