/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Aside from './Aside'
import BackButton from './BackButton'
import Images from './Images'
import Mixpanel from '../../../services/Mixpanel'
import ProgressNavigation from '../ProgressNavigation'
import { API } from '../../../services/Api'
import { useNavigate, useParams } from 'react-router-dom'
import { ProductVariantProvider, useProductVariant } from '../../../context/use-product-variant'
import { usePatientSession } from '../../../context/use-patient-session'
import { useCookies } from 'react-cookie'
import { useQuery } from 'react-query'

import {
  useToast,
  Container,
  Grid,
  GridItem
} from '@chakra-ui/react'

const ProductDetailLayout = () => {
  const { patientSession } = usePatientSession()
  const { quantity } = useProductVariant()
  const { uuid } = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  // Redirect to shop if product is not within the current product vertical
  const cookieName = 'doorbellhealth_productVertical'
  const [cookies] = useCookies([cookieName])
  const productVerticalUuid = cookies.doorbellhealth_productVertical

  const handleError = () => {
    navigate('/shop', { replace: true })

    toast({
      position: 'top-right',
      title: 'Invalid product id',
      status: 'error',
      duration: null,
      isClosable: true
    })
  }

  const fetchProductVariant = async () => {
    const { data } = await API.productVariants.single(uuid, {
      insurance_status_uuid: patientSession.insuranceStatus?.uuid,
      quantity
    })

    return data
  }

  const fetchProductVariants = async () => {
    const { data } = await API.productVariants.index({
      product_uuid: productVariant.product.uuid
    })

    return data
  }

  const { data: productVariant } = useQuery(
    ['fetchProductVariant', patientSession.insuranceStatus, quantity, uuid],
    fetchProductVariant,
    {
      retry: false,
      onError: handleError,
      keepPreviousData: true,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const { data: productVariants } = useQuery(
    ['fetchProductVariants', productVariant?.product?.uuid],
    fetchProductVariants,
    {
      enabled: !!productVariant,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (!productVerticalUuid || !productVariant) return

    if (productVariant.product.hcpcsCode.productVertical.uuid !== productVerticalUuid) {
      navigate('/shop', { replace: true })
    }
  }, [productVariant, productVerticalUuid])

  useEffect(() => {
    if (productVariant) {
      const { product } = productVariant

      Mixpanel.trackEventWithProduct('Arrive at product details page', product)
    }
  }, [!!productVariant])

  if (productVariant && productVariants) {
    const { product } = productVariant

    return (
      <Grid templateColumns='repeat(12, 1fr)' gap={6} mt='8'>
        <GridItem colSpan={12}>
          <BackButton />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 7 }}>
          <Images imageUrls={product.imageUrls} productUuid={product.uuid} />
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 5 }}
          pb={
            /** Padding was added in base to fill the space of <ProgressNavigation />
             *  which is fixed at the bottom */
            { base: '20', lg: '0' }
          }
        >
          <Aside
            productVariant={productVariant}
            productVariants={productVariants}
          />
        </GridItem>

        <ProgressNavigation />
      </Grid>
    )
  } else {
    return null
  }
}

const ProductDetail = () => (
  <ProductVariantProvider>
    <Container maxW='7xl' flex='1' px='4' pb='10'>
      <ProductDetailLayout />
    </Container>
  </ProductVariantProvider>
)

export default ProductDetail
