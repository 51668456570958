import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, HStack } from '@chakra-ui/react'
import RoundedBox from '../../common/RoundedBox'
import ShippingAddress from '../../Orders/Detail/ShippingAddress'
import OrderSummary from '../../Orders/Detail/OrderSummary'

const Title = ({ label }) => (
  <Text w='100%' fontWeight='semibold'>
    {label}
  </Text>
)

Title.propTypes = {
  label: PropTypes.string
}

const Summary = ({ order, isMobile }) => {
  const AddressBox = (
    <Box w='100%'>
      <ShippingAddress order={order} fontWeight='normal' fontSize='sm' />
    </Box>
  )

  const SummaryBox = (
    <Box w='100%'>
      <OrderSummary order={order} />
    </Box>
  )

  if (isMobile) {
    return (
      <>
        <RoundedBox data-test='order-history-summary'>
          <Box py='3' px='4'>
            <Title label='Shipping Address' />
          </Box>
          <Box py='3' px='4'>
            {AddressBox}
          </Box>
        </RoundedBox>

        <RoundedBox>
          <Box py='3' px='4'>
            <Title label='Order Summary' />
          </Box>
          <Box py='3' px='4'>
            {SummaryBox}
          </Box>
        </RoundedBox>
      </>
    )
  }

  return (
    <RoundedBox data-test='order-history-summary'>
      <HStack
        textAlign='left'
        px='8'
        py='4'
      >
        <Title label='Shipping Address' />
        <Title label='Order Summary' />
      </HStack>
      <HStack
        align='flex-start'
        px='8'
        py='4'
        spacing='4'
      >
        {AddressBox}
        {SummaryBox}
      </HStack>
    </RoundedBox>
  )
}

Summary.propTypes = {
  order: PropTypes.object,
  isMobile: PropTypes.bool
}

export default Summary
