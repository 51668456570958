import drawingSearchSvg from '../../../../assets/images/landing/drawing-search.svg'
import drawingFindSvg from '../../../../assets/images/landing/drawing-find.svg'
import drawingSkipSvg from '../../../../assets/images/landing/drawing-skip.svg'

export const ASSETS = [
  {
    title: 'Search devices',
    description: 'Compare and shop all of your favorite brands.',
    src: drawingSearchSvg,
    position: 'left'
  },
  {
    title: 'Find in-network stores and save',
    description: 'We match you with the stores that take your insurance.',
    src: drawingFindSvg,
    position: 'right'
  },
  {
    title: 'Skip the line',
    description: 'Our local suppliers deliver your equipment directly to you, so you never have to leave home.',
    src: drawingSkipSvg,
    position: 'left'
  }
]
