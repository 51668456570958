/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
  useBoolean,
  useModalContext,
  useToast
} from '@chakra-ui/react'
import { fileChecksum } from '../../utils/checksum'
import { API } from '../../services/Api'
import { compressImage } from '../../services/Compressorjs'
import { convertHeicToJpeg } from '../../services/Heic2any'
import Mixpanel from '../../services/Mixpanel'
import Constants from '../common/constants'
import AttachmentItem from './AttachmentItem'
import AttachmentsUploader from './AttachmentsUploader'
import { attachmentsModalConfig } from './attachmentsModalConfig'

const AttachmentsModalContent = ({
  afterAttachmentsChange,
  headerCopy,
  modalType,
  modalData = {},
  onClose
}) => {
  const [pendingAttachments, setPendingAttachments] = useState([])
  const [isUploading, setIsUploading] = useBoolean(false)

  const isUploadPending = pendingAttachments.length > 0

  const {
    allowDeletion,
    allowMultipleUploads,
    attachments,
    closeButtonCopy,
    closeButtonStyles,
    createAttachmentRequest,
    headerStyles,
    showCloseButton,
    showUploadedAttachments
  } = attachmentsModalConfig({
    modalType,
    modalData
  })

  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })
  const toast = useToast()
  const { onClose: modalOnClose } = useModalContext()

  useEffect(() => {
    Mixpanel.trackEvent('Arrive at Attachments Modal', { modalType })
  }, [])

  const handleClose = () => {
    setPendingAttachments([])
    Mixpanel.trackEvent(
      'Close Attachments Modal',
      { modalType, buttonCopy: closeButtonCopy }
    )
    onClose()
  }

  const handleModalCloseButtonClick = () => {
    Mixpanel.trackEvent(
      'Close Attachments Modal',
      { modalType, buttonCopy: 'ModalCloseButton' }
    )
    // This is the default behavior of the modal close button
    // It will give the user another chance to upload their insurance card
    modalOnClose()
  }

  const convertHeicFilesToJpeg = (file) => {
    const { type } = file

    const isAHeicFile = type === 'image/heic' || type === 'image/heif'

    if (isAHeicFile) {
      return convertHeicToJpeg(file)
    } else {
      return file
    }
  }

  const compressAttachment = (file) => {
    const { size, type } = file

    const fileSizeInMB = size / 1024 / 1024
    const isAnImage = type === 'image/jpeg' || type === 'image/png'

    if (fileSizeInMB > 1 && isAnImage) {
      return compressImage(file)
    } else {
      // Note that pdf files are not compressed in the front-end.
      // In the back-end, the first pdf page is converted to a compressed image.
      return file
    }
  }

  const fetchPresignedUrl = async (attachment) => {
    const checksum = await fileChecksum(attachment)

    const file = {
      checksum,
      filename: attachment.name,
      byte_size: attachment.size,
      content_type: attachment.type
    }

    return API.attachments.getPresignedUrl({ file })
  }

  const uploadToS3 = async (presignedUrl, attachment) => {
    const { data } = presignedUrl
    const options = {
      headers: {
        'Content-Disposition': data.directUpload.headers.contentDisposition,
        'Content-MD5': data.directUpload.headers.contentMd5,
        'Content-Type': data.directUpload.headers.contentType
      }
    }

    return axios.put(data.directUpload.url, attachment, options)
  }

  const uploadAttachment = async (attachment) => {
    const convertedAttachment = await convertHeicFilesToJpeg(attachment)

    const compressedAttachment = await compressAttachment(convertedAttachment)

    const presignedUrl = await fetchPresignedUrl(compressedAttachment)

    await uploadToS3(presignedUrl, compressedAttachment)

    return presignedUrl.data.blobSignedId
  }

  const uploadAttachments = () => {
    setIsUploading.on()

    Mixpanel.trackEvent('Click Upload CTA', { modalType })

    Promise.all(
      pendingAttachments.map(attachment => uploadAttachment(attachment))
    )
      .then(createAttachmentRequest)
      .then((response) => {
        setPendingAttachments([])
        afterAttachmentsChange(response)
        onClose()
      })
      .catch((error) => {
        let errorMessage
        if (error?.response?.data?.type === 'file_too_large_error') {
          errorMessage = error.response.data.message
        }

        return toast({
          position: 'top-right',
          title: errorMessage || 'Something went wrong. Please try again.',
          status: 'error',
          isClosable: true
        })
      }
      )
      .finally(() =>
        setIsUploading.off()
      )
  }

  return (
    <ModalContent
      maxW='500px'
      top={isMobile ? '-10' : '0'}
      data-test='attachments-modal'
    >
      <ModalHeader
        data-test='attachments-modal-header'
        bg='providerDashboard.grayHeader'
        borderBottom='1px solid'
        borderBottomColor='gray.100'
        fontSize='sm'
        fontWeight='500'
        borderTopLeftRadius='6'
        borderTopRightRadius='6'
        {...headerStyles}
      >
        {headerCopy}
      </ModalHeader>
      {showCloseButton &&
        <ModalCloseButton onClick={handleModalCloseButtonClick} size='lg' data-test='close-attachments-icon' />}
      <ModalBody px={8} py={6}>
        <Box minH='58px'>
          {showUploadedAttachments && attachments.length > 0 && (
            <>
              <Text fontSize='sm' fontWeight='700'>Uploaded Documents</Text>

              <VStack
                data-test='current-attachments'
                align='left'
                fontSize='sm'
                fontWeight='500'
                mt={4}
              >
                {attachments.map((attachment) =>
                  <AttachmentItem
                    afterAttachmentsChange={afterAttachmentsChange}
                    allowDeletion={allowDeletion}
                    attachment={attachment}
                    key={attachment.uuid}
                  />
                )}
              </VStack>
            </>
          )}
        </Box>

        <AttachmentsUploader
          isMobile={isMobile}
          isUploading={isUploading}
          pendingAttachments={pendingAttachments}
          setPendingAttachments={setPendingAttachments}
          allowMultipleUploads={allowMultipleUploads}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          data-test='close-attachments-modal'
          variant='ghost'
          mr={3}
          fontSize='sm'
          h='8'
          isDisabled={isUploading}
          onClick={handleClose}
          {...closeButtonStyles}
        >
          {isUploadPending ? 'Cancel' : closeButtonCopy}
        </Button>

        <Button
          data-test='upload-attachments'
          isDisabled={!isUploadPending}
          isLoading={isUploading}
          onClick={uploadAttachments}
          colorScheme='green'
          fontSize='sm'
          color='white'
          bg='green.600'
          variant='solid'
          h='8'
          w='126px'
          _hover={{
            color: 'white',
            background: 'green.700'
          }}
        >
          Upload
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

AttachmentsModalContent.propTypes = {
  afterAttachmentsChange: PropTypes.func.isRequired,
  headerCopy: PropTypes.node.isRequired,
  modalType: PropTypes.oneOf(
    Object.values(Constants.attachmentModalTypes)
  ).isRequired,
  modalData: PropTypes.object,
  onClose: PropTypes.func.isRequired
}

export default AttachmentsModalContent
