/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import startCaseString from 'lodash/startCase'
import Analytics from '../../services/Analytics'
import Mixpanel from '../../services/Mixpanel'
import Constants from '../common/constants'
import ContactSuccess from './ContactSuccess'
import InsuranceSuccess from './InsuranceSuccess'
import PatientAdditionalInformation from './PatientAdditionalInformation'
import PatientContactForm from './PatientContactForm'
import dayjs from 'dayjs'
import useRouteMatching from '../common/useRouteMatching'
import { API } from '../../services/Api'
import { InsuranceForm, notListedOption } from './InsuranceForm'
import { IoShieldCheckmark } from 'react-icons/io5'
import { useForm } from 'react-hook-form'
import { useInsuranceModal } from '../../context/use-insurance-modal'
import { useMutation, useQuery } from 'react-query'
import { usePatientSession } from '../../context/use-patient-session'
import AttachmentsModalContent from '../Attachments/AttachmentsModalContent'

import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'

export const InsuranceModal = () => {
  const patientSession = usePatientSession()
  const productVertical = patientSession.getProductVertical()
  const isBreastPump = productVertical?.name === 'Breast Pump'
  const { isModalOpen, isUpdateModal, handleCloseModal } = useInsuranceModal()
  const [defaultFormData, setDefaultFormData] = useState()
  const [isACoveredCaliforniaPlan, setIsACoveredCaliforniaPlan] = useState(false)

  const [selectedInsuranceCarrier, setSelectedInsuranceCarrier] = useState({})
  const { value: selectedInsuranceCarrierPayerId, label: selectedInsuranceCarrierName } =
    selectedInsuranceCarrier

  const { isShopRoute, isProductRoute, isCartRoute } = useRouteMatching()

  const {
    hasGoneThroughCardUpload,
    hasRequestedContact,
    hasSubmittedAdditionalInfo
  } = patientSession.patientSession.insuranceModal || {}

  const continueToShopClickHandler = () => {
    Mixpanel.trackEvent('Click No, Continue to Shop alternate CTA')
    handleCloseModal()
  }

  const handleModalCloseButtonClick = () => {
    Mixpanel.trackEvent('Click Close icon on Insurance Modal')
    handleCloseModal()
  }

  const trackInsuranceActivated = () => {
    Analytics.trackEvent({
      category: 'insurance',
      action: 'insurance activated'
    })
  }

  const trackUncoveredEquipments = () => {
    let label

    if (isShopRoute) {
      label = 'product listing page'
    }

    if (isProductRoute) {
      label = 'product details page'
    }

    if (isCartRoute) {
      label = 'cart review page'
    }

    Analytics.trackEvent({
      category: 'insurance',
      action: 'uncovered equipments',
      label
    })
  }

  const trackInsuranceFailed = () => {
    Analytics.trackEvent({
      category: 'insurance',
      action: 'insurance failed'
    })
  }

  const trackInsuranceIncomplete = (data) => {
    Analytics.trackEvent({
      category: 'insurance',
      action: 'incomplete',
      customParams: {
        data
      }
    })
  }

  const trackOcrExtractedEvents = (extractedFields) => (
    Object.entries(extractedFields).forEach(
      ([field, extractedValue]) => {
        Mixpanel.trackEvent(
          `OCR: Extracted ${startCaseString(field)}`,
          {
            was_able_to_pre_populate: !!extractedValue
          }
        )
      })
  )

  const trackOcrSubmittedEvents = (submittedValues, params = {}) => (
    Object.entries(defaultFormData).forEach(
      ([field, extractedValue]) => {
        // Does not send the event if the value wasn't pre-populated
        if (!extractedValue) return

        let wasEdited
        if (field === 'insuranceCarrier') {
          wasEdited = submittedValues[field] !== extractedValue?.value
        } else {
          wasEdited = submittedValues[field] !== extractedValue
        }

        Mixpanel.trackEvent(
          `OCR: Submitted ${startCaseString(field)}`,
          {
            was_edited_by_patient: wasEdited,
            ...params
          }
        )
      })
  )

  const handleSuccess = async ({ data: insuranceStatus }, values) => {
    trackInsuranceActivated()

    await patientSession.setInsuranceStatus(insuranceStatus)
    const { data: cart } = await API.cart.single()
    patientSession.setCart(cart)

    if (insuranceStatus.insuranceSavingsAvailable) {
      trackUncoveredEquipments()
    }

    if (defaultFormData) {
      trackOcrSubmittedEvents(values, { passed_backend_validation: true })
    }
  }

  const handleError = (error, values) => {
    if (error.response.data?.error?.type === 'health_plan_error') {
      // This analytic event is logged in the scenario that a health plan validation
      // error occurred. Due to the range of validation errors, it's possible
      // the issue lies on our end, in that we didn't supply the appropriate params
      // to the health plan to get a sufficient response.
      trackInsuranceIncomplete(error.response.data?.error?.data)
    } else {
      trackInsuranceFailed()
    }

    if (defaultFormData) {
      trackOcrSubmittedEvents(values, { passed_backend_validation: false })
    }
  }

  const handleCardUpload = ({ data: insuranceFormResults }) => {
    const { fields, data } = insuranceFormResults
    const { isACoveredCaliforniaPlan } = data

    setIsACoveredCaliforniaPlan(isACoveredCaliforniaPlan)
    setDefaultFormData(fields)
    patientSession.setHasUploadedCard()
    patientSession.setHasGoneThroughCardUpload()
    trackOcrExtractedEvents(fields)
  }

  const handleCardUploadClose = () => {
    patientSession.setHasGoneThroughCardUpload()
  }

  const handleClose = () => {
    handleCloseModal()
    insuranceStatusMutation.reset()
  }

  const insuranceCarriers = useQuery(
    ['fetchInsuranceCarriers', productVertical?.uuid],
    API.insuranceCarriers.index,
    {
      enabled: !!productVertical,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const insuranceStatusMutation = useMutation(
    newInsuranceStatus => API.insuranceStatuses.create(newInsuranceStatus),
    {
      onSuccess: handleSuccess,
      onError: handleError
    }
  )

  const contactMutation = useMutation(
    params => API.patients.contactRequest(params),
    {
      onSuccess: () => {
        patientSession.setHasRequestedContact()
      }
    }
  )

  const LABELS = {
    insuranceCarrier: 'Insurance Carrier',
    memberId: 'Member ID',
    firstName: 'Patient First Name',
    lastName: 'Patient Last Name',
    patientBirthDate: 'Patient Date of Birth'
  }

  const schema = yup.object().shape({
    insuranceCarrier: yup.object()
      .label(LABELS.insuranceCarrier)
      .nullable()
      .required(),
    memberId: yup.string().label(LABELS.memberId).required(),
    firstName: yup.string().label(LABELS.firstName).required(),
    lastName: yup.string().label(LABELS.lastName).required(),
    patientBirthDate: yup.date()
      .max(
        dayjs(), // Current Date
        'Cannot be in the future'
      )
      .label(LABELS.patientBirthDate)
      .required()
      .typeError('Must be a valid date')
  })

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (insuranceStatusMutation.isSuccess) {
      resetInsuranceForm()
    }
  }, [insuranceStatusMutation.isSuccess])

  const isStatusActive = () => {
    if (insuranceStatusMutation.isSuccess) {
      return insuranceStatusMutation.data.data.active === true
    }
  }

  const submitForm = ({
    insuranceCarrier,
    memberId,
    patientBirthDate,
    ...values
  }) => {
    values.memberId = memberId.replaceAll('-', '')
    values.patientBirthDate = dayjs(patientBirthDate).format('YYYY-MM-DD')
    values.insuranceCarrier = insuranceCarrier.value

    insuranceStatusMutation.mutate(values)
  }

  const onValidatedSubmit = (values) => {
    Mixpanel.trackEvent(
      'Click submit CTA on insurance details entry form',
      { insuranceCarrier: values.insuranceCarrier.label }
    )
    submitForm(values)
  }

  const otherInsuranceCarrierSelected = () => (
    selectedInsuranceCarrierName === notListedOption.label
  )

  const headerCopy = () => {
    if (isStatusActive()) {
      return `Insurance Pricing ${isUpdateModal ? 'Updated' : 'Unlocked'}`
    } else if (hasRequestedContact) {
      return 'We\'re On It'
    } else {
      return isUpdateModal ? 'Insurance Details' : 'Unlock Insurance Pricing'
    }
  }

  const resetInsuranceForm = () => {
    setDefaultFormData()
    reset({}, { keepDefaultValues: false })
  }

  const unsupportedCarrierSelected = () => {
    if (insuranceCarriers.isSuccess && selectedInsuranceCarrierPayerId !== undefined) {
      const carriers = insuranceCarriers.data?.data
      const selectedCarrier =
        carriers.find(carrier => carrier.payerId === selectedInsuranceCarrierPayerId)

      return selectedCarrier === undefined || !selectedCarrier.hasSuppliers
    }
  }

  const unsupportedPlan = () => isACoveredCaliforniaPlan || (
    insuranceStatusMutation.isError &&
    insuranceStatusMutation.error.response.data?.error?.type === 'unsupported_plan'
  )

  const unableToVerifyActiveInsurance = () => (
    insuranceStatusMutation.isError && !unsupportedPlan()) ||
      (insuranceStatusMutation.isSuccess && !isStatusActive())

  const showContactForm =
    (unsupportedCarrierSelected() || unsupportedPlan()) &&
    !hasRequestedContact

  const insuranceErrorMessage = () => {
    if (unableToVerifyActiveInsurance()) {
      return (
        <Text
          data-test='error-message'
          color='red.500'
          fontSize='sm'
          mt='3'
        >
          Please confirm that the information you've entered is correct. If the
          problem persists, please contact us at {Constants.doorbell.supportEmail}
          &nbsp;or close this modal to continue purchasing equipment
          out-of-pocket.
        </Text>
      )
    }
  }

  const insuranceContactErrorMessage = () => {
    if (unsupportedCarrierSelected() || unsupportedPlan()) {
      Mixpanel.trackEvent('Arrive at patient contact request form')

      let messageForBreastPumps = (
        <>
          Unfortunately, your insurance carrier is not in our system yet. <br /><br />
          The good news is, we can help you find the best solution outside of our system.
        </>
      )

      if (unsupportedPlan()) {
        messageForBreastPumps = (
          <>
            Unfortunately, we are currently not contracted with your insurance plan. <br /><br />
            The good news is, we can help you find the best solution outside of our system.
          </>
        )

        if (isACoveredCaliforniaPlan) {
          messageForBreastPumps = (
            <>
              Unfortunately, we are not yet contracted with your Covered California plan. <br /><br />
              The good news is, we are still able to help you find the best option outside of our system.
            </>
          )
        }
      }

      const messageForCPAP = (
        <>
          Great news - we can help you get your machine through insurance from a provider nearby!  <br /><br />
          Please provide us with your contact information and the best time to call, and we will reach out to you shortly to coordinate your order. <br /><br />
          If you'd like to shop now without your insurance, you can do so by closing this window or clicking "Shop Without Insurance".
        </>
      )

      return (
        <Text
          data-test='unsupported-insurance-message'
          color='gray.600'
          fontSize='sm'
        >
          {isBreastPump ? messageForBreastPumps : messageForCPAP}
        </Text>
      )
    }
  }

  const submitDisabled = () => {
    return unsupportedCarrierSelected() ||
            unsupportedPlan() ||
            insuranceStatusMutation.isLoading
  }

  const contactForm = (
    <PatientContactForm
      errorMessage={insuranceContactErrorMessage()}
      isBreastPump={isBreastPump}
      onDoThisLaterClick={continueToShopClickHandler}
      otherInsuranceCarrierSelected={otherInsuranceCarrierSelected()}
      contactMutation={contactMutation}
      selectedInsuranceCarrier={selectedInsuranceCarrierName}
      showContactFields={!patientSession.hasReferralConsultation}
    />
  )

  const bodyContent = () => {
    if (isStatusActive()) {
      return (
        <InsuranceSuccess handleCloseModal={handleClose} mutation={insuranceStatusMutation} />
      )
    } else if (hasSubmittedAdditionalInfo) {
      return (
        <ContactSuccess handleCloseModal={handleClose} />
      )
    } else if (hasRequestedContact) {
      return (
        <PatientAdditionalInformation
          handleCloseModal={handleClose}
          isBreastPump={isBreastPump}
        />
      )
    } else if (insuranceCarriers.isSuccess) {
      return (
        <InsuranceForm
          labels={LABELS}
          onSubmit={handleSubmit(onValidatedSubmit)}
          errors={errors}
          register={register}
          reset={reset}
          defaultFormData={defaultFormData}
          errorMessage={insuranceErrorMessage()}
          handleCarrierChange={insuranceStatusMutation.reset}
          control={control}
          isBreastPump={isBreastPump}
          isLoading={insuranceStatusMutation.isLoading}
          onDoThisLaterClick={handleClose}
          setSelectedInsuranceCarrier={setSelectedInsuranceCarrier}
          hideInsuranceInputs={showContactForm}
          submitDisabled={submitDisabled()}
          insuranceCarriers={insuranceCarriers}
          isACoveredCaliforniaPlan={isACoveredCaliforniaPlan}
          isUpdateModal={isUpdateModal}
        />
      )
    }
  }

  return (
    <Modal
      onClose={handleClose}
      isOpen={isModalOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {!hasGoneThroughCardUpload && !isUpdateModal
        ? (
          <AttachmentsModalContent
            afterAttachmentsChange={handleCardUpload}
            headerCopy='Please upload a photo of the front of your insurance card'
            modalType={Constants.attachmentModalTypes.insuranceCard}
            onClose={handleCardUploadClose}
          />
          )
        : (
          <ModalContent data-test='insurance-modal' maxW='500px'>
            <ModalHeader>
              <VStack>
                <Box as={IoShieldCheckmark} size='56px' color='blue.600' />
                <Heading size='lg'>{headerCopy()}</Heading>
              </VStack>
            </ModalHeader>
            <ModalCloseButton data-test='modal-close-button' onClick={handleModalCloseButtonClick} />
            <ModalBody px={{ base: 8, md: 12 }}>
              {bodyContent()}
              {showContactForm && contactForm}
            </ModalBody>
            <ModalFooter />
          </ModalContent>
          )}
    </Modal>
  )
}
