import React from 'react'
import Routes from './router/Routes'
import ErrorFallback, { errorHandler } from './common/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'
import shopTheme from '../theme/shop'
import { AuthProvider } from '../context/use-auth'
import { ApiWrapper } from './request/ApiWrapper'
import { ChakraProvider } from '@chakra-ui/react'
import { CookiesProvider } from 'react-cookie'
import { PatientSessionProvider } from '../context/use-patient-session'
import { PrescriptionModalProvider } from '../context/use-prescription-modal'
import * as CustomerSupportService from '../services/CustomerSupportService'
import Mixpanel from '../services/Mixpanel'
import useSetTimeZoneCookie from '../utils/useSetTimeZoneCookie'
import { QueryClient, QueryClientProvider } from 'react-query'

import '@fontsource/work-sans/300.css'
import '@fontsource/work-sans/400.css'
import '@fontsource/work-sans/500.css'
import '@fontsource/work-sans/600.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'

import { createStandaloneToast } from '@chakra-ui/toast'
const { ToastContainer } = createStandaloneToast({ theme: shopTheme })

Mixpanel.initialize()

const App = () => {
  const queryClient = new QueryClient()

  CustomerSupportService.useSetWidgetPosition()
  CustomerSupportService.trackWidgetOpen()

  useSetTimeZoneCookie()

  return (
    <ChakraProvider theme={shopTheme}>
      <ToastContainer />
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <AuthProvider>
          <PatientSessionProvider>
            <PrescriptionModalProvider>
              <ApiWrapper>
                <QueryClientProvider client={queryClient}>
                  <CookiesProvider>
                    <Routes />
                  </CookiesProvider>
                </QueryClientProvider>
              </ApiWrapper>
            </PrescriptionModalProvider>
          </PatientSessionProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

export default App
