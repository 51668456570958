import React from 'react'
import PropTypes from 'prop-types'
import { Center, Text } from '@chakra-ui/react'
import { useFilters } from '../useFilters'

export const MobilePriceFilterButton = ({ onClick }) => {
  const { currentFilters } = useFilters()

  const priceFilterActive = currentFilters.priceFrom || currentFilters.priceTo

  return (
    <Center
      onClick={onClick}
      borderRadius='full'
      h='40px'
      bg={priceFilterActive ? 'shop.grayOyster' : 'blackAlpha.50'}
      color={priceFilterActive ? 'white' : 'gray.700'}
      data-test='filters-mobile-price-button'
    >
      <Text
        px='12px'
        fontWeight={priceFilterActive ? '700' : '500'}
        fontSize='sm'
      >
        Price
      </Text>
    </Center>
  )
}
MobilePriceFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
