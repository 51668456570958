import React from 'react'
import PropTypes from 'prop-types'
import { Center, Text } from '@chakra-ui/react'
import { useFilters } from '../useFilters'

export const MobileBrandFilterButton = ({ onClick }) => {
  const { currentFilters } = useFilters()

  const brandsFilterActive = currentFilters.brands

  return (
    <Center
      onClick={onClick}
      borderRadius='full'
      h='40px'
      bg={brandsFilterActive ? 'shop.grayOyster' : 'blackAlpha.50'}
      color={brandsFilterActive ? 'white' : 'gray.700'}
      data-test='filters-mobile-brand-button'
    >
      <Text
        px='12px'
        fontWeight={brandsFilterActive ? '700' : '500'}
        fontSize='sm'
      >
        Brand
      </Text>
    </Center>
  )
}
MobileBrandFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
