import React from 'react'
import PropTypes from 'prop-types'
import { Circle, HStack, Box, Text } from '@chakra-ui/react'
import {
  IoCheckmarkSharp
} from 'react-icons/io5'

const StepConnector = ({ color }) => {
  return (
    <Box
      border='1px solid'
      borderColor={color}
      height='0'
      w={{ base: '80px', lg: '90px' }}
    />
  )
}
StepConnector.propTypes = {
  color: PropTypes.string.isRequired
}

const getCircleColors = (isActive, isSelected) => {
  let circleColor = 'blackAlpha.300'
  let circleBg = 'white'
  if (isActive && !isSelected) {
    circleColor = 'green.600'
  } else if (isSelected) {
    circleColor = 'teal.800'
    circleBg = 'teal.800'
  }

  return { circleColor, circleBg }
}

export const Step = ({
  label,
  isClickeable,
  onClick,
  isActive,
  isSelected,
  isLast
}) => {
  const { circleColor, circleBg } = getCircleColors(isActive, isSelected)
  const connectorColor = isSelected ? 'teal.800' : 'blackAlpha.300'

  return (
    <HStack spacing={0}>
      <Box position='relative' role='group'>
        <Circle
          size='16px'
          color='white'
          border='solid 3px'
          borderColor={circleColor}
          bg={circleBg}
          onClick={onClick}
          cursor={isClickeable ? 'pointer' : 'default'}
          _groupHover={isClickeable
            ? {
                background: 'teal.900',
                borderColor: 'teal.900'
              }
            : {}}
        >
          {isSelected ? <IoCheckmarkSharp data-test='progress-step-checked' /> : null}
        </Circle>
        <Text
          data-test='progress-step-label'
          fontSize='11px'
          fontWeight={isActive ? '600' : '500'}
          position='absolute'
          left='50%'
          top='16px'
          padding='7px'
          transform='translate(-50%, 0)'
          color={isActive ? 'black' : 'blackAlpha.600'}
          cursor={isClickeable ? 'pointer' : 'default'}
          onClick={onClick}
          userSelect='none'
          maxW='100px'
          whiteSpace='initial'
          textAlign='center'
          _groupHover={{
            color: isClickeable ? 'black' : 'none'
          }}
        >
          {label}
        </Text>
      </Box>
      {isLast ? null : <StepConnector color={connectorColor} />}
    </HStack>
  )
}
Step.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isClickeable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
}
