import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  GridItem
} from '@chakra-ui/react'

const Table = ({ children }) => {
  return (
    <Box
      border='1px solid'
      borderColor='providerDashboard.grayBorder'
      w='6xl'
      p='0'
      bg='white'
    >
      <Grid
        bg='providerDashboard.grayHeader'
        color='gray.600'
        fontSize='xs'
        fontWeight='700'
        templateColumns='repeat(12, 1fr)'
        py={3}
      >
        <GridItem colSpan={{ base: 2 }} pl={6}>
          PATIENT
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          CONFIRMATION NUMBER
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          ORDER DATE
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          FULFILLMENT TRACKER
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          REQUEST TYPE
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          REQUEST STATUS
        </GridItem>
      </Grid>

      {children}
    </Box>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired
}

export default Table
