import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import { useDisclosure, useBoolean } from '@chakra-ui/hooks'
import Analytics from '../services/Analytics'
import useRouteMatching from '../components/common/useRouteMatching'

const insuranceModalContext = createContext()
// Provider component that wraps your app and makes modal object ...
// ... available to any child component that calls useInsuranceModal().
export function InsuranceModalProvider ({ children }) {
  const modal = useInsuranceDisclosure()
  return (
    <insuranceModalContext.Provider value={modal}>
      {children}
    </insuranceModalContext.Provider>
  )
}
InsuranceModalProvider.propTypes = {
  children: PropTypes.node.isRequired
}
// Hook for child components to get the modal object ...
// ... and re-render when it changes.
export const useInsuranceModal = () => {
  return useContext(insuranceModalContext)
}
// Provider hook that creates modal object and handles state
function useInsuranceDisclosure () {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLandingModalOpened, setIsLandingModalOpened] = useBoolean()
  const [isUpdateModal, setIsUpdateModal] = useBoolean()
  const { isShopRoute, isProductRoute, isCartRoute } = useRouteMatching()

  const trackModal = ({ isOpening, isLandingModal }) => {
    let label

    if (isLandingModal) {
      label = 'landing'
    } else if (isShopRoute) {
      label = 'product listing page'
    } else if (isProductRoute) {
      label = 'product details page'
    } else if (isCartRoute) {
      label = 'cart review page'
    }

    Analytics.trackEvent({
      category: 'insurance',
      action: isOpening ? 'open modal' : 'close modal',
      label
    })
  }

  const handleOpenModal = ({ isLandingModal = false, isUpdateModal = false } = {}) => {
    trackModal({ isOpening: true, isLandingModal })

    if (isLandingModal) {
      setIsLandingModalOpened.on()
    }

    if (isUpdateModal) {
      setIsUpdateModal.on()
    }

    onOpen()
  }

  const handleCloseModal = () => {
    trackModal({ isOpening: false, isLandingModal: isLandingModalOpened })

    if (isLandingModalOpened) {
      setIsLandingModalOpened.off()
    }

    if (isUpdateModal) {
      setIsUpdateModal.off()
    }

    onClose()
  }

  return {
    handleOpenModal,
    handleCloseModal,
    isModalOpen: isOpen,
    isUpdateModal
  }
}
