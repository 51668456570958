import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Grid, GridItem } from '@chakra-ui/react'
import ProgressBar from '../ProgressBar'
import ProgressNavigation from '../ProgressNavigation'
import Filters from '../Filters'
import MatriarchToast from './MatriarchToast'

export const ProductListLayout = ({ children }) => (
  <Box
    flex='1'
    pt='0'
    pb={
      /** More pb was added in base to fill the space of <ProgressNavigation />
       *  which is fixed at the bottom */
      { base: '20', lg: '8' }
    }
  >
    <ProgressNavigation />
    <ProgressBar />
    <MatriarchToast />
    <Container maxW='7xl'>
      <Grid templateColumns='267px 1fr 1fr 1fr'>

        {/** Sidebar Area */}
        <GridItem
          as='nav'
          colSpan={1}
          borderRight='solid 1px'
          borderRightColor='blackAlpha.300'
          display={{
            base: 'none',
            lg: 'block'
          }}
        >
          <Filters.Desktop data-test='desktop-filters' />
        </GridItem>

        {/** Main Content Area */}
        <GridItem
          as='main'
          colSpan={{
            base: 4,
            lg: 3
          }}
        >
          {children}
        </GridItem>
      </Grid>
    </Container>
  </Box>
)
ProductListLayout.propTypes = {
  children: PropTypes.node
}
