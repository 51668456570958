import React from 'react'
import PropTypes from 'prop-types'
import { Icon, useDisclosure } from '@chakra-ui/react'
import {
  BsFileEarmarkCheckFill,
  BsFileEarmarkX
} from 'react-icons/bs'
import Constants from '../../common/constants'
import UsageDataModal from './UsageDataModal'

const UsageDataIcon = ({ fulfillment }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { usageDataStatus } = fulfillment

  const showGreenIcon = usageDataStatus === Constants.usageDataStatuses.uploaded

  return (
    <>
      <Icon
        data-test='usage-data-icon'
        cursor='pointer'
        boxSize='5'
        as={showGreenIcon ? BsFileEarmarkCheckFill : BsFileEarmarkX}
        color={showGreenIcon ? 'green.700' : 'red.700'}
        onClick={onOpen}
      />

      <UsageDataModal
        fulfillment={fulfillment}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

UsageDataIcon.propTypes = {
  fulfillment: PropTypes.object.isRequired
}

export default UsageDataIcon
