import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'react-query'
import { API } from '../../../services/Api'
import { BsFileEarmarkCheckFill } from 'react-icons/bs'

import {
  Box,
  Text,
  Icon,
  Menu,
  MenuList,
  MenuItem,
  MenuButton
} from '@chakra-ui/react'

const UsageDataDownloadIcon = ({ usageDataDocuments }) => {
  const downloadUrlMutation = useMutation(
    params => API.attachments.getDownloadUrl(params),
    {
      onSuccess: (data) => {
        window.open(data.data.downloadUrl, '_blank')
      }
    }
  )

  const handleDownloadClick = (uuid) => () => (
    downloadUrlMutation.mutate({ uuid })
  )

  const BlueIcon = (props) => (
    <Icon
      data-test='usage-data-download-icon'
      boxSize='5'
      as={BsFileEarmarkCheckFill}
      color='blue.500'
      cursor='pointer'
      {...props}
    />
  )

  if (usageDataDocuments.length === 1) {
    return (
      <BlueIcon
        onClick={handleDownloadClick(usageDataDocuments[0].uuid)}
      />
    )
  }

  const MenuItems = usageDataDocuments.map(({ filename, uuid }) => {
    return (
      <MenuItem
        key={uuid}
        onClick={handleDownloadClick(uuid)}
      >
        <Text data-test='usage-data-menu-item'>
          {filename}
        </Text>
      </MenuItem>
    )
  })

  return (
    <Box data-test='usage-data-download-menu'>
      <Menu>
        <MenuButton>
          <BlueIcon />
        </MenuButton>
        <MenuList
          maxWidth='350px'
          minWidth='40'
          wordBreak='break-word'
        >
          {MenuItems}
        </MenuList>
      </Menu>
    </Box>
  )
}

UsageDataDownloadIcon.propTypes = {
  usageDataDocuments: PropTypes.array.isRequired
}

export default UsageDataDownloadIcon
