import React from 'react'
import * as yup from 'yup'
import Analytics from '../../services/Analytics'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import Mixpanel from '../../services/Mixpanel'
import { ExcludeFromRecording } from '../../services/RecordingService'
import { FormControl } from '../common/FormElements'
import { phoneNumberRegex } from '../common/utils'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  chakra, Input, VStack, Checkbox, Button, Link, Text
} from '@chakra-ui/react'

export const PatientContactForm = ({
  contactMutation,
  errorMessage,
  isBreastPump,
  onDoThisLaterClick,
  otherInsuranceCarrierSelected,
  selectedInsuranceCarrier,
  showContactFields
}) => {
  const schema = yup.object().shape({
    checkbox: isBreastPump && yup.bool().oneOf([true]),
    email: showContactFields && yup.string().email().required(),
    insuranceCarrierName: otherInsuranceCarrierSelected && yup.string().required(),
    phoneNumber: showContactFields && yup.string().matches(
      phoneNumberRegex,
      {
        excludeEmptyString: true
      }).required(),
    bestTimeToCall: !isBreastPump && yup.string().required(),
    physicianName: showContactFields && yup.string().required()
  })

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const onSubmit = ({
    email,
    phoneNumber,
    bestTimeToCall,
    physicianName,
    insuranceCarrierName
  }) => {
    if (otherInsuranceCarrierSelected) {
      trackOtherCarrier(insuranceCarrierName)
    }

    const carrierName = insuranceCarrierName || selectedInsuranceCarrier

    Mixpanel.trackEvent(
      'Click Contact Me CTA',
      { insuranceCarrier: carrierName }
    )

    contactMutation.mutate({
      patient: {
        email,
        phoneNumber,
        ...(bestTimeToCall && { bestTimeToCall }),
        physicianName,
        insuranceCarrierName: carrierName
      }
    })
  }

  const trackOtherCarrier = (insuranceCarrierName) => {
    Analytics.trackEvent({
      category: 'insurance',
      action: 'other insurance carrier selected',
      label: insuranceCarrierName
    })
  }

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} w='100%'>
      <VStack spacing={6}>
        {errorMessage}

        {isBreastPump && (
          <Checkbox
            spacing={4}
            data-test='insurance-modal-contact-checkbox'
            {...register('checkbox')}
          >
            <Text
              color='gray.600'
              fontSize='sm'
            >
              Yes, please contact me to help me find a pump covered by my insurance.
            </Text>
          </Checkbox>
        )}

        {otherInsuranceCarrierSelected && (
          <FormControl
            errorMessage={!!errors?.insuranceCarrierName?.message}
            isRequired
            label='Insurance Carrier Name'
          >
            <ExcludeFromRecording>
              <Input
                data-test='insurance-carrier-name-input'
                id='insuranceCarrierName'
                fontSize='sm'
                {...register('insuranceCarrierName')}
              />
            </ExcludeFromRecording>
          </FormControl>
        )}

        {showContactFields && (
          <FormControl
            errorMessage={!!errors?.email?.message}
            isRequired
            label='Patient Email'
          >
            <ExcludeFromRecording>
              <Input
                data-test='email-input'
                id='email'
                fontSize='sm'
                {...register('email')}
              />
            </ExcludeFromRecording>
          </FormControl>
        )}

        {showContactFields && (
          <FormControl
            errorMessage={!!errors?.phoneNumber?.message}
            label='Patient Phone Number'
            isRequired
          >
            <ExcludeFromRecording>
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputMask
                    mask='(999) 999-9999'
                    onChange={onChange}
                    value={value}
                  >
                    {(inputProps) => (
                      <Input
                        data-test='phone-number-input'
                        id='phoneNumber'
                        fontSize='sm'
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </ExcludeFromRecording>
          </FormControl>
        )}

        {!isBreastPump && (
          <FormControl
            errorMessage={!!errors?.bestTimeToCall?.message}
            isRequired
            label='Best Time To Call'
          >
            <Input
              data-test='best-time-to-call-input'
              id='bestTimeToCall'
              fontSize='sm'
              {...register('bestTimeToCall')}
            />
          </FormControl>
        )}

        {showContactFields && (
          <FormControl
            errorMessage={!!errors?.physicianName?.message}
            isRequired
            label='Physician Name'
          >
            <Input
              data-test='physician-name-input'
              id='physicianName'
              fontSize='sm'
              {...register('physicianName')}
            />
          </FormControl>
        )}

      </VStack>
      <VStack spacing={4}>
        <Button
          width='full'
          type='submit'
          colorScheme='green'
          disabled={!isValid || isSubmitting || contactMutation.isLoading}
          isLoading={contactMutation.isLoading}
          data-test='insurance-modal-contact-button'
          mt='2rem'
        >
          {isBreastPump ? 'Contact Me' : 'Submit Request'}
        </Button>
        <Link
          color='green.600'
          onClick={onDoThisLaterClick}
          data-test='insurance-modal-contact-continue-button'
        >
          {isBreastPump ? 'No, Continue to Shop' : 'Shop Without Insurance'}
        </Link>
      </VStack>
    </chakra.form>
  )
}

PatientContactForm.propTypes = {
  contactMutation: PropTypes.object.isRequired,
  errorMessage: PropTypes.element,
  isBreastPump: PropTypes.bool,
  onDoThisLaterClick: PropTypes.func.isRequired,
  otherInsuranceCarrierSelected: PropTypes.bool.isRequired,
  selectedInsuranceCarrier: PropTypes.string,
  showContactFields: PropTypes.bool.isRequired
}

export default PatientContactForm
