import React from 'react'
import PropTypes from 'prop-types'
import { API } from '../../services/Api'
import { useQuery } from 'react-query'
import { useAuth } from '../../context/use-auth'

import {
  Box,
  SimpleGrid,
  Text
} from '@chakra-ui/react'

const ProviderSignatureCard = () => {
  const auth = useAuth()

  const { isLoading, data } = useQuery(
    'fetchProvider',
    async () => {
      const providerUuid = auth.user.role.resourceUuid

      const { data } = await API.providers.single(providerUuid)

      return data
    },
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const ShowcaseText = ({ text, ...props }) => {
    const displayText = isLoading ? '' : text
    return <Text color='gray.600' {...props}>{displayText}</Text>
  }
  ShowcaseText.propTypes = {
    text: PropTypes.string
  }

  const ShowcaseLabel = (props) => (
    <Text color='gray.500' fontWeight='semibold'>{props.text}</Text>
  )
  ShowcaseLabel.propTypes = {
    text: PropTypes.string
  }

  return (
    <Box bg='gray.50' width='100%' p={5} borderRadius={4}>
      <SimpleGrid
        width='100%'
        columns={{ base: 1, md: 2 }}
        spacing={3}
      >
        <ShowcaseLabel text='Provider Name' />
        <ShowcaseText text={`${data?.firstName} ${data?.lastName}`} />
        <ShowcaseLabel text='NPI' />
        <ShowcaseText text={data?.npi} data-test='provider-npi' />
      </SimpleGrid>
    </Box>
  )
}

export default ProviderSignatureCard
