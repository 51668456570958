import * as React from 'react'
import PropTypes from 'prop-types'
import { Stack, Box, Container, Center, Link } from '@chakra-ui/react'
import Constants from '../../common/constants'

export const FooterLink = ({ label, ...props }) => (
  <Link
    color='blackAlpha.600'
    fontSize='xs'
    py={{ base: '2', lg: '0' }}
    _hover={{
      color: 'blackAlpha.800'
    }}
    {...props}
  >
    {label}
  </Link>
)
FooterLink.propTypes = {
  label: PropTypes.string
}

const FooterLinks = () => (
  <Stack
    direction={{ base: 'column', lg: 'row' }}
    w={{ base: '100%', lg: 'initial' }}
    spacing={{ base: '4', lg: '12' }}
  >
    <FooterLink
      href={Constants.doorbell.termsOfServiceLink}
      label={Constants.doorbell.termsOfService}
      isExternal
    />
    <FooterLink
      href={Constants.doorbell.privacyPolicyLink}
      label={Constants.doorbell.privacyPolicy}
      isExternal
    />
  </Stack>
)

const FooterContainer = (props) => {
  return (
    <Box
      as='footer'
      role='contentinfo'
      {...props}
    >
      <Container maxW='7xl'>
        <Center
          borderTop={{ base: 'none', lg: 'solid 1px' }}
          borderTopColor={{ base: 'none', lg: 'gray.300' }}
          px={{ base: 2, lg: 8 }}
          py={{ base: 0, lg: 8 }}
          mt={{ base: 4, lg: 8 }}
        >
          <FooterLinks />
        </Center>
      </Container>
    </Box>
  )
}

const DesktopFooter = () => (
  <FooterContainer
    display={{
      base: 'none',
      lg: 'block'
    }}
  />
)

const MobileFooter = () => (
  <FooterContainer
    display={{
      lg: 'none'
    }}
  />
)

export const Footer = {
  Mobile: MobileFooter,
  Desktop: DesktopFooter
}
