import React from 'react'
import PropTypes from 'prop-types'
import { Button, Text, Image, VStack, Stack, StackDivider } from '@chakra-ui/react'
import * as CustomerSupportService from '../../../services/CustomerSupportService'
import { useAuth } from '../../../context/use-auth'

const ItemList = ({
  orderConfirmationNumber,
  items,
  showCtas = false,
  isMobile = false
}) => {
  const { user } = useAuth()

  const handleGetHelpClick = ({ productTitle }) => {
    CustomerSupportService.prefillContactForm({
      name: user.fullName,
      email: user.email,
      subject: `I need help with: ${productTitle}`,
      description: `My order number is: ${orderConfirmationNumber}`
    })

    CustomerSupportService.openWidget()
  }

  return (
    <VStack
      spacing='0'
      divider={<StackDivider borderColor='gray.200' />}
      align='left'
    >
      {items.map(({ firstImageUrl, title, manufacturerName, quantity }, i) => {
        return (
          <Stack
            key={i}
            direction={isMobile ? 'column' : 'row'}
            px='8'
            py='4'
            spacing='3'
            justifyContent='space-between'
          >
            <Image
              boxSize='100px'
              fit='contain'
              src={firstImageUrl}
            />
            <VStack align='left' spacing='1' w='100%'>
              <Text
                fontSize='lg'
                fontWeight='bold'
              >
                {title}
              </Text>
              <Text color='gray.600' fontSize='sm'>
                {manufacturerName}
              </Text>
              <Text color='gray.600' fontSize='sm'>
                {'Quantity: ' + quantity}
              </Text>
            </VStack>
            {showCtas && (
              <VStack spacing='4' w='220px'>
                <Button
                  w='100%'
                  bg='blue.500'
                  color='white'
                  size='sm'
                  _hover={{
                    background: 'blue.600',
                    color: 'white',
                    textDecoration: 'none'
                  }}
                  _active={{
                    bg: 'blue.600'
                  }}
                >
                  Write a Product Review
                </Button>
                <Button
                  size='sm'
                  w='100%'
                  variant='outline'
                  onClick={() => handleGetHelpClick(
                    { productTitle: title }
                  )}
                >
                  Get Help
                </Button>
              </VStack>)}
          </Stack>
        )
      })}
    </VStack>
  )
}

ItemList.propTypes = {
  items: PropTypes.array,
  showCtas: PropTypes.bool,
  isMobile: PropTypes.bool,
  orderConfirmationNumber: PropTypes.string
}

export default ItemList
