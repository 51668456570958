import React from 'react'
import CardContainer from '../common/CardContainer'
import InputMask from 'react-input-mask'
import {
  useBoolean,
  Text,
  Button,
  Input
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import Analytics from '../../services/Analytics'
import { FormControl } from '../common/FormElements'
import { Spinner } from '../common/Spinner'
import { useCode } from './useCode'
import { ExcludeFromRecording } from '../../services/RecordingService'

const schema = yup.object().shape({
  referralCode: yup
    .string()
    .required('Patient referral code is required.')
})

const ContinueButton = (props) => (
  <Button
    disabled
    mt='75px'
    mb='30px'
    bg='green.800'
    color='white'
    w='100%'
    borderRadius='0'
    size='md'
    {...props}
  >
    Continue
  </Button>
)

const Gate = () => {
  const location = useLocation()
  const [hasPressedAKey, setHasPressedAKey] = useBoolean()
  const {
    validateCode,
    isValidating,
    codeErrorMessage
  } = useCode()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const errorMessage = codeErrorMessage ||
    errors?.referralCode?.message ||
    location.state?.errorMessage

  const onValidatedSubmit = async (data) => {
    Analytics.trackEvent({
      category: 'referral code',
      action: 'submit code'
    })

    const code = data.referralCode
    const codeWithoutHypen = code?.replace('-', '')
    await validateCode(codeWithoutHypen)
  }

  const handleKeyDown = () => {
    if (!hasPressedAKey) {
      setHasPressedAKey.on()

      Analytics.trackEvent({
        category: 'referral code',
        action: 'enter code'
      })
    }
  }

  return (
    <CardContainer heading='Save up to 100% on your medical equipment'>
      {isValidating
        ? (
          <Spinner labelText='Assembling your personalized treatment plan' />
          )
        : (
          <form
            id='referralCodeForm'
            onSubmit={handleSubmit(onValidatedSubmit)}
          >
            <Text fontSize='sm' color='gray.600'>
              Enter your referral code to shop the best prices on
              medical equipment prescribed by your provider.
            </Text>
            <FormControl
              color='gray.600'
              errorMessage={errorMessage}
              label='Patient referral code'
              labelProps={{ mb: 0 }}
              mt='8'
              isRequired
            >
              <ExcludeFromRecording>
                <Controller
                  name='referralCode'
                  defaultValue={location.state?.code}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputMask
                      mask='***-***'
                      maskChar='X'
                      onChange={onChange}
                      value={value}
                    >
                      {(inputProps) => (
                        <Input
                          id='referralCode'
                          placeholder='XXX-XXX'
                          fontSize='sm'
                          onKeyDown={handleKeyDown}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </ExcludeFromRecording>
            </FormControl>
            <ContinueButton
              disabled={isValidating}
              type='submit'
            />
          </form>
          )}
    </CardContainer>
  )
}

export default Gate
