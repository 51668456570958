import React from 'react'
import { Link, useMatch } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { isAuthenticated } from '../../../context/use-auth'

export const useCtaConfig = () => {
  const isProviderLandingPage = useMatch('/providers')
  const isLoggedIn = isAuthenticated()

  let CtaText = 'Shop'
  let CtaDestination = '/shop'

  if (isProviderLandingPage) {
    CtaText = 'Get Started'

    if (isLoggedIn) {
      CtaDestination = '/'
    } else {
      CtaDestination = '/providers/signup'
    }
  }

  return { CtaText, CtaDestination }
}

export const CtaButton = (props) => {
  const { CtaText, CtaDestination } = useCtaConfig()

  return (
    <Button
      as={Link}
      to={CtaDestination}
      size='lg'
      color='white'
      bg='green.600'
      variant='solid'
      _hover={{
        color: 'white',
        background: 'green.700'
      }}
      {...props}
    >
      {CtaText}
    </Button>
  )
}
