import PropTypes from 'prop-types'

import {
  Box,
  Heading,
  Flex,
  Center
} from '@chakra-ui/react'
import React from 'react'

const Card = ({ children }) => {
  const cardProps = {
    w: {
      base: '100%',
      md: '577px'
    },
    px: {
      base: '15px',
      md: '100px'
    },
    py: {
      base: '5px',
      md: '60px'
    },
    shadow: {
      base: 'none',
      md: 'base'
    },
    bg: 'white',
    rounded: {
      md: 'sm'
    }
  }
  return <Box {...cardProps}>{children}</Box>
}
Card.propTypes = {
  children: PropTypes.node.isRequired
}

export default function CardContainer (props) {
  const { footer, heading, children } = props
  return (
    <Flex minHeight='100vh' height='100%' direction='column'>
      <Flex
        direction='column'
        grow='1'
      >
        <Center w='100%' mt={{ base: 4, md: '100px' }} mb={{ base: 4, md: '100px' }}>
          <Card>
            <Heading size='lg' mb='8' textAlign='center'>
              {heading}
            </Heading>
            {children}
            {footer}
          </Card>
        </Center>
      </Flex>
    </Flex>
  )
}

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  heading: PropTypes.string.isRequired
}
