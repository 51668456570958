import React from 'react'
import PropTypes from 'prop-types'
import { API } from '../../services/Api'
import { useQuery } from 'react-query'

import {
  Box,
  Heading,
  Text
} from '@chakra-ui/react'

const ReferralCode = ({ setFormValue }) => {
  const fetchConsultation = async () => {
    const { data } = await API.consultations.newReferralCode()

    return data
  }

  const { data } = useQuery(
    'newReferralCode',
    fetchConsultation,
    {
      cacheTime: 0,
      retry: 0,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )
  let formattedReferralCode
  const referralCode = data?.referralCode

  if (referralCode) {
    setFormValue(referralCode)
    formattedReferralCode = referralCode.replace(/(\S{3})(\S{3})/, '$1-$2')
  }

  return (
    <Box
      bg='gray.50'
      borderRadius={4}
      display={{ base: 'block', md: 'inline-block' }}
      padding='3'
      textAlign='center'
    >
      <Heading size='sm' fontWeight='semibold' color='gray.600'>
        Referral Code

        <Text id='referralCode' as='span' ml='3' color='teal.400'>
          {formattedReferralCode}
        </Text>
      </Heading>
    </Box>
  )
}

ReferralCode.propTypes = {
  setFormValue: PropTypes.func.isRequired
}

export default ReferralCode
