export default {
  attachmentModalTypes: {
    patientPackets: 'patientPackets',
    insuranceCard: 'insuranceCard',
    usageData: 'usageData'
  },
  doorbell: {
    faxEmail: process.env.FAX_EMAIL,
    faxEmailMailTo: `mailto:${process.env.FAX_EMAIL}`,
    faxNumber: {
      formatted: process.env.FAX_NUMBER_FORMATTED,
      unformatted: process.env.FAX_NUMBER_UNFORMATTED
    },
    phoneNumber: {
      formatted: process.env.COMPANY_PHONE_NUMBER_FORMATTED,
      unformatted: process.env.COMPANY_PHONE_NUMBER_UNFORMATTED
    },
    supportEmail: 'support@doorbellhealth.com',
    supportEmailMailTo: 'mailto:support@doorbellhealth.com',
    termsOfService: 'Terms of Service',
    termsOfServiceLink: 'https://doorbellhealth.notion.site/doorbellhealth/Doorbell-Health-Terms-of-Service-003dd0f207af481b9f47c15dcbfd2ead',
    privacyPolicy: 'Privacy',
    privacyPolicyLink: 'https://doorbellhealth.notion.site/doorbellhealth/Doorbell-Health-Privacy-Policy-8807e9991c804aa8b29341055c3b3b1d'
  },
  packetTypes: {
    prescription: {
      type: 'prescription'
    },
    sleepStudy: {
      type: 'sleep_study'
    },
    consultationNotes: {
      type: 'consultation_notes'
    },
    bloodGasStudy: {
      type: 'blood_gas_study_(bgs)'
    },
    exerciseTest: {
      type: 'exercise_test'
    },
    faxBundle: {
      type: 'fax_bundle'
    }
  },
  productVertical: {
    breastPump: 'Breast Pump',
    respiratoryCare: 'Respiratory Care'
  },
  usageDataStatuses: {
    ineligible_for_request: 'ineligible_for_request',
    unrequested: 'unrequested',
    requested: 'requested',
    uploaded: 'uploaded'
  }
}
