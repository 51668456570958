import mixpanel from 'mixpanel-browser'

const MIXPANEL_ENABLED = [
  process.env.MIXPANEL_TOKEN,
  process.env.MIXPANEL_PROJECT_ID,
  process.env.MIXPANEL_WORKSPACE_ID
].every(value => value !== undefined)

const MIXPANEL_DEBUG = process.env.MIXPANEL_DEBUG === 'true'

// Mixpanel Api Docs: https://developer.mixpanel.com/docs/javascript
const initialize = () => {
  if (!MIXPANEL_ENABLED) return

  // Enable debug mode by setting the environment variable
  // MIXPANEL_DEBUG=true

  // cross_subdomain_cookie should be disabled for herokuapp domain
  // https://help.mixpanel.com/hc/en-us/articles/115004507486-Mixpanel-and-herokuapp-com-subdomains-and-other-common-top-level-domains-
  const isHerokuappDomain = window.location.hostname.endsWith('herokuapp.com')

  const options = {
    debug: MIXPANEL_DEBUG,
    cross_subdomain_cookie: !isHerokuappDomain
  }

  mixpanel.init(process.env.MIXPANEL_TOKEN, options)
}

const identify = ({ uniqueId, userProperties }) => {
  if (MIXPANEL_DEBUG) {
    console.log(
      'Mixpanel identify:',
      { uniqueId, userProperties }
    )
  }

  if (!MIXPANEL_ENABLED) return

  mixpanel.identify(uniqueId)

  if (userProperties) {
    mixpanel.people.set(userProperties)
  }
}

const trackEvent = (name, params = {}) => {
  if (MIXPANEL_DEBUG) {
    console.log(
      'Mixpanel event:',
      { name, params: { ...params } }
    )
  }

  if (!MIXPANEL_ENABLED) return

  mixpanel.track(name, params)
}

const trackEventWithProduct = (name, product, additionalParams = {}) => {
  trackEvent(name, {
    ...additionalParams,
    productCategory: product.hcpcsCode.productCategory.name,
    hcpcsCode: product.hcpcsCode.code,
    productName: product.name,
    manufacturer: product.manufacturer
  })
}

const reset = () => {
  if (!MIXPANEL_ENABLED) return

  mixpanel.reset()
}

const Mixpanel = {
  initialize,
  identify,
  trackEvent,
  trackEventWithProduct,
  reset
}

export default Mixpanel
