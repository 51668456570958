import React from 'react'
import PropTypes from 'prop-types'
import AttachmentsModal from './AttachmentsModal'
import {
  BsFileEarmarkCheckFill,
  BsFileEarmarkX
} from 'react-icons/bs'

import {
  Icon,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import Tooltip from '../common/Tooltip'

const Attachments = ({ consultation, packetType }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { uploadEnabled, uploaded } = packetType

  const color = () => {
    if (uploaded) {
      return 'green.700'
    } else if (uploadEnabled) {
      return 'red.700'
    } else {
      return 'gray.500'
    }
  }
  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })
  const toast = useToast()
  const noDocRequired =
    'No documents required. The patient has purchased equipment without insurance.'

  const handleOnClick = () => {
    if (uploadEnabled === true) {
      return onOpen()
    } else {
      if (isMobile) {
        toast({
          title: noDocRequired,
          status: 'info',
          position: 'top',
          isClosable: true
        })
      } else {
        return null
      }
    }
  }

  return (
    <>
      <Tooltip
        isDisabled={uploadEnabled || isMobile}
        placement='right'
        hasArrow
        label={noDocRequired}
        bg='shop.B4'
        fontSize='xs'
        color='blue.600'
        rounded='md'
        width='180px'
        p={3}
      >
        <span>
          <Icon
            data-test={`attachments-icon-${packetType.type}`}
            cursor={uploadEnabled ? 'pointer' : 'not-allowed'}
            boxSize='5'
            as={uploaded ? BsFileEarmarkCheckFill : BsFileEarmarkX}
            color={color}
            onClick={handleOnClick}
          />
        </span>
      </Tooltip>

      <AttachmentsModal
        consultation={consultation}
        packetType={packetType}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

Attachments.propTypes = {
  consultation: PropTypes.object.isRequired,
  packetType: PropTypes.object.isRequired
}

export default Attachments
