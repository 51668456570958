import React, { useEffect, useState } from 'react'
import { usePatientSession } from '../../../context/use-patient-session'
import {
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Heading,
  HStack,
  Center,
  VStack
} from '@chakra-ui/react'
import ActionItems from './ActionItems'
import ProgressIndicators from './ProgressIndicators'
import CategoryButton from './CategoryButton'
import kebabCase from 'lodash/kebabCase'
import Mixpanel from '../../../services/Mixpanel'
import CpapImage from '../../../../assets/images/onboarding/cpap.png'

const StepThree = () => {
  const patientSession = usePatientSession()
  const currentProductVertical = patientSession.getProductVertical()
  const [selectedCategory, setSelectedCategory] = useState(null)
  const currentStep = patientSession.getCurrentOnboardingStep()

  const productCategoriesWithoutOxygenCategories = currentProductVertical.productCategories.filter(
    (category) => !category.isAccessory && category.isActive === true
  )

  const handleClick = (category) => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Click Product Vertical',
      { category: category.name }
    )
    setSelectedCategory(category)
  }

  const handleNextAction = () => {
    patientSession.setProductCategory(selectedCategory)
    patientSession.setOnboardingStep({ step: currentStep + 1 })
  }

  useEffect(() => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Arrive at product category selection screen'
    )
  }, [])

  // When the vertical has only one category, we automatically select it and move to the next step
  useEffect(() => {
    if (productCategoriesWithoutOxygenCategories?.length === 1) {
      const category = productCategoriesWithoutOxygenCategories[0]
      setSelectedCategory(category)
      patientSession.setProductCategory(category)
      patientSession.setOnboardingStep({ step: currentStep + 1 })
    }
  }, [productCategoriesWithoutOxygenCategories, currentStep, patientSession])

  return (
    <>
      <ModalBody>
        <Grid templateColumns='repeat(12, 1fr)'>
          <GridItem colSpan={{ md: 6, base: 12 }} colStart={{ md: 4 }}>
            <Heading color='white' textAlign='center' pt={12} size='2xl'>
              Which item do you need?
            </Heading>
          </GridItem>
          <GridItem colSpan={12} pt={12}>
            <Center>
              <HStack spacing={4} overflowX='auto'>
                {productCategoriesWithoutOxygenCategories.map((category) => (
                  <CategoryButton
                    key={category.uuid}
                    src={CpapImage}
                    alt={category.name}
                    title={category.name}
                    cursor='pointer'
                    isSelected={category.uuid === selectedCategory?.uuid}
                    onClick={() => handleClick(category)}
                    data-test={`product-category-${kebabCase(category.name)}`}
                    data-selected={category.uuid === selectedCategory?.uuid}
                  />
                ))}
              </HStack>
            </Center>
          </GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter alignSelf={{ base: 'center' }}>
        <VStack>
          <ActionItems
            buttonCopy='Next'
            buttonAction={handleNextAction}
            buttonIsDisabled={!selectedCategory?.uuid}
          />
          <ProgressIndicators amount={4} />
        </VStack>
      </ModalFooter>
    </>
  )
}

StepThree.propTypes = {}

export default StepThree
