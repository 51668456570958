import React from 'react'
import PropTypes from 'prop-types'
import { useTheme, NumberInput, NumberInputField, FormControl, FormHelperText } from '@chakra-ui/react'

export const PriceInput = ({
  id,
  helperText,
  onChange,
  value,
  isInvalid,
  ...props
}) => {
  const theme = useTheme()

  return (
    <FormControl
      id={id}
      isInvalid={isInvalid}
    >
      <FormHelperText fontSize='12px' mb='2px' mt='0'>{helperText}</FormHelperText>
      <NumberInput
        size='md'
        value={value}
        onChange={onChange}
      >
        <NumberInputField
          w='full'
          type='number'
          min='0.00'
          inputMode='numeric'
          borderRadius='sm'
          fontSize='sm'
          h='40px'
          _hover={{ border: `solid 1px ${theme.colors.blackAlpha['800']}` }}
          borderColor='blackAlpha.600'
          {...props}
        />
      </NumberInput>
    </FormControl>
  )
}
PriceInput.propTypes = {
  id: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isInvalid: PropTypes.bool
}
