/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useBoolean } from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
/** NOTE: This hook comes from the premium shell theme
 *  https://pro.chakra-ui.com/components/application-ui/shells */
export const useMobileMenuState = (
  { mobileBreakpointValue } = { mobileBreakpointValue: '(max-width: 62em)' }
) => {
  const [isMenuOpen, actions] = useBoolean()
  /**
   * Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
   * Result: We'll close the menu
   */

  const isMobileBreakpoint = useMediaQuery({ query: mobileBreakpointValue })
  React.useEffect(() => {
    if (isMobileBreakpoint === false && isMenuOpen) {
      actions.off()
    }
  }, [isMobileBreakpoint, actions])

  return {
    isMenuOpen,
    ...actions
  }
}
