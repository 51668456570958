import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { IoBagHandleOutline, IoInformationCircleOutline, IoPersonOutline } from 'react-icons/io5'
import { useAuth } from '../../../context/use-auth'
import { usePatientSession } from '../../../context/use-patient-session'

export const MobilePatientAuth = ({ toggleMenu }) => {
  const patientSession = usePatientSession()
  const auth = useAuth()
  const navigate = useNavigate()

  const NavItem = ({ label, path, icon, onClick, ...props }) => (
    <Box
      as={RouterLink}
      to={path}
      display='block'
      py='3'
      px='3'
      fontWeight='medium'
      borderBottom='solid 1px'
      borderBottomColor='gray.300'
      _hover={{
        bg: 'whiteAlpha.200'
      }}
      _activeLink={{
        bg: 'blackAlpha.300',
        color: 'white'
      }}
      onClick={onClick}
      {...props}
    >
      <HStack spacing='3'>
        <Icon as={icon} boxSize='4' color='subtle' />
        <Text fontSize='sm'>{label}</Text>
      </HStack>
    </Box>
  )

  MobilePatientAuth.propTypes = {
    toggleMenu: PropTypes.func.isRequired
  }

  NavItem.propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    onClick: PropTypes.func
  }

  const handleSignOutClick = async () => {
    const response = await auth.signout()
    const { sessionId } = response.data
    await patientSession.resetStateUponlogout({
      sessionId
    })
    toggleMenu()
    navigate('/patients/signin')
  }

  return (
    <Accordion allowToggle defaultIndex={[0]}>
      <AccordionItem border='0px'>
        <AccordionButton
          p={0}
          borderBottom='solid 1px'
          borderBottomColor='gray.300'
        >
          <Box
            display='block'
            px='3'
            py='6'
            w='full'
            textAlign='left'
            fontWeight='medium'
            _hover={{
              bg: 'whiteAlpha.200'
            }}
            _activeLink={{
              bg: 'blackAlpha.300',
              color: 'white'
            }}
          >
            Account
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p={0}>
          <NavItem
            label='Order History'
            path='/order_history'
            icon={IoBagHandleOutline}
            onClick={toggleMenu}
          />
        </AccordionPanel>
        <AccordionPanel p={0}>
          <NavItem
            label='Insurance Information'
            path='/insurance_information'
            icon={IoInformationCircleOutline}
            onClick={toggleMenu}
          />
        </AccordionPanel>
        <AccordionPanel p={0}>
          <NavItem
            label='Account Settings'
            path='/account_settings'
            icon={IoPersonOutline}
            onClick={toggleMenu}
          />
        </AccordionPanel>
        <AccordionPanel p={0}>
          <NavItem
            label='Sign Out'
            path='/patients/signin'
            as='span'
            icon={BsArrowRight}
            onClick={handleSignOutClick}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>

  )
}

export const DesktopPatientAuth = ({ ...props }) => {
  const patientSession = usePatientSession()
  const auth = useAuth()
  const user = auth.user
  const navigate = useNavigate()

  const NavItem = ({ label, path, icon, onClick, ...props }) => (
    <Box
      as={RouterLink}
      to={path}
      display='block'
      fontWeight='medium'
      py={2}
      px={3}
      w='full'
      _hover={{
        bg: 'whiteAlpha.200'
      }}
      _activeLink={{
        bg: 'blackAlpha.300',
        color: 'white'
      }}
      onClick={onClick}
      {...props}
    >
      <HStack spacing='3'>
        <Icon as={icon} boxSize='4' color='subtle' />
        <Text fontSize='sm'>{label}</Text>
      </HStack>
    </Box>
  )

  NavItem.propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    onClick: PropTypes.func
  }

  const handleSignOutClick = async () => {
    const response = await auth.signout()
    const { sessionId } = response.data
    await patientSession.resetStateUponlogout({
      sessionId
    })
    navigate('/patients/signin')
  }

  return (
    <Menu data-test='auth-menu' gutter={0}>
      <MenuButton data-test='auth-menu-button' padding={2}>
        <Avatar boxSize='9' name={user.fullName} {...props} />
      </MenuButton>
      <MenuList minWidth='240px' py={0}>
        <Box
          borderBottom='1px solid'
          borderColor='gray.100'
          py={6}
          px={3}
          textAlign='center'
        >
          <Text fontWeight='medium' fontSize='md'>{user.fullName}</Text>
          <Text
            fontSize={user.fullName ? 'sm' : 'md'}
            fontWeight={user.fullName ? 'regular' : 'medium'}
          >
            {user.email}
          </Text>
        </Box>
        <MenuItem p={0}>
          <NavItem
            label='Order History'
            path='/order_history'
            icon={IoBagHandleOutline}
          />
        </MenuItem>
        <MenuItem p={0}>
          <NavItem
            label='Insurance Information'
            path='/insurance_information'
            icon={IoInformationCircleOutline}
          />
        </MenuItem>
        <MenuItem p={0}>
          <NavItem
            label='Account Settings'
            path='/account_settings'
            icon={IoPersonOutline}
          />
        </MenuItem>
        <MenuItem p={0}>
          <NavItem
            label='Sign Out'
            path='/patients/signin'
            as='span'
            icon={BsArrowRight}
            onClick={handleSignOutClick}
            data-test='sign-out'
          />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const PatientAuth = {
  Desktop: DesktopPatientAuth,
  Mobile: MobilePatientAuth
}
