import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InnerImageZoom from 'react-inner-image-zoom'
import { Box, Flex, Stack } from '@chakra-ui/react'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import Analytics from '../../../services/Analytics'
import Thumbnail from './Thumbnail'

const Images = ({ imageUrls, productUuid }) => {
  const initialIndex = 0
  const [activeIndex, setActiveIndex] = useState(initialIndex)

  const trackThumbnailClick = (index) => {
    if (index !== activeIndex) {
      Analytics.trackEvent({
        category: 'product image',
        action: 'toggle product images',
        label: 'product details page',
        customParams: {
          product_uuid: productUuid
        }
      })
    }
  }

  const handleThumbnailClick = (index) => {
    trackThumbnailClick(index)
    setActiveIndex(index)
  }

  return (
    <Flex direction={{ base: 'column-reverse', lg: 'row' }}>
      <Stack
        as={Flex}
        overflowX='auto'
        direction={{ base: 'row', lg: 'column' }}
        mr='4'
        spacing='3'
        w={{ base: 'auto', lg: '140px' }}
        wrap={{ base: 'nowrap', lg: 'wrap' }}
      >
        {imageUrls.map((imageUrl, index) => {
          return (
            <Thumbnail
              key={index}
              isActive={activeIndex === index}
              imageUrl={imageUrl}
              onClick={() => handleThumbnailClick(index)}
            />
          )
        })}
      </Stack>
      <Box
        alignItems='center'
        flex='1'
        minW={{ base: 'auto', lg: '400px' }}
        data-test='active-image'
      >
        <InnerImageZoom
          key={imageUrls[activeIndex]}
          mb={3}
          src={imageUrls[activeIndex]}
        />
      </Box>
    </Flex>
  )
}

Images.propTypes = {
  imageUrls: PropTypes.array.isRequired,
  productUuid: PropTypes.string.isRequired
}

export default Images
