import React from 'react'
import { HStack } from '@chakra-ui/react'
import { useProgress } from '../ProgressBar/useProgress'
import { Wrapper } from './Wrapper'
import { NextButton } from './NextButton'
import { Details } from './Details'

const ProgressNavigation = () => {
  const {
    currentStep
  } = useProgress()

  if (!currentStep) return null

  return (
    <Wrapper>
      <HStack justify='space-between'>
        <Details />
        <NextButton />
      </HStack>
    </Wrapper>
  )
}

export default ProgressNavigation
