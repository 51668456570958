import { attachments } from './attachments'
import { auth } from './auth'
import { cart } from './cart'
import { cartItems } from './cartItems'
import { consultations } from './consultations'
import { fulfillments } from './fulfillments'
import { hcpcsCodes } from './hcpcsCodes'
import { icd10 } from './icd10'
import { insuranceCarriers } from './insuranceCarriers'
import { insuranceStatuses } from './insuranceStatuses'
import { orders } from './orders'
import { patients } from './patients'
import { productVariants } from './productVariants'
import { productVerticals } from './productVerticals'
import { providerRegistrations } from './providerRegistrations'
import { providers } from './providers'
import { providerTypes } from './providerTypes'
import { termsOfServiceAgreements } from './termsOfServiceAgreements'
import { shipments } from './shipments'

export const API = {
  attachments,
  auth,
  cart,
  cartItems,
  consultations,
  fulfillments,
  hcpcsCodes,
  icd10,
  insuranceCarriers,
  insuranceStatuses,
  orders,
  patients,
  providers,
  providerRegistrations,
  providerTypes,
  productVariants,
  productVerticals,
  termsOfServiceAgreements,
  shipments
}
