import React from 'react'
import { Flex, Container } from '@chakra-ui/react'
import { useMobileMenuState } from '../../common/useMobileMenuState'
import Logo from '../../common/SvgLogo'
import NewOrderButton from './NewOrderButton'
import { Link as RouterLink } from 'react-router-dom'
import { MobileHamburgerMenu } from './MobileHamburgerMenu'
import { NavMenu } from './NavMenu'
import { useAuth } from '../../../context/use-auth'

const TopBar = () => {
  const { isMenuOpen, toggle } = useMobileMenuState()
  const { isSupplier, isEhrProvider } = useAuth()

  const handleClick = () => (
    isMenuOpen ? toggle() : null
  )

  const showNewOrderButton = !isSupplier && !isEhrProvider

  return (
    <Flex
      align='center'
      bg='green.900'
      color='white'
      px={{ base: 3, lg: 6 }}
      minH={{ base: '3.5rem', lg: '4rem' }}
    >
      <Container maxW='7xl' px='0'>
        <Flex align='center'>
          <Flex
            justify={{ base: 'flex-start', lg: 'space-between' }}
            align='center'
            w='full'
          >
            <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />

            <RouterLink to='/' onClick={handleClick}>
              <Logo.Mobile color='white' sx={{ height: '26px' }} />
              <Logo.Desktop color='white' sx={{ height: '26px' }} marginEnd='0' />
            </RouterLink>

            <NavMenu.Mobile isSupplier={isSupplier} isOpen={isMenuOpen} toggleMenu={toggle} />
            <NavMenu.Desktop isSupplier={isSupplier} />
          </Flex>

          {showNewOrderButton && <NewOrderButton toggleMenu={toggle} isMenuOpen={isMenuOpen} />}
        </Flex>
      </Container>
    </Flex>
  )
}

export default TopBar
