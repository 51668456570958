import { destroy, post, get } from './base'

export const attachments = {
  getPresignedUrl: (params) =>
    post('/api/presigned_urls', params),
  createInsuranceCard: (params) =>
    post('/api/attachments/create_insurance_card', params),
  createPatientPacket: (params) =>
    post('/api/attachments/create_patient_packet', params),
  createUsageData: (params) =>
    post('/api/attachments/create_usage_data', params),
  getDownloadUrl: (params) =>
    get('/api/attachments/download_url', { params }),
  remove: (uuid) =>
    destroy(`/api/attachments/${uuid}`)
}
