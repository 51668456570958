import React from 'react'
import { VStack, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { useProgress } from '../ProgressBar/useProgress'
import { Link as RouterLink } from 'react-router-dom'
import { getIndefiniteArticle } from '../../common/utils'

export const Details = () => {
  const {
    currentStep,
    currentStepIndex,
    stepHasProductSelection,
    getSelectedProducts
  } = useProgress()

  const category = currentStep.productCategory
  const stepText = `STEP ${currentStepIndex + 1}`
  const isPlural = pluralize.isPlural(category)
  const categoryText = isPlural ? pluralize.plural(category) : pluralize.singular(category)
  const indefiniteArticle = getIndefiniteArticle(categoryText)
  const selectionDefaultText = `Select ${isPlural ? '' : indefiniteArticle + ' '}${categoryText}`
  const productListLink = () => (
    <RouterLink to='/shop' data-test='select-products-list-link'>
      {selectionDefaultText}
    </RouterLink>
  )
  const selectedProductsCount = getSelectedProducts(currentStep).length
  const selectionText = stepHasProductSelection(currentStep)
    ? `${pluralize('item', selectedProductsCount, true)} selected`
    : productListLink()

  return (
    <VStack
      spacing='0'
      align='left'
    >
      <Text
        color='blackAlpha.600'
        fontSize='xs'
        fontWeight='500'
        display={{
          base: 'none',
          lg: 'flex'
        }}
      >
        {stepText}
      </Text>
      <Text
        color={{
          base: 'blackAlpha.600',
          lg: 'black'
        }}
        fontSize={{
          base: 'xs',
          lg: 'sm'
        }}
        fontWeight={{
          base: 'normal',
          lg: '400'
        }}
        data-test='progress-navigation-selection-text'
      >
        {selectionText}
      </Text>
    </VStack>
  )
}
