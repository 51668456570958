import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Box, Button, Text, Link, VStack, Stack, StackDivider } from '@chakra-ui/react'
import { IoBagHandleOutline } from 'react-icons/io5'
import RoundedBox from '../../common/RoundedBox'
import ItemList from './ItemList'

const ShipmentHeader = ({ shipment, isMobile }) => {
  const { createdAt, trackingUrl, supplierName } = shipment

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      align={isMobile ? 'left' : 'center'}
      justify='space-between'
      px='8'
      py='4'
      spacing='4'
    >
      <VStack align='left' spacing='1'>
        <Text fontSize='lg' fontWeight='bold'>
          {'Shipped: ' + dayjs(createdAt).format('MM/DD/YYYY')}
        </Text>
        <Text color='gray.600' fontSize='sm'>
          {'Fulfilled By: ' + supplierName}
        </Text>
      </VStack>
      <Box>
        <Button
          as={Link}
          href={trackingUrl}
          isExternal
          bg='blue.500'
          color='white'
          _hover={{
            background: 'blue.600',
            color: 'white',
            textDecoration: 'none'
          }}
          _active={{
            bg: 'blue.600'
          }}
          leftIcon={<IoBagHandleOutline />}
        >
          Track Shipment
        </Button>
      </Box>
    </Stack>
  )
}

ShipmentHeader.propTypes = {
  shipment: PropTypes.object,
  isMobile: PropTypes.bool
}

const Shipment = ({ shipment, isMobile, orderConfirmationNumber }) => (
  <VStack
    align='left'
    spacing='0'
    divider={<StackDivider borderColor='gray.200' />}
  >
    <ShipmentHeader
      shipment={shipment}
      isMobile={isMobile}
    />
    <ItemList
      items={shipment.shippedCartItems}
      isMobile={isMobile}
      orderConfirmationNumber={orderConfirmationNumber}
      showCtas
    />
  </VStack>
)

Shipment.propTypes = {
  shipment: PropTypes.object,
  isMobile: PropTypes.bool,
  orderConfirmationNumber: PropTypes.string
}

const Shipments = ({ shipments, ...props }) => {
  if (shipments.length === 0) {
    return null
  }

  return (
    <RoundedBox data-test='order-history-shipments'>
      {shipments.map((shipment, i) => (
        <Shipment key={i} shipment={shipment} {...props} />
      ))}
    </RoundedBox>
  )
}

Shipments.propTypes = {
  shipments: PropTypes.array
}

export default Shipments
