import React from 'react'
import PropTypes from 'prop-types'
import InsuranceInfo from '../Product/InsuranceInfo'

import {
  IoShieldCheckmarkOutline
} from 'react-icons/io5'

import {
  HStack,
  Stack,
  Text
} from '@chakra-ui/react'

const InsurancePrice = ({ prices }) => {
  return (
    <div>
      <Stack
        color='blue.600'
        direction={{ base: 'column', sm: 'row' }}
        fontWeight='medium'
        mb={1}
        spacing={1}
      >
        <HStack spacing={1}>
          <IoShieldCheckmarkOutline />

          <Text data-test='insurance-price'>
            {prices.patientCost}
          </Text>
        </HStack>
        <HStack spacing={1}>
          <Text fontSize='sm'>
            with insurance
          </Text>

          <InsuranceInfo />
        </HStack>
      </Stack>

      <Text color='blackAlpha.600' fontSize='sm'>
        {`${prices.retailCost} Retail`}
      </Text>
    </div>
  )
}

InsurancePrice.propTypes = {
  prices: PropTypes.object.isRequired
}

export default InsurancePrice
