import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import ReactSelect from '../../common/ChakraReactSelect'
import { Box } from '@chakra-ui/react'

const OrdersDropDown = ({ orders, selectedOrder, onChange }) => {
  const options = orders.map(
    ({ orderConfirmationNumber, createdAt }) => ({
      value: orderConfirmationNumber,
      label: `Order: ${orderConfirmationNumber} - ${dayjs(createdAt).format('MM/DD/YYYY')}`
    })
  )

  const selectedOption = options.find(
    option => option.value === selectedOrder.orderConfirmationNumber
  )

  const handleChange = (option) => {
    const order = orders.find(order => order.orderConfirmationNumber === option.value)

    onChange(order)
  }

  return (
    <Box
      w='350px'
      fontWeight='500'
      cursor='pointer'
      mb='10'
      data-test='order-history-dropdown'
    >
      <ReactSelect
        options={options}
        value={selectedOption}
        onChange={handleChange}
        closeMenuOnSelect
        size='md'
        fontSize='sm'
        isSearchable={false}
      />
    </Box>
  )
}

OrdersDropDown.propTypes = {
  orders: PropTypes.array,
  selectedOrder: PropTypes.object,
  onChange: PropTypes.func
}

export default OrdersDropDown
