import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Text } from '@chakra-ui/react'

const NewOrderButton = ({ isMenuOpen, toggleMenu }) => {
  const handleClick = () => (
    isMenuOpen ? toggleMenu() : null
  )

  return (
    <Button
      as={RouterLink}
      to='/work_orders/new'
      bg='white'
      color='blackAlpha.800'
      cursor='pointer'
      fontSize={{ base: 'sm', lg: 'md' }}
      transition='none'
      data-test='add-new-order'
      position='relative'
      px={{ base: '4', lg: '7' }}
      maxH={{ base: '32px', lg: '40px' }}
      onClick={handleClick}
    >
      <Text pr='2'>+</Text>
      <Text display={{ base: 'none', lg: 'block' }}>Add new order</Text>
      <Text display={{ base: 'block', lg: 'none' }}>Add</Text>
    </Button>
  )
}

NewOrderButton.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired
}

export default NewOrderButton
