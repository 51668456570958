import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Center,
  Text,
  VStack
} from '@chakra-ui/react'
import Mixpanel from '../../services/Mixpanel'

export const ProviderConsultationFound = ({ handleCloseModal }) => {
  useEffect(() => {
    Mixpanel.trackEvent('Arrive at Prescription Verification success screen')
  }, [])

  return (
    <Center mt={5}>
      <VStack spacing={10}>
        <Text fontSize='md' align='center' fontWeight={500}>
          Good news! Your provider will be sharing your prescription with us so there's no action for you.
        </Text>

        <Button
          onClick={handleCloseModal}
          colorScheme='green'
        >
          Return to shop
        </Button>
      </VStack>
    </Center>
  )
}

ProviderConsultationFound.propTypes = {
  handleCloseModal: PropTypes.func.isRequired
}
