import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useDisclosure, useBoolean } from '@chakra-ui/hooks'
import Analytics from '../services/Analytics'
import { usePatientSession } from '../context/use-patient-session'
import useRouteMatching from '../components/common/useRouteMatching'
import Mixpanel from '../services/Mixpanel'

const prescriptionModalContext = createContext()
// Provider component that wraps your app and makes modal object ...
// ... available to any child component that calls usePrescriptionModal().
export function PrescriptionModalProvider ({ children }) {
  const modal = usePrescriptionDisclosure()
  return (
    <prescriptionModalContext.Provider value={modal}>
      {children}
    </prescriptionModalContext.Provider>
  )
}
PrescriptionModalProvider.propTypes = {
  children: PropTypes.node.isRequired
}
// Hook for child components to get the modal object ...
// ... and re-render when it changes.
export const usePrescriptionModal = () => {
  return useContext(prescriptionModalContext)
}
// Provider hook that creates modal object and handles state
function usePrescriptionDisclosure () {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [state, setState] = useState()
  const [showCheckoutContents, setShowCheckoutContents] = useBoolean()
  const { isShopRoute, isProductRoute, isCartRoute } = useRouteMatching()

  const patientSession = usePatientSession()
  const isUploadOptional = patientSession.hasAuthorizationRequired()

  const trackModal = ({ isOpening }) => {
    let label

    if (isShopRoute) {
      label = 'product listing page'
    }

    if (isProductRoute) {
      label = 'product details page'
    }

    if (isCartRoute) {
      label = 'cart review page'
    }

    Analytics.trackEvent({
      category: 'prescription',
      action: isOpening ? 'open modal' : 'close modal',
      label
    })
  }

  const handleOpenModal = ({
    isCheckoutButton = false,
    shouldEnableCheckoutContents = true,
    callback,
    triggerSource
  } = {}) => {
    if (isCheckoutButton) {
      if (!isUploadOptional && shouldEnableCheckoutContents) {
        setShowCheckoutContents.on()
      }

      setState({
        triggeredByCheckout: true,
        callback
      })
    } else {
      setState({
        triggeredByCheckout: false
      })
    }

    if (triggerSource) {
      Mixpanel.trackEvent(
        'Arrive at Prescription Verification modal',
        { triggerSource }
      )
    }

    onOpen()
  }

  const handleCloseModal = () => {
    trackModal({ isOpening: false })

    setShowCheckoutContents.off()
    Mixpanel.trackEvent(
      'Close Prescription Verification modal'
    )
    onClose()
  }

  return {
    handleOpenModal,
    handleCloseModal,
    isUploadOptional,
    showCheckoutContents,
    setShowCheckoutContents,
    state,
    isModalOpen: isOpen
  }
}
