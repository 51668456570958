import { usePatientSession } from '../../../context/use-patient-session'
import { useFilters } from '../Filters/useFilters'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { API } from '../../../services/Api'

/** Returns an array of categories with this form:
  {
    stepIndex -> Number, step position. Starts from 0.
    productCategory -> Example: 'Humidifier'.
    hcpcsCodes -> Array of HCPCS codes of productCategory.
  }
 */

const buildProgressSteps = ({
  hcpcsCodes,
  hideMachineStep = false,
  productVertical,
  productCategory,
  isReferral
}) => {
  return hcpcsCodes
    .filter(
      (hcpcsCode) =>
        hcpcsCode.productCategory.isActive &&
        (hideMachineStep ? hcpcsCode.productCategory.name !== 'CPAP Machine' : true)
    )
    .filter((hcpcsCode) => {
      // For Open Platform users, we show the category that has been selected
      // In the onboarding modal including the Accessories category
      if (!isReferral) {
        const accessoryCategory = productVertical.productCategories.find(
          (category) => category.isAccessory && category.verticalType === productCategory.verticalType
        )

        return hcpcsCode.productCategory.uuid === productCategory.uuid || hcpcsCode.productCategory.uuid === accessoryCategory.uuid
      } else {
        return true
      }
    })
    .sort((a, b) => a.productCategory.order - b.productCategory.order)
    .reduce((array, hcpcsCode) => {
      let productCategory = array.find(
        (category) =>
          category.productCategoryUuid === hcpcsCode.productCategory.uuid
      )

      if (productCategory === undefined) {
        productCategory = {
          stepIndex: array.length,
          productCategory: hcpcsCode.productCategory.name,
          productCategoryUuid: hcpcsCode.productCategory.uuid,
          hcpcsCodes: []
        }

        array.push(productCategory)
      }

      productCategory.hcpcsCodes.push(hcpcsCode)

      return array
    }, [])
}

export const useProgress = () => {
  const {
    getProductVertical,
    getProductCategory,
    patientSession,
    getEligibleForMachine,
    setProgress
  } = usePatientSession()
  const location = useLocation()
  const navigate = useNavigate()
  const { reset: resetFilters } = useFilters()
  const { progressSteps = [], progressStatus } = patientSession
  const currentStepIndex = progressStatus?.currentStepIndex
  const stepMessage = progressStatus?.message

  const getStepAtIndex = (index) =>
    progressSteps.find((progressStep) => progressStep.stepIndex === index)
  const currentStep = getStepAtIndex(currentStepIndex)
  const nextStep = getStepAtIndex(currentStepIndex + 1)

  const stepHasProductSelection = (step) => getSelectedProducts(step).length > 0

  const eligibleForMachine = getEligibleForMachine()

  const getSelectedProducts = (step) => {
    if (!step) return []

    if (patientSession.cart) {
      return patientSession.cart.cartItems.filter(
        (cartItem) =>
          cartItem.productVariant.product.hcpcsCode.productCategory.uuid ===
          step.productCategoryUuid
      )
    } else {
      return []
    }
  }

  const goToStep = (stepIndex, { force } = {}) => {
    const isCurrentStep = stepIndex === currentStepIndex
    const isAFollowingStep = stepIndex > currentStepIndex
    const isCheckoutStep = stepIndex === progressSteps.length
    const destinationStep = getStepAtIndex(stepIndex)

    if (isCurrentStep) {
      return
    }

    if (isCheckoutStep) {
      navigate('/cart', {
        state: {
          triggerSource: 'review cart cta'
        }
      })

      return
    }

    if (isAFollowingStep) {
      if (
        !stepHasProductSelection(currentStep) &&
        !stepHasProductSelection(destinationStep)
      ) {
        setProgress({
          status: {
            currentStepIndex,
            message:
              'Please make your product selection in this step before moving forward.'
          }
        })

        return
      }

      if (!force && !stepHasProductSelection(destinationStep)) {
        return
      }
    } else if (!stepHasProductSelection(destinationStep)) {
      return
    }

    resetFilters()
    setProgress({ status: { currentStepIndex: stepIndex, message: '' } })
  }

  const getLatestIncompleteStep = (steps) => {
    const latestIncompleteStep = steps.find(
      (step) => !stepHasProductSelection(step)
    )

    let stepIndex
    if (latestIncompleteStep) {
      stepIndex = latestIncompleteStep.stepIndex
    } else {
      // If all steps are completed it returns latest step
      const latestStepIndex = steps.length - 1
      stepIndex = latestStepIndex
    }

    return stepIndex
  }

  const goToTheLatestIncompleteStep = () => {
    if (!progressSteps) return

    const incompleteStepIndex = getLatestIncompleteStep(progressSteps)

    if (currentStepIndex !== incompleteStepIndex) {
      resetFilters()
    }
    setProgress({
      status: { currentStepIndex: incompleteStepIndex, message: '' }
    })
  }

  const productVertical = getProductVertical()
  const productCategory = getProductCategory()

  const setInitialProgressSteps = (hcpcsData) => {
    let hcpcsCodes, currentStepIndex

    if (patientSession.consultation?.hcpcsCodes?.length) {
      hcpcsCodes = patientSession.consultation.hcpcsCodes
    } else {
      hcpcsCodes = hcpcsData.data
    }

    const progressSteps = buildProgressSteps({
      hcpcsCodes,
      hideMachineStep: !eligibleForMachine,
      productCategory,
      productVertical,
      isReferral: patientSession.consultation?.isReferral
    })

    if (location.state?.stepIndex !== undefined) {
      currentStepIndex = location.state?.stepIndex
    } else {
      currentStepIndex = getLatestIncompleteStep(progressSteps)
    }

    setProgress({
      steps: progressSteps,
      status: {
        currentStepIndex,
        message: ''
      }
    })
  }

  const fetchHcpcsCodes = async () => {
    const data = await API.hcpcsCodes.index({
      product_vertical_uuid: productVertical?.uuid
    })

    return data
  }

  useQuery(
    ['fetchHcpcsCodes', productVertical?.uuid, eligibleForMachine],
    fetchHcpcsCodes,
    {
      enabled: (!!productVertical && patientSession.consultation?.isReferral) || (!!productVertical && !!productCategory),
      keepPreviousData: true,
      onSuccess: setInitialProgressSteps,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    progressSteps,
    goToStep,
    goToTheLatestIncompleteStep,
    currentStepIndex,
    currentStep,
    nextStep,
    stepHasProductSelection,
    getSelectedProducts,
    stepMessage
  }
}
