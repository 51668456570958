/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Box, Center, Heading, VStack } from '@chakra-ui/layout'
import { Button, useToast } from '@chakra-ui/react'
import { CartList } from './CartList'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { usePatientSession } from '../../../context/use-patient-session'
import { useQueryParams } from '../../../utils/useQueryParams'
import Mixpanel from '../../../services/Mixpanel'

const Cart = () => {
  const location = useLocation()
  const session = usePatientSession()
  const { patientSession } = session
  const queryParams = useQueryParams()
  const toast = useToast()

  useEffect(() => {
    let triggerSource = location.state?.triggerSource ||
      queryParams['trigger-source']

    if (['email', 'sms'].includes(queryParams.medium)) {
      triggerSource = 'abandoned cart link'
    }

    Mixpanel.trackEvent('Arrive at cart review page', {
      triggerSource
    })
  }, [])

  if (queryParams.error) {
    toast({
      position: 'top-right',
      description: queryParams.error,
      title: 'Something went wrong',
      status: 'error',
      isClosable: true
    })
  }

  const quantity = session.getCartQuantity()

  const isCartEmpty = quantity === 0

  return (
    <Box p='6' flex='1'>
      {
        isCartEmpty
          ? <EmptyCart />
          : <CartList
              quantity={quantity}
              cart={patientSession.cart}
            />
      }
    </Box>
  )
}

const EmptyCart = () => {
  return (
    <Center>
      <VStack>
        <Heading size='lg' mb={4}>Your cart is empty</Heading>
        <Button
          as={RouterLink}
          to='/shop'
          colorScheme='green'
          _hover={{
            background: 'green.600',
            color: 'white'
          }}
          data-test='browse-shop-button'
        >
          Browse Store
        </Button>
      </VStack>
    </Center>
  )
}

export default Cart
