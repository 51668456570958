import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IoSearchOutline, IoClose } from 'react-icons/io5'

import {
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button
} from '@chakra-ui/react'

import {
  FormControl
} from '../../common/FormElements'

const WorkOrderFilters = ({ onSubmit }) => {
  const LABEL = 'Search by patient name or DoB'
  const PLACEHOLDER_LABEL = 'Type seach'

  const schema = yup.object().shape({
    searchFilter: yup.string().optional().label(LABEL)
  })

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const searchFilterWatcher = watch('searchFilter')

  const onValidatedSubmit = ({
    searchFilter
  }) => {
    onSubmit(searchFilter)
  }

  const handleSearchClearClick = () => {
    onSubmit('')
    reset({
      searchFilter: ''
    })
  }

  return (
    <form id='searchForm' onSubmit={handleSubmit(onValidatedSubmit)}>
      <Grid
        width='100%'
        templateColumns='repeat(12, 1fr)'
        gap={4}
        mt={8}
        alignItems='flex-end'
      >

        <GridItem colSpan={{ base: 4, sm: 4 }}>
          <FormControl
            errorMessage={errors?.searchFilter?.message}
            label={LABEL}
          >
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <IoSearchOutline color='gray' />
              </InputLeftElement>
              <Input
                id='searchFilter'
                fontSize='sm'
                bg='white'
                textOverflow='ellipsis'
                placeholder={PLACEHOLDER_LABEL}
                {...register('searchFilter')}
              />
              <InputRightElement
                hidden={!searchFilterWatcher}
                onClick={handleSearchClearClick}
                cursor='pointer'
                color='gray'
                _hover={{ color: 'gray.700' }}
                data-test='search-input-clear'
              >
                <IoClose fontSize='20px' />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 12, sm: 8 }}>
          <Button
            type='submit'
            colorScheme='blue'
            data-test='work-order-submit'
          >
            Search
          </Button>
        </GridItem>
      </Grid>
    </form>
  )
}

WorkOrderFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default WorkOrderFilters
