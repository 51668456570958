import { SkeletonText, Box } from '@chakra-ui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

const TableSkeleton = ({ tableProps }) => (
  <>
    <SkeletonText noOfLines={1} mt='39px' mb='30px' w='100px' />

    <Box {...tableProps}>
      <SkeletonText py={7} px={6} noOfLines={4} spacing='6' />
    </Box>
  </>
)

TableSkeleton.propTypes = {
  tableProps: PropTypes.object
}

export default TableSkeleton
