import React from 'react'
import PropTypes from 'prop-types'
import useDimensions from 'react-use-dimensions'
import { TopBar } from '../TopBar/TopBar'
import { InsuranceBanner } from '../../Insurance/InsuranceBanner'
import { PrescriptionBanner } from '../../Prescription/PrescriptionBanner'

const TopBanners = ({ showTopBarOnly }) => {
  const [topBannersRef, topBannerSizes] = useDimensions()
  const topBannersHeight = topBannerSizes.height + topBannerSizes.y

  if (showTopBarOnly) {
    return (
      <div ref={topBannersRef}>
        <TopBar topBannersHeight={topBannersHeight} />
      </div>
    )
  }

  return (
    <div ref={topBannersRef}>
      <InsuranceBanner.Mobile />
      <PrescriptionBanner.Mobile />
      <TopBar topBannersHeight={topBannersHeight} />
      <InsuranceBanner.Desktop />
      <PrescriptionBanner.Desktop />
    </div>
  )
}

TopBanners.propTypes = {
  showTopBarOnly: PropTypes.bool
}

export default TopBanners
