import React from 'react'
import PropTypes from 'prop-types'
import TopBar from './TopBar'
import TermsBanner from './TermsBanner'

import {
  Box,
  Flex,
  Heading
} from '@chakra-ui/react'

const Dashboard = ({ children, heading, ...props }) => {
  return (
    <Flex
      direction='column'
      bg='providerDashboard.bg'
      minHeight='100vh'
      as='main'
      pb='12'
      {...props}
    >
      <TopBar />
      <Box overflowX='scroll' width='100%' display='flex'>
        <Box
          margin={{ base: 3, xl: 'auto' }}
          marginTop={{ base: 2, xl: 8 }}
          width='7xl'
        >
          {heading &&
            <Heading
              fontSize={{ base: '20px', md: '24px' }}
              fontWeight='700'
              color='gray.800'
              mb='2'
            >
              {heading}
            </Heading>}
          {children}
        </Box>
      </Box>

      <TermsBanner position='fixed' bottom='0' right='0' left='0' />
    </Flex>
  )
}

Dashboard.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string
}

export default Dashboard
