import React from 'react'
import { VStack, Text } from '@chakra-ui/react'
import { useAuth } from '../../context/use-auth'

const PatientInfo = () => {
  const { user } = useAuth()

  return (
    <VStack spacing='1' mx='4' my='8' textAlign='center'>
      {user.fullName && (
        <Text
          color='gray.800'
          fontWeight='bold'
          fontSize='md'
          wordBreak='break-word'
        >
          {user.fullName}
        </Text>
      )}
      <Text
        color={user.fullName ? 'gray.600' : 'gray.800'}
        fontSize={user.fullName ? null : 'md'}
        fontWeight={user.fullName ? null : 'bold'}
        wordBreak='break-all'
      >
        {user.email}
      </Text>
    </VStack>
  )
}

export default PatientInfo
