import React from 'react'
import {
  Text,
  VStack
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const CartTotalPrice = ({ patientCost, retailCost, showInsurancePricing }) => {
  if (showInsurancePricing) {
    return (
      <VStack align='right' fontSize={14} spacing={1}>
        <Text
          color='blue.600'
          fontWeight='600'
          data-test='cart-item-price'
        >
          {patientCost}
        </Text>
        <Text decoration='line-through' data-test='cart-item-retail-price'>
          {retailCost}
        </Text>
      </VStack>
    )
  } else {
    return (
      <Text fontSize={14} data-test='cart-item-price'>
        {patientCost}
      </Text>
    )
  }
}

CartTotalPrice.propTypes = {
  patientCost: PropTypes.string.isRequired,
  retailCost: PropTypes.string.isRequired,
  showInsurancePricing: PropTypes.bool
}
