import React from 'react'
import PropTypes from 'prop-types'
import { Circle } from '@chakra-ui/react'
import { IoSearchSharp } from 'react-icons/io5'
import { useFilters } from '../useFilters'

export const MobileSearchFilterButton = ({ onClick }) => {
  const { currentFilters } = useFilters()

  const searchQuery = currentFilters.query

  return (
    <Circle
      onClick={onClick}
      size='40px'
      bg={searchQuery ? 'shop.grayOyster' : 'blackAlpha.50'}
      color={searchQuery ? 'white' : 'gray.700'}
      data-test='filters-mobile-circle-button'
    >
      <IoSearchSharp />
    </Circle>
  )
}
MobileSearchFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired
}
