import React, { useState, useEffect } from 'react'
import Analytics from '../../../../services/Analytics'
import { HStack, Text, Checkbox } from '@chakra-ui/react'
import { useFilters } from '../useFilters'
import { useMediaQuery } from 'react-responsive'
import { FilterButton } from '../FilterButton'
import { ClearFilterButton } from '../ClearFilterButton'
import { PriceInput } from './PriceInput'

const PriceFilter = () => {
  const filters = useFilters()
  const emptyState = ''
  const [priceFrom, setPriceFrom] = useState() // intentionally undefined
  const [priceTo, setPriceTo] = useState() // intentionally undefined
  const isFree = (priceFrom === '0' && priceTo === '0')

  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })

  useEffect(() => {
    if (filters.currentFilters.priceFrom !== priceFrom) {
      setPriceFrom(filters.currentFilters.priceFrom || emptyState)
    }
    /* eslint-disable-next-line */
  }, [filters.currentFilters.priceFrom])

  useEffect(() => {
    if (filters.currentFilters.priceTo !== priceTo) {
      setPriceTo(filters.currentFilters.priceTo || emptyState)
    }
    /* eslint-disable-next-line */
  }, [filters.currentFilters.priceTo])

  useEffect(() => {
    const initialPageLoad = priceFrom === undefined && priceTo === undefined

    if (initialPageLoad) {
      return
    }

    const priceChanged = (
      priceFrom !== filters.currentFilters.priceFrom ||
        priceTo !== filters.currentFilters.priceTo
    )

    if (isMobile && priceChanged) {
      filters.setPrices(priceFrom, priceTo)
    }
    /* eslint-disable-next-line */
  }, [priceFrom, priceTo])

  const handleApplyFilterClick = () => {
    Analytics.trackEvent({
      category: 'search filters',
      action: 'price filter applied',
      label: 'product listing page'
    })

    filters.setPrices(priceFrom, priceTo)
  }

  const handleClearPriceClick = () => {
    filters.setPrices(emptyState, emptyState)
  }

  const handleFreeToggle = (event) => {
    if (event.target.checked) {
      filters.setPrices('0', '0')
    } else {
      handleClearPriceClick()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleApplyFilterClick()
    }
  }

  const isPriceFromInvalid = priceFrom < 0 || (priceTo && priceFrom ? Number(priceFrom) > Number(priceTo) : false)
  const isPriceToInvalid = priceTo > 9999999 || (priceTo && priceFrom ? Number(priceTo) < Number(priceFrom) : false)
  const isActive = priceFrom || priceTo
  return (
    <>
      <Checkbox
        isChecked={isFree}
        onChange={handleFreeToggle}
        pb={4}
        data-test='price-filter-free-checkbox'
      >
        <Text fontSize='sm'>Free</Text>
      </Checkbox>
      <HStack align='flex-end'>
        <PriceInput
          disabled={isFree}
          id='from'
          helperText='From:'
          placeholder='$50'
          value={priceFrom}
          onChange={setPriceFrom}
          onKeyDown={handleKeyDown}
          isInvalid={isPriceFromInvalid}
          textOverflow='ellipsis'
          data-test='price-filter-from-input'
        />
        <Text fontSize='xs' pb='9px'>
          to
        </Text>
        <PriceInput
          disabled={isFree}
          id='to'
          helperText='To:'
          placeholder='$3000'
          value={priceTo}
          onChange={setPriceTo}
          onKeyDown={handleKeyDown}
          isInvalid={isPriceToInvalid}
          textOverflow='ellipsis'
          data-test='price-filter-to-input'
        />
      </HStack>
      <HStack align='flex-end' spacing={2}>
        {!isMobile && (
          <FilterButton
            text='Apply'
            onClick={handleApplyFilterClick}
            variant={isActive ? 'solid' : 'outline'}
            data-test='price-filter-apply-button'
          />
        )}
        {priceFrom || priceTo
          ? <ClearFilterButton
              text='Clear price'
              onClick={handleClearPriceClick}
              data-test='price-filter-clear-button'
            />
          : null}
      </HStack>
    </>
  )
}

export default PriceFilter
