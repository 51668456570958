import secureLoginSvg from '../../../../assets/images/landing/secure-login.svg'
import icd10LookupSvg from '../../../../assets/images/landing/icd-10-lookup.svg'
import hcpcsCodesSvg from '../../../../assets/images/landing/hcpcs-codes.svg'
import prescriptionSpecificationSvg from '../../../../assets/images/landing/prescription-specification.svg'
import reviewPatientInfoSvg from '../../../../assets/images/landing/review-patient-info.svg'

export const ASSETS = [
  {
    title: 'HIPAA Compliant with Secure Login',
    description: 'Sign up in just a few steps and get access.',
    src: secureLoginSvg,
    position: 'left'
  },
  {
    title: 'Easy ICD-10 Code Lookup',
    description: 'The patient’s MBI or Member ID allows us to provide them with an accurate after-insurance cost.',
    src: icd10LookupSvg,
    position: 'right'
  },
  {
    title: 'Presourced HCPCS Codes',
    description: 'More efficient prescription and billing process.',
    src: hcpcsCodesSvg,
    position: 'left'
  },
  {
    title: 'Add Prescription Specification',
    description: 'Add any prescription specifications and necessary documentation.',
    src: prescriptionSpecificationSvg,
    position: 'right'
  },
  {
    title: 'Review Patient Info',
    description: 'Information is reviewed, signed, and patient is invited to shop DME inventory.',
    src: reviewPatientInfoSvg,
    position: 'left'
  }
]
