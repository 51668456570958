import React from 'react'
import PropTypes from 'prop-types'
import Constants from '../common/constants'
import AttachmentsModalContent from './AttachmentsModalContent'
import { useQueryClient } from 'react-query'
import {
  Text,
  Modal,
  ModalOverlay
} from '@chakra-ui/react'

const AttachmentsModal = ({ consultation, packetType, isOpen, onClose }) => {
  const queryClient = useQueryClient()

  const headerCopy = (
    <Text>
      {`${consultation.patientName || consultation.patientPrimaryContact} - ${packetType.name}`}
    </Text>
  )

  const afterAttachmentsChange = () => {
    queryClient.invalidateQueries('consultations')
  }

  return (
    <Modal
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <AttachmentsModalContent
        afterAttachmentsChange={afterAttachmentsChange}
        headerCopy={headerCopy}
        modalType={Constants.attachmentModalTypes.patientPackets}
        modalData={{ consultation, packetType }}
        onClose={onClose}
      />
    </Modal>
  )
}

AttachmentsModal.propTypes = {
  consultation: PropTypes.object.isRequired,
  packetType: PropTypes.oneOf(
    Object.values(Constants.packetTypes)
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default AttachmentsModal
