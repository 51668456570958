import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  HStack,
  Text
} from '@chakra-ui/react'
import { IoTrashOutline } from 'react-icons/io5'
import Mixpanel from '../../../services/Mixpanel'
import useRemoveFromCartMutation from '../Cart/useRemoveFromCartMutation'

const RemoveFromCartButton = ({ cartItem, variant }) => {
  const removeFromCartMutation = useRemoveFromCartMutation()

  const handleSubmit = () => {
    const { productVariant: { product } } = cartItem

    removeFromCartMutation.mutate(cartItem.uuid)

    Mixpanel.trackEventWithProduct('Remove product from cart', product)
  }

  const baseAttributes = {
    isDisabled: removeFromCartMutation.isLoading,
    isLoading: removeFromCartMutation.isLoading,
    onClick: handleSubmit,
    'data-test': 'remove-from-cart'
  }

  if (variant === 'large') {
    return (
      <Button
        background='white'
        borderColor='gray.200'
        shadow='base'
        rounded='md'
        variant='outline'
        width='full'
        size='lg'
        height={{ base: '40px', lg: '60px' }}
        {...baseAttributes}
      >
        <HStack spacing='1'>
          <Text color='green.600'>
            <IoTrashOutline />
          </Text>

          <Text>
            Remove from cart
          </Text>
        </HStack>
      </Button>
    )
  } else if (variant === 'small') {
    return (
      <Button
        background='green.800'
        _hover={{ background: 'green.600' }}
        shadow='base'
        color='white'
        borderColor='gray.200'
        borderRadius='full'
        position='absolute'
        variant='solid'
        top={2}
        right={2}
        {...baseAttributes}
      >
        <HStack spacing={1} fontWeight='medium'>
          <Text>
            <IoTrashOutline />
          </Text>

          <Text>
            Remove
          </Text>
        </HStack>
      </Button>
    )
  }
}

RemoveFromCartButton.propTypes = {
  cartItem: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['large', 'small']).isRequired
}

export default RemoveFromCartButton
