import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dashboard from '../../providers/Dashboard'
import WorkOrderListGroup from './WorkOrderListGroup'
import { API } from '../../../services/Api'
import { Link as RouterLink } from 'react-router-dom'
import { groupByKey, sortArrayOfObjects } from '../../common/utils'
import { filterByPatientNameAndDob } from '../../common/consultationFilters'
import TableSkeleton from '../../common/TableSkeleton'
import { useQuery } from 'react-query'
import WorkOrderFilters from './WorkOrderFilters'
import SortableColumn from './SortableColumn.jsx'
import WorkOrderCount from './WorkOrderCount.jsx'

import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text
} from '@chakra-ui/react'

import {
  IoAdd
} from 'react-icons/io5'

const LABELS = {
  patient: 'PATIENT',
  date: 'DATE',
  status: 'STATUS',
  priorAuth: 'PRIOR AUTH STATUS',
  docType: 'DOC TYPE',
  docStatus: 'DOC STATUS'
}

const WorkOrderTable = ({ children, onSortButtonClick, sortedBy }) => {
  return (
    <Box
      border='1px solid'
      borderColor='providerDashboard.grayBorder'
      w='6xl'
      p='0'
      bg='white'
    >
      <Grid
        bg='providerDashboard.grayHeader'
        color='gray.600'
        fontSize='xs'
        fontWeight='700'
        templateColumns='repeat(12, 1fr)'
        py={3}
      >
        <GridItem colSpan={{ base: 2 }} pl={6}>
          <SortableColumn
            label={LABELS.patient}
            sortedBy={sortedBy}
            onSortButtonClick={(direction) => onSortButtonClick(LABELS.patient, direction)}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1 }} />
        <GridItem colSpan={{ base: 1 }}>
          <SortableColumn
            label={LABELS.date}
            sortedBy={sortedBy}
            onSortButtonClick={(direction) => onSortButtonClick(LABELS.date, direction)}
          />
        </GridItem>
        <GridItem colSpan={{ base: 2 }} pl={4}>
          <SortableColumn
            label={LABELS.status}
            sortedBy={sortedBy}
            onSortButtonClick={(direction) => onSortButtonClick(LABELS.status, direction)}
          />
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          <SortableColumn
            label={LABELS.priorAuth}
            sortedBy={sortedBy}
            onSortButtonClick={(direction) => onSortButtonClick(LABELS.priorAuth, direction)}
          />
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          {LABELS.docType}
        </GridItem>
        <GridItem colSpan={{ base: 2 }}>
          {LABELS.docStatus}
        </GridItem>
      </Grid>

      {children}
    </Box>
  )
}

WorkOrderTable.propTypes = {
  children: PropTypes.node.isRequired,
  onSortButtonClick: PropTypes.func.isRequired,
  sortedBy: PropTypes.string.isRequired
}

const WorkOrderList = () => {
  const [priorAuthChecked, setPriorAuthChecked] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredConsultations, setFilteredConsultations] = useState([])
  const [sortedConsultations, setSortedConsultations] = useState([])
  const [sortedBy, setSortedBy] = useState(null)

  const resetSortButtons = (defaultConsultations) => {
    setSortedBy(null)
    setSortedConsultations(defaultConsultations)
  }

  const onSortButtonClick = (column, direction) => {
    if (direction === 'unsorted') {
      resetSortButtons(filteredConsultations)
      return
    }

    let sortedConsultations
    switch (column) {
      case LABELS.patient:
        sortedConsultations = sortArrayOfObjects(
          {
            array: filteredConsultations,
            direction,
            sortKeys: ['patientName', 'patientPrimaryContact']
          }
        )
        break
      case LABELS.date:
        sortedConsultations = sortArrayOfObjects(
          {
            array: filteredConsultations,
            direction,
            sortKeys: ['createdAt']
          }
        )
        break
      case LABELS.status:
        sortedConsultations = sortArrayOfObjects(
          {
            array: filteredConsultations,
            direction,
            sortKeys: ['displayStatus']
          }
        )
        break
      case LABELS.priorAuth:
        sortedConsultations = sortArrayOfObjects(
          {
            array: filteredConsultations,
            direction,
            sortKeys: ['priorAuthStatus']
          }
        )
        break
      default:
        sortedConsultations = filteredConsultations
        break
    }

    setSortedBy(column)
    setSortedConsultations(sortedConsultations)
  }

  const fetchConsultations = async () => {
    let params = {}
    if (!priorAuthChecked) {
      setPriorAuthChecked(true)

      params = {
        check_prior_auth_statuses: true
      }
    }

    const { data } = await API.consultations.index(params)

    return data
  }

  const { data: consultations, isLoading } = useQuery(
    'consultations',
    fetchConsultations,
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true
    }
  )

  useEffect(() => {
    if (consultations) {
      const result = filterByPatientNameAndDob(
        consultations,
        searchText
      )

      setFilteredConsultations(result)
      resetSortButtons(result)
    }
  }, [consultations, searchText])

  if (isLoading) {
    return (
      <Dashboard heading='Work Orders'>
        <TableSkeleton tableProps={{ bg: 'providerDashboard.grayHeader', w: '5xl' }} />
      </Dashboard>
    )
  } else if (!consultations.length) {
    return (
      <Dashboard heading='Work Orders'>
        <WorkOrderCount count={consultations.length} />

        <Button
          px='5'
          py='3'
          as={RouterLink}
          to='/work_orders/new'
          colorScheme='green'
          _hover={{
            background: 'green.600',
            color: 'white'
          }}
        >
          <HStack spacing={2}>
            <Icon as={IoAdd} boxSize={4} />
            <Text>Add new order</Text>
          </HStack>
        </Button>
      </Dashboard>
    )
  } else {
    const groupedConsultations = groupByKey(
      sortedConsultations || [],
      'patientPrimaryContact'
    )

    return (
      <Dashboard heading='Work Orders'>
        <WorkOrderFilters
          onSubmit={setSearchText}
          searchText={searchText}
        />
        <WorkOrderCount count={sortedConsultations.length} searchText={searchText} />
        {!sortedConsultations.length
          ? null
          : (
            <WorkOrderTable onSortButtonClick={onSortButtonClick} sortedBy={sortedBy}>
              {Object.entries(groupedConsultations).map(
                ([patientPrimaryContact, group]) => {
                  return (
                    <WorkOrderListGroup
                      key={patientPrimaryContact}
                      group={group}
                    />
                  )
                })}
            </WorkOrderTable>
            )}
      </Dashboard>
    )
  }
}

export default WorkOrderList
