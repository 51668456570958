import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'
import { Box, Container, Flex } from '@chakra-ui/react'
import DesktopSidebar from './DesktopSidebar'

const AccountLayout = () => {
  const mobileBreakpointValue = '(max-width: 62em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })

  return (
    <Flex px={{ base: 0, lg: 6 }}>
      <Container
        maxW='7xl'
        px={{ base: 4, lg: 0 }}
        py={10}
      >
        <Flex bg='white' minHeight='100vh'>
          {!isMobile && (
            <Box w='350px'>
              <DesktopSidebar />
            </Box>
          )}
          <Box px={{ base: 0, lg: 12 }} flex='1'>
            <Outlet />
          </Box>
        </Flex>
      </Container>
    </Flex>
  )
}

export default AccountLayout
