import React from 'react'
import PropTypes from 'prop-types'
import { useQueryClient } from 'react-query'
import {
  Modal,
  ModalOverlay
} from '@chakra-ui/react'
import Constants from '../../common/constants'
import AttachmentsModalContent from '../../Attachments/AttachmentsModalContent'

const UsageDataModal = ({ fulfillment, isOpen, onClose }) => {
  const queryClient = useQueryClient()

  const afterAttachmentsChange = () => {
    queryClient.invalidateQueries('fetchFulfillments')
  }

  return (
    <Modal
      autoFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <AttachmentsModalContent
        afterAttachmentsChange={afterAttachmentsChange}
        headerCopy={fulfillment.patientName}
        modalType={Constants.attachmentModalTypes.usageData}
        modalData={{ fulfillment }}
        onClose={onClose}
      />
    </Modal>
  )
}

UsageDataModal.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default UsageDataModal
