/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { createStandaloneToast } from '@chakra-ui/toast'
import { apiClient } from '../../services/Api/base'
import { isAuthenticated, useAuth } from '../../context/use-auth'
import { usePatientSession } from '../../context/use-patient-session'
import camelcaseKeys from 'camelcase-keys'
import SnakecaseKeys from 'snakecase-keys'
import landingPageTheme from '../../theme/landing'

const { toast } = createStandaloneToast({ theme: landingPageTheme })

export const ApiWrapper = ({ children }) => {
  const auth = useAuth()
  const { patientSession } = usePatientSession()
  const authenticated = isAuthenticated()
  const currentUser = auth.user

  useMemo(() => {
    const currentUserHeaders = () => {
      if (currentUser) {
        return {
          'access-token': currentUser.accessToken,
          client: currentUser.client,
          uid: currentUser.uid
        }
      } else {
        return {}
      }
    }

    const insuranceStatusHeaders = () => {
      if (patientSession?.insuranceStatus) {
        return {
          'insurance-status-uuid': patientSession.insuranceStatus.uuid
        }
      } else {
        return {}
      }
    }

    // Clear any existing interceptors before registering, because
    // Axios chains all registered interceptors, it doesn't replace them

    apiClient.interceptors.request.handlers = []
    apiClient.interceptors.response.handlers = []

    apiClient.interceptors.request.use(({ headers: configHeaders, ...config }) => {
      const headers = {
        ...currentUserHeaders(),
        ...insuranceStatusHeaders(),
        ...configHeaders
      }

      if (config.data) {
        config.data = SnakecaseKeys(config.data)
      }

      return ({
        ...config,
        headers
      })
    },
    error => Promise.reject(error)
    )

    apiClient.interceptors.response.use(unformattedResponse => {
      const user = auth.user
      let userAuth

      const response = camelcaseKeys(unformattedResponse, { deep: true })

      if (user && response.headers.accessToken && response.headers.client) {
        userAuth = {
          ...user,
          accessToken: response.headers.accessToken,
          client: response.headers.client
        }
      } else {
        return response
      }

      auth.updateUser(userAuth)

      return (
        response
      )
    },
    error => {
      if (authenticated && error.response.status === 401) {
        toast({
          position: 'top-right',
          title: 'Logged out due to inactivity',
          status: 'error',
          duration: null,
          isClosable: true
        })

        auth.clearUser()
      }

      return Promise.reject(error)
    }
    )
  }, [auth.user, patientSession?.insuranceStatus])

  return children
}
