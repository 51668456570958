import { post, put } from './base'

export const cartItems = {
  create: (cartItemParams) =>
    post(
      '/api/cart_items/',
      { cartItem: cartItemParams }
    ),
  remove: (cartItemUuid) =>
    put(
      `/api/cart_items/${cartItemUuid}`,
      { cartItem: { quantity: 0 } }
    ),
  edit: ({ cartItemUuid, quantity }) =>
    put(
      `/api/cart_items/${cartItemUuid}`,
      { cartItem: { quantity } }
    )
}
