import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { API } from '../services/Api'
import { useProgress } from '../components/Shop/ProgressBar/useProgress'
import { useFilters } from '../components/Shop/Filters/useFilters'
import { usePatientSession } from './use-patient-session'

const productVariantsContext = createContext()
// Provider component that wraps your app and returns the product variants ...
// ... available to any child component that calls useProductVariants().
export function ProductVariantsProvider ({ children }) {
  const productVariants = useProvideProductVariants()

  return (
    <productVariantsContext.Provider value={productVariants}>
      {children}
    </productVariantsContext.Provider>
  )
}
ProductVariantsProvider.propTypes = {
  children: PropTypes.node.isRequired
}
// Hook for child components to get the product variants object ...
// ... and re-render when it changes.
export const useProductVariants = () => {
  return useContext(productVariantsContext)
}

// Provider hook that fetches and filters product variants
function useProvideProductVariants () {
  const session = usePatientSession()
  const patientSession = session.patientSession
  const { progressSteps, currentStep } = useProgress()
  const { currentFilters } = useFilters()

  const filterByStepHcpcs = (productVariants) => {
    if (!progressSteps || !currentStep?.hcpcsCodes) return []

    const hcpcsCodes = currentStep.hcpcsCodes.map(hcpcs => hcpcs.code)

    return productVariants.filter(
      productVariant => hcpcsCodes.includes(productVariant.product.hcpcsCode.code)
    )
  }

  const filterByQuery = (productVariants) => {
    const query = currentFilters.query
    if (!query) return productVariants

    const filterFunction = (productVariant) => {
      const lowerCaseQuery = query.toLowerCase().trim()
      const lowerCaseProductTitle = productVariant.productTitle.toLowerCase()
      const lowerCaseManufacturer = productVariant.product.manufacturer.toLowerCase()
      return (
        lowerCaseProductTitle.includes(lowerCaseQuery) ||
        lowerCaseManufacturer.includes(lowerCaseQuery)
      )
    }

    return productVariants.filter(filterFunction)
  }

  const filterByBrand = (productVariants) => {
    const brands = currentFilters.brands
    if (!brands) return productVariants

    const filterFunction = (productVariant) => {
      return brands.includes(productVariant.product.manufacturer)
    }

    return productVariants.filter(filterFunction)
  }

  const filterByPrice = (productVariants) => {
    const priceFrom = currentFilters.priceFrom
    const priceTo = currentFilters.priceTo

    if (!priceFrom && !priceTo) return productVariants

    const filterFunction = (productVariant) => {
      const { retailCost, patientCost } = productVariant.prices
      const isCashOnly = productVariant.product.isCashOnly

      // Casting to Number permits comparing the number with a string
      // (priceFrom or priceTo) because javascript automatically converts strings
      // to numbers to perform the comparision. That's called Type Coercion
      const productCost = Number(
        !isCashOnly && session.hasActiveInsurance()
          ? patientCost
          : retailCost
      )

      if (!priceTo) {
        return productCost >= priceFrom
      }

      if (!priceFrom) {
        return productCost <= priceTo
      }

      return productCost >= priceFrom && productCost <= priceTo
    }

    return productVariants.filter(filterFunction)
  }

  const groupByProduct = (productVariants) => {
    return productVariants.reduce((products, productVariant) => {
      let product = products.find(
        product => product.uuid === productVariant.product.uuid
      )

      if (product) {
        product.productVariants.push(productVariant)
      } else {
        product = { ...productVariant.product }
        product.productVariants = [productVariant]
        products.push(product)
      }

      return products
    }, [])
  }

  const stepsHcpcsCodes = () => {
    const hcpcsCodes = patientSession.progressSteps.reduce((array, step) => (
      array.concat(step.hcpcsCodes)
    ), [])

    return hcpcsCodes.map(hcpcsCode => hcpcsCode.code)
  }

  const productVertical = session.getProductVertical()

  const {
    data: productVariantsData,
    isFetching
  } = useQuery(
    [
      'fetchProductVariants',
      patientSession.insuranceStatus,
      patientSession.cart,
      productVertical,
      progressSteps
    ],
    () => API.productVariants.index({
      hcpcs_codes: stepsHcpcsCodes()
    }),
    {
      enabled: !!patientSession.progressSteps,
      keepPreviousData: true,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  let productVariants = []
  let stepProductVariants = []
  let filteredProductVariants = []

  if (productVariantsData) {
    productVariants = productVariantsData.data
    stepProductVariants = filterByStepHcpcs(productVariants)
    filteredProductVariants =
      filterByPrice(filterByBrand(filterByQuery(stepProductVariants)))
  }

  return {
    filteredProductVariants,
    groupByProduct,
    isFetching,
    productVariants,
    stepProductVariants
  }
}
