import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Heading } from '@chakra-ui/react'
import SearchFilter from './SearchFilter'
import BrandFilter from './BrandFilter'
import PriceFilter from './PriceFilter'

const Divider = () => <Box borderTop='1px solid' borderColor='blackAlpha.300' />

const FilterBox = ({ title, children, ...rest }) => (
  <Box mt='24px' mb='30px' pr={{ base: '0px', lg: '16px' }} {...rest}>
    <Heading as='h5' size='sm' pb='18px'>
      {title}
    </Heading>
    {children}
  </Box>
)
FilterBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

export const DesktopFilters = ({
  hideSearchFilter = false,
  ...props
}) => (
  <Flex direction='column' {...props}>
    <FilterBox title='Price' pt='0'>
      <PriceFilter />
    </FilterBox>
    <Divider />
    <FilterBox title='Brand'>
      <BrandFilter />
    </FilterBox>
    {hideSearchFilter
      ? null
      : (
        <>
          <Divider />
          <FilterBox title='Search'>
            <SearchFilter />
          </FilterBox>
        </>
        )}
  </Flex>
)
DesktopFilters.propTypes = {
  hideSearchFilter: PropTypes.bool
}
