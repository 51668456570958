import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { usePatientSession } from '../../../context/use-patient-session'
import {
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  Heading,
  HStack,
  Center,
  VStack
} from '@chakra-ui/react'
import ActionItems from './ActionItems'
import ProgressIndicators from './ProgressIndicators'
import BreastPumpImage from '../../../../assets/images/onboarding/breast-pumps.png'
import CpapImage from '../../../../assets/images/onboarding/cpap.png'
import ImageWithOverlay from './ImageWithOverlay'
import dayjs from 'dayjs'
import kebabCase from 'lodash/kebabCase'
import Mixpanel from '../../../services/Mixpanel'

const StepTwo = ({ productVerticals }) => {
  const productVerticalCookieName = 'doorbellhealth_productVertical'
  const [, setCookie] = useCookies([productVerticalCookieName])
  const patientSession = usePatientSession()
  const currentProductVertical = patientSession.getProductVertical()
  const [selectedProductVertical, setSelectedProductVertical] =
    useState(currentProductVertical)
  const currentStep = patientSession.getCurrentOnboardingStep()

  const handleClick = (productVertical) => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Click Product Vertical',
      { productVertical: productVertical.name }
    )
    setSelectedProductVertical(productVertical)
  }

  const handleNextAction = () => {
    setCookie(productVerticalCookieName, selectedProductVertical.uuid, {
      expires: dayjs().add(20, 'years').toDate(),
      path: '/'
    })

    patientSession.setProductVertical(selectedProductVertical)
    patientSession.setOnboardingStep({ step: currentStep + 1 })
  }

  const productVerticalImage = (productVerticalName) => {
    if (productVerticalName === 'Breast Pump') {
      return BreastPumpImage
    } else if (productVerticalName === 'Respiratory Care') {
      return CpapImage
    } else {
      return null
    }
  }

  useEffect(() => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Arrive at product vertical selection screen'
    )
  }, [])

  // Resets the product category when a patient lands in this step
  // Note that a patient might go back a step by clicking the dot icons
  useEffect(() => {
    patientSession.setProductCategory(null)
  }, [patientSession])

  return (
    <>
      <ModalBody>
        <Grid templateColumns='repeat(12, 1fr)'>
          <GridItem colSpan={{ md: 6, base: 12 }} colStart={{ md: 4 }}>
            <Heading color='white' textAlign='center' pt={12} size='2xl'>
              What are you shopping for today?
            </Heading>
          </GridItem>
          <GridItem colSpan={12} pt={12}>
            <Center>
              <HStack spacing={4} overflowX='auto'>
                {productVerticals?.map((productVertical) => (
                  <ImageWithOverlay
                    key={productVertical.uuid}
                    src={productVerticalImage(productVertical.name)}
                    alt={productVertical.name}
                    title={productVertical.name}
                    cursor='pointer'
                    isSelected={productVertical.uuid === selectedProductVertical?.uuid}
                    onClick={() => handleClick(productVertical)}
                    data-test={`product-vertical-${kebabCase(productVertical.name)}`}
                    data-selected={productVertical.uuid === selectedProductVertical?.uuid}
                  />
                ))}
              </HStack>
            </Center>
          </GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter alignSelf={{ base: 'center' }}>
        <VStack>
          <ActionItems
            buttonCopy='Next'
            buttonAction={handleNextAction}
            buttonIsDisabled={!selectedProductVertical?.uuid}
          />
          <ProgressIndicators amount={4} />
        </VStack>
      </ModalFooter>
    </>
  )
}

StepTwo.propTypes = {
  productVerticals: PropTypes.array
}

export default StepTwo
