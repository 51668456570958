import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  HStack,
  Text
} from '@chakra-ui/react'
import { IoCartOutline } from 'react-icons/io5'
import useAddToCartMutation from '../Cart/useAddToCartMutation'
import Mixpanel from '../../../services/Mixpanel'

const AddToCartButton = ({
  isDisabled,
  productVariant,
  variant,
  quantity
}) => {
  const addToCartMutation = useAddToCartMutation()
  const productVariantUuid = productVariant.uuid

  const handleSubmit = () => {
    const { product } = productVariant

    addToCartMutation.mutate({
      productVariantUuid,
      quantity
    })

    Mixpanel.trackEventWithProduct('Add product to cart', product)
  }

  const baseAttributes = {
    isDisabled: isDisabled || addToCartMutation.isLoading,
    isLoading: addToCartMutation.isLoading,
    onClick: handleSubmit,
    'data-test': 'add-to-cart'
  }

  if (variant === 'large') {
    return (
      <Button
        background='green.700'
        _hover={{ background: 'green.600' }}
        color='white'
        rounded='md'
        width='full'
        size='lg'
        height={{ base: '40px', lg: '60px' }}
        {...baseAttributes}
      >
        <HStack spacing='1'>
          <IoCartOutline />
          <Text>
            Add to cart
          </Text>
        </HStack>
      </Button>
    )
  } else if (variant === 'small') {
    return (
      <Button
        shadow='base'
        background='white'
        borderColor='gray.200'
        borderRadius='full'
        position='absolute'
        top={2}
        right={2}
        variant='outline'
        {...baseAttributes}
      >
        <HStack spacing={1} fontWeight='medium'>
          <Text color='green.600'>
            <IoCartOutline />
          </Text>

          <Text>
            Add
          </Text>
        </HStack>
      </Button>
    )
  }
}

AddToCartButton.propTypes = {
  isDisabled: PropTypes.bool,
  productVariant: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['large', 'small']).isRequired,
  quantity: PropTypes.number.isRequired
}

export default AddToCartButton
