import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/react'
import { Section } from '../Section'
import { Heading } from './Heading'
import { WhyDoorbellFeatures } from './WhyDoorbellFeatures'

export const WhyDoorbell = ({ features, disclaimerMessage = '' }) => (
  <Section
    bg='landingPage.gray'
    id='why-doorbell-section'
    data-test='landing-page-why-doorbell-section'
  >
    <Heading>Why Doorbell Health?</Heading>
    <WhyDoorbellFeatures features={features} />
    {disclaimerMessage && (
      <Text
        fontSize='xs'
        color='gray.500'
        align='center'
        fontWeight='500'
        mt='32px'
      >
        {disclaimerMessage}
      </Text>
    )}
  </Section>
)

WhyDoorbell.propTypes = {
  features: PropTypes.array.isRequired,
  disclaimerMessage: PropTypes.string
}
