const extractDoB = (dateString) => {
  // Match dates with format MM/DD/YYYY, with optional leading zeros.
  // Examples: 01/01/2021, 1/1/2021, 01/1/2021, 1/01/2021
  // Note: It returns the first match
  const regex = /(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{4})/
  const match = dateString.match(regex)
  return match ? match[0] : null
}

// Removes spaces between words in search text
const sanitizeSearchText = (searchText) => {
  return searchText.replace(/\s+/g, ' ').trim()
}

const checkDob = (consultation, searchDob) => {
  let dobMatches = false

  if (consultation.patientDob && searchDob) {
    const patientDob = extractDoB(consultation.patientDob)
    const date1 = new Date(searchDob)
    const date2 = new Date(patientDob)

    // Compare the time values of both Date objects
    if (date1.getTime() === date2.getTime()) {
      dobMatches = true
    }
  }

  return dobMatches
}

const checkPatientName = (consultation, searchText, searchDob) => {
  let patientNameMatches = false

  const displayName =
    consultation.patientName || consultation.patientPrimaryContact

  if (searchText === '' && consultation.patientName === '') {
    patientNameMatches = true
  } else if (displayName) {
    const searchTextWithoutDoB = searchDob
      ? searchText.replace(searchDob, '')
      : searchText

    patientNameMatches = searchTextWithoutDoB
      .toLowerCase()
      .split(' ')
      .every((searchWord) =>
        displayName
          .toLowerCase()
          .split(' ')
          .some((w) => w.startsWith(searchWord))
      )
  }

  return patientNameMatches
}

export const filterByPatientNameAndDob = (consultations, searchText) => {
  const sanitizedSearchText = sanitizeSearchText(searchText)
  const searchDob = extractDoB(sanitizedSearchText)

  const filteredConsultations = consultations.filter((consultation) => {
    const dobMatches = checkDob(consultation, searchDob)

    const patientNameMatches = checkPatientName(
      consultation,
      sanitizedSearchText,
      searchDob
    )

    if (searchDob) {
      return patientNameMatches && dobMatches
    } else {
      return patientNameMatches
    }
  })

  return filteredConsultations
}
