import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { usePatientSession } from '../../context/use-patient-session'
import { useQuery } from 'react-query'
import { API } from '../../services/Api'
import { useQueryParams } from '../../utils/useQueryParams'
import dayjs from 'dayjs'

export const useCode = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = useQueryParams()
  const patientSession = usePatientSession()
  // Referral code query parameter must be named 'code'
  // That way, FullStory hides it from their URL recordings
  // More info: https://help.fullstory.com/hc/en-us/articles/360040770154-Why-do-I-see-redacted-query-parameters-in-FullStory-
  const referralCodeParam = queryParams.code
  const [referralCode, setReferralCode] = useState(referralCodeParam)
  const [errorMessage, setErrorMessage] = useState('')
  const codeCookieName = 'doorbellhealth_referralCode'
  const [, setCookie] = useCookies([codeCookieName])

  const fetchConsultationByReferralCode = async () => {
    const { data } = await API.consultations.getByReferralCode(referralCode)

    return data
  }

  const setCookieAndGoToShop = async (consultation) => {
    setCookie(codeCookieName, referralCode, {
      expires: dayjs().add(20, 'years').toDate(),
      path: '/'
    })
    await patientSession.reset()
    await patientSession.setConsultation(consultation)

    const { data: cart } = await API.cart.single()
    await patientSession.setCart(cart)

    if (location.pathname === '/code') {
      navigate('/shop')
    } else {
      navigate(location.pathname)
    }
  }

  const handleError = (error) => {
    const serverErrorMessage = 'Unexpected server error.'
    const excessiveAttemptsMessage = 'Excessive number of unsuccessful code attempts. Please try again later.'

    let errorMessage
    if (error.response.status === 404) {
      errorMessage = error.response.data.error
    } else if (error.response.status === 429) {
      errorMessage = excessiveAttemptsMessage
    } else {
      errorMessage = serverErrorMessage
    }

    setErrorMessage(errorMessage)

    if (referralCodeParam) {
      navigate('/code', {
        state: {
          errorMessage,
          code: referralCode
        }
      })
    }
  }

  const { isFetching, isError } = useQuery(
    ['fetchConsultationByReferralCode', referralCode],
    fetchConsultationByReferralCode,
    {
      enabled: !!referralCode,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: setCookieAndGoToShop,
      onError: handleError,
      retry: 0
    }
  )

  return {
    referralCodeParam,
    validateCode: setReferralCode,
    isValidating: isFetching,
    isError,
    codeErrorMessage: errorMessage
  }
}
