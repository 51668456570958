import {
  useToast
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { API } from '../../../services/Api'
import Analytics from '../../../services/Analytics'
import { usePatientSession } from '../../../context/use-patient-session'
import useRouteMatching from '../../common/useRouteMatching'

export const useRemoveFromCartMutation = () => {
  const patientSession = usePatientSession()
  const toast = useToast()
  const { isShopRoute, isProductRoute, isCartRoute } = useRouteMatching()

  const trackRemoveFromCart = () => {
    let label

    if (isShopRoute) {
      label = 'product listing page'
    }

    if (isProductRoute) {
      label = 'product details page'
    }

    if (isCartRoute) {
      label = 'cart review page'
    }

    Analytics.trackEvent(
      {
        category: 'cart',
        action: 'remove item',
        label
      })
  }

  const handleSuccess = (data) => {
    patientSession.setCart(data.data)
  }

  const handleError = (error) => {
    let errorMessage

    if (error.response.status === 400) {
      errorMessage = error.response.data.error
    }

    toast({
      position: 'top-right',
      description: errorMessage,
      title: 'Something went wrong',
      status: 'error',
      isClosable: true
    })
  }

  const handleSettled = () => (
    trackRemoveFromCart()
  )

  const mutation = useMutation(
    removeCartItem => API.cartItems.remove(removeCartItem),
    {
      onSuccess: handleSuccess,
      onError: handleError,
      onSettled: handleSettled
    }
  )

  return mutation
}

export default useRemoveFromCartMutation
