import React from 'react'
import { Cta } from '../Sections/Cta'
import { WhyDoorbell } from '../Sections/WhyDoorbell'
import { ASSETS as howItWorksAssets } from './howItWorksAssets'
import { Testimonials } from '../Sections/Testimonials'
import { ASSETS as features } from './features'
import { HowItWorks } from '../Sections/HowItWorks'
import womanPictureSvg from '../../../../assets/images/landing/woman-reading.svg'
import { useTrackLandingPage } from '../useTrackLandingPage'

const ForProviders = () => {
  useTrackLandingPage('Providers')

  return (
    <>
      <Cta
        color='landingPage.blue'
        illustration={womanPictureSvg}
        headingCopy='The future of DME prescription & care'
        bodyCopy='We improve outcomes for DME patients and medical providers everywhere.'
      />
      <WhyDoorbell features={features} />
      <HowItWorks assets={howItWorksAssets} wideVariant />
      <Testimonials />
    </>
  )
}

export default ForProviders
