import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useProgress } from '../Shop/ProgressBar/useProgress'
import useRouteMatching from '../common/useRouteMatching'
import { ExcludeFromRecording } from '../../services/RecordingService'

import {
  Box,
  Button,
  Center,
  FormLabel,
  HStack,
  Text
} from '@chakra-ui/react'

const InsuranceSuccess = ({ handleCloseModal, mutation }) => {
  const navigate = useNavigate()
  const memberId = mutation.data.data.patientInsurancePlan.insuranceMemberId
  const insuranceSavingsAvailable = mutation.data.data.insuranceSavingsAvailable

  const { goToStep } = useProgress()
  const { isShopRoute } = useRouteMatching()

  const handleViewPumpsClick = () => {
    if (isShopRoute) {
      goToStep(0)
    } else {
      navigate('/shop', { state: { stepIndex: 0 } })
    }

    handleCloseModal()
  }

  return (
    <>
      <FormLabel>
        Member ID
      </FormLabel>
      <ExcludeFromRecording>
        <Text fontSize='md' data-test='formatted-member-id'>{memberId}</Text>
      </ExcludeFromRecording>

      {insuranceSavingsAvailable
        ? (
          <Box pt={4}>
            <Text fontSize='md' data-test='other-products-covered' fontWeight='500'>
              Great news! Your insurance covers other pumps that are not currently in your cart. <br /><br />
              You can switch to a different pump for a better discount, or continue with your current selection.
            </Text>

            <Center pt={8}>
              <HStack spacing={4}>
                <Button
                  colorScheme='green'
                  variant='outline'
                  data-test='insurance-modal-continue-button'
                  onClick={handleCloseModal}
                >
                  Keep My Pump
                </Button>
                <Button
                  colorScheme='green'
                  data-test='view-other-products'
                  onClick={handleViewPumpsClick}
                >
                  View Pumps
                </Button>
              </HStack>
            </Center>
          </Box>
          )
        : (
          <Center pt={4}>
            <Button
              colorScheme='green'
              data-test='insurance-modal-continue-button'
              onClick={handleCloseModal}
            >
              Continue
            </Button>
          </Center>
          )}
    </>
  )
}

InsuranceSuccess.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  mutation: PropTypes.object.isRequired
}

export default InsuranceSuccess
