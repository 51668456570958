import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Outlet } from 'react-router-dom'
import { isAuthenticated } from '../../context/use-auth'

const ProtectedRoute = ({ forPatients = false }) => {
  const loggedIn = isAuthenticated()

  if (!loggedIn) {
    return <Navigate to={forPatients ? '/patients/signin' : '/providers/signin'} replace />
  }

  return <Outlet />
}

ProtectedRoute.propTypes = {
  forPatients: PropTypes.bool
}

export default ProtectedRoute
