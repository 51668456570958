/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import isBoolean from 'lodash/isBoolean'
import { API } from '../../../services/Api'
import Mixpanel from '../../../services/Mixpanel'
import Analytics from '../../../services/Analytics'
import * as RecordingService from '../../../services/RecordingService'
import * as CustomerSupportService from '../../../services/CustomerSupportService'
import { FooterLink } from '../../Shop/Footer/Footer'
import { EmptyTopBar } from '../../Shop/TopBar/EmptyTopBar'
import OrderSummary from './OrderSummary'
import { OrderItems } from './OrderItems'
import Shipments from './Shipments'
import Constants from '../../common/constants'
import AssistancePromptBanner from './AssistancePromptBanner'
import { useQueryParams, useRemoveQueryParam } from '../../../utils/useQueryParams'
import { usePatientSession } from '../../../context/use-patient-session'
import ShippingAddress from './ShippingAddress'

import {
  useToast,
  Box,
  Center,
  Container,
  Heading,
  HStack,
  Flex,
  Text,
  Grid,
  GridItem,
  Spinner,
  VStack
} from '@chakra-ui/react'

const Footer = () => {
  return (
    <Box
      as='footer'
      role='contentinfo'
    >
      <Center
        borderTop='solid 1px'
        borderTopColor='gray.300'
        p={{ base: 6, lg: 8 }}
      >
        <HStack
          w='100hw'
          spacing={{ base: '12', lg: '12' }}
        >
          <FooterLink
            href={Constants.doorbell.termsOfServiceLink}
            label={Constants.doorbell.termsOfService}
            isExternal
          />
          <FooterLink
            href={Constants.doorbell.privacyPolicyLink}
            label={Constants.doorbell.privacyPolicy}
            isExternal
          />
        </HStack>
      </Center>
    </Box>
  )
}

const MainContent = ({ order }) => {
  const {
    orderConfirmationNumber,
    customerDetails,
    shipments
  } = order

  return (
    <Flex align='center' direction='column' pt={{ base: '3rem', lg: '5rem' }}>
      <Heading size='lg' fontWeight='700' color='gray.700' data-test='orders-heading'>
        Thank you for your order
      </Heading>

      <RecordingService.ExcludeFromRecording>
        <Text color='blackAlpha.600' fontSize='sm' mt='2'>
          Order #<span data-test='order-number'>{orderConfirmationNumber}</span>
        </Text>
      </RecordingService.ExcludeFromRecording>

      <RecordingService.ExcludeFromRecording>
        <VStack
          align='left'
          spacing={10}
          mt={12}
          fontSize={{ base: 'sm', lg: 'md' }}
          w='100%'
        >
          <Text>
            You will receive an email confirmation at {customerDetails.email} shortly.
            Your order will be delivered to:
          </Text>
          <ShippingAddress order={order} />
          <Shipments shipments={shipments} />
        </VStack>
      </RecordingService.ExcludeFromRecording>
    </Flex>
  )
}
MainContent.propTypes = {
  order: PropTypes.object
}

const OrderDetail = () => {
  const { orderConfirmationNumber } = useParams()
  const queryParams = useQueryParams()
  const removeQueryParam = useRemoveQueryParam()
  const patientSession = usePatientSession()
  const toast = useToast()
  const navigate = useNavigate()

  const handleError = () => {
    navigate('/shop', { replace: true })

    toast({
      position: 'top-right',
      title: 'Invalid order confirmation number',
      status: 'error',
      duration: null,
      isClosable: true
    })
  }

  useEffect(() => {
    if (queryParams.clearSession) {
      setTimeout(() => {
        patientSession.reset()
        removeQueryParam('clearSession')
      }, 0)
    }
  }, [queryParams.clearSession])

  const fetchOrder = async () => {
    const { data } = await API.orders.single(orderConfirmationNumber)

    return data
  }

  const { data: order, isLoading, isSuccess } = useQuery(
    'fetchOrder',
    fetchOrder,
    {
      retry: false,
      onError: handleError,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  // Re identifies the user using order data.
  // This order detail page is public (can be opened in different browsers)
  // and we might not have the user identified there.
  useEffect(() => {
    if (order) {
      const { patientUuid, isTestAccount, customerDetails } = order

      Mixpanel.identify({
        uniqueId: patientUuid,
        userProperties: {
          resourceType: 'Patient',
          ...(isBoolean(isTestAccount) && { isTestAccount })
        }
      })

      Mixpanel.trackEvent('Arrived at Order Confirmation Page')

      Analytics.identify(
        patientUuid,
        'Patient',
        isTestAccount
      )

      RecordingService.identify(
        patientUuid,
        'Patient'
      )

      CustomerSupportService.identify(
        {
          name: customerDetails.name,
          email: customerDetails.email,
          resourceType: 'Patient',
          uuid: patientUuid
        }
      )
    }
  }, [isSuccess])

  return (
    <Flex direction='column' bg='white' height='100vh'>
      <EmptyTopBar
        borderBottom='solid 1px'
        borderBottomColor='gray.300'
      />
      <AssistancePromptBanner order={order} />

      <Container
        maxW={{ base: 'xl', lg: '6xl' }}
        marginBottom='auto'
        px={{ base: '16px', lg: '24px' }}
      >
        {isLoading
          ? (
            <Center h='200px'>
              <Spinner />
            </Center>
            )
          : (
            <Grid width='100%' templateColumns='repeat(12, 1fr)' h='full' gap={6}>
              <GridItem
                as='main'
                colSpan={{
                  base: 12,
                  lg: 7
                }}
                minH={{ base: 'unset', lg: '600px' }}
              >
                <MainContent order={order} />
              </GridItem>

              <GridItem
                as='nav'
                borderLeft={{ base: '', lg: 'solid 1px' }}
                borderLeftColor={{ lg: 'blackAlpha.300' }}
                colSpan={{
                  base: 12,
                  lg: 5
                }}
                pl={{ base: 0, lg: 6 }}
                pt={{ base: 2, lg: 4 }}
                pb={8}
              >
                <Heading size='lg' display={{ base: 'block', lg: 'none' }} pb={4}>
                  Order Summary
                </Heading>
                <OrderItems order={order} />
                <OrderSummary order={order} pt={8} pb={2} />
              </GridItem>
            </Grid>)}
      </Container>

      <Footer />
    </Flex>
  )
}

export default OrderDetail
