import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Banner } from '../../common/Banner'
import { Menu, MenuButton, MenuList, MenuItem, Button, useToast } from '@chakra-ui/react'
import { IoChevronDown } from 'react-icons/io5'
import { useMutation } from 'react-query'
import { API } from '../../../services/Api'
import Constants from '../../common/constants'
import Mixpanel from '../../../services/Mixpanel'

const CtaMenu = ({ defaultButtonCopy, editOrderHandler }) => {
  const [buttonCopy, setButtonCopy] = useState(defaultButtonCopy)

  const handlePromptChoice = (event) => {
    setButtonCopy(event.target.innerText)
    editOrderHandler(event)
    Mixpanel.trackEvent(
      'Order Confirmation: Select Assistance Required',
      { selectedOption: event.target.innerText }
    )
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<IoChevronDown />}
        fontSize='sm'
        data-test='banner-cta-button'
        borderWidth='1px'
        h='37px'
        borderColor='whiteAlpha.400'
        fontWeight='medium'
        bg='none'
        _hover={{
          background: 'blue.600',
          color: 'white'
        }}
        _active={{
          bg: 'blue.600'
        }}
      >
        {buttonCopy}
      </MenuButton>
      <MenuList color='black'>
        <MenuItem
          onClick={handlePromptChoice}
          value
        >
          Yes
        </MenuItem>
        <MenuItem
          onClick={handlePromptChoice}
          value={false}
        >
          No
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

CtaMenu.propTypes = {
  defaultButtonCopy: PropTypes.string.isRequired,
  editOrderHandler: PropTypes.func.isRequired
}

const AssistancePromptBanner = ({ order }) => {
  const orderQualifiesForAssistance = () => {
    if (!order) return false

    return !order.assistanceRequired && order.containsCpapMachine
  }

  if (orderQualifiesForAssistance()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toast = useToast()

    const handleSuccess = (data) => {
      const { data: order } = data
      const choice = order.assistanceRequired

      if (choice === true) {
        toast({
          position: 'top-right',
          status: 'success',
          description: 'Great, our supplier partner will reach out to you soon after your equipment is delivered.'
        })
      } else {
        toast({
          position: 'top-right',
          status: 'info',
          description: `No problem. If you change your mind, you can reach out to us at ${Constants.doorbell.supportEmail}.`
        })
      }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const editOrderMutation = useMutation(
      params => API.orders.edit(params),
      {
        onSuccess: handleSuccess
      }
    )

    const editOrderHandler = (element) => {
      editOrderMutation.mutate({
        orderConfirmationNumber: order.orderConfirmationNumber,
        assistanceRequired: element.target.value
      })
    }

    const buttonCopy = () => {
      if (order.assistanceRequired) {
        return 'Yes'
      } else if (order.assistanceRequired === null) {
        return 'Select...'
      } else {
        return 'No'
      }
    }

    return (
      <Banner
        data-test='assistance-prompt'
        text='Would you like assistance in setting up your machine?'
        ctaComponent={
          <CtaMenu defaultButtonCopy={buttonCopy} editOrderHandler={editOrderHandler} />
        }
      />
    )
  } else {
    return null
  }
}

AssistancePromptBanner.propTypes = {
  order: PropTypes.object.isRequired
}

export default AssistancePromptBanner
