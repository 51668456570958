import * as React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Icon, Box, HStack } from '@chakra-ui/react'

const DesktopNavItem = ({ icon, label, path, active, ...props }) => {
  return (
    <HStack
      as={RouterLink}
      to={path}
      aria-current={active ? 'page' : undefined}
      spacing='2'
      px='3'
      py='2'
      rounded='md'
      transition='all 0.2s'
      color='white'
      _hover={{
        color: 'white'
      }}
      {...props}
    >
      {icon && (
        <Icon as={icon} boxSize={4} />
      )}
      <Box fontWeight='medium'>{label}</Box>
    </HStack>
  )
}

DesktopNavItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool
}

const MobileNavItem = ({
  label,
  icon,
  path,
  active,
  isMenuOpen,
  toggleMenu,
  ...props
}) => {
  const handleClick = () => (
    isMenuOpen ? toggleMenu() : null
  )

  return (
    <HStack
      as={RouterLink}
      to={path}
      aria-current={active ? 'page' : undefined}
      spacing='2'
      px='3'
      py='2'
      rounded='md'
      transition='all 0.2s'
      color='white'
      _hover={{
        bg: 'whiteAlpha.200'
      }}
      onClick={handleClick}
      {...props}
    >
      {icon && (
        <Icon as={icon} boxSize={4} />
      )}
      <Box fontWeight='medium'>{label}</Box>
    </HStack>
  )
}

MobileNavItem.propTypes = {
  icon: PropTypes.element,
  isMenuOpen: PropTypes.bool,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func,
  active: PropTypes.bool
}

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem
}
