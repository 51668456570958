import React from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import ReactSelect from '../../common/ChakraReactSelect'
import {
  Heading,
  Stack,
  chakra,
  Input,
  FormControl as ChakraFormControl,
  HStack,
  Button
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import { US_STATES } from '../../../utils/us_states'
import { useForm, Controller } from 'react-hook-form'
import { FormControl } from '../../common/FormElements'
import { API } from '../../../services/Api'
import { useNavigate } from 'react-router-dom'
import { ExcludeFromRecording } from '../../../services/RecordingService'

const LABELS = {
  city: 'City',
  email: 'Email',
  name: 'Name',
  state: 'State',
  streetLine1: 'Address Line 1',
  streetLine2: 'Address Line 2',
  zipcode: 'Zip code'
}

const schema = yup.object().shape({
  name: yup.string().label(LABELS.name).required(),
  email: yup.string().email().required(),
  streetLine1: yup.string().label(LABELS.streetLine1).required(),
  city: yup.string().label(LABELS.city).required(),
  state: yup.object().label(LABELS.state).nullable().required(),
  zipcode: yup.string().label(LABELS.zipcode).required()
})

export const ShippingInformation = ({ cart }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()

  const stateOptions = () => (
    Object.keys(US_STATES).map((key) => (
      { value: key, label: US_STATES[key] }
    ))
  )

  const mutation = useMutation(
    createOrder => API.orders.create(createOrder),
    {
      onSuccess: (data) => {
        navigate(`/orders/${data.data.orderConfirmationNumber}?clearSession=true`)
      }
    }
  )

  const onSubmit = (values) => {
    const data = {
      order: {
        cartUuid: cart.uuid,
        customerDetails: {
          name: values.name,
          email: values.email,
          shipping: {
            address: {
              line1: values.streetLine1,
              line2: values.streetLine2,
              city: values.city,
              state: values.state.value,
              country: 'US',
              postal_code: values.zipcode
            }
          }
        }
      }
    }
    mutation.mutate(data)
  }

  return (
    <>
      <Heading size='md' mb={6} data-test='checkout-heading'>
        Shipping Information
      </Heading>
      <ExcludeFromRecording>
        <chakra.form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Heading size='xs' textAlign='left'>
              {LABELS.email}
            </Heading>
            <FormControl
              mt={0}
              isRequired
              errorMessage={errors?.email?.message}
            >
              <Input
                id='email'
                data-test='checkout-shipping-email'
                fontSize='sm'
                {...register('email')}
              />
            </FormControl>

            <Heading size='xs' textAlign='left' pt={4}>
              Shipping Address
            </Heading>

            <ChakraFormControl
              isRequired
              isInvalid={!!errors?.name?.message}
            >
              <Input
                id='name'
                data-test='checkout-shipping-name'
                fontSize='sm'
                placeholder={LABELS.name}
                {...register('name')}
              />
            </ChakraFormControl>

            <Input variant='filled' value='United States' disabled />

            <ChakraFormControl
              isRequired
              isInvalid={!!errors?.streetLine1?.message}
            >
              <Input
                id='streetLine1'
                data-test='checkout-shipping-streetLine1'
                fontSize='sm'
                placeholder={LABELS.streetLine1}
                {...register('streetLine1')}
              />
            </ChakraFormControl>
            <ChakraFormControl
              isInvalid={!!errors?.streetLine2?.message}
            >
              <Input
                id='streetLine2'
                data-test='checkout-shipping-streetLine2'
                fontSize='sm'
                placeholder={LABELS.streetLine2}
                {...register('streetLine2')}
              />
            </ChakraFormControl>
            <HStack>
              <ChakraFormControl
                isRequired
                isInvalid={!!errors?.city?.message}
              >
                <Input
                  id='city'
                  data-test='checkout-shipping-city'
                  fontSize='sm'
                  placeholder={LABELS.city}
                  {...register('city')}
                />
              </ChakraFormControl>

              <ChakraFormControl
                isRequired
                isInvalid={!!errors?.zipcode?.message}
              >
                <Input
                  id='zipcode'
                  data-test='checkout-shipping-zipcode'
                  fontSize='sm'
                  placeholder={LABELS.zipcode}
                  {...register('zipcode')}
                />
              </ChakraFormControl>
            </HStack>

            <ChakraFormControl
              isRequired
              isInvalid={!!errors?.state?.message}
            >
              <Controller
                control={control}
                name='state'
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id='state'
                    data-test='checkout-shipping-state'
                    isInvalid={!!errors?.state?.message}
                    options={stateOptions()}
                    closeMenuOnSelect
                    size='md'
                    fontSize='sm'
                    placeholder={LABELS.state}
                  />
                )}
              />
            </ChakraFormControl>
          </Stack>

          <Button
            colorScheme='green'
            width='full'
            mt={6}
            type='submit'
            data-test='checkout-create-order'
            disabled={mutation.isLoading}
          >
            Place Order
          </Button>
        </chakra.form>
      </ExcludeFromRecording>
    </>
  )
}

ShippingInformation.propTypes = {
  cart: PropTypes.object.isRequired
}
