import React from 'react'
import { Outlet } from 'react-router-dom'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  useColorModeValue
} from '@chakra-ui/react'

const Layout = () => (
  <Flex minHeight='100vh' height='100%' bg={useColorModeValue('gray.50', 'inherit')}>

    <Box display={{ base: 'none', md: 'block' }} w='320px' bg='teal.400' color='white' py={8} px={6}>
      <Heading size='md'>Doorbell Health</Heading>
    </Box>

    <Grid flex='1' templateColumns='repeat(6, 1fr)' gap={8} m={{ base: 5, md: 8 }}>
      <GridItem colSpan={{ base: 6, md: 4 }}>
        <Box>
          <Outlet />
        </Box>
      </GridItem>
    </Grid>

  </Flex>
)

export default Layout
