import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import Analytics from '../../../services/Analytics'
import { Badge, Button } from '@chakra-ui/react'
import { IoCartOutline } from 'react-icons/io5'
import { usePatientSession } from '../../../context/use-patient-session'

export const CartButton = (props) => {
  const { color } = props
  const patientSession = usePatientSession()

  const handleCartClick = () => {
    Analytics.trackEvent({
      category: 'cart',
      action: 'click review cart',
      label: 'product listing page'
    })
  }

  return (
    <Button
      as={RouterLink}
      to='/cart'
      state={{
        triggerSource: 'cart icon'
      }}
      onClick={handleCartClick}
      colorScheme='white'
      aria-label='View cart'
      cursor='pointer'
      fontSize='32px'
      transition='none'
      _hover={{ color }}
      data-test='cart-button'
      position='relative'
      flexShrink={0}
      padding={2}
      {...props}
    >
      <Badge
        background='green.700'
        borderRadius='full'
        color='white'
        minWidth='17px'
        position='absolute'
        py='1px'
        right='5px'
        textAlign='center'
        top='0px'
        data-test='cart-quantity'
      >
        {patientSession.getCartQuantity()}
      </Badge>
      <IoCartOutline />
    </Button>
  )
}

CartButton.propTypes = {
  color: PropTypes.string.isRequired
}
