import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'

import {
  Box,
  Text
} from '@chakra-ui/react'

import {
  IoCaretDown,
  IoCaretUp
} from 'react-icons/io5'

const SortableColumn = ({ label, sortedBy, onSortButtonClick }) => {
  const [direction, setDirection] = useState('unsorted')

  useEffect(() => {
    if (sortedBy !== label) {
      setDirection('unsorted')
    }
  }, [label, sortedBy])

  const handleSortClick = () => {
    switch (direction) {
      case 'up':
        setDirection('down')
        onSortButtonClick('down')
        break
      case 'down':
        setDirection('unsorted')
        onSortButtonClick('unsorted')
        break
      case 'unsorted':
        setDirection('up')
        onSortButtonClick('up')
        break
      default:
        break
    }
  }

  const getSortIcon = () => {
    switch (direction) {
      case 'up':
        return <IoCaretUp color='gray.800' fontSize='17px' />
      case 'down':
        return <IoCaretDown color='gray.800' fontSize='17px' />
      case 'unsorted':
        return <IoCaretDown color='#CBD5E0' fontSize='17px' />
      default:
        return null
    }
  }

  return (
    <Box
      display='flex'
      cursor='pointer'
      onClick={handleSortClick}
      data-test={'sort-button-' + kebabCase(label)}
    >
      <Text marginRight='5px'>{label}</Text>
      {getSortIcon()}
    </Box>
  )
}

SortableColumn.propTypes = {
  label: PropTypes.string,
  onSortButtonClick: PropTypes.func,
  sortedBy: PropTypes.string
}

export default SortableColumn
