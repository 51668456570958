// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './components/App'
import { BrowserRouter } from 'react-router-dom'
import * as RecordingService from './services/RecordingService'

RecordingService.init()

const rootContainer = document.getElementById('root')
const root = createRoot(rootContainer)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
