import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import {
  Text
} from '@chakra-ui/react'

const WorkOrderCount = ({ count, searchText = '' }) => (
  <Text color='gray.600' fontSize='sm' mt='8' mb='6'>
    {count === 0 ? 'No results' : pluralize('work order', count, true)}
    <b>{searchText && ` matching "${searchText}"`}</b>
  </Text>
)

WorkOrderCount.propTypes = {
  count: PropTypes.number.isRequired,
  searchText: PropTypes.string
}

export default WorkOrderCount
