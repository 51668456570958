import React from 'react'
import Constants from '../../common/constants'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import { SvgLogo as Logo } from '../../common/SvgLogo'

import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'

const QRBox = ({ code, ...props }) => {
  return (
    <Box position='absolute' {...props}>
      <QRCode
        value={code}
        size={128}
        level='H'
        renderAs='svg'
      />
    </Box>
  )
}

QRBox.propTypes = {
  code: PropTypes.string.isRequired
}

export default class FaxCoverSheet extends React.PureComponent {
  render () {
    return (
      <Center h='90vh'>
        <QRBox code={this.props.unformattedReferralCode} top='10' left='10' />
        <QRBox code={this.props.unformattedReferralCode} top='10' right='10' />
        <QRBox code={this.props.unformattedReferralCode} bottom='10' left='10' />
        <QRBox code={this.props.unformattedReferralCode} bottom='10' right='10' />

        <Heading
          position='absolute'
          size='md'
          top='10'
        >
          Fax Cover Sheet
        </Heading>

        <VStack spacing='12'>
          <Logo color='black' h='12' />

          <SimpleGrid columns={2} spacing={2}>
            <Text>Doorbell Health Fax</Text>
            <Text>{Constants.doorbell.faxNumber.formatted}</Text>
            <Text>Doorbell Health E-Fax</Text>
            <Text>{Constants.doorbell.faxEmail}</Text>
            <Text>Patient Referral Code</Text>
            <Text>{this.props.formattedReferralCode}</Text>
          </SimpleGrid>
        </VStack>
      </Center>
    )
  }
}

FaxCoverSheet.propTypes = {
  formattedReferralCode: PropTypes.string.isRequired,
  unformattedReferralCode: PropTypes.string.isRequired
}
