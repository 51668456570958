import React, { useState } from 'react'
import * as yup from 'yup'
import CardContainer from '../common/CardContainer'
import useRouteMatching from '../common/useRouteMatching'
import { useQueryParams } from '../../utils/useQueryParams'
import { API } from '../../services/Api'
import { passwordRegex, passwordErrorMessage } from './PasswordField'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import QueryString from 'query-string'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast
} from '@chakra-ui/react'

const schema = yup.object().shape({
  password: yup.string().required().matches(
    passwordRegex,
    passwordErrorMessage
  ),
  passwordConfirmation: yup
    .string()
    .required('Please confirm your password')
    .when('password', {
      is: password => (!!(password && password.length > 0)),
      then: yup.string().oneOf([yup.ref('password')], "Password doesn't match")
    })
})

export default function PasswordReset () {
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)

  const toast = useToast()
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const { isPatientPasswordResetRoute } = useRouteMatching()

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onValidatedSubmit = (values) => {
    setServerState()
    setSubmitting(true)

    const requestConfig = {
      headers: {
        'access-token': queryParams['access-token'],
        client: queryParams.client,
        uid: queryParams.uid,
        expiry: queryParams.expiry
      }
    }

    API.auth.passwordUpdate(values, requestConfig)
      .then(response => {
        handleServerResponse(true, 'Password Reset!')
        setSubmitting(false)

        const successMessage = 'Your password has been reset.\n\nPlease login with your new password.'

        // Redirect to non-react login page
        if (queryParams.return_url.length) {
          const { query } = QueryString.parseUrl(queryParams.return_url)

          query.success_message = successMessage

          // To prevent XSS attacks this parses the URL and only uses the pathname part of the URL
          const sanitizedUrl = window.location.origin + new URL(queryParams.return_url).pathname

          window.location.href = QueryString.stringifyUrl({ url: sanitizedUrl, query })
        } else {
        // Display the default react login page
          toast({
            position: 'top-right',
            title: successMessage,
            status: 'success',
            isClosable: true
          })

          if (isPatientPasswordResetRoute) {
            navigate('/patients/signin')
          } else {
            navigate('/providers/signin')
          }
        }
      })
      .catch(error => {
        const status = error.response.status

        if (status === 401) {
          handleServerResponse(false, 'You are not authorized to perform this action')
        } else {
          handleServerResponse(false, 'Something went wrong')
        }
      })
      .finally(
        () => setSubmitting(false)
      )
  }

  return (
    <CardContainer
      heading='Reset your password'
    >
      <chakra.form onSubmit={handleSubmit(onValidatedSubmit)}>
        {serverState && !serverState.ok && (
          <Box mb={8}>
            <Alert status='error' borderRadius={4}>
              <AlertIcon />
              <AlertDescription>{serverState.msg}</AlertDescription>
            </Alert>
          </Box>
        )}
        <Stack spacing='6'>
          <FormControl
            id='password'
            isInvalid={!!errors?.password?.message}
            errortext='errors?.password?.message'
          >
            <FormLabel>Password</FormLabel>
            <Input name='password' type='password' autoComplete='password' {...register('password')} />
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id='passwordConfirmation'
            isInvalid={!!errors?.passwordConfirmation?.message}
            errortext='errors?.passwordConfirmation?.message'
          >
            <FormLabel>Password Confirmation</FormLabel>
            <Input name='passwordConfirmation' type='password' autoComplete='passwordConfirmation' {...register('passwordConfirmation')} />
            <FormErrorMessage>{errors?.passwordConfirmation?.message}</FormErrorMessage>
          </FormControl>
          <Button
            isLoading={submitting}
            type='submit'
            colorScheme='blue'
            size='lg'
            fontSize='md'
            disabled={submitting || !!errors?.password || !!errors?.passwordConfirmation}
          >
            Reset Password
          </Button>
        </Stack>
      </chakra.form>
    </CardContainer>
  )
}
