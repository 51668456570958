import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

export const FilterButton = ({ text, variant = 'outline', ...props }) => (
  <Button
    colorScheme='green'
    variant={variant}
    bg={variant === 'solid' ? 'green.700' : 'initial'}
    size='sm'
    mt='16px'
    transition='none'
    {...props}
  >
    {text}
  </Button>
)
FilterButton.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string.isRequired
}
