import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Text
} from '@chakra-ui/react'

const ShipmentCartItem = ({ shipmentCartItem, ...props }) => {
  const {
    cartItem: {
      quantity,
      productVariant: {
        productTitle,
        product: { manufacturer }
      }
    }
  } = shipmentCartItem

  return (
    <Box {...props}>
      <Text fontSize='sm'>
        {manufacturer} - {productTitle}
      </Text>
      <Text fontSize='sm' color='gray.500'>
        Quantity: {quantity}
      </Text>
    </Box>
  )
}

ShipmentCartItem.propTypes = {
  shipmentCartItem: PropTypes.object.isRequired
}

export default ShipmentCartItem
