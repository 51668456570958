import React from 'react'
import PropTypes from 'prop-types'
import { VStack } from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'

import Summary from './Summary'
import Shipments from './Shipments'
import ItemsPendingShipment from './ItemsPendingShipment'

const OrderHistoryDetails = ({ order }) => {
  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })

  const { shipments, unshippedCartItems } = order

  return (
    <VStack spacing='6' align='left'>
      <Summary order={order} isMobile={isMobile} />
      <Shipments
        shipments={shipments}
        isMobile={isMobile}
        orderConfirmationNumber={order.orderConfirmationNumber}
      />
      <ItemsPendingShipment unshippedItems={unshippedCartItems} />
    </VStack>
  )
}

OrderHistoryDetails.propTypes = {
  order: PropTypes.object
}

export default OrderHistoryDetails
