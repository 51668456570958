export const members = [
  {
    role: 'Head of Growth',
    image:
      'https://public.doorbellhealth.com/team/Fadhi+Ali.png',
    name: 'Fadhi Ali',
    linkedIn: 'https://www.linkedin.com/in/fadhi-ali/',
    description: 'I’m here to help build solutions that will improve the lives of patients and equally here to help build an organization that people want to be a part of.'
  },
  {
    role: 'Founding Engineer',
    image:
      'https://public.doorbellhealth.com/team/Drew+Baumann.png',
    name: 'Drew Baumann',
    linkedIn: 'https://www.linkedin.com/in/drewbaumann/',
    description: "I am here to work with talented and thoughtful partners to create products that enrich patients' lives."
  },
  {
    role: 'Software Engineer',
    image:
      'https://public.doorbellhealth.com/team/Agusti%CC%81n+Brandoni.png',
    name: 'Agustín Brandoni',
    linkedIn: 'https://www.linkedin.com/in/agustinbrandoni/',
    description: 'I am here to build high-quality software within an amazing team.'
  },
  {
    role: 'Founding Engineer',
    image:
      'https://public.doorbellhealth.com/team/Loren+Burton.png',
    name: 'Loren Burton',
    linkedIn: 'https://www.linkedin.com/in/aguynamedloren/',
    description: 'I enjoy building technology to improve efficiency in healthcare systems.'
  },
  {
    role: 'Co-Founder and CEO',
    image:
      'https://public.doorbellhealth.com/team/Ben+Golombek.png',
    name: 'Ben Golombek',
    linkedIn: 'https://www.linkedin.com/in/benjamin-golombek/',
    description: 'I’m here to use technology to help people in moments when they need help most.'
  },
  {
    role: 'Head of Engineering',
    image:
      'https://public.doorbellhealth.com/team/Khang+Pham.png',
    name: 'Khang Pham',
    linkedIn: 'https://www.linkedin.com/in/khang-pham-0892a54/',
    description: "Working with great people to solve important healthcare issues that improve patients' quality of care."
  },
  {
    role: 'Head of Operations',
    image:
      'https://public.doorbellhealth.com/team/SarahZhou.png',
    name: 'Sarah Pei Chang Zhou',
    linkedIn: 'https://www.linkedin.com/in/sarah-pei-chang-z-21078021/',
    description: 'I want to be a part of this endeavor that helps folks age with dignity.'
  },
  {
    role: 'Co-Founder and Advisor',
    image:
      'https://public.doorbellhealth.com/team/SebastianMessier.png',
    name: 'Sebastian Messier',
    linkedIn: 'https://www.linkedin.com/in/sebastian-messier-a1b35098/',
    description: 'I started Doorbell Health to fix the broken medical equipment acquisition process.'
  }
]
