import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme, Flex, Container, Button, Box, HStack } from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { useMobileMenuState } from '../../common/useMobileMenuState'
import useRouteMatching from '../../common/useRouteMatching'
import Logo from '../../common/SvgLogo'
import { NavMenu } from '../NavMenu/NavMenu'
import { MobileHamburgerMenu } from './MobileHamburgerMenu'
import { PrescriptionButton } from './PrescriptionButton'
import { CartButton } from './CartButton'
import { isAuthenticated } from '../../../context/use-auth'
import { PatientAuth } from '../NavMenu/PatientAuth'

export const TopBar = ({ topBannersHeight }) => {
  const { isMenuOpen, toggle } = useMobileMenuState()
  const theme = useTheme()
  const mobileBreakpointValue = '(max-width: 62em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })

  const {
    isCartRoute,
    isProductRoute,
    isShopRoute
  } = useRouteMatching()

  const isLoggedIn = isAuthenticated()

  const showPrescriptionButton = isCartRoute || isProductRoute || isShopRoute

  const SignInButton = () => (
    <Box pl={2}>
      <Button
        as={RouterLink}
        data-test='sign-in'
        variant='outline'
        color='gray.600'
        to='/patients/signin'
        display={{
          base: 'none',
          lg: 'flex'
        }}
      >
        Sign In
      </Button>
    </Box>
  )

  return (
    <Flex
      align='center'
      bg={{
        base: isMenuOpen ? 'green.800' : 'white',
        lg: 'white'
      }}
      color='black'
      px={{ base: 3, lg: 6 }}
      minH={{ base: '4rem', lg: '4.5rem' }}
      borderBottom='solid 1px'
      borderBottomColor='gray.300'
    >
      <Container maxW='7xl' px={{ base: 4, lg: 0 }}>
        <Flex align='center'>
          <Flex
            justify={{ base: 'flex-start', lg: 'space-between' }}
            align='center'
            w='full'
          >
            <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
            <NavMenu.Mobile isOpen={isMenuOpen} topBannersHeight={topBannersHeight} toggleMenu={toggle} />
            <Logo.Mobile color={isMenuOpen ? 'white' : theme.colors.green[600]} />

            <Logo.Desktop />
            <NavMenu.Desktop />
          </Flex>
          <HStack spacing={2}>
            {showPrescriptionButton && <PrescriptionButton whiteColor={isMenuOpen} />}
            <CartButton color={isMenuOpen ? 'white' : 'black'} />
            {window.location.hostname !== 'www.doorbellhealth.com' && !isMobile
              ? isLoggedIn
                ? <PatientAuth.Desktop
                    display={{
                      base: 'none',
                      lg: 'flex'
                    }}
                  />
                : <SignInButton />
              : null}
          </HStack>
        </Flex>
      </Container>
    </Flex>
  )
}

TopBar.propTypes = {
  topBannersHeight: PropTypes.number
}
