import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from '../common/ChakraReactSelect'
import {
  useToast,
  FormLabel,
  Box
} from '@chakra-ui/react'
import HcpcsCodeForm from './HcpcsCodeForm'

const HcpcsSelection = ({
  hcpcsCodes,
  handleAddHcpcs,
  handleRemoveHcpcs,
  setProductVerticalName
}) => {
  const [forms, setForms] = useState([])
  const [productVertical, setProductVertical] = useState(null)
  const toast = useToast()

  const findHcpcs = (code) => (
    hcpcsCodes?.data?.find(hcpcs => hcpcs.code === code)
  )

  const AllHcpcsOptions = hcpcsCodes?.data?.map(hcpcs => ({
    value: hcpcs.code,
    label: `${hcpcs.code} ${hcpcs.alias}`
  }))

  const hcpcsOptions = AllHcpcsOptions?.filter(option => {
    const selectedHcpcsCodes = forms.map(form => form.hcpcsCode.code)

    return !selectedHcpcsCodes.includes(option.value)
  })

  const handleHcpcsCodeChange = (option) => {
    const hcpcsCode = findHcpcs(option.value)

    if (!productVertical) {
      setProductVertical(hcpcsCode.productVertical)
      setProductVerticalName(hcpcsCode.productVertical.name)
    } else if (productVertical.name !== hcpcsCode.productVertical.name) {
      return toast({
        position: 'top-right',
        title: 'We cannot add ' + hcpcsCode.productVertical.name + ' HCPCs codes. This form already contains ' + productVertical.name + ' HCPCs codes.',
        status: 'info',
        duration: null,
        isClosable: true
      })
    }

    setForms([{
      hcpcsCode,
      hcpcsLabel: option.label,
      hcpcsDescription: hcpcsCode.description,
      productVertical: hcpcsCode.productVertical.name,
      productCategory: hcpcsCode.productCategory.name,
      hcpcsCodeIcd10S: hcpcsCode.hcpcsCodeIcd10S
    }, ...forms])
  }

  const handleAdd = (form, values) => {
    handleAddHcpcs(
      {
        ...form.hcpcsCode,
        ...values
      })
  }

  const handleRemove = (form) => {
    if (forms.length === 1) {
      setProductVertical(null)
      setProductVerticalName('')
    }

    setForms(forms.filter(f => f.hcpcsCode.code !== form.hcpcsCode.code))

    handleRemoveHcpcs(form.hcpcsCode)
  }

  return (
    <Box w='100%'>
      <FormLabel fontSize='sm' color='gray.500' mb={3} mr={1}>
        HCPCs Code
      </FormLabel>
      <ReactSelect
        id='hcpcs-code-selector'
        options={hcpcsOptions}
        value={null}
        placeholder='Type HCPCs code or description'
        closeMenuOnSelect
        size='md'
        fontSize='sm'
        onChange={handleHcpcsCodeChange}
      />

      <Box my={8}>
        {forms.map((form) => {
          return (
            <HcpcsCodeForm
              key={form.hcpcsCode.code}
              form={form}
              handleRemove={handleRemove}
              handleAdd={handleAdd}
            />
          )
        })}
      </Box>
    </Box>
  )
}

HcpcsSelection.propTypes = {
  hcpcsCodes: PropTypes.object,
  handleAddHcpcs: PropTypes.func,
  handleRemoveHcpcs: PropTypes.func,
  setProductVerticalName: PropTypes.func
}

export default HcpcsSelection
