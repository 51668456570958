import React from 'react'
import PropTypes from 'prop-types'
import {
  Spinner as ChakraSpinner,
  Text,
  VStack
} from '@chakra-ui/react'

export const Spinner = ({ labelText, ...props }) => {
  return (
    <VStack align='center' py={20}>
      <ChakraSpinner
        thickness='4px'
        emptyColor='gray.200'
        color='green.600'
        size='lg'
        {...props}
      />
      <Text fontSize='sm' color='gray.600'>{labelText}</Text>
    </VStack>
  )
}
Spinner.propTypes = {
  labelText: PropTypes.string
}
