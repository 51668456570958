import React from 'react'
import PropTypes from 'prop-types'
import { useInsuranceModal } from '../../../context/use-insurance-modal'

import {
  HStack,
  Text,
  Link
} from '@chakra-ui/react'

const RetailPrice = ({ prices, showUnlockInsuranceLink }) => {
  const insuranceModal = useInsuranceModal()

  return (
    <>
      <HStack align='baseline' spacing={1} mb={{ base: '0', lg: '2' }}>
        <Text fontWeight='medium' fontSize='lg' data-test='detail-retail-price'>
          {prices.patientCost}
        </Text>
        <Text fontSize='sm'>Retail</Text>
      </HStack>
      {showUnlockInsuranceLink &&
        <Link
          data-test='product-insurance-savings-link'
          borderBottom='1px'
          color='blue.600'
          fontSize='sm'
          fontWeight='600'
          _hover={{ textDecoration: 'none' }}
          onClick={insuranceModal.handleOpenModal}
        >
          Unlock insurance savings
        </Link>}
    </>
  )
}

RetailPrice.propTypes = {
  prices: PropTypes.object.isRequired,
  showUnlockInsuranceLink: PropTypes.bool.isRequired
}

export default RetailPrice
