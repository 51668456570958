import React, { useEffect } from 'react'
import {
  ModalBody,
  ModalFooter,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react'
import Mixpanel from '../../../services/Mixpanel'
import ActionItems from './ActionItems'
import ProgressIndicators from './ProgressIndicators'

const StepOne = () => {
  useEffect(() => {
    Mixpanel.trackEvent('Onboarding Wizard: Arrive at welcome screen')
  }, [])
  return (
    <>
      <ModalBody>
        <VStack spacing={4} px={{ md: 40 }}>
          <Heading color='white' textAlign='center' pt={12} size='2xl'>
            Welcome to Doorbell Health!
          </Heading>

          <Text textAlign='center' color='white' fontWeight={700} fontSize='xl'>
            We are delighted that you have chosen us for your durable medical
            equipment. The next steps will set you up for a smooth
            shopping experience.
          </Text>
        </VStack>
      </ModalBody>
      <ModalFooter alignSelf={{ base: 'center' }}>
        <VStack>
          <ActionItems buttonCopy='Next' />
          <ProgressIndicators amount={4} />
        </VStack>
      </ModalFooter>
    </>
  )
}
export default StepOne
