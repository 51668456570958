import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'
import SearchFilter from './index'

export const MobileSearchFilter = ({ onCloseClick }) => (
  <>
    <SearchFilter hideSearchButton autoFocus />
    <Button
      variant='ghost'
      onClick={onCloseClick}
      size='xs'
      color='gray.600'
      data-test='filters-mobile-close'
    >
      Close
    </Button>
  </>
)
MobileSearchFilter.propTypes = {
  onCloseClick: PropTypes.func.isRequired
}
