import React from 'react'
import { Heading } from '@chakra-ui/react'

const AccountSettings = () => (
  <Heading size='lg' color='black' fontWeight='bold'>
    Account Settings
  </Heading>
)

export default AccountSettings
