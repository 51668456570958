import * as React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  VStack,
  StackDivider
} from '@chakra-ui/react'

const RoundedBox = ({ children, ...props }) => (
  <Box
    border='1px solid'
    borderColor='gray.200'
    borderRadius='12'
    {...props}
  >
    <VStack
      align='left'
      spacing='0'
      divider={<StackDivider borderColor='gray.200' />}
    >
      {children}
    </VStack>
  </Box>
)

RoundedBox.propTypes = {
  children: PropTypes.node
}

export default RoundedBox
