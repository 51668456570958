import React from 'react'
import PropTypes from 'prop-types'
import { Heading as ChakraHeading } from '@chakra-ui/react'

export const Heading = ({ children }) => (
  <ChakraHeading as='h1' size='lg' pb={{ base: 8, md: 9 }} textAlign='center'>
    {children}
  </ChakraHeading>
)
Heading.propTypes = {
  children: PropTypes.string.isRequired
}
