import React, { useState } from 'react'
import * as yup from 'yup'
import CardContainer from '../common/CardContainer'
import useRouteMatching from '../common/useRouteMatching'
import { API } from '../../services/Api'
import { Link as RouterLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ExcludeFromRecording } from '../../services/RecordingService'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast
} from '@chakra-ui/react'

const schema = yup.object().shape({
  email: yup.string().email().required()
})

export default function PasswordRecovery () {
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)

  const toast = useToast()
  const { isPatientPasswordRecoveryRoute } = useRouteMatching()

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onValidatedSubmit = (values) => {
    setServerState()
    setSubmitting(true)

    const redirectPath = isPatientPasswordRecoveryRoute
      ? '/patients/password_reset'
      : '/auth/password_reset'

    const params = {
      email: values.email,
      redirect_url: window.location.origin + redirectPath
    }

    API.auth.passwordReset(params)
      .then(response => {
        handleServerResponse(true, 'Email Sent!')

        toast({
          position: 'top-right',
          title: response.data.message,
          status: 'success',
          isClosable: true
        })
      })
      .catch(error => {
        const errorMessage = error.response.data?.errors?.join(' ') ||
          "We're sorry, but something went wrong."

        handleServerResponse(false, errorMessage)
      })
      .finally(
        () => setSubmitting(false)
      )
  }

  function ButtonPresenter () {
    if (serverState?.ok) {
      return (
        <Button
          variant='outline'
          as={RouterLink}
          to={isPatientPasswordRecoveryRoute ? '/patients/signin' : '/providers/signin'}
        >
          Return to Sign In
        </Button>
      )
    } else {
      return (
        <Button
          isLoading={submitting}
          type='submit'
          colorScheme='blue'
          size='lg'
          fontSize='md'
          disabled={submitting || !!errors?.email}
        >
          Send Recovery Email
        </Button>
      )
    }
  }

  return (
    <CardContainer heading='Recover your password'>
      <chakra.form onSubmit={handleSubmit(onValidatedSubmit)}>
        {serverState && !serverState.ok && (
          <Box mb={8}>
            <Alert status='error' borderRadius={4}>
              <AlertIcon />
              <AlertDescription>{serverState.msg}</AlertDescription>
            </Alert>
          </Box>
        )}
        <Stack spacing='6'>
          <FormControl
            id='email'
            isInvalid={!!errors?.email?.message}
            errortext='errors?.email?.message'
          >
            <FormLabel>Email address</FormLabel>
            <ExcludeFromRecording>
              <Input name='email' autoComplete='email' {...register('email')} />
            </ExcludeFromRecording>
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <ButtonPresenter />
        </Stack>
      </chakra.form>
    </CardContainer>
  )
}
