import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useFilters } from '../Filters/useFilters'
import { FilterButton } from '../Filters/FilterButton'

export const NoResults = () => {
  const filters = useFilters()

  const handleClearFiltersClick = () => {
    filters.reset()
  }

  return (
    <Box align='left'>
      <Text
        fontSize='sm'
        color='blackAlpha.600'
        data-test='products-no-results-message'
        mt={{ base: '24px', lg: '0' }}
      >
        Sorry, there are no products that match your search. Clear your filters and try again.
      </Text>
      <FilterButton
        text='Clear all filters'
        variant='solid'
        onClick={handleClearFiltersClick}
        mt='24px'
        data-test='products-clear-filters-button'
      />
    </Box>
  )
}
