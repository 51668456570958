import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import {
  Box,
  Center,
  Heading,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { ExcludeFromRecording } from '../../../services/RecordingService'
import { API } from '../../../services/Api'

import ShopButton from './ShopButton'
import OrdersDropdown from './OrdersDropDown'
import OrderHistoryDetails from './OrderHistoryDetails'

const OrderHistory = () => {
  const [selectedOrder, setSelectedOrder] = useState()

  const fetchOrders = async () => {
    const { data } = await API.orders.index()

    return data
  }

  const { isFetched, isLoading, isSuccess, data: orders } = useQuery(
    'fetchOrders',
    fetchOrders,
    {
      retry: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )
  const hasOrders = orders?.length > 0

  const handleOrderChange = (order) => (
    setSelectedOrder(order)
  )

  useEffect(() => {
    if (hasOrders) {
      setSelectedOrder(orders[0])
    }
    /* eslint-disable-next-line */
  }, [hasOrders])

  return (
    <Box data-test='order-history'>
      <Heading mb='10' size='lg' color='black' fontWeight='bold'>
        Order History
      </Heading>

      {!isFetched && isLoading && (
        <Center h='200px'>
          <Spinner />
        </Center>
      )}

      {isSuccess && (
        <VStack align='left' spacing='7'>
          {!hasOrders && (
            <>
              <Text
                color='blackAlpha.600'
                fontSize='sm'
                data-test='no-insurance-message'
              >
                You haven’t made a purchase yet!
              </Text>
              <ShopButton />
            </>
          )}

          {selectedOrder && (
            <ExcludeFromRecording>
              <OrdersDropdown
                orders={orders}
                selectedOrder={selectedOrder}
                onChange={handleOrderChange}
              />
              <OrderHistoryDetails order={selectedOrder} />
            </ExcludeFromRecording>
          )}
        </VStack>
      )}
    </Box>
  )
}

export default OrderHistory
