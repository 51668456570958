import React from 'react'
import PropTypes from 'prop-types'
import { Stack, VStack, Img, Text } from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { Section } from '../Section'
import { Heading } from '../Sections/Heading'

const HowToes = ({ assets, ...props }) => {
  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobileBreakpoint = useMediaQuery({ query: mobileBreakpointValue })

  return (
    <>
      {assets.map(({ title, description, src, position }, i) => (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 4, md: 16 }}
          key={i}
          margin='auto'
          mb={{ base: 12, md: 14 }}
          align={{ base: 'left', md: 'center' }}
          {...props}
        >
          {position === 'left' || isMobileBreakpoint
            ? <DescriptionImage src={src} title={title} />
            : null}

          <VStack spacing={{ base: 0, md: 1 }} align='left'>
            <Text
              fontSize={{ base: 'xl', md: '2xl' }}
              fontWeight='600'
              align={{ base: 'center', md: 'left' }}
            >
              {title}
            </Text>
            <Text fontSize='lg' fontWeight='400' align={{ base: 'center', md: 'left' }}>
              {description}
            </Text>
          </VStack>

          {position === 'right' && !isMobileBreakpoint
            ? <DescriptionImage src={src} title={title} />
            : null}
        </Stack>)
      )}
    </>
  )
}

HowToes.propTypes = {
  assets: PropTypes.array.isRequired
}

const DescriptionImage = ({ src, title }) => {
  return (
    <Img
      w={{ base: '130px', md: 'auto' }}
      src={src}
      margin='auto'
      alt={`${title} image`}
    />
  )
}
DescriptionImage.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export const HowItWorks = ({
  assets,
  bottomMessage = '',
  wideVariant = false
}) => (
  <Section
    data-test='landing-page-how-it-works-section'
  >
    <Heading>How It Works</Heading>
    <HowToes assets={assets} maxW={wideVariant ? '800px' : '675px'} />
    {bottomMessage
      ? (
        <Text
          fontSize={{ base: 'xl', md: '2xl' }}
          fontWeight='600'
          align='center'
          mb='50px'
        >
          {bottomMessage}
        </Text>
        )
      : null}
  </Section>
)

HowItWorks.propTypes = {
  assets: PropTypes.array.isRequired,
  bottomMessage: PropTypes.string,
  wideVariant: PropTypes.bool
}
