import {
  Box,
  Flex,
  HStack,
  Img,
  Link,
  Text,
  useColorModeValue,
  VisuallyHidden
} from '@chakra-ui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FaLinkedinIn } from 'react-icons/fa'

export const Member = (props) => {
  const { image, name, role, linkedIn, children } = props
  return (
    <Flex direction='column' align='center' textAlign='center'>
      <Img alt={name} w='40' h='40' rounded='full' objectFit='cover' src={image} />
      <Box mt='4'>
        <Text fontWeight='bold' fontSize='lg' data-test='team-page-member-name'>
          {name}
        </Text>
        <Text
          fontWeight='semibold'
          color={useColorModeValue('gray.500', 'whiteAlpha.700')}
          data-test='team-page-member-role'
        >
          {role}
        </Text>
      </Box>
      <Text
        mt='2'
        color={useColorModeValue('gray.600', 'whiteAlpha.900')}
        maxW={{
          base: 'unset',
          md: '20rem'
        }}
        data-test='team-page-member-description'
      >
        {children}
      </Text>
      <HStack mt='5' spacing='3'>
        <Link
          isExternal
          color={useColorModeValue('blue.600', 'blue.300')}
          href={linkedIn}
          data-test='team-page-member-linked-in-link'
        >
          <VisuallyHidden>{`${name}'s Linkedin page`}</VisuallyHidden>
          <FaLinkedinIn aria-hidden />
        </Link>
      </HStack>
    </Flex>
  )
}

Member.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  linkedIn: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
