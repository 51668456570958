import { Box, Heading, Image, Center, AspectRatio } from '@chakra-ui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

const ImageWithOverlay = (props) => {
  const {
    title,
    src,
    isSelected = false,
    ...rest
  } = props

  let borderProps = {}

  if (isSelected) {
    borderProps = {
      borderWidth: '3px',
      borderColor: 'yellow.400'
    }
  }

  return (
    <AspectRatio ratio={4 / 3} minWidth='200px'>
      <Box
        borderRadius='xl'
        overflow='hidden'
        position='relative'
        maxWidth='240px'
        {...borderProps}
        {...rest}
      >
        <Image
          boxSize='full'
          minHeight='220px'
          src={src}
          alt={title}
          objectFit='cover'
          objectPosition='cover'
          fallback={<Box bg='purple.500' h='220px' w='240px' />}
        />
        <Box
          position='absolute'
          inset='0'
          bgGradient='linear(to-t, blackAlpha.300 20%, blackAlpha.700)'
          px={6}
          py={6}
          boxSize='full'
        >
          <Center height='100%'>
            <Heading size='lg' color='white' textAlign='center'>
              {title}
            </Heading>
          </Center>
        </Box>
      </Box>
    </AspectRatio>
  )
}

ImageWithOverlay.propTypes = {
  isSelected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
  spacing: PropTypes.string
}

export default ImageWithOverlay
