import React from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { useColorMode, Input } from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'

export const DatePicker = ({ selected, onChange, minDate, maxDate, isClearable = false, showPopperArrow = false, ...props }) => {
  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })
  let input

  if (isMobile === true) {
    // supports the case where a user changes their browser window size
    // while utilizing this component
    if (typeof selected === 'object') {
      selected = dayjs(selected).format('YYYY-MM-DD')
    }

    input = (
      <Input
        onChange={onChange}
        type='date'
        min={minDate}
        max={maxDate}
        fontSize='sm'
        value={selected}
        {...props}
      />
    )
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isLight = useColorMode().colorMode === 'light' // you can check what theme you are using right now however you want

    if (typeof selected === 'string') {
      selected = dayjs(selected).toDate()
    }

    input = (
      <div className={isLight ? 'light-theme' : 'dark-theme'}>
        <ReactDatePicker
          autoComplete='off'
          selected={selected}
          onChange={onChange}
          isClearable={isClearable}
          showPopperArrow={showPopperArrow}
          minDate={minDate}
          maxDate={maxDate}
          className='react-datapicker__input-text' // input is white by default and there is no already defined class for it so I created a new one
          {...props}
        />
      </div>
    )
  }

  return (
    input
  )
}

DatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  showPopperArrow: PropTypes.bool
}

export default DatePicker
