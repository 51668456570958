import React from 'react'
import PropTypes from 'prop-types'
import Airbrake from '../../services/Airbrake'
import { Box, Heading, Text, Center, VStack } from '@chakra-ui/react'
import { omitDeep } from 'deepdash-es/standalone'
import Layout from '../LandingPage/Layout'

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  // This isn't a super designed error page, but it's a good place to start.
  return (
    <Layout renderChildren>
      <Box as='main' minHeight='900px' p={12}>
        <Center>
          <VStack spacing={4}>
            <Heading fontSize='5xl'>Something went wrong:</Heading>
            <Text>{error.message}</Text>
          </VStack>
        </Center>
      </Box>
    </Layout>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired
}

export const errorHandler = (error, info) => {
  // Fetches the cookie for the doorbellhealth_sessionId
  const cookie =
      document.cookie.split('; ').find(c => c.startsWith('doorbellhealth_sessionId'))

  if (cookie) {
    const sessionId = cookie.split('=')[1]
    let patientSession
    // Fetches the patientSession from localStorage
    try {
      patientSession = JSON.parse(localStorage.getItem('patientSession'))
    } catch (error) {
      localStorage.setItem('patientSession', JSON.stringify({}))
      patientSession = {}
    }

    // Removes the sensitive data from the patientSession using omitDeep
    const keysToOmit = [
      'insuranceMemberId',
      'patientEmail',
      'patientName',
      'patientPhone',
      'patientPrimaryContact',
      'referralCode'
    ]
    const patientSessionOmitted = omitDeep(patientSession, keysToOmit)

    Airbrake.notify({
      error: error.message,
      params: { info },
      context: { patientSessionId: sessionId, patientSession: patientSessionOmitted }
    })
  } else {
    Airbrake.notify({
      error: error.message,
      params: { info }
    })
  }
}

errorHandler.propTypes = {
  error: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired
}

export default ErrorFallback
