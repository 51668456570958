import * as React from 'react'
import PropTypes from 'prop-types'
import { chakra, useTheme } from '@chakra-ui/react'

export const SvgLogo = (props) => {
  const theme = useTheme()
  const { color = theme.colors.green[600] } = props

  return (
    <chakra.svg
      aria-hidden
      viewBox='0 0 150 33'
      fill='none'
      h='33px'
      flexShrink={0}
      data-test='logo'
      {...props}
    >
      <path d='M24.1366 12.4857C25.0769 12.4857 25.9063 12.6857 26.6274 13.0831C27.3485 13.4831 27.9111 14.0568 28.3178 14.8068C28.7246 15.5568 28.9253 16.4515 28.9253 17.491C28.9253 18.5305 28.722 19.4253 28.3178 20.1753C27.9111 20.9253 27.3485 21.499 26.6274 21.8963C25.9063 22.2937 25.0769 22.4937 24.1366 22.4937C23.1962 22.4937 22.3669 22.2963 21.6458 21.8963C20.9247 21.499 20.3594 20.9253 19.9447 20.1753C19.5327 19.4253 19.3267 18.5305 19.3267 17.491C19.3267 16.4515 19.5327 15.5568 19.9447 14.8068C20.3568 14.0568 20.922 13.4831 21.6458 13.0831C22.3669 12.6857 23.1989 12.4857 24.1366 12.4857ZM24.1366 14.1015C23.5581 14.1015 23.0642 14.2331 22.66 14.4963C22.2533 14.7594 21.9416 15.141 21.7224 15.641C21.5031 16.141 21.3922 16.7568 21.3922 17.4884C21.3922 18.2068 21.5031 18.82 21.7224 19.3279C21.9416 19.8358 22.2533 20.22 22.66 20.4832C23.0668 20.7463 23.5581 20.8779 24.1366 20.8779C24.715 20.8779 25.2063 20.7463 25.6052 20.4832C26.004 20.22 26.313 19.8358 26.5323 19.3279C26.7515 18.8226 26.8624 18.2095 26.8624 17.4884C26.8624 16.7568 26.7515 16.1436 26.5323 15.641C26.313 15.141 26.004 14.7594 25.6052 14.4963C25.2063 14.2331 24.715 14.1015 24.1366 14.1015Z' fill={color} />
      <path d='M35.7269 12.4857C36.6672 12.4857 37.4966 12.6857 38.2177 13.0831C38.9388 13.4831 39.5014 14.0568 39.9082 14.8068C40.3149 15.5568 40.5157 16.4515 40.5157 17.491C40.5157 18.5305 40.3123 19.4253 39.9082 20.1753C39.5014 20.9253 38.9388 21.499 38.2177 21.8963C37.4966 22.2937 36.6672 22.4937 35.7269 22.4937C34.7866 22.4937 33.9572 22.2963 33.2361 21.8963C32.515 21.499 31.9498 20.9253 31.5351 20.1753C31.1204 19.4253 30.917 18.5305 30.917 17.491C30.917 16.4515 31.123 15.5568 31.5351 14.8068C31.9471 14.0568 32.5124 13.4831 33.2361 13.0831C33.9545 12.6857 34.7866 12.4857 35.7269 12.4857ZM35.7269 14.1015C35.1484 14.1015 34.6545 14.2331 34.2504 14.4963C33.8436 14.7594 33.5319 15.141 33.3127 15.641C33.0935 16.141 32.9825 16.7568 32.9825 17.4884C32.9825 18.2068 33.0935 18.82 33.3127 19.3279C33.5319 19.8358 33.8436 20.22 34.2504 20.4832C34.6572 20.7463 35.1484 20.8779 35.7269 20.8779C36.3054 20.8779 36.7966 20.7463 37.1955 20.4832C37.5943 20.22 37.9034 19.8358 38.1226 19.3279C38.3418 18.8226 38.4528 18.2095 38.4528 17.4884C38.4528 16.7568 38.3418 16.1436 38.1226 15.641C37.9034 15.141 37.5943 14.7594 37.1955 14.4963C36.7966 14.2331 36.3054 14.1015 35.7269 14.1015Z' fill={color} />
      <path d='M43.1226 22.2989V12.6778H44.8817L45.0745 14.4094C45.3334 13.7804 45.7137 13.3041 46.2235 12.9751C46.7333 12.6488 47.3593 12.483 48.1068 12.483C48.2996 12.483 48.4924 12.4988 48.6852 12.533C48.8781 12.5646 49.0392 12.612 49.1686 12.6751L48.8807 14.4831C48.7381 14.4331 48.5822 14.3909 48.4079 14.3594C48.2336 14.3278 47.9985 14.312 47.7027 14.312C47.3012 14.312 46.9103 14.4199 46.5246 14.6304C46.139 14.841 45.8194 15.1594 45.5685 15.5831C45.3175 16.0068 45.1934 16.5462 45.1934 17.1989V22.2989H43.1226Z' fill={color} />
      <path d='M56.2975 22.491C55.7296 22.491 55.2119 22.3831 54.7417 22.1647C54.2715 21.9463 53.8753 21.641 53.5557 21.2515C53.2335 20.8594 52.9958 20.4199 52.8399 19.9331L53.0909 19.6041L52.9377 22.2989H51.123V8.34875H53.1886V15.1041L52.9588 14.7751C53.2018 14.1067 53.6218 13.5593 54.2134 13.1304C54.8051 12.7014 55.5209 12.4856 56.3582 12.4856C57.1295 12.4856 57.8427 12.6856 58.4924 13.083C59.1422 13.483 59.6546 14.0541 60.0271 14.7962C60.3995 15.5409 60.587 16.4462 60.587 17.5094C60.587 18.5489 60.3968 19.441 60.0165 20.1831C59.6361 20.9278 59.1211 21.4989 58.4713 21.8963C57.8189 22.2936 57.0952 22.491 56.2975 22.491ZM55.8511 20.8384C56.6884 20.8384 57.3408 20.541 57.811 19.9436C58.2811 19.3462 58.5162 18.5278 58.5162 17.4883C58.5162 16.4488 58.2864 15.6357 57.8215 15.0462C57.3593 14.4567 56.7069 14.1593 55.8722 14.1593C55.3307 14.1593 54.8606 14.2988 54.4617 14.5725C54.0602 14.8488 53.7486 15.2383 53.524 15.7383C53.2995 16.2383 53.1859 16.8357 53.1859 17.5278C53.1859 18.2067 53.2942 18.7936 53.5135 19.2883C53.7327 19.7831 54.0444 20.1647 54.4512 20.4357C54.8579 20.7015 55.3228 20.8384 55.8511 20.8384Z' fill={color} />
      <path d='M67.4811 22.491C66.5144 22.491 65.6665 22.2937 64.9322 21.8937C64.1979 21.4963 63.6274 20.9226 63.2232 20.1726C62.8191 19.4226 62.6157 18.5278 62.6157 17.4883C62.6157 16.4489 62.8191 15.5541 63.2232 14.8041C63.63 14.0541 64.1926 13.4804 64.9137 13.0804C65.6348 12.683 66.451 12.483 67.3649 12.483C68.3052 12.483 69.1029 12.6751 69.7606 13.062C70.4156 13.4462 70.9175 13.9725 71.2662 14.641C71.6122 15.3094 71.7865 16.0646 71.7865 16.912C71.7865 17.1436 71.7786 17.362 71.768 17.5673C71.7548 17.7726 71.7363 17.9515 71.7099 18.1068H63.9443V16.5304H70.8013L69.7791 16.8383C69.7791 15.9646 69.5598 15.2962 69.1214 14.8278C68.6829 14.3567 68.086 14.1252 67.3253 14.1252C66.7706 14.1252 66.2872 14.2515 65.8752 14.5094C65.4631 14.7673 65.1488 15.1462 64.9295 15.6541C64.7103 16.162 64.5994 16.7804 64.5994 17.512C64.5994 18.2305 64.7156 18.841 64.948 19.341C65.1805 19.841 65.508 20.22 65.9333 20.4752C66.3585 20.7305 66.8604 20.8594 67.4388 20.8594C68.0833 20.8594 68.6037 20.7384 69.0025 20.4936C69.4014 20.2515 69.7183 19.9121 69.9508 19.4752L71.591 20.2463C71.3612 20.7068 71.0469 21.1094 70.656 21.4489C70.2624 21.7884 69.7949 22.0489 69.2561 22.2279C68.7172 22.4016 68.1229 22.491 67.4811 22.491Z' fill={color} />
      <path d='M76.4036 8.34875V19.6068C76.4036 20.0831 76.4855 20.4094 76.6546 20.5884C76.821 20.7699 77.0983 20.8594 77.4866 20.8594C77.7164 20.8594 77.9145 20.8436 78.0756 20.8094C78.2367 20.7778 78.4401 20.7173 78.6831 20.6278L78.4507 22.1673C78.2314 22.2699 77.9805 22.3515 77.6979 22.4068C77.4126 22.4647 77.13 22.4936 76.8474 22.4936C75.9837 22.4936 75.3524 22.2726 74.9456 21.8305C74.5388 21.3884 74.3354 20.7041 74.3354 19.7804V8.34875H76.4036Z' fill={color} />
      <path d='M82.4311 8.34875V19.6068C82.4311 20.0831 82.5129 20.4094 82.682 20.5884C82.8484 20.7699 83.1257 20.8594 83.514 20.8594C83.7464 20.8594 83.9419 20.8436 84.103 20.8094C84.2642 20.7778 84.4675 20.7173 84.7105 20.6278L84.4807 22.1673C84.2615 22.2699 84.0106 22.3515 83.728 22.4068C83.4427 22.4647 83.1601 22.4936 82.8774 22.4936C82.0137 22.4936 81.3798 22.2726 80.9757 21.8305C80.5689 21.3884 80.3682 20.7041 80.3682 19.7804V8.34875H82.4311Z' fill={color} />
      <path d='M92.59 9.59875V22.2989H90.5244V9.59875H92.59ZM99.9303 14.9857V16.7567H91.5677V14.9857H99.9303ZM100.974 9.59875V22.2989H98.8869V9.59875H100.974Z' fill={color} />
      <path d='M108.565 22.491C107.598 22.491 106.75 22.2937 106.016 21.8937C105.281 21.4963 104.711 20.9226 104.307 20.1726C103.903 19.4226 103.699 18.5278 103.699 17.4883C103.699 16.4489 103.903 15.5541 104.307 14.8041C104.713 14.0541 105.276 13.4804 105.997 13.0804C106.718 12.683 107.534 12.483 108.448 12.483C109.389 12.483 110.186 12.6751 110.844 13.062C111.502 13.4488 112.004 13.9725 112.35 14.641C112.698 15.3094 112.87 16.0646 112.87 16.912C112.87 17.1436 112.865 17.362 112.852 17.5673C112.838 17.7726 112.82 17.9515 112.793 18.1068H105.028V16.5304H111.885L110.86 16.8383C110.86 15.9646 110.641 15.2962 110.205 14.8278C109.766 14.3567 109.167 14.1252 108.409 14.1252C107.854 14.1252 107.371 14.2515 106.959 14.5094C106.547 14.7673 106.232 15.1462 106.013 15.6541C105.794 16.162 105.686 16.7804 105.686 17.512C105.686 18.2305 105.802 18.841 106.032 19.341C106.264 19.841 106.591 20.22 107.017 20.4752C107.442 20.7305 107.944 20.8594 108.522 20.8594C109.167 20.8594 109.687 20.7384 110.086 20.4936C110.485 20.2515 110.802 19.9121 111.032 19.4752L112.672 20.2463C112.442 20.7068 112.128 21.1094 111.737 21.4489C111.343 21.7884 110.878 22.0489 110.337 22.2279C109.801 22.4016 109.206 22.491 108.565 22.491Z' fill={color} />
      <path d='M121.061 16.1225C121.061 15.4804 120.884 14.9857 120.53 14.633C120.176 14.2804 119.69 14.1041 119.072 14.1041C118.491 14.1041 117.997 14.2383 117.585 14.5094C117.173 14.7778 116.872 15.2146 116.676 15.8173L114.938 15.0857C115.181 14.3041 115.662 13.6751 116.378 13.2014C117.091 12.7278 118.015 12.4883 119.151 12.4883C119.922 12.4883 120.607 12.6146 121.198 12.8646C121.79 13.1146 122.257 13.4883 122.598 13.9909C122.939 14.4909 123.111 15.133 123.111 15.9173V20.2068C123.111 20.6831 123.348 20.9199 123.824 20.9199C124.056 20.9199 124.281 20.8884 124.5 20.8226L124.384 22.2463C124.088 22.3884 123.713 22.4568 123.264 22.4568C122.852 22.4568 122.482 22.3805 122.154 22.2357C121.824 22.0884 121.568 21.8647 121.383 21.5621C121.196 21.2594 121.103 20.8857 121.103 20.4357V20.2989L121.528 20.2805C121.412 20.7805 121.174 21.1936 120.815 21.5226C120.453 21.8515 120.036 22.0936 119.561 22.2542C119.082 22.4147 118.602 22.4936 118.11 22.4936C117.506 22.4936 116.961 22.3963 116.478 22.2042C115.995 22.0121 115.614 21.72 115.34 21.3278C115.062 20.9357 114.922 20.4515 114.922 19.8752C114.922 19.1568 115.157 18.5673 115.628 18.1041C116.098 17.6436 116.763 17.3278 117.627 17.162L121.375 16.4304L121.354 17.8725L118.631 18.4304C118.089 18.5331 117.685 18.6805 117.413 18.8726C117.141 19.0647 117.006 19.3462 117.006 19.7199C117.006 20.0805 117.146 20.3647 117.424 20.5752C117.701 20.7857 118.071 20.8936 118.533 20.8936C118.855 20.8936 119.167 20.8568 119.471 20.7778C119.772 20.7015 120.047 20.5752 120.292 20.4015C120.538 20.2278 120.725 20.0041 120.863 19.7278C120.997 19.4515 121.066 19.1226 121.066 18.7383V16.1225H121.061Z' fill={color} />
      <path d='M128.496 8.34875V19.6068C128.496 20.0831 128.581 20.4094 128.747 20.5884C128.914 20.7699 129.191 20.8594 129.576 20.8594C129.809 20.8594 130.004 20.8436 130.166 20.8094C130.327 20.7778 130.527 20.7173 130.773 20.6278L130.543 22.1673C130.324 22.2699 130.07 22.3515 129.788 22.4068C129.505 22.4647 129.223 22.4936 128.94 22.4936C128.076 22.4936 127.442 22.2726 127.038 21.8305C126.631 21.3884 126.431 20.7041 126.431 19.7804V8.34875H128.496Z' fill={color} />
      <path d='M138.29 12.6777V14.2962H131.048V12.6777H138.29ZM134.968 9.84875V19.4146C134.968 19.8883 135.092 20.2357 135.343 20.4541C135.594 20.6726 135.932 20.7804 136.357 20.7804C136.706 20.7804 137.007 20.7252 137.266 20.6173C137.524 20.5068 137.762 20.3568 137.981 20.1646L138.444 21.6647C138.148 21.9199 137.783 22.1226 137.353 22.2699C136.922 22.4173 136.441 22.491 135.913 22.491C135.359 22.491 134.851 22.4015 134.387 22.2226C133.922 22.0436 133.562 21.7515 133.304 21.3462C133.047 20.941 132.91 20.412 132.897 19.7594V10.4277L134.968 9.84875Z' fill={color} />
      <path d='M140.414 22.2989V8.34875H142.48V14.2172C142.802 13.6146 143.235 13.1751 143.784 12.8988C144.331 12.6225 144.939 12.483 145.61 12.483C146.291 12.483 146.907 12.6146 147.456 12.8777C148.003 13.1409 148.436 13.5356 148.761 14.062C149.08 14.5883 149.244 15.2541 149.244 16.0646V22.2989H147.176V16.7199C147.176 15.7699 146.98 15.1094 146.587 14.7383C146.193 14.3672 145.702 14.1804 145.11 14.1804C144.698 14.1804 144.289 14.2804 143.885 14.4778C143.478 14.6778 143.143 14.9988 142.881 15.4409C142.617 15.883 142.485 16.4778 142.485 17.2199V22.2989H140.414Z' fill={color} />
      <path d='M16.7198 5.24886H14.4693C14.0626 3.78043 13.1196 2.5462 11.8016 1.76725C10.4518 0.96461 8.86702 0.738291 7.3456 1.12514C5.29063 1.6462 3.68205 3.2199 3.12208 5.2515H0.871647C0.39092 5.2515 0 5.64097 0 6.11993V27.6465C0 28.1254 0.39092 28.5149 0.871647 28.5149H3.11944C3.52621 29.9833 4.46917 31.2176 5.78721 31.9965C6.70904 32.5439 7.73917 32.8228 8.78514 32.8228C9.27115 32.8228 9.76244 32.7623 10.2458 32.6386C12.3008 32.1176 13.9094 30.5439 14.4693 28.5123H16.7171C17.1979 28.5123 17.5888 28.1228 17.5888 27.6438V6.11729C17.5888 5.63834 17.2005 5.24886 16.7198 5.24886ZM15.8481 6.98572V26.778H13.7667C13.3362 26.778 12.9638 27.0991 12.903 27.5228C12.5861 29.7781 10.4862 31.3623 8.22253 31.0439C6.39736 30.7912 4.94461 29.3412 4.6884 27.5201C4.62501 27.0886 4.2605 26.7754 3.82468 26.7754H1.74329V6.98572H3.82468C4.25522 6.98572 4.62765 6.66467 4.6884 6.24098C5.00536 3.98569 7.09996 2.40147 9.36888 2.71989C11.1941 2.97253 12.6468 4.42254 12.903 6.24098C12.9638 6.66467 13.3336 6.98572 13.7667 6.98572H15.8481Z' fill={color} />
      <path d='M10.9564 23.678H6.63515C6.23631 23.678 5.91406 23.999 5.91406 24.3964C5.91406 24.7938 6.23631 25.1148 6.63515 25.1148H10.9564C11.3553 25.1148 11.6775 24.7938 11.6775 24.3964C11.6775 23.999 11.3553 23.678 10.9564 23.678Z' fill={color} />
      <path d='M8.47079 10.97H4.63818V21.1359H8.47079C10.1692 21.1359 11.4951 20.6964 12.4434 19.8201C13.3916 18.9411 13.8644 17.6858 13.8644 16.0542C13.8644 14.4226 13.389 13.1674 12.4434 12.2884C11.4925 11.4094 10.1692 10.97 8.47079 10.97ZM11.1095 18.77C10.502 19.4122 9.62242 19.7332 8.47079 19.7332H6.39733V12.37H8.47079C9.62242 12.37 10.502 12.691 11.1095 13.3332C11.7197 13.9753 12.0234 14.8805 12.0234 16.0516C12.0234 17.2227 11.7197 18.1279 11.1095 18.77Z' fill={color} />
    </chakra.svg>
  )
}
SvgLogo.propTypes = {
  color: PropTypes.string
}

const DesktopLogo = (props) => (
  <SvgLogo
    display={{
      base: 'none',
      lg: 'block'
    }}
    marginEnd='10'
    {...props}
  />
)

const MobileLogo = ({ color, ...props }) => (
  <SvgLogo
    display={{
      lg: 'none'
    }}
    color={color}
    {...props}
  />
)
MobileLogo.propTypes = {
  color: PropTypes.string.isRequired,
  props: PropTypes.object
}

const Logo = {
  Mobile: MobileLogo,
  Desktop: DesktopLogo
}

export default Logo
