import React, { useEffect } from 'react'
import * as yup from 'yup'
import DatePicker from '../common/DatePicker'
import PropTypes from 'prop-types'
import ReactSelect from '../common/ChakraReactSelect'
import dayjs from 'dayjs'
import Mixpanel from '../../services/Mixpanel'
import { API } from '../../services/Api'
import { ExcludeFromRecording } from '../../services/RecordingService'
import { FormControl } from '../common/FormElements'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { usePatientSession } from '../../context/use-patient-session'
import { useProductVariants } from '../../context/use-product-variants'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  chakra, Input, VStack, Button, Link, Text
} from '@chakra-ui/react'

const productSelectOptions = (isBreastPump) => {
  const productCategory = isBreastPump ? 'Breast Pump' : 'CPAP Machine'
  const catchallCopy = isBreastPump
    ? 'Please provide a list of breast pumps 100% covered by my insurance.'
    : 'Please provide a list of CPAP machines covered by my insurance.'

  const {
    productVariants,
    groupByProduct
  } = useProductVariants() // eslint-disable-line react-hooks/rules-of-hooks

  let selectOptions = []

  const stringsToOptions = (strings) => (
    strings.map((string) => (
      {
        label: string,
        value: string
      }
    ))
  )

  if (productVariants) {
    const optionStrings = groupByProduct(productVariants).filter(
      product => product.hcpcsCode.productCategory.name === productCategory
    ).sort((a, b) => {
      // Sort alphabetically by manufacturer name, then product name
      return a.manufacturer.localeCompare(b.manufacturer) ||
        a.name.localeCompare(b.name)
    }).map((product) => {
      return `${product.manufacturer} - ${product.name}`
    })

    const productOptions = [
      {
        options: stringsToOptions(optionStrings)
      }
    ]

    const otherOptions = stringsToOptions([
      catchallCopy,
      'I\'m not sure yet, can you guide me?'
    ])

    selectOptions = productOptions.concat(otherOptions)

    return selectOptions
  }
}

export const PatientAdditionalInfo = ({ handleCloseModal, isBreastPump }) => {
  const equipment = isBreastPump ? 'breast pump' : 'CPAP equipment'

  const LABELS = {
    birthDate: 'Birthday',
    fullName: 'Full Name',
    medicalGroup: 'Medical Group',
    memberId: 'Member ID',
    product: (isBreastPump ? 'Desired Breast Pump' : 'Desired CPAP Machine')
  }

  const schema = yup.object().shape({
    product: yup.object().label(LABELS.product).nullable(),
    fullName: yup.string().label(LABELS.fullName).required(),
    memberId: yup.string().label(LABELS.memberId).required(),
    medicalGroup: yup.string().label(LABELS.medicalGroup),
    birthDate: yup.date()
      .max(
        dayjs(), // Current Date
        'Cannot be in the future'
      )
      .label(LABELS.birthDate)
      .required()
      .typeError('Must be a valid date')
  })

  const patientSession = usePatientSession()

  const mutation = useMutation(
    params => API.patients.contactRequest(params),
    {
      onSuccess: () => {
        patientSession.setHasSubmittedAdditionalInfo()
      }
    }
  )

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const handleCloseClick = () => {
    Mixpanel.trackEvent('Click Close alternate CTA on additional patient details form')
    handleCloseModal()
  }

  const onSubmit = ({ product, medicalGroup, ...values }) => {
    Mixpanel.trackEvent(
      'Click Submit CTA on additional patient details form',
      { desiredProduct: product?.label }
    )

    mutation.mutate({
      patient: {
        product: product.value,
        medicalGroup: medicalGroup || '-',
        ...values
      }
    })
  }

  useEffect(() => {
    Mixpanel.trackEvent('Arrive at additional patient details form')
  }, [])

  return (
    <>
      <Text
        fontSize='sm'
        mt='1rem'
        mb='2rem'
        data-test='insurance-modal-contact-support-message'
      >
        We're working hard on your request and will reach out to you again shortly.
        In the meantime, want to speed up the process of finding your {equipment}?
        Please let us know a little more about which device you want
        and your insurance details below.
      </Text>

      <chakra.form onSubmit={handleSubmit(onSubmit)} w='100%'>
        <ExcludeFromRecording>
          <VStack spacing={6}>
            <FormControl
              errorMessage={errors?.product?.message}
              label={LABELS.product}
            >

              <Controller
                control={control}
                name='product'
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    isClearable
                    id='product-input'
                    isInvalid={!!errors?.product?.message}
                    options={productSelectOptions(isBreastPump)}
                    placeholder='Select one'
                    closeMenuOnSelect
                    size='md'
                    fontSize='sm'
                  />
                )}
              />
            </FormControl>

            <FormControl
              errorMessage={errors?.fullName?.message}
              isRequired
              label={LABELS.fullName}
            >
              <Input
                data-test='full-name-input'
                fontSize='sm'
                {...register('fullName')}
              />
            </FormControl>

            <FormControl
              errorMessage={errors?.memberId?.message}
              isRequired
              label={LABELS.memberId}
            >
              <Input
                data-test='member-id-input'
                fontSize='sm'
                {...register('memberId')}
              />
            </FormControl>

            <FormControl
              errorMessage={errors?.medicalGroup?.message}
              label={LABELS.medicalGroup}
              tooltip={`Medical Group is the sub-group of providers who participate
                in your insurance network. Your insurance company may use the terms
                Participating Partner Group, PPG, or IPA instead.`}
            >
              <Input
                data-test='medical-group-input'
                fontSize='sm'
                {...register('medicalGroup')}
              />
            </FormControl>

            <FormControl
              isRequired
              errorMessage={errors?.birthDate?.message}
              label={LABELS.birthDate}
            >
              <Controller
                name='birthDate'
                control={control}
                render={({ field: { onBlur, onChange, value } }) => (
                  <DatePicker
                    id='birthDate'
                    onChange={onChange}
                    selected={value}
                    onBlur={onBlur}
                    maxDate={new Date()}
                  />
                )}
              />
            </FormControl>
          </VStack>
        </ExcludeFromRecording>
        <VStack spacing={4}>
          <Button
            width='full'
            type='submit'
            colorScheme='green'
            disabled={!isValid || isSubmitting || mutation.isLoading}
            isLoading={mutation.isLoading}
            data-test='additional-information-submit-button'
            mt='2rem'
          >
            Submit
          </Button>
          <Link
            color='green.600'
            onClick={handleCloseClick}
            data-test='insurance-modal-contact-close-button'
          >
            Close
          </Link>
        </VStack>
      </chakra.form>
    </>
  )
}

PatientAdditionalInfo.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  isBreastPump: PropTypes.bool
}

export default PatientAdditionalInfo
