import { Flex, Heading, Center, AspectRatio } from '@chakra-ui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

const CategoryButton = (props) => {
  const {
    title,
    isSelected = false,
    ...rest
  } = props

  let borderProps = {}

  if (isSelected) {
    borderProps = {
      borderWidth: '3px',
      borderColor: 'yellow.400'
    }
  }

  return (
    <AspectRatio ratio={4 / 3} minWidth='150px'>
      <Flex
        flexWrap='wrap'
        borderRadius='xl'
        overflow='hidden'
        maxWidth='240px'
        padding='0px 20px'
        background='gray.600'
        bgGradient='linear(to-t, blackAlpha.300 20%, blackAlpha.700)'
        {...borderProps}
        {...rest}
      >
        <Center height='100%'>
          <Heading size='md' color='white' textAlign='center'>
            {title}
          </Heading>
        </Center>

      </Flex>
    </AspectRatio>
  )
}

CategoryButton.propTypes = {
  isSelected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  spacing: PropTypes.string
}

export default CategoryButton
