import React from 'react'
import PropTypes from 'prop-types'
import { Flex, HStack, VStack } from '@chakra-ui/react'
import { NavItem } from './NavItem'
import { useLockBodyScroll } from '../../common/useLockBodyScroll'
import { useAuth } from '../../../context/use-auth'

import {
  IoGrid,
  IoArrowBackCircleOutline
} from 'react-icons/io5'

const MobileNavMenu = ({ isOpen, toggleMenu, isSupplier }) => {
  const auth = useAuth()

  const handleSignOutClick = () => (
    auth.signout()
  )

  useLockBodyScroll(isOpen)

  return (
    <Flex
      hidden={!isOpen}
      as='nav'
      direction='column'
      bg='green.900'
      position='fixed'
      height='100vh'
      top='56px'
      insetX='0'
      zIndex={10}
      w='full'
      data-test='mobile-nav-menu'
    >
      <VStack px='4' py='2' spacing='3' align='left'>
        <NavItem.Mobile
          icon={IoGrid}
          isMenuOpen={isOpen}
          toggleMenu={toggleMenu}
          label={isSupplier ? 'Fulfillments' : 'Work orders'}
          path='/'
          data-test={
            isSupplier
              ? 'mobile-supplier-fulfillments-nav'
              : 'mobile-provider-work-orders-nav'
            }
        />

        <NavItem.Mobile
          label='Sign out'
          icon={IoArrowBackCircleOutline}
          path={isSupplier ? '/suppliers/signin' : '/providers/signin'}
          onClick={handleSignOutClick}
          data-test='mobile-sign-out-nav'
        />
      </VStack>
    </Flex>
  )
}

MobileNavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isSupplier: PropTypes.bool.isRequired
}

const DesktopNavMenu = ({ isSupplier }) => {
  const auth = useAuth()

  const handleSignOutClick = () => (
    auth.signout()
  )

  return (
    <HStack
      ml='10'
      spacing='1'
      flex='1'
      display={{
        base: 'none',
        lg: 'flex'
      }}
      data-test='desktop-nav-menu'
    >
      <NavItem.Desktop
        label={isSupplier ? 'Fulfillments' : 'Work orders'}
        icon={IoGrid}
        path='/'
        data-test={
          isSupplier ? 'supplier-fulfillments-nav' : 'provider-work-orders-nav'
        }
      />

      <NavItem.Desktop
        label='Sign out'
        data-test='sign-out'
        icon={IoArrowBackCircleOutline}
        path={isSupplier ? '/suppliers/signin' : '/providers/signin'}
        onClick={handleSignOutClick}
      />
    </HStack>
  )
}

DesktopNavMenu.propTypes = {
  isSupplier: PropTypes.bool.isRequired
}

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu
}
