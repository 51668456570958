// This wraps react-select with Chakra UI styling, which allows for
// searchable select, multiselect, and more.
// Source: https://gist.github.com/csandman/c687a9fb4275112f281ab9a5701457e4

// The source has been extended with additional functionality for
// configurable font size, and inline error styling.

import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import AsyncReactSelect from 'react-select/async'
import {
  useTheme,
  useColorModeValue
} from '@chakra-ui/react'
import ChakraReactSelectComponents from './ChakraReactSelectComponents'

// Custom styles for components which do not have a chakra equivalent
const chakraStyles = (chakraTheme) => ({
  group: (provided) => ({
    ...provided,
    paddingTop: '0',
    borderBottom: '1px solid',
    borderColor: chakraTheme.colors.gray[200]
  }),
  input: (provided) => ({
    ...provided,
    color: 'inherit',
    lineHeight: 1
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'none',
    zIndex: '10'
  }),
  valueContainer: (provided, { selectProps: { size } }) => {
    const px = {
      sm: '0.75rem',
      md: '1rem',
      lg: '1rem'
    }

    return {
      ...provided,
      padding: `0.125rem ${px[size]}`
    }
  },
  loadingMessage: (provided, { selectProps: { size } }) => {
    const fontSizes = {
      sm: '0.875rem',
      md: '1rem',
      lg: '1.125rem'
    }

    const paddings = {
      sm: '6px 9px',
      md: '8px 12px',
      lg: '10px 15px'
    }

    return {
      ...provided,
      fontSize: fontSizes[size],
      padding: paddings[size]
    }
  },
  // Add the chakra style for when a TagCloseButton has focus
  multiValueRemove: (
    provided,
    { isFocused, selectProps: { multiValueRemoveFocusStyle } }
  ) => (isFocused ? multiValueRemoveFocusStyle : {}),
  control: () => ({}),
  menuList: () => ({}),
  option: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({})
})

const ChakraReactSelect = ({
  children,
  styles = {},
  components = {},
  theme = () => ({}),
  size = 'md',
  isAsync = false,
  ...props
}) => {
  const chakraTheme = useTheme()

  // The chakra theme styles for TagCloseButton when focused
  const closeButtonFocus =
    chakraTheme.components.Tag.baseStyle.closeButton._focusVisible

  const multiValueRemoveFocusStyle = {
    background: closeButtonFocus.bg,
    boxShadow: chakraTheme.shadows[closeButtonFocus.boxShadow]
  }

  // The chakra UI global placeholder color
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts#L13
  const placeholderColor = useColorModeValue(
    chakraTheme.colors.gray[400],
    chakraTheme.colors.whiteAlpha[400]
  )

  // Ensure that the size used is one of the options, either `sm`, `md`, or `lg`
  let realSize = size
  const sizeOptions = ['sm', 'md', 'lg']
  if (!sizeOptions.includes(size)) {
    realSize = 'md'
  }

  const selectProps = {
    // Uncomment following line to open the menu for styling
    // menuIsOpen: true
    components: {
      ...ChakraReactSelectComponents,
      ...components
    },
    styles: {
      ...chakraStyles(chakraTheme),
      ...styles
    },
    theme: (baseTheme) => {
      const propTheme = theme(baseTheme)

      return {
        ...baseTheme,
        ...propTheme,
        colors: {
          ...baseTheme.colors,
          neutral50: placeholderColor, // placeholder text color
          neutral40: placeholderColor, // noOptionsMessage color
          ...propTheme.colors
        },
        spacing: {
          ...baseTheme.spacing,
          ...propTheme.spacing
        }
      }
    },
    size: realSize,
    multiValueRemoveFocusStyle,
    ...props
  }

  return (
    <>
      {isAsync
        ? <AsyncReactSelect {...selectProps} />
        : <ReactSelect {...selectProps} />}
    </>
  )
}

ChakraReactSelect.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  components: PropTypes.object,
  theme: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  isAsync: PropTypes.bool
}

export default ChakraReactSelect
