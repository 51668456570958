import React from 'react'
import PropTypes from 'prop-types'
import { Button, Text, Center, VStack } from '@chakra-ui/react'

export const CheckoutPrompt = ({ handleConfirmationClick, handleRemoveInsuranceClick }) => {
  return (
    <Center data-test='prescription-checkout-prompt'>
      <VStack spacing={8}>
        <Text textAlign='center' mt={4} fontSize='md' fontWeight={500}>
          You're almost there! In order to use your insurance and save on your
          equipment, please upload a copy of your prescription.
        </Text>
        <Button
          colorScheme='green'
          bg='green.700'
          onClick={handleConfirmationClick}
          data-test='upload-prescription-button'
        >
          Upload prescription
        </Button>
        {/* onclick we want to remove insurance from the current session */}
        <Button
          colorScheme='red'
          variant='outline'
          onClick={handleRemoveInsuranceClick}
          data-test='remove-insurance-button'
        >
          Remove insurance
        </Button>
      </VStack>
    </Center>
  )
}

CheckoutPrompt.propTypes = {
  handleConfirmationClick: PropTypes.func.isRequired,
  handleRemoveInsuranceClick: PropTypes.func.isRequired
}
