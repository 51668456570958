import React from 'react'
import PropTypes from 'prop-types'
import { GridItem, VStack, Grid } from '@chakra-ui/react'
import { usePatientSession } from '../../../context/use-patient-session'
import { useMediaQuery } from 'react-responsive'
import ProgressButton from './ProgressButton'

const ActionItems = ({
  alternateActionItem,
  buttonAction,
  buttonCopy,
  buttonIsDisabled = false
}) => {
  const patientSession = usePatientSession()
  const currentStep = patientSession.getCurrentOnboardingStep()

  const goToNextStep = () => {
    patientSession.setOnboardingStep({ step: currentStep + 1 })
  }
  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })

  if (isMobile) {
    return (
      <VStack spacing={4} pb={4}>
        <ProgressButton
          buttonAction={buttonAction || goToNextStep}
          buttonCopy={buttonCopy}
          buttonIsDisabled={buttonIsDisabled}
          currentStep={currentStep}
        />
        {alternateActionItem}
      </VStack>
    )
  }

  return (
    <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(12, 1fr)'>
      <GridItem rowStart={1} colStart={12}>
        <ProgressButton
          buttonAction={buttonAction || goToNextStep}
          buttonCopy={buttonCopy}
          buttonIsDisabled={buttonIsDisabled}
          currentStep={currentStep}
        />
      </GridItem>
      {alternateActionItem && (
        <GridItem
          display='flex'
          rowStart={2}
          colStart={11}
          colSpan={2}
          justifyContent='flex-end'
          alignItems='center'
        >
          {alternateActionItem}
        </GridItem>
      )}
    </Grid>
  )
}

ActionItems.propTypes = {
  alternateActionItem: PropTypes.object,
  buttonAction: PropTypes.func,
  buttonCopy: PropTypes.string.isRequired,
  buttonIsDisabled: PropTypes.bool
}

export default ActionItems
