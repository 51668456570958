import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import useLocalStorageState from 'use-local-storage-state'
import update from 'immutability-helper'
import isBoolean from 'lodash/isBoolean'
import Mixpanel from '../services/Mixpanel'
import Analytics from '../services/Analytics'
import * as RecordingService from '../services/RecordingService'
import * as CustomerSupportService from '../services/CustomerSupportService'
import { useNavigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../context/use-auth'

const patientSessionContext = createContext()

// Provider component that wraps your app and makes patientSession object
// available to any child component that calls usePatientSession().
export function PatientSessionProvider ({ children }) {
  const patientSession = useProvidePatient()

  return (
    <patientSessionContext.Provider value={patientSession}>
      {children}
    </patientSessionContext.Provider>
  )
}
PatientSessionProvider.propTypes = {
  children: PropTypes.element.isRequired
}
// Hook for child components to get the patientSession object
// and re-render when it changes.
export const usePatientSession = () => {
  return useContext(patientSessionContext)
}
// Provider hook that creates patientSession object and handles state
function useProvidePatient () {
  const navigate = useNavigate()
  const location = useLocation()
  const isLoggedIn = isAuthenticated()

  const [patientSession, setPatientSessionState] = useLocalStorageState(
    'patientSession',
    { defaultValue: {}, storageSync: false }
  )

  const hasActiveInsurance = () => (
    !!patientSession.insuranceStatus?.active
  )

  const hasAuthorizationRequired = () => (
    patientSession.insuranceStatus?.requiresAuthorization
  )

  const hasInboundLeadConsultation = () => (
    !!patientSession.consultation?.inboundLead
  )

  const hasInsuranceSavings = () => (
    patientSession.cart.prices.subtotalPayerResponsibility !== '0.00'
  )

  const hasCpapMachinesInTheCart = () => {
    if (patientSession?.cart) {
      return patientSession.cart.cartItems
        .some(cartItem => {
          const {
            productCategory,
            productVertical
          } = cartItem.productVariant.product.hcpcsCode

          return productVertical.name === 'Respiratory Care' && productCategory.name === 'CPAP Machine'
        })
    } else {
      return false
    }
  }

  const hasPatientPhone = () => (
    !!patientSession.consultation?.patientPhone
  )

  const hasPatientEmail = () => (
    !!patientSession.consultation?.patientEmail
  )

  const hasPrescription = () => (
    !!patientSession.consultation?.hasPrescription
  )

  const hasReferralConsultation = !!(
    patientSession.consultation && !patientSession.consultation.inboundLead
  )

  const shouldPromptForPrescription = () => (
    !hasPrescription() && (
      !getConsultation() ||
      hasInboundLeadConsultation()
    )
  )

  const getCartQuantity = () => {
    if (patientSession?.cart) {
      return patientSession.cart.cartItems
        .reduce((sum, cartItem) => sum + cartItem.quantity, 0)
    } else {
      return 0
    }
  }

  const getCartItem = uuid => {
    if (patientSession?.cart) {
      return patientSession.cart.cartItems.find(
        cartItem => cartItem.productVariant.uuid === uuid
      )
    } else {
      return null
    }
  }

  const getConsultation = () => {
    if (patientSession?.consultation) {
      return patientSession.consultation
    } else {
      return null
    }
  }

  const getCurrentOnboardingStep = () => (
    patientSession.onboardingModal?.currentStep || 0
  )

  const getEligibleForMachine = () => {
    const eligibleForMachine = patientSession?.eligibleForMachine
    const consultationEligibleForMachine = patientSession?.consultation?.patientEligibleForMachine

    if (isBoolean(eligibleForMachine)) {
      return eligibleForMachine
    } else if (isBoolean(consultationEligibleForMachine)) {
      return consultationEligibleForMachine
    } else {
      return true
    }
  }

  const getEstimatedDueDate = () => (
    patientSession.estimatedDueDate
  )

  const getProductVertical = () => (
    patientSession?.productVertical
  )

  const getProductCategory = () => (
    patientSession?.productCategory
  )

  const identifyLoggedOutPatient = (consultation) => {
    // Don't re-identify a visitor that has already been identified
    if (consultation.patientUuid === getConsultation()?.patientUuid) {
      return false
    }

    const isTestAccount = consultation.provider?.isTestAccount

    Mixpanel.identify({
      uniqueId: consultation.patientUuid,
      userProperties: {
        resourceType: 'Patient',
        ...(isBoolean(isTestAccount) && { isTestAccount })
      }
    })

    Analytics.identify(
      consultation.patientUuid,
      'Patient',
      isTestAccount
    )

    RecordingService.identify(
      consultation.patientUuid,
      'Patient'
    )

    CustomerSupportService.identify(
      {
        name: consultation.patientName,
        email: consultation.patientEmail,
        resourceType: 'Patient',
        uuid: consultation.patientUuid
      }
    )
  }

  // Available update commands: https://github.com/kolodny/immutability-helper#available-commands
  const updatePatientSession = (updateCommand) => {
    setPatientSessionState(
      prevSession => update(prevSession, updateCommand)
    )
  }

  const updateInsuranceModal = (data) => {
    updatePatientSession({
      insuranceModal: insuranceModal => update(
        insuranceModal || {},
        { $merge: data })
    })
  }

  const setCart = (cart) => {
    updatePatientSession({
      cart: { $set: cart }
    })
  }

  // - Resets state upon logout: cart, insurance status, consultation, and
  // the current progress bar step.
  // - Updates with new sessionId
  // Note that we are keeping a slice of state, like the Onboarding Modal,
  // product vertical and product variants.
  const resetStateUponlogout = ({ sessionId }) => (
    updatePatientSession({
      $unset: [
        'cart',
        'insuranceStatus',
        'consultation',
        'hasRequestedContact',
        'hasSeenModal',
        'hasSubmittedAdditionalInfo',
        'hasGoneThroughCardUpload',
        'hasUploadedCard',
        'hasClosedMatriarchToast'
      ],
      progressStatus: {
        $set: {
          currentStepIndex: 0,
          message: ''
        }
      },
      sessionId: { $set: sessionId }
    })
  )

  const setConsultation = (consultation) => {
    // Note: When patient is logged in, gets identified in use-auth.js
    if (!isLoggedIn) {
      identifyLoggedOutPatient(consultation)
    }

    updatePatientSession({
      consultation: { $set: consultation }
    })
  }

  const setEligibleForMachine = (eligibleForMachine) => {
    updatePatientSession({
      eligibleForMachine: { $set: eligibleForMachine }
    })
  }

  const setEstimatedDueDate = (estimatedDueDate) => {
    updatePatientSession({
      estimatedDueDate: { $set: estimatedDueDate }
    })
  }

  const setHasCompletedOnboarding = () => {
    updatePatientSession({
      onboardingModal: onboardingModal => update(
        onboardingModal || {},
        { $merge: { hasCompletedOnboarding: true } })
    })
  }

  const setHasRequestedContact = () => {
    updateInsuranceModal({ hasRequestedContact: true })
  }

  const setHasSeenInsuranceModal = () => {
    updateInsuranceModal({ hasSeenModal: true })
  }

  const setHasSubmittedAdditionalInfo = () => {
    updateInsuranceModal({ hasSubmittedAdditionalInfo: true })
  }

  const setHasGoneThroughCardUpload = () => {
    updateInsuranceModal({ hasGoneThroughCardUpload: true })
  }

  const setHasUploadedCard = () => {
    updateInsuranceModal({ hasUploadedCard: true })
  }

  const setHasClosedMatriarchToast = () => {
    updatePatientSession({
      hasClosedMatriarchToast: { $set: true }
    })
  }

  const setInsuranceStatus = (insuranceStatus) => {
    updatePatientSession({
      insuranceStatus: { $set: insuranceStatus }
    })
  }

  const setOnboardingStep = ({ step }) => {
    updatePatientSession({
      onboardingModal: onboardingModal => update(
        onboardingModal || {},
        { $merge: { currentStep: step } })
    })
  }

  const setProductVertical = (productVertical) => {
    updatePatientSession({
      productVertical: { $set: productVertical }
    })
  }

  const setProductCategory = (productCategory) => {
    updatePatientSession({
      productCategory: { $set: productCategory }
    })
  }

  const setProgress = ({ steps, status }) => {
    if (steps) {
      updatePatientSession({
        progressSteps: { $set: steps }
      })
    }

    if (status) {
      updatePatientSession({
        progressStatus: { $set: status }
      })
    }
  }

  const setSessionId = (sessionId) => {
    // If there's a previously set sessionId that doesn't match the
    // new sessionId, reset the session and initialize with the new sessionId
    if (patientSession.sessionId !== undefined && patientSession.sessionId !== sessionId) {
      updatePatientSession({
        $set: { sessionId }
      })

      if (location.pathname !== '/shop') {
        navigate('/shop')
      }
    } else {
      updatePatientSession({
        sessionId: { $set: sessionId }
      })
    }
  }

  const reset = () => {
    updatePatientSession({
      // Reset the session and keep the sessionId
      $set: {
        sessionId: patientSession.sessionId
      }
    })
  }

  return {
    getCartItem,
    getCartQuantity,
    getConsultation,
    getCurrentOnboardingStep,
    getEstimatedDueDate,
    getEligibleForMachine,
    getProductVertical,
    getProductCategory,
    hasActiveInsurance,
    hasAuthorizationRequired,
    hasInboundLeadConsultation,
    hasInsuranceSavings,
    hasCpapMachinesInTheCart,
    hasPatientEmail,
    hasPatientPhone,
    hasReferralConsultation,
    hasPrescription,
    patientSession,
    reset,
    resetStateUponlogout,
    setCart,
    setConsultation,
    setEstimatedDueDate,
    setHasRequestedContact,
    setHasSeenInsuranceModal,
    setHasCompletedOnboarding,
    setEligibleForMachine,
    setHasSubmittedAdditionalInfo,
    setHasGoneThroughCardUpload,
    setHasUploadedCard,
    setHasClosedMatriarchToast,
    setInsuranceStatus,
    setOnboardingStep,
    setProductVertical,
    setProductCategory,
    setProgress,
    setSessionId,
    shouldPromptForPrescription
  }
}
