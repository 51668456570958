import React from 'react'
import PropTypes from 'prop-types'
import AddToCartButton from './AddToCartButton'
import RemoveFromCartButton from './RemoveFromCartButton'
import { usePatientSession } from '../../../context/use-patient-session'

const ProductCartButton = ({ productVariant, variant, quantity }) => {
  const session = usePatientSession()
  const { patientSession } = session

  const cartProductCategoryQuantity = () => {
    if (patientSession.cart) {
      return patientSession.cart.cartItems.filter(
        cartItem =>
          cartItem.productVariant.product.hcpcsCode.productCategory.uuid ===
          productVariant.product.hcpcsCode.productCategory.uuid
      ).length
    } else {
      return 0
    }
  }

  const productCategoryLimit = productVariant.product.hcpcsCode.productCategory.quantityLimit
  const isCartButtonDisabled = productCategoryLimit && cartProductCategoryQuantity() >= productCategoryLimit
  const cartItem = session.getCartItem(productVariant.uuid)

  if (cartItem) {
    return (
      <RemoveFromCartButton
        cartItem={cartItem}
        variant={variant}
      />
    )
  } else {
    return (
      <AddToCartButton
        isDisabled={isCartButtonDisabled}
        productVariant={productVariant}
        variant={variant}
        quantity={quantity}
      />
    )
  }
}

ProductCartButton.propTypes = {
  productVariant: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['large', 'small']).isRequired,
  quantity: PropTypes.number.isRequired
}

export default ProductCartButton
