import React from 'react'
import PropTypes from 'prop-types'
import { Box, Image } from '@chakra-ui/react'

const Thumbnail = ({ imageUrl, isActive, ...rest }) => {
  return (
    <Box
      position='relative'
      borderRadius='sm'
      overflow='hidden'
      minW='80px'
    >
      <Image
        data-test='image-thumbnail'
        boxSize={{ base: '80px', lg: 'auto' }}
        cursor='pointer'
        fit='contain'
        src={imageUrl}
        {...rest}
      />

      {/* Hack to display an inner border over an img */}
      <Box
        boxShadow={isActive ? 'inset 0px 0px 0px 2px rgb(45, 130, 97)' : ''}
        h='100%'
        w='100%'
        top='0'
        left='0'
        pointerEvents='none'
        position='absolute'
      />
    </Box>
  )
}

Thumbnail.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
}

export default Thumbnail
