import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { API } from '../../../services/Api'
import { useInsuranceModal } from '../../../context/use-insurance-modal'
import { usePatientSession } from '../../../context/use-patient-session'
import InsuranceDetails from './InsuranceDetails'

const CtaButton = ({ text, onClick, ...props }) => (
  <Box>
    <Button
      color='white'
      bg='green.700'
      _hover={{ background: 'green.500' }}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  </Box>
)

CtaButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
}

const InsuranceInformation = () => {
  const insuranceModal = useInsuranceModal()
  const { patientSession } = usePatientSession()

  const insuranceStatusUuid = patientSession?.insuranceStatus?.uuid

  const fetchInsuranceStatus = async () => {
    const { data } = await API.insuranceStatuses.single()

    return data
  }

  const { isFetched, isLoading, isSuccess, error, data } = useQuery(
    ['fetchInsuranceStatus', insuranceStatusUuid],
    fetchInsuranceStatus,
    {
      retry: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const notFound = error?.response?.status === 404
  const patientInsurancePlan = data?.patientInsurancePlan

  return (
    <>
      <Heading mb='10' size='lg' color='black' fontWeight='bold'>
        Insurance Information
      </Heading>

      {!isFetched && isLoading && (
        <Center h='200px'>
          <Spinner />
        </Center>
      )}

      <VStack align='left' spacing='7'>
        {notFound && (
          <>
            <Text
              color='blackAlpha.600'
              fontSize='sm'
              data-test='no-insurance-message'
            >
              You haven’t submitted your insurance yet!
            </Text>
            <CtaButton
              text='Submit Insurance Details'
              onClick={insuranceModal.handleOpenModal}
              data-test='submit-insurance-button'
            />
          </>
        )}

        {isSuccess && (
          <>
            <InsuranceDetails patientInsurancePlan={patientInsurancePlan} />
            <CtaButton
              text='Update Insurance Details'
              onClick={() => insuranceModal.handleOpenModal({ isUpdateModal: true })}
              data-test='update-insurance-button'
            />
          </>
        )}
      </VStack>
    </>
  )
}

export default InsuranceInformation
