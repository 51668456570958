import React, { useState } from 'react'
import * as yup from 'yup'
import Dashboard from '../providers/Dashboard'
import InputMask from 'react-input-mask'
import ProviderSignatureCard from './ProviderSignatureCard'
import ReferralCode from './ReferralCode'
import ReactSelect from '../common/ChakraReactSelect'
import { API } from '../../services/Api'
import { Card } from '../common/Card'
import { phoneNumberRegex } from '../common/utils'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { ExcludeFromRecording } from '../../services/RecordingService'
import DatePicker from '../common/DatePicker'
import HcpcsSelection from './HcpcsSelection'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Grid,
  GridItem,
  Input,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'

import {
  FormControl,
  FormDivider,
  FormSection
} from '../common/FormElements'

const NewWorkOrder = () => {
  const navigate = useNavigate()
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [selectedHcpcsCodes, setSelectedHcpcsCodes] = useState([])

  const hasSelectedCpapMachines = selectedHcpcsCodes.some(hcpcsCode => {
    const {
      productVertical,
      productCategory
    } = hcpcsCode

    return productVertical.name === 'Respiratory Care' && productCategory.name === 'CPAP Machine'
  })

  const [productVerticalName, setProductVerticalName] = useState(null)
  // TODO: RESPIRATORY CARE: Add switch by category
  const isCPAP = productVerticalName === 'Respiratory Care'

  const LABELS = {
    patientEmail: 'Patient Email',
    patientPhone: 'Patient Phone',
    providerAttestation: 'Attestation',
    phoneAssistanceRequired: 'Phone Assistance Required',
    bestTimeToCall: 'Best Time To Call',
    providerSignature: 'Signature',
    providerSignatureDate: 'Date',
    eligibleForMachine: 'Does this patient currently have a machine or has had one in the last five years?'
  }

  const baseSchema = yup.object().shape({
    providerSignature: yup.string().required().label(LABELS.providerSignature),
    providerSignatureDate: yup.date().required('Must be a valid date'),
    patientEmail: yup.string().email().label(LABELS.patientEmail),
    patientPhone: yup.string().matches(
      phoneNumberRegex,
      {
        message: 'Patient Phone must be a valid phone number',
        excludeEmptyString: true
      }).label(LABELS.patientPhone)
  }).test(
    function (values) {
      const hasEmail = values.patientEmail?.length > 0
      const hasPhone = values.patientPhone?.length > 0

      if (isCPAP && !hasPhone) {
        return new yup.ValidationError(
          'Please enter patient phone',
          null,
          'patientContact'
        )
      }

      if (hasEmail || hasPhone) {
        return true
      }

      return new yup.ValidationError(
        'Please enter patient email or phone',
        null,
        'patientContact'
      )
    })

  const baseCpapSchema = baseSchema.shape({
    providerAttestation: yup.bool().oneOf([true], 'Attestation is required'),
    phoneAssistanceRequired: yup.bool().optional()
      .label(LABELS.phoneAssistanceRequired),
    bestTimeToCall: yup.string()
      .label(LABELS.bestTimeToCall)
      .when('phoneAssistanceRequired', {
        is: true,
        then: yup.string().required()
      })
  })

  const cpapSchema = hasSelectedCpapMachines
    ? baseCpapSchema.shape({
      eligibleForMachine: yup.object().required('Must select Yes or No').label(
        LABELS.eligibleForMachine
      )
    })
    : baseSchema

  const schema = isCPAP ? cpapSchema : baseSchema

  const {
    control,
    register,
    resetField,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const fetchHcpcsCodes = async () => {
    const data = await API.hcpcsCodes.index({
      include_icd10s: true
    })

    return data
  }

  const { data: hcpcsCodes } = useQuery(
    'fetchHcpcsCodes',
    fetchHcpcsCodes,
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  const watchPhoneAssistanceRequired = watch('phoneAssistanceRequired')

  const setReferralCode = (value) => {
    setValue('referralCode', value)
  }

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onValidatedSubmit = ({
    eligibleForMachine,
    ...params
  }) => {
    setServerState()
    setSubmitting(true)

    const eligibilityValue = eligibleForMachine?.value

    if (eligibilityValue === true || eligibilityValue === false) {
      params.eligibleForMachine = !eligibilityValue
    }

    params.hcpcsCodes = selectedHcpcsCodes.map(hcpcs => ({
      code: hcpcs.code,
      pressureSetting: hcpcs?.pressureSetting,
      lengthOfNeed: hcpcs?.lengthOfNeed,
      po2Percent: hcpcs?.po2Percent,
      arterialSaturationPercent: hcpcs?.arterialSaturationPercent,
      icd10_codes: hcpcs?.icd10_codes
    }))

    API.consultations.create({ consultation: params })
      .then(response => {
        handleServerResponse(true, 'Success')
        navigate(`/work_orders/${response.data.uuid}`)
      })
      .catch(error => {
        handleServerResponse(false, error.response.data.error)
      })
      .finally(
        () => setSubmitting(false)
      )
  }

  const signatureHeading = () => {
    if (isCPAP) {
      return 'Physician Face-to-Face Attestation'
    } else {
      return 'Provider Signature'
    }
  }

  const handleAddHcpcs = (hcpcsCode) => {
    setSelectedHcpcsCodes([...selectedHcpcsCodes, hcpcsCode])
  }

  const handleRemoveHcpcs = (hcpcsCode) => {
    setSelectedHcpcsCodes(selectedHcpcsCodes.filter(hcpcs => hcpcs.code !== hcpcsCode.code))
  }

  return (
    <Dashboard heading='Standard Work Order Form'>
      <Text color='gray.600'>Fill out your work order details</Text>

      <Card mt={6} maxWidth='700px' fontSize='sm'>

        <FormSection heading='Prescription Details'>
          <VStack mt={5} w='100%'>
            <HcpcsSelection
              hcpcsCodes={hcpcsCodes}
              handleAddHcpcs={handleAddHcpcs}
              handleRemoveHcpcs={handleRemoveHcpcs}
              setProductVerticalName={setProductVerticalName}
            />
          </VStack>
        </FormSection>

        <form id='workOrderForm' onSubmit={handleSubmit(onValidatedSubmit)}>
          <ExcludeFromRecording>
            <VStack
              align='stretch'
              divider={<FormDivider />}
            >

              {hasSelectedCpapMachines &&
                (
                  <FormSection heading='Medical Equipment'>
                    <VStack mt={5} spacing={5}>
                      <>
                        <FormControl
                          errorMessage={errors?.eligibleForMachine?.message}
                          label={LABELS.eligibleForMachine}
                        >
                          <Controller
                            control={control}
                            name='eligibleForMachine'
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id='eligibleForMachine'
                                isInvalid={!!errors?.eligibleForMachine?.message}
                                options={yesNoOptions}
                                size='md'
                                fontSize='sm'
                                onChange={(value) => {
                                  resetField('machine', { defaultValue: null })

                                  return field.onChange(value)
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    </VStack>
                  </FormSection>
                )}

              <FormSection heading={signatureHeading()}>
                <VStack mt={5} spacing={5} align='stretch'>
                  <ProviderSignatureCard />

                  {
                    isCPAP && (
                      <Box>
                        <Checkbox
                          id='providerAttestation'
                          width='100%'
                          textAlign='left'
                          color='gray.600'
                          spacing={4}
                          isInvalid={!!errors?.providerAttestation?.message}
                          {...register('providerAttestation')}
                        >
                          <Text fontSize='sm'>
                            I certify that the patient's clinical condition supports
                            that this patient requires the DME listed in this
                            standard work order.
                          </Text>
                        </Checkbox>

                        <Text fontSize='sm' ml={8} mt={2} color='red.500'>
                          {errors?.provider?.message}
                        </Text>
                      </Box>
                    )
                  }

                  <Grid width='100%' templateColumns='repeat(3, 1fr)' gap={6}>
                    <GridItem colSpan={{ base: 3, md: 2 }}>
                      <FormControl
                        errorMessage={errors?.providerSignature?.message}
                        label={LABELS.providerSignature}
                      >
                        <Input
                          id='providerSignature'
                          fontSize='sm'
                          placeholder='Type signature'
                          {...register('providerSignature')}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 3, md: 1 }}>
                      <FormControl
                        errorMessage={errors?.providerSignatureDate?.message}
                        label={LABELS.providerSignatureDate}
                      >
                        <Controller
                          name='providerSignatureDate'
                          control={control}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker
                              id='providerSignatureDate'
                              onChange={onChange}
                              selected={value}
                              onBlur={onBlur}
                              maxDate={new Date()}
                            />
                          )}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                </VStack>
              </FormSection>

              <FormSection heading='Patient Contact Details'>
                <Text color='red.500' mt={5}>
                  {errors?.patientContact?.message}
                </Text>
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  mt={5}
                  spacing={6}
                  width='100%'
                >
                  <FormControl
                    errorMessage={errors?.patientEmail?.message}
                    label={LABELS.patientEmail}
                  >
                    <Input
                      id='patientEmail'
                      fontSize='sm'
                      {...register('patientEmail')}
                    />
                  </FormControl>

                  <FormControl
                    errorMessage={errors?.patientPhone?.message}
                    label={LABELS.patientPhone}
                  >
                    <Controller
                      name='patientPhone'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputMask
                          mask='(999) 999-9999'
                          onChange={onChange}
                          value={value}
                        >
                          {(inputProps) => (
                            <Input
                              id='patientPhone'
                              fontSize='sm'
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                  </FormControl>
                </SimpleGrid>

                {isCPAP && (
                  <VStack mt={5} spacing={5} align='stretch'>
                    <Checkbox
                      id='phoneAssistanceRequired'
                      width='100%'
                      textAlign='left'
                      color='gray.600'
                      spacing={4}
                      isInvalid={!!errors?.phoneAssistanceRequired?.message}
                      {...register('phoneAssistanceRequired')}
                    >
                      <Text fontSize='sm'>
                        This patient requires additional assistance by phone to complete their order.
                      </Text>
                    </Checkbox>

                    {watchPhoneAssistanceRequired && (
                      <FormControl
                        errorMessage={errors?.bestTimeToCall?.message}
                        isRequired
                        label='Best time to call this patient'
                      >
                        <Input
                          id='bestTimeToCall'
                          fontSize='sm'
                          {...register('bestTimeToCall')}
                        />
                      </FormControl>
                    )}
                  </VStack>
                )}
              </FormSection>
            </VStack>
          </ExcludeFromRecording>

          {serverState && !serverState.ok && (
            <Box mt={8}>
              <Alert status='error' borderRadius={4}>
                <AlertIcon />
                <AlertDescription>{serverState.msg}</AlertDescription>
              </Alert>
            </Box>
          )}

          <Grid width='100%' templateColumns='repeat(4, 1fr)' gap={5} mt={8}>
            <GridItem colSpan={{ base: 4, md: 3 }}>
              <ExcludeFromRecording>
                <ReferralCode setFormValue={setReferralCode} />
              </ExcludeFromRecording>
            </GridItem>
            <GridItem colSpan={{ base: 4, md: 1 }} textAlign='right'>
              <Button
                disabled={submitting}
                isLoading={submitting}
                type='submit'
                colorScheme='blue'
                width={{ base: '100%', md: 'auto' }}
                data-test='work-order-submit'
              >
                Submit
              </Button>
            </GridItem>
          </Grid>
        </form>
      </Card>
    </Dashboard>
  )
}

export default NewWorkOrder
