import React from 'react'
import PropTypes from 'prop-types'
import { Text, VStack, StackDivider } from '@chakra-ui/react'
import RoundedBox from '../../common/RoundedBox'
import { groupByKey } from '../../common/utils'
import ItemList from './ItemList'

const ShipmentHeader = ({ supplierName }) => (
  <VStack
    px='8'
    py='4'
    align='left'
    spacing='1'
  >
    <Text fontSize='lg' fontWeight='bold'>
      Pending Shipment
    </Text>
    <Text color='gray.600' fontSize='sm'>
      {'Fulfilled By: ' + supplierName}
    </Text>
  </VStack>
)

ShipmentHeader.propTypes = {
  supplierName: PropTypes.string
}

const PendingShipment = ({ items, supplierName }) => (
  <VStack
    align='left'
    spacing='0'
    divider={<StackDivider borderColor='gray.200' />}
  >
    <ShipmentHeader supplierName={supplierName} />
    <ItemList items={items} />
  </VStack>
)

PendingShipment.propTypes = {
  items: PropTypes.array,
  supplierName: PropTypes.string
}

const ItemsPendingShipment = ({ unshippedItems }) => {
  const itemsBySupplier = groupByKey(unshippedItems, 'supplierName')

  if (unshippedItems.length === 0) {
    return null
  }

  return (
    <RoundedBox data-test='order-history-pending-shipment'>
      {Object.entries(itemsBySupplier)
        .map(([supplierName, items]) => (
          <PendingShipment
            key={supplierName}
            supplierName={supplierName}
            items={items}
          />
        ))}
    </RoundedBox>
  )
}

ItemsPendingShipment.propTypes = {
  unshippedItems: PropTypes.array
}

export default ItemsPendingShipment
