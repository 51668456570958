import React, { useRef } from 'react'
import FaxCoverSheet from './FaxCoverSheet'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'

import {
  Container,
  HStack,
  Link,
  Text
} from '@chakra-ui/react'

const FaxCoverSheetCta = ({ formattedReferralCode, unformattedReferralCode }) => {
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Doorbell Health - Cover Sheet'
  })

  return (
    <>
      <HStack>
        <Text>
          Cover sheet including patient referral code and
          consultation QR code (
          <Link
            display='inline-block'
            data-test='print-cover-sheet'
            onClick={handlePrint}
            color='green.600'
          >
            print here
          </Link>
          )
        </Text>
      </HStack>

      <Container hidden>
        {formattedReferralCode &&
          <FaxCoverSheet
            ref={componentRef}
            formattedReferralCode={formattedReferralCode}
            unformattedReferralCode={unformattedReferralCode}
          />}
      </Container>
    </>
  )
}

FaxCoverSheetCta.propTypes = {
  formattedReferralCode: PropTypes.string,
  unformattedReferralCode: PropTypes.string
}

export default FaxCoverSheetCta
