import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Img, Text, Flex, VStack } from '@chakra-ui/react'

const FeatureBox = ({ title, description, src }) => (
  <Box
    boxShadow='0px 2px 12px 2px rgba(0, 0, 0, 0.1)'
    borderRadius='8px'
    minHeight='164px'
    padding={{ base: 3, md: '32px 24px' }}
  >
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Img
        w={{ base: '52px', md: '64px' }}
        h={{ base: '52px', md: '64px' }}
        src={src}
        mr='24px'
        alt={`${title} icon`}
      />
      <VStack
        spacing={1}
        align='left'
        pt={{ base: 2, md: 0 }}
      >
        <Text fontSize='md' fontWeight='600'>{title}</Text>
        <Text fontSize='md' fontWeight='400'>{description}</Text>
      </VStack>
    </Flex>
  </Box>
)
FeatureBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

export const WhyDoorbellFeatures = ({ features }) => (
  <Box>
    <Grid
      templateColumns={{ base: 'repeat(5, 272px)', md: 'repeat(2, 1fr)' }}
      templateRows={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
      gap={4}
      overflowX={{ base: 'scroll', md: 'unset' }}
      p={{ base: 5, md: 0 } /* Padding required because overflowX='scroll' hides the box-shadow :( */}
      m={{ base: -5, md: 0 } /* With padding = 5, and negative margin = -5 moves it to the initial position */}
    >
      {features.map((box, i) => (
        <FeatureBox key={i} {...box} />
      ))}
    </Grid>
  </Box>
)

WhyDoorbellFeatures.propTypes = {
  features: PropTypes.array.isRequired
}
