/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { isValidUrl } from '../../common/utils'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useQueryParams } from '../../../utils/useQueryParams'
import { yupResolver } from '@hookform/resolvers/yup'
import { API } from '../../../services/Api'
import { Card } from '../../common/Card'
import { FormControl } from '../../common/FormElements'
import ShipmentCartItem from './ShipmentCartItem'
import useFulfillment from '../../common/useFulfillment'

import {
  Checkbox,
  CheckboxGroup,
  Divider,
  Heading,
  Text,
  Button,
  Input,
  VStack,
  useToast
} from '@chakra-ui/react'

const UnshippedCartItem = ({ index, register, shipmentCartItem }) => {
  const { uuid } = shipmentCartItem

  return (
    <Checkbox
      alignItems='top'
      value={uuid}
      iconSize={5}
      {...register(`shipmentsCartItems[${index}]`)}
    >
      <ShipmentCartItem shipmentCartItem={shipmentCartItem} mt='-1' />
    </Checkbox>

  )
}

UnshippedCartItem.propTypes = {
  index: PropTypes.number.isRequired,
  register: PropTypes.func.isRequired,
  shipmentCartItem: PropTypes.object.isRequired
}

const schema = (unshippedItems) => yup.object().shape({
  shipmentsCartItems: unshippedItems.length > 0 && yup.array().required().test(
    'has-selection',
    'Select at least one item to ship',
    value => value.some(element => element)
  ),
  trackingUrl: yup.string().required('Tracking Number Link is required').test(
    'is-valid-url',
    'Invalid URL Format',
    isValidUrl
  )
})

const Form = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { fulfillment: fulfillmentConfirmationNumber } = useQueryParams()

  const {
    fetchFulfillment,
    setFulfillment,
    shippedItems,
    unshippedItems
  } = useFulfillment()

  useEffect(() => {
    fetchFulfillment(fulfillmentConfirmationNumber)
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema(unshippedItems))
  })

  const handleSuccess = async ({ data: fulfillment }) => {
    setFulfillment(fulfillment)

    toast({
      position: 'top-right',
      title: 'Tracking link added',
      status: 'success',
      isClosable: true
    })

    navigate(`/tracking/${fulfillment.fulfillmentConfirmationNumber}`)
  }

  const mutation = useMutation(
    newShipment => API.shipments.create(newShipment),
    {
      onSuccess: handleSuccess
    }
  )

  const onSubmit = (values) => {
    if (values.shipmentsCartItems) {
      // Submit UUIDs of checked items only
      values.shipmentsCartItems = values.shipmentsCartItems.filter(value =>
        value !== false
      )
    }

    values.fulfillmentConfirmationNumber = fulfillmentConfirmationNumber

    mutation.mutate(values)
  }

  const errorMessage = mutation.isError ? mutation.error.response.data.error : ''

  return (
    <>
      <Heading size='md' fontWeight='medium' color='gray.800' mb='2'>
        Order Tracking Form
      </Heading>
      <Text color='gray.600'>Fill out your order tracking details</Text>
      <Card mt={6} maxWidth='600px' fontSize='sm'>
        <form
          id='orderTrackingForm'
          onSubmit={handleSubmit(onSubmit)}
          data-test='tracking-form'
        >
          <VStack mb={5} spacing={5} align='left'>
            <FormControl
              label='Fulfillment Confirmation Number'
            >
              {fulfillmentConfirmationNumber}
            </FormControl>
            <FormControl
              errorMessage={errors?.trackingUrl?.message}
              label='Tracking Number Link'
              isRequired
            >
              <Input
                id='trackingUrl'
                data-test='tracking-url-input'
                fontSize='sm'
                {...register('trackingUrl')}
              />
            </FormControl>

            {unshippedItems.length > 0 && (
              <FormControl
                errorMessage={errors?.shipmentsCartItems?.message}
                label='Ready to ship'
                isRequired
              >

                <CheckboxGroup>
                  <VStack spacing='5' align='left'>
                    {
                  unshippedItems.map((shipmentCartItem, index) => {
                    return (
                      <UnshippedCartItem
                        key={index}
                        index={index}
                        register={register}
                        shipmentCartItem={shipmentCartItem}
                      />
                    )
                  })
                }
                  </VStack>
                </CheckboxGroup>
              </FormControl>

            )}
          </VStack>
          <Button
            disabled={mutation.isLoading}
            isLoading={mutation.isLoading}
            type='submit'
            colorScheme='blue'
            width={{ base: '100%', md: 'auto' }}
          >
            Submit
          </Button>
          {errorMessage ? <Text color='red.500' mt={3}>{errorMessage}</Text> : null}
        </form>

        {shippedItems.length > 0 && (
          <>
            <Divider my='5' />

            <FormControl
              label='Shipped'
            >
              <VStack spacing='5' align='left'>
                {
                  shippedItems.map((shipmentCartItem, index) => {
                    return (
                      <ShipmentCartItem
                        key={index}
                        shipmentCartItem={shipmentCartItem}
                      />
                    )
                  })
                }
              </VStack>
            </FormControl>
          </>
        )}

      </Card>
    </>
  )
}

export default Form
