import { Box, Heading, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

const CardTitle = ({ title }) => (
  <Box
    bg='green.600'
    color='white'
    py='4'
    mt='-1px'
    roundedTopLeft='lg'
    roundedTopRight='lg'
    px={{
      base: '4',
      md: '6'
    }}
  >
    <Heading size='md'>
      {title}
    </Heading>
  </Box>
)

CardTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export const Card = ({ children, px, py, title, ...props }) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    shadow='base'
    rounded='lg'
    {...props}
  >
    {title &&
      <CardTitle title={title} />}
    <Box
      py={py || '8'}
      px={px || {
        base: '4',
        md: '10'
      }}
    >
      {children}
    </Box>
  </Box>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
  px: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  py: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  title: PropTypes.string
}
