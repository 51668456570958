import React from 'react'
import useDimensions from 'react-use-dimensions'
import { useTheme, Flex, Container, Link } from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { useMobileMenuState } from '../../common/useMobileMenuState'
import { SvgLogo as Logo } from '../../common/SvgLogo'
import { IconLogo } from '../../common/IconLogo'
import NavMenu from '../NavMenu'
import { CtaButton } from '../Sections/CtaButton'
import { MobileHamburgerMenu } from './MobileHamburgerMenu'
import { Link as RouterLink, useMatch } from 'react-router-dom'
import { isAuthenticated } from '../../../context/use-auth'

const TopBar = () => {
  const { isMenuOpen, toggle } = useMobileMenuState(
    { mobileBreakpointValue: '(max-width: 48em)' }
  )
  const isLoggedIn = isAuthenticated()
  const isProviderLandingPage = useMatch('/providers')
  const showSignIn = isProviderLandingPage && !isLoggedIn
  const theme = useTheme()
  const [topBarRef, topBarRefSizes] = useDimensions()
  const mobileBreakpointValue = '(max-width: 48em)'
  const isMobileBreakpoint = useMediaQuery({ query: mobileBreakpointValue })

  const topBarVisibleHeight = topBarRefSizes.height + topBarRefSizes.y

  const LOGO_COLOR = isMobileBreakpoint && isMenuOpen ? 'white' : theme.colors.green[600]

  return (
    <Flex
      ref={topBarRef}
      align='center'
      bg={{
        base: isMenuOpen ? 'green.800' : 'white',
        md: 'white'
      }}
      color='black'
      px={{ base: 3, md: 6 }}
      minH={{ base: '47px', md: '96px' }}
      borderBottom='solid 1px'
      borderBottomColor='gray.300'
      data-test='landing-page-top-bar'
    >
      <Container maxW='5xl' px={{ base: 2, md: 0 }}>
        <Flex
          justify='space-between'
          align='center'
          w='full'
        >
          <NavMenu.Mobile
            isOpen={isMenuOpen}
            topBarHeight={topBarVisibleHeight}
            toggleMenu={toggle}
          />
          <RouterLink to='/'>
            <IconLogo
              h='48px'
              display={{ base: 'none', md: 'block', lg: 'none' }}
            />
            <Logo
              height={{ base: '24px', md: '48px' }}
              marginEnd={{ base: '16', md: '10' }}
              color={LOGO_COLOR}
              display={{ base: 'block', md: 'none', lg: 'block' }}
            />
          </RouterLink>
          <NavMenu.Desktop />
          {showSignIn &&
            <Link
              as={RouterLink}
              color='green.600'
              display={{ base: 'none', md: 'inline-flex' }}
              fontWeight={600}
              pl={4}
              pr={8}
              py={2}
              to='/providers/signin'
              _hover={{
                color: 'green.800',
                textDecoration: 'none'
              }}
              data-test='landing-page-sign-in-link'
            >
              Sign In
            </Link>}
          <CtaButton
            display={{ base: 'none', md: 'inline-flex' }}
            data-test='nav-cta'
          />
          <MobileHamburgerMenu
            onClick={toggle}
            isOpen={isMenuOpen}
          />
        </Flex>
      </Container>
    </Flex>
  )
}

export default TopBar
