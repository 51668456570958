import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

export const ClearFilterButton = ({ text, ...props }) => (
  <Button
    variant='ghost'
    size='sm'
    color='green.600'
    fontWeight='500'
    {...props}
  >
    {text}
  </Button>
)
ClearFilterButton.propTypes = {
  text: PropTypes.string.isRequired
}
