import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@chakra-ui/react'

export const Wrapper = ({ isOpen, children }) => {
  return (
    <Flex
      position='fixed'
      hidden={!isOpen}
      direction='row'
      height='100%'
      top='0'
      left='0'
      zIndex={10}
      w='full'
      data-test='filters-mobile-sidebar'
    >
      {children}
    </Flex>
  )
}
Wrapper.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}
