import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Price from '../Product/Price'
import ProductCartButton from '../Product/CartButton'
import Mixpanel from '../../../services/Mixpanel'
import { Link as RouterLink } from 'react-router-dom'
import { usePatientSession } from '../../../context/use-patient-session'

import {
  Box,
  Button,
  Image,
  Text
} from '@chakra-ui/react'

const ProductCard = ({ product }) => {
  const insuranceSavingsRef = useRef(null)
  const { patientSession } = usePatientSession()
  const hasOptions = product.productVariants.length > 1

  const cheapestOption = (options) => (
    options.sort((a, b) => parseFloat(a.prices.patientCost) - parseFloat(b.prices.patientCost))[0]
  )

  const cheapestProductVariantCartItem = () => {
    const items = patientSession.cart?.cartItems || []

    const cartItems = items.filter(cartItem =>

      product.productVariants.some(
        variant =>
          cartItem.productVariant.uuid ===
          variant.uuid
      )
    )

    return cheapestOption(cartItems)
  }

  const cheapestProductVariant = cheapestOption(product.productVariants)

  const selectedProductVariant = cheapestProductVariantCartItem()?.productVariant ||
    cheapestProductVariant

  const prices = (
    cheapestProductVariantCartItem() || cheapestProductVariant
  ).formattedPrices

  const cartButton = () => {
    if (hasOptions) {
      return (
        <Button
          as={RouterLink}
          shadow='base'
          background='white'
          borderColor='gray.200'
          borderRadius='full'
          position='absolute'
          top={2}
          right={2}
          variant='outline'
          fontWeight='medium'
          to={`/products/${selectedProductVariant.uuid}`}
          data-test='view-options'
        >
          View Options
        </Button>
      )
    } else {
      return (
        <ProductCartButton
          variant='small'
          productVariant={selectedProductVariant}
          quantity={1}
        />
      )
    }
  }

  const title = hasOptions ? product.name : selectedProductVariant.productTitle

  const trackProductClicked = (event) => {
    // Don't log an event when insurance link is clicked
    if (event.target !== insuranceSavingsRef.current) {
      Mixpanel.trackEventWithProduct(
        'Click product card on listing page',
        product
      )
    }
  }

  return (
    <Box
      position='relative'
      align='left'
      fontSize='16px'
      maxWidth='282px'
      borderRadius='sm'
      transition='background 0.2s'
      _hover={{
        background: 'gray.100'
      }}
      p='3px'
      data-test='product-card'
      cursor='pointer'
    >

      {cartButton()}

      <RouterLink
        to={`/products/${selectedProductVariant.uuid}`}
        onClick={trackProductClicked}
      >

        <Image borderRadius='sm' bg='white' boxSize='282px' fit='contain' src={product.imageUrls[0]} />
        <Box px={2} py={3}>

          <Text
            fontWeight='medium'
            noOfLines={2}
            mb={1}
            data-test='product-title'
          >
            {title}
          </Text>

          <Text
            noOfLines={1}
            color='blackAlpha.600'
            fontSize='sm'
            mb={1}
            data-test='product-manufacturer'
          >
            {product.manufacturer}
          </Text>

          <Price
            product={product}
            prices={prices}
            designVariant='card'
            insuranceSavingsRef={insuranceSavingsRef}
          />
        </Box>
      </RouterLink>

    </Box>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired
}

export default ProductCard
