import { extendTheme } from '@chakra-ui/react'
import colors from './colors'

const theme = extendTheme({
  colors,
  fonts: {
    heading: 'Work Sans',
    body: 'Work Sans'
  }
})
export default theme
