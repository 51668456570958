/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { CheckboxGroup, Checkbox, VStack, Text } from '@chakra-ui/react'
import Analytics from '../../../../services/Analytics'
import { useProductVariants } from '../../../../context/use-product-variants'
import { useFilters } from '../useFilters'

const BrandFilter = () => {
  const { stepProductVariants } = useProductVariants()
  const filters = useFilters()
  const [selectedBrands, setSelectedBrands] = useState([])

  useEffect(() => {
    const currentBrands = filters.currentFilters.brands || []

    if (currentBrands.join() !== selectedBrands.join()) {
      setSelectedBrands(currentBrands)
    }
  }, [filters.currentFilters.brands])

  const getAvailableBrands = () => {
    if (!stepProductVariants.length) return []

    const brands = stepProductVariants.map(productVariant => productVariant.product.manufacturer)
    const uniqueBrands = [...new Set(brands)]
    const alphabeticallySortedBrands = uniqueBrands.sort()

    return alphabeticallySortedBrands
  }

  const onCheckboxChange = (event) => {
    Analytics.trackEvent({
      category: 'search filters',
      action: 'brand filter applied',
      label: 'product listing page'
    })

    if (event.target.checked) {
      filters.setBrands([...selectedBrands, event.target.value])
    } else {
      filters.setBrands(selectedBrands.filter(brand => brand !== event.target.value))
    }
  }

  return (
    <>
      <CheckboxGroup value={selectedBrands}>
        <VStack
          spacing={2}
          align='left'
          data-test='brand-filter'
        >
          {getAvailableBrands().map(brand => (
            <Checkbox
              key={brand}
              iconSize={5}
              value={brand}
              onChange={onCheckboxChange}
            >
              <Text fontSize='sm'>{brand}</Text>
            </Checkbox>))}
        </VStack>
      </CheckboxGroup>
    </>
  )
}

export default BrandFilter
