import * as React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Box, HStack, Link } from '@chakra-ui/react'

const DesktopNavItem = ({ icon, label, path, active, asHref, href, ...props }) => {
  let hrefProps = {}
  if (asHref) {
    hrefProps = {
      as: Link,
      href
    }
  }

  return (
    <HStack
      as={RouterLink}
      to={path}
      aria-current={active ? 'page' : undefined}
      spacing='2'
      px='4'
      py='2'
      rounded='md'
      transition='all 0.2s'
      color='black'
      _hover={{
        color: 'gray.600',
        textDecoration: 'none'
      }}
      whiteSpace='nowrap'
      {...hrefProps}
      {...props}
    >
      {icon && (
        <Box aria-hidden fontSize='md'>
          {icon}
        </Box>
      )}
      <Box fontWeight='600'>{label}</Box>
    </HStack>
  )
}
DesktopNavItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  active: PropTypes.bool,
  asHref: PropTypes.bool,
  href: PropTypes.string
}

const MobileNavItem = ({ label, path, active, asHref, href, ...props }) => {
  let hrefProps = {}
  if (asHref) {
    hrefProps = {
      as: Link,
      href
    }
  }

  return (
    <Box
      as={RouterLink}
      to={path}
      display='block'
      px='3'
      py='6'
      fontWeight='medium'
      borderBottom='solid 1px'
      borderBottomColor='gray.300'
      aria-current={active ? 'page' : undefined}
      _hover={{
        bg: 'whiteAlpha.200'
      }}
      _activeLink={{
        bg: 'blackAlpha.300',
        color: 'white'
      }}
      {...hrefProps}
      {...props}
    >
      {label}
    </Box>
  )
}
MobileNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  active: PropTypes.bool,
  asHref: PropTypes.bool,
  href: PropTypes.string
}

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem
}
