import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { IoCloseCircleSharp } from 'react-icons/io5'

export const CloseIcon = ({ onClose }) => (
  <Box
    position='absolute'
    top='16px'
    left='-44px'
    p={1}
    onClick={onClose}
    data-test='mobile-filters-sidebar-close-button'
  >
    <IoCloseCircleSharp color='white' size='30px' />
  </Box>
)

CloseIcon.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default CloseIcon
