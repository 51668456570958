import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { Banner } from '../common/Banner'
import { useInsuranceModal } from '../../context/use-insurance-modal'
import { usePatientSession } from '../../context/use-patient-session'

const InsuranceBannerComponent = (props) => {
  const insuranceModal = useInsuranceModal()
  const patientSession = usePatientSession()

  if (insuranceModal.isModalOpen) {
    return null
  }

  if (patientSession.hasActiveInsurance()) {
    return null
  }

  // This is used to only load the modal for first time users if they
  // came in via a consultation referral.
  const showInsuranceModal =
    !patientSession.patientSession.insuranceModal?.hasSeenModal &&
      patientSession.hasReferralConsultation

  if (showInsuranceModal) {
    insuranceModal.handleOpenModal({ isLandingModal: true })
    patientSession.setHasSeenInsuranceModal()
  }

  return (
    <Banner
      data-test='insurance-banner'
      text='Save up to 100% on your prescription DME'
      ctaText='Unlock insurance pricing'
      onCtaAction={insuranceModal.handleOpenModal}
      {...props}
    />
  )
}
InsuranceBannerComponent.propTypes = {
  props: PropTypes.object
}

const DesktopBanner = () => {
  const desktopBreakpointValue = '(min-width: 62em)'
  const isDesktopBreakpoint = useMediaQuery({ query: desktopBreakpointValue })

  return isDesktopBreakpoint
    ? <InsuranceBannerComponent />
    : null
}

const MobileBanner = () => {
  const mobileBreakpointValue = '(max-width: 62em)'
  const isMobileBreakpoint = useMediaQuery({ query: mobileBreakpointValue })

  return isMobileBreakpoint
    ? <InsuranceBannerComponent />
    : null
}
export const InsuranceBanner = {
  Desktop: DesktopBanner,
  Mobile: MobileBanner
}
