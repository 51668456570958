import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from 'react-query'
import { usePatientSession } from '../../../context/use-patient-session'
import { useInsuranceModal } from '../../../context/use-insurance-modal'
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  ModalBody,
  ModalFooter,
  Select,
  Text,
  VStack
} from '@chakra-ui/react'
import { ExcludeFromRecording } from '../../../services/RecordingService'
import dayjs from 'dayjs'
import ActionItems from './ActionItems'
import ProgressIndicators from './ProgressIndicators'
import { API } from '../../../services/Api'
import Mixpanel from '../../../services/Mixpanel'

const StepFour = ({ onClose }) => {
  const [estimatedDueDateValue, setEstimatedDueDateValue] = useState('')
  const [eligibleForMachine, setEligibleForMachine] = useState('')

  const patientSession = usePatientSession()
  const insuranceModal = useInsuranceModal()
  const { name: productVerticalName } = patientSession.getProductVertical()
  const productCategory = patientSession.getProductCategory()

  const isButtonDisabled = () => (
    (productVerticalName === 'Breast Pump' && estimatedDueDateValue === '') ||
    (productCategory.isCpap && eligibleForMachine === '')
  )

  const patientUpdateMutation = useMutation(
    (data) => API.patients.update(data)
  )

  const handleButtonClick = () => {
    patientSession.setHasCompletedOnboarding(true)
    onClose()
    insuranceModal.handleOpenModal({ isLandingModal: true })
  }

  const handleManualInsuranceClick = () => {
    patientSession.setHasGoneThroughCardUpload()
    patientSession.setHasCompletedOnboarding(true)
    Mixpanel.trackEvent(
      'Onboarding Wizard: Click Manually Enter Details Alternate CTA'
    )
    onClose()
    insuranceModal.handleOpenModal({ isLandingModal: true })
  }

  const handleEstimatedDueDateChange = (e) => {
    const estimatedDueDate = e.target.selectedOptions[0].value
    setEstimatedDueDateValue(estimatedDueDate)

    Mixpanel.trackEvent(
      'Onboarding Wizard: Select Estimated Due Date',
      { selectedOption: e.target.selectedOptions[0].text }
    )

    patientSession.setEstimatedDueDate(estimatedDueDate)
  }

  const handleOwnsMachineField = (e) => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Select Machine Field',
      { selectedOption: e.target.selectedOptions[0].text }
    )

    const value = e.target.value
    const booleanValue = value === 'Yes'

    if (value === '') {
      setEligibleForMachine('')
      patientSession.setEligibleForMachine(null)
      patientUpdateMutation.mutate({
        eligible_for_machine: null
      })
    } else {
      setEligibleForMachine(booleanValue)

      const isEligible = !booleanValue

      patientSession.setEligibleForMachine(isEligible)
      patientUpdateMutation.mutate({
        eligible_for_machine: isEligible
      })
    }
  }

  const handleSkipClick = () => {
    patientSession.setHasCompletedOnboarding(true)
    Mixpanel.trackEvent('Onboarding Wizard: Click Skip')
    onClose()
  }

  useEffect(() => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Arrive at insurance upload option screen'
    )
  }, [])

  const estimatedDueDateField = () => {
    if (productVerticalName === 'Breast Pump') {
      return (
        <VStack spacing={4} pt={16}>
          <Heading size='md' color='white'>When are you due?</Heading>
          <ExcludeFromRecording>
            <Select
              isRequired
              placeholder='Select...'
              name='estimatedDueDate'
              onChange={handleEstimatedDueDateChange}
              focusBorderColor='blue.200'
              data-test='estimated-due-date-select'
            >
              {Array.from(Array(8), (_, i) => {
                const month = dayjs().endOf('month').add(i, 'month')
                return (
                  <option key={i} value={month.format()}>
                    {month.format('MMM YYYY')}
                  </option>
                )
              })}
              {/* This is simply to signify that the birth has already happened */}
              {/* CS will update the date when they review the consultation */}
              <option value={dayjs().subtract(100, 'year').format()}>
                I already gave birth
              </option>
            </Select>
          </ExcludeFromRecording>
        </VStack>
      )
    }
  }

  const ownsMachineField = () => {
    if (productCategory.isCpap) {
      return (
        <VStack spacing={4} pt={16}>
          <Heading size='md' color='white'>
            Do you currently have a machine or have you had one in the last 5 years?
          </Heading>
          <ExcludeFromRecording>
            <Select
              isRequired
              placeholder='Select...'
              name='ownsMachineField'
              onChange={handleOwnsMachineField}
              focusBorderColor='blue.200'
              minW='200px'
              data-test='has-machine-select'
            >
              <option value='Yes'>
                Yes
              </option>
              <option value='No'>
                No
              </option>
            </Select>
          </ExcludeFromRecording>
        </VStack>
      )
    }
  }

  const manuallyEnterInsurance = () => {
    return (
      <Button
        color={{ md: 'green.600', base: 'white' }}
        onClick={handleManualInsuranceClick}
        data-test='onboarding-manually-enter-insurance'
        isDisabled={isButtonDisabled()}
        variant='link'
      >
        Manually Enter Insurance
      </Button>
    )
  }

  return (
    <>
      <Flex justifyContent='flex-end'>
        <Button
          mt={4}
          mr={6}
          isDisabled={isButtonDisabled()}
          onClick={handleSkipClick}
          color='#6372FF'
          fontWeight={700}
          data-test='onboarding-insurance-skip-button'
          variant='link'
        >
          Skip
        </Button>
      </Flex>
      <ModalBody>
        <Grid templateColumns='repeat(12, 1fr)'>
          <GridItem colSpan={{ md: 6, base: 12 }} colStart={{ md: 4 }}>
            <Heading color='white' textAlign='center' pt={12} size='2xl'>
              Unlock Insurance Pricing
            </Heading>
          </GridItem>
          <GridItem colSpan={{ md: 6, base: 12 }} colStart={{ md: 4 }} pt={4}>
            <Text textAlign='center' color='white' fontWeight={700} fontSize='xl'>
              Get the best price on your {productVerticalName} equipment
              by using your insurance. Your details will remain secure.
            </Text>
            {estimatedDueDateField()}
            {ownsMachineField()}
          </GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter alignSelf={{ base: 'center' }}>
        <VStack>
          <ActionItems
            alternateActionItem={manuallyEnterInsurance()}
            buttonCopy='Upload Insurance Card'
            buttonAction={handleButtonClick}
            buttonIsDisabled={isButtonDisabled()}
          />
          <ProgressIndicators amount={4} />
        </VStack>
      </ModalFooter>
    </>
  )
}

StepFour.propTypes = {
  onClose: PropTypes.func
}

export default StepFour
