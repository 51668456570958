import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Text,
  useTheme
} from '@chakra-ui/react'
import Analytics from '../../../services/Analytics'
import { useInsuranceModal } from '../../../context/use-insurance-modal'
import { usePatientSession } from '../../../context/use-patient-session'
import { IoInformationCircleOutline } from 'react-icons/io5'
import { useMutation } from 'react-query'
import { API } from '../../../services/Api'
import { usePrescriptionModal } from '../../../context/use-prescription-modal'
import Mixpanel from '../../../services/Mixpanel'
import Tooltip from '../../common/Tooltip'

const EnterInsuranceLink = () => {
  const modal = useInsuranceModal()

  return (
    <Link
      color='blue.600'
      fontWeight={500}
      onClick={modal.handleOpenModal}
      data-test='total-insurance-savings-link'
    >
      Enter Insurance
    </Link>
  )
}

export const CartDetails = ({ cart, ...props }) => {
  const theme = useTheme()
  const insuranceLabel =
    'Shipping price determined by the participating retailer ' +
    'shipping to your location and not by Doorbell Health.'

  const patientSession = usePatientSession()

  let insuranceSavings

  if (patientSession.hasActiveInsurance()) {
    insuranceSavings = (
      <Text
        color='blue.600'
        fontWeight='500'
        data-test='cart-savings'
      >
        -{cart.formattedPrices.insuranceSavings}
      </Text>
    )
  } else {
    insuranceSavings = <EnterInsuranceLink />
  }

  return (
    <Box {...props}>
      <SimpleGrid columns={2} fontSize={14} spacing={4} py={2}>
        <Text>Subtotal</Text>
        <Text
          align='right'
          data-test='cart-subtotal'
        >
          {cart.formattedPrices.subtotal}
        </Text>
        <Text>Service Fee</Text>
        <Text
          align='right'
          data-test='service-fee'
        >
          {cart.formattedPrices.patientServiceFee}
        </Text>
        <HStack spacing={0}>
          <Text as='span'>
            Shipping
          </Text>
          <Tooltip
            arrowShadowColor={theme.colors.gray['200']}
            backgroundColor='white'
            bg='white'
            borderWidth={1}
            color='blackAlpha.600'
            hasArrow
            label={insuranceLabel}
            p={6}
            placement='bottom-start'
          >
            <Box>
              <Icon as={IoInformationCircleOutline} pl={1} />
            </Box>
          </Tooltip>

        </HStack>
        <Text align='right'>Free</Text>
        <Text>Estimated Tax</Text>
        <Text align='right'>TBD</Text>
        {insuranceSavings
          ? (
            <>
              <Text>Insurance Savings</Text>
              <Box display='flex' justifyContent='flex-end'>
                {insuranceSavings}
              </Box>
            </>)
          : null}
      </SimpleGrid>
      <Divider />
      <SimpleGrid
        columns={2}
        fontSize={14}
        spacing={4}
        pt={2}
      >
        <Text fontWeight={700}>Total</Text>
        <Text align='right' fontWeight={700}>
          {cart.formattedPrices.subtotalWithInsuranceDiscount}
        </Text>
      </SimpleGrid>
    </Box>
  )
}
CartDetails.propTypes = {
  cart: PropTypes.object.isRequired
}

export const CartSummary = ({ cart, setShowSummary }) => {
  const buttonCopy = cart.isFree ? 'Continue' : 'Proceed to Checkout'

  const mutation = useMutation(
    createCheckout => API.cart.checkout(createCheckout),
    {
      onSuccess: (data) => {
        window.location.href = data.data.checkoutUrl
      }
    }
  )

  const prescriptionModal = usePrescriptionModal()

  const {
    shouldPromptForPrescription,
    hasActiveInsurance,
    hasInsuranceSavings,
    hasCpapMachinesInTheCart
  } = usePatientSession()

  const shouldShowCheckoutPrescriptionPrompt = () => {
    // TODO: RESPIRATORY CARE: See if we should add a condition for respiratory care
    const meetsCartRequirements = hasCpapMachinesInTheCart() || (
      hasActiveInsurance() && hasInsuranceSavings()
    )

    return shouldPromptForPrescription() && meetsCartRequirements
  }

  const checkout = () => {
    Analytics.trackEvent({
      category: 'checkout',
      action: 'proceed to checkout'
    })

    Mixpanel.trackEvent(
      'Clicked Checkout button',
      { zeroDollarCheckout: cart.isFree }
    )

    if (cart.isFree) {
      setShowSummary.off()
    } else {
      mutation.mutate(cart.uuid)
    }
  }

  const handleCheckoutClick = (triggerSource) => {
    if (shouldShowCheckoutPrescriptionPrompt()) {
      prescriptionModal.handleOpenModal({
        isCheckoutButton: true,
        shouldEnableCheckoutContents: hasActiveInsurance(),
        callback: checkout,
        triggerSource
      })
    } else {
      checkout()
    }
  }

  return (
    <>
      <CartDetails cart={cart} />
      <Button
        colorScheme='green'
        data-test='cart-checkout'
        width='full'
        isLoading={mutation.isLoading}
        mt={6}
        onClick={() => handleCheckoutClick('Checkout Button')}
      >
        {buttonCopy}
      </Button>
      <Text
        color='blackAlpha.600'
        fontSize={12}
        mt={4}
        display={cart.isFree ? 'none' : 'block'}
      >
        You will be taken to a separate page to pay securely through Stripe.
      </Text>

      <Link
        color='blue.600'
        onClick={() => handleCheckoutClick('Upload Prescription CTA')}
        fontSize={12}
        mt={4}
        data-test='cart-prescription-upload-message'
        display={
          hasActiveInsurance() && shouldShowCheckoutPrescriptionPrompt() && !prescriptionModal.isUploadOptional ? 'block' : 'none'
        }
      >
        In order to use insurance, please upload a copy of your prescription.
      </Link>
    </>
  )
}

CartSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  setShowSummary: PropTypes.object.isRequired
}
