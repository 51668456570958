/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

const useSetTimeZoneCookie = () => {
  const timeZoneCookieName = 'doorbellhealth_timeZone'
  const [cookies, setCookie] = useCookies([timeZoneCookieName])

  useEffect(() => {
    const currentValue = cookies[timeZoneCookieName]
    const browserTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone

    if (!currentValue || currentValue !== browserTimeZone) {
      setCookie(timeZoneCookieName, browserTimeZone, {
        expires: dayjs().add(20, 'years').toDate(),
        path: '/'
      })
    }
  }, [])
}

export default useSetTimeZoneCookie
