import React, { useEffect } from 'react'
import { FaFilePrescription } from 'react-icons/fa'
import Constants from '../common/constants'
import { CheckoutPrompt } from './CheckoutPrompt'
import { usePatientSession } from '../../context/use-patient-session'
import { usePrescriptionModal } from '../../context/use-prescription-modal'
import { PrescriptionForm } from './PrescriptionForm'
import { ProviderConsultationFound } from './ProviderConsultationFound'
import { API } from '../../services/Api'
import AttachmentsModalContent from '../Attachments/AttachmentsModalContent'
import {
  Box,
  Heading,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  VStack
} from '@chakra-ui/react'
import Mixpanel from '../../services/Mixpanel'

export const PrescriptionModal = () => {
  const {
    state: modalState,
    isModalOpen,
    handleCloseModal,
    isUploadOptional,
    setShowCheckoutContents,
    showCheckoutContents
  } = usePrescriptionModal()
  const patientSession = usePatientSession()
  const consultation = patientSession.getConsultation()
  const patientDoesNotHaveConsultation = !consultation
  const patientHasConsultation = !!consultation
  const patientHasPrescription = patientSession.hasPrescription()
  const productVerticalName = patientSession.getProductVertical()?.name

  const headingCopy = () => {
    if (
      (modalState?.triggeredByCheckout && !patientHasPrescription) ||
      patientDoesNotHaveConsultation
    ) {
      return 'Prescription Verification'
    } else {
      return 'Prescription Verified'
    }
  }

  const shouldRedirectToCheckout = isModalOpen &&
    modalState?.triggeredByCheckout &&
    isUploadOptional &&
    patientHasConsultation

  useEffect(() => {
    if (shouldRedirectToCheckout) {
      modalState.callback()
      handleCloseModal()
    }
    /* eslint-disable-next-line */
  }, [shouldRedirectToCheckout])

  const handleCheckoutUploadConfirmation = () => {
    setShowCheckoutContents.off()
  }

  const handleRemoveInsuranceClick = async () => {
    await patientSession.setInsuranceStatus({})
    const { data: cart } = await API.cart.single()
    patientSession.setCart(cart)
    Mixpanel.trackEvent('Click Remove Insurance alternate CTA')
    handleCloseModal()
  }

  const bodyComponent = () => {
    if (showCheckoutContents) {
      return (
        <CheckoutPrompt
          handleConfirmationClick={handleCheckoutUploadConfirmation}
          handleRemoveInsuranceClick={handleRemoveInsuranceClick}
        />
      )
    } else if (patientDoesNotHaveConsultation) {
      return <PrescriptionForm handleCloseModal={handleCloseModal} />
    } else if (consultation.inboundLead === false) {
      return (
        <ProviderConsultationFound handleCloseModal={handleCloseModal} />
      )
    }
  }

  const afterAttachmentsChange = ({ data: consultation }) => {
    patientSession.setConsultation(consultation)

    if (modalState.triggeredByCheckout && consultation.patientPackets.length) {
      modalState.callback()
    }
  }

  const modalContent = () => {
    if (patientDoesNotHaveConsultation || consultation.inboundLead === false || showCheckoutContents) {
      return (
        <ModalContent data-test='prescription-modal' maxW='470px'>
          <ModalHeader>
            <VStack>
              {headerIcon()}
              <Heading size='lg'>
                {headingCopy()}
              </Heading>
            </VStack>
          </ModalHeader>
          {patientDoesNotHaveConsultation
            ? (
              <Text
                color='gray.500'
                fontSize='sm'
                px={12}
                textAlign='center'
                data-test='prescription-modal-helper-text'
              >
                Doorbell Health will first check if your provider is already in the process of sharing your prescription with us.
              </Text>
              )
            : null}

          <ModalCloseButton data-test='modal-close-button' />
          <ModalBody px={{ base: 8, md: 12 }}>
            {bodyComponent()}
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      )
    } else {
      const identifiedProviderCopy = 'If you do not have it, you can close this modal and upon order our ' +
        'Support team will reach out to your physician to request it on your behalf.'

      const unidentifiedProviderCopy = '\n\nIf you do not have it, you can close this modal and upon order our ' +
        'Support team will reach out to you for your physician\'s information to request it on your behalf.'

      const uploadOptionalCopy = isUploadOptional
        ? (consultation?.provider ? identifiedProviderCopy : unidentifiedProviderCopy)
        : null

      // TODO: RESPIRATORY CARE: This copy is for Machines only
      const cpapCopy =
        'In order to fill your order, we will also need a copy of ' +
          'your Sleep Study and Consultation Notes, but we will collect them from ' +
          'your physician directly. For now we only need your prescription.'

      const productVerticalCopy = () => {
        switch (productVerticalName) {
          case Constants.productVertical.respiratoryCare:
            return cpapCopy
          default:
            return null
        }
      }

      const baseHeaderCopy = 'Unfortunately, we were unable to find a prescription for you on file with us. ' +
        'Please upload a copy of your prescription below.'

      const headerCopy = () => {
        return (
          <VStack spacing='2'>
            <Text>{baseHeaderCopy}</Text>
            {productVerticalCopy() &&
              <Text>{productVerticalCopy()}</Text>}
            {uploadOptionalCopy &&
              <Text>{uploadOptionalCopy}</Text>}
          </VStack>
        )
      }

      return (
        <AttachmentsModalContent
          afterAttachmentsChange={afterAttachmentsChange}
          headerCopy={headerCopy()}
          modalType={Constants.attachmentModalTypes.patientPackets}
          modalData={{
            consultation,
            packetType: Constants.packetTypes.prescription
          }}
          onClose={handleCloseModal}
        />
      )
    }
  }

  const headerIcon = () => {
    if (patientDoesNotHaveConsultation || showCheckoutContents) {
      return <Box as={FaFilePrescription} size='48px' color='red.700' mb={4} />
    } else if (consultation.inboundLead === false) {
      return <Box as={FaFilePrescription} size='48px' color='green.700' mb={4} />
    }
  }
  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={isModalOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {modalContent()}
    </Modal>
  )
}
