import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { Text } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { API } from '../../../services/Api'
import TableSkeleton from '../../common/TableSkeleton'
import Dashboard from '../../providers/Dashboard'
import List from './List'
import Table from './Table'

const FulfillmentCount = ({ count }) => (
  <Text color='gray.600' fontSize='sm' mt='8' mb='6'>
    {pluralize('fulfillment', count, true)}
  </Text>
)

FulfillmentCount.propTypes = {
  count: PropTypes.number.isRequired
}

const Fulfillments = () => {
  const fetchFulfillments = async () => {
    const { data } = await API.fulfillments.index()

    return data
  }

  const { data: fulfillments, isLoading } = useQuery(
    'fetchFulfillments',
    fetchFulfillments,
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true
    }
  )

  return (
    <Dashboard
      heading='Fulfillments'
      data-test='supplier-fulfillments-dashboard'
    >
      {isLoading
        ? <TableSkeleton tableProps={{ bg: 'supplierDashboard.grayHeader', w: '5xl' }} />
        : (
          <>
            <FulfillmentCount count={fulfillments.length} />
            {fulfillments.length
              ? (
                <Table>
                  <List
                    fulfillments={fulfillments}
                  />
                </Table>
                )
              : null}
          </>
          )}
    </Dashboard>
  )
}

export default Fulfillments
