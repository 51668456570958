import React from 'react'
import { Grid, GridItem, Heading, Flex, Text, Img, Center } from '@chakra-ui/react'
import { Section } from '../Section'
import { CtaButtons } from './CtaButtons'
import PropTypes from 'prop-types'

export const Cta = ({ illustration, color, headingCopy, bodyCopy }) => (
  <Section
    bg={color}
    minHeight={{ base: 'auto', md: '480px' }}
    data-test='landing-page-cta-section'
    withPaddingTop={false}
  >
    <Center>
      <Flex
        h='full'
        align='center'
      >
        <Grid
          color='white'
          templateColumns='repeat(2, 1fr)'
          gap={5}
          alignItems='center'
        >
          <GridItem
            colSpan={{
              base: 2,
              md: 1
            }}
          >
            <Flex direction='column' align='left'>
              <Heading
                as='h1'
                fontSize={{ base: '32px', md: '47px' }}
                fontWeight='600'
              >
                {headingCopy}
              </Heading>
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                mt={{ base: '8px', md: '16px' }}
              >
                {bodyCopy}
              </Text>
              <CtaButtons />
            </Flex>
          </GridItem>
          <GridItem
            colSpan={1}
            m='0'
            display={{
              base: 'none',
              md: 'block'
            }}
          >
            <Center>
              <Img
                src={illustration}
                alt='a woman looking at a computer'
              />
            </Center>
          </GridItem>
        </Grid>
      </Flex>
    </Center>
  </Section>
)

Cta.propTypes = {
  illustration: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  headingCopy: PropTypes.string.isRequired,
  bodyCopy: PropTypes.string.isRequired
}
