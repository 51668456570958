import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Circle,
  Divider,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Text
} from '@chakra-ui/react'

import Tooltip from './Tooltip'
import { IoInformationOutline } from 'react-icons/io5'

export const FormControl = ({
  children,
  errorMessage,
  isRequired,
  label,
  labelProps,
  tooltip,
  ...props
}) => {
  return (
    <ChakraFormControl isInvalid={!!errorMessage} {...props}>
      {
        label &&
          <HStack spacing={0}>
            <FormLabel fontSize='sm' color='gray.500' mb={3} mr={1} {...labelProps}>
              {label}
              {isRequired &&
                <Text as='span' ml={1}>*</Text>}
            </FormLabel>
            {tooltip &&
              <sup>
                <Tooltip
                  placement='right'
                  hasArrow
                  label={tooltip}
                  bg='shop.B4'
                  fontSize='xs'
                  color='blue.600'
                  rounded='md'
                  width='180px'
                  p={3}
                >
                  <Circle
                    size='16px'
                    color='shop.B1'
                    bg='shop.B4'
                    cursor='pointer'
                  >
                    <IoInformationOutline />
                  </Circle>
                </Tooltip>
              </sup>}
          </HStack>
      }
      {children}
      <FormErrorMessage data-test='error-message'>
        {errorMessage}
      </FormErrorMessage>
    </ChakraFormControl>
  )
}

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  label: PropTypes.string,
  labelProps: PropTypes.object,
  tooltip: PropTypes.string
}

export const FormSection = ({ children, heading }) => (
  <Box>
    <Heading size='sm' fontWeight='medium' color='gray.600'>
      {heading}
    </Heading>
    {children}
  </Box>
)

FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired
}

export const FormDivider = () => (
  <Divider color='gray.200' mt={8} mb={7} />
)
