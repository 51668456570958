import React from 'react'
import PropTypes from 'prop-types'
import { components as selectComponents } from 'react-select'
import {
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  CloseButton,
  Center,
  Box,
  Portal,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  useTheme,
  createIcon
} from '@chakra-ui/react'

const Control = ({
  children,
  innerRef,
  innerProps,
  isDisabled,
  isFocused,
  selectProps: { fontSize },
  selectProps: { isInvalid },
  selectProps: { size }
}) => {
  const chakraTheme = useTheme()
  const inputStyles = useMultiStyleConfig('Input', { size })
  let errorStyles = {}

  const heights = {
    sm: 8,
    md: 10,
    lg: 12
  }

  if (isInvalid) {
    errorStyles = {
      borderColor: chakraTheme.colors.red[500],
      boxShadow: `0 0 0 1px ${chakraTheme.colors.red[500]}`
    }
  }

  return (
    <StylesProvider value={inputStyles}>
      <Flex
        ref={innerRef}
        sx={{
          ...inputStyles.field,
          ...errorStyles,
          p: 0,
          overflow: 'hidden',
          h: 'auto',
          minH: heights[size],
          fontSize: fontSize || size
        }}
        {...innerProps}
        {...(isFocused && { 'data-focus': true })}
        {...(isDisabled && { disabled: true })}
      >
        {children}
      </Flex>
    </StylesProvider>
  )
}
Control.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.object
}

const MultiValueContainer = ({
  children,
  innerRef,
  innerProps,
  data: { isFixed },
  selectProps: { size }
}) => (
  <Tag
    ref={innerRef}
    {...innerProps}
    m='0.125rem'
    // react-select Fixed Options example: https://react-select.com/home#fixed-options
    variant={isFixed ? 'solid' : 'subtle'}
    size={size}
  >
    {children}
  </Tag>
)
MultiValueContainer.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  data: PropTypes.object,
  selectProps: PropTypes.object
}

const MultiValueLabel = ({ children, innerRef, innerProps }) => (
  <TagLabel ref={innerRef} {...innerProps}>
    {children}
  </TagLabel>
)
MultiValueLabel.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object
}

const MultiValueRemove = ({ children, innerRef, innerProps, data: { isFixed } }) => {
  if (isFixed) {
    return null
  }

  return (
    <TagCloseButton ref={innerRef} {...innerProps} tabIndex={-1}>
      {children}
    </TagCloseButton>
  )
}
MultiValueRemove.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  data: PropTypes.object
}

const IndicatorSeparator = ({ innerProps }) => (
  <Divider {...innerProps} orientation='vertical' opacity='1' />
)
IndicatorSeparator.propTypes = {
  innerProps: PropTypes.object
}

const ClearIndicator = ({ innerProps, selectProps: { size } }) => (
  <CloseButton {...innerProps} size={size} mx={2} tabIndex={-1} />
)
ClearIndicator.propTypes = {
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
}

// Taken from the @chakra-ui/icons package to prevent needing it as a dependency
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/icons/src/ChevronDown.tsx
const ChevronDown = createIcon({
  displayName: 'ChevronDownIcon',
  d: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'
})
const DropDownIndicator = ({ innerProps, selectProps: { size } }) => {
  const { addon } = useStyles()

  const iconSizes = {
    sm: 4,
    md: 5,
    lg: 6
  }
  const iconSize = iconSizes[size]

  return (
    <Center
      {...innerProps}
      sx={{
        ...addon,
        h: '100%',
        borderRadius: 0,
        borderWidth: 0,
        cursor: 'pointer'
      }}
    >
      <ChevronDown h={iconSize} w={iconSize} />
    </Center>
  )
}
DropDownIndicator.propTypes = {
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
}

const MenuPortal = ({ children }) => <Portal>{children}</Portal>
MenuPortal.propTypes = {
  children: PropTypes.node
}

const Menu = ({ children, ...props }) => {
  const menuStyles = useMultiStyleConfig('Menu')
  return (
    <selectComponents.Menu {...props}>
      <StylesProvider value={menuStyles}>{children}</StylesProvider>
    </selectComponents.Menu>
  )
}
Menu.propTypes = {
  children: PropTypes.node
}

const MenuList = ({ innerRef, children, maxHeight }) => {
  const { list } = useStyles()
  return (
    <Box
      sx={{
        ...list,
        maxH: `${maxHeight}px`,
        overflowY: 'auto'
      }}
      ref={innerRef}
    >
      {children}
    </Box>
  )
}
MenuList.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  maxHeight: PropTypes.number
}

const Option = ({
  innerRef,
  innerProps,
  children,
  isFocused,
  isDisabled,
  selectProps: { fontSize },
  selectProps: { size }
}) => {
  const { item } = useStyles()
  return (
    <Box
      role='button'
      sx={{
        ...item,
        w: '100%',
        textAlign: 'left',
        bg: isFocused ? item._focus.bg : 'transparent',
        fontSize: fontSize || size,
        ...(isDisabled && item._disabled)
      }}
      ref={innerRef}
      {...innerProps}
      {...(isDisabled && { disabled: true })}
    >
      {children}
    </Box>
  )
}
Option.propTypes = {
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  selectProps: PropTypes.object
}

const GroupHeading = ({ innerProps, children }) => {
  const { groupTitle } = useStyles()

  if (children !== undefined) {
    return (
      <Box sx={groupTitle} {...innerProps}>
        {children}
      </Box>
    )
  } else {
    return null
  }
}
GroupHeading.propTypes = {
  innerProps: PropTypes.object,
  children: PropTypes.node
}

const ChakraReactSelectComponents = {
  // Control components
  Control,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  IndicatorSeparator,
  ClearIndicator,
  DropDownIndicator,
  // Menu components
  MenuPortal,
  Menu,
  MenuList,
  GroupHeading,
  Option
}

export default ChakraReactSelectComponents
