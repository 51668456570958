import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { useTheme, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react'
import { IoSearchOutline, IoClose } from 'react-icons/io5'
import Analytics from '../../../../services/Analytics'
import { useProgress } from '../../ProgressBar/useProgress'
import { useFilters } from '../useFilters'
import { FilterButton } from '../FilterButton'

const SearchFilter = ({ hideSearchButton = false, autoFocus = false }) => {
  const theme = useTheme()
  const { currentStep } = useProgress()
  const filters = useFilters()
  const [search, setSearch] = useState('')
  const searchPlaceholder = `Search ${pluralize.plural(currentStep?.productCategory || '')}`.trim()

  useEffect(() => {
    setSearch(filters.currentFilters.query || '')
  }, [filters.currentFilters.query])

  const handleSearchChange = (event) => setSearch(event.target.value)
  const handleSearchClick = () => {
    Analytics.trackEvent({
      category: 'search filters',
      action: 'search input applied',
      label: 'product listing page'
    })

    filters.setSearch(search)
  }
  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick()
    }
  }
  const handleSearchClearClick = () => {
    setSearch('')
    filters.setSearch('')
  }

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <IoSearchOutline color='gray' />
        </InputLeftElement>
        <Input
          type='search'
          value={search}
          placeholder={searchPlaceholder}
          size='md'
          fontSize='sm'
          borderColor='blackAlpha.600'
          _hover={{ border: `solid 1px ${theme.colors.blackAlpha['800']}` }}
          borderRadius={{ base: 'full', lg: 'sm' }}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          data-test='search-input'
          h='40px'
          autoFocus={autoFocus}
          textOverflow='ellipsis'
        />
        <InputRightElement
          hidden={!search}
          onClick={handleSearchClearClick}
          cursor='pointer'
          color='gray'
          _hover={{ color: 'gray.700' }}
          data-test='search-input-clear'
        >
          <IoClose fontSize='20px' />
        </InputRightElement>
      </InputGroup>
      {hideSearchButton
        ? null
        : <FilterButton
            text='Search'
            variant={search ? 'solid' : 'outline'}
            onClick={handleSearchClick}
            data-test='search-input-button'
          />}
    </>
  )
}
SearchFilter.propTypes = {
  hideSearchButton: PropTypes.bool,
  autoFocus: PropTypes.bool
}

export default SearchFilter
