import { get, post } from './base'

export const consultations = {
  create: (params) =>
    post('/api/consultations', params),
  getByReferralCode: (referralCode) =>
    get(
      '/api/consultations/show_by_referral_code',
      { params: { referral_code: referralCode } }
    ),
  findOrCreate: (params) =>
    post('/api/consultations/find_or_create', params),
  index: (params) =>
    get('/api/consultations', { params }),
  newReferralCode: () =>
    get('/api/consultations/new'),
  single: (uuid) =>
    get(`/api/consultations/${uuid}`)
}
