import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Image,
  Heading,
  HStack,
  VStack,
  Text,
  Link
} from '@chakra-ui/react'
import dayjs from 'dayjs'

const Shipments = ({ shipments }) => {
  if (shipments.length > 0) {
    return (
      <Box data-test='order-shipment-details'>
        <Heading size='lg'>Shipments</Heading>
        <VStack spacing={4} pt={4}>
          {shipments.map((shipment, index) => (
            <Shipment key={index} shipment={shipment} index={index + 1} />
          ))}
        </VStack>
      </Box>
    )
  } else {
    return null
  }
}

Shipments.propTypes = {
  shipments: PropTypes.array
}

const Shipment = ({ shipment, index }) => {
  const {
    createdAt,
    fulfillment: {
      supplierName
    },
    shipmentsCartItems,
    trackingUrl
  } = shipment

  return (
    <Box
      borderRadius={4}
      border='solid 1px'
      borderColor='blackAlpha.300'
      p={4}
      w='100%'
    >
      <Heading size='md'>Shipment {index}</Heading>
      <HStack>
        <Text fontWeight='bold'>Fulfilled By:</Text>
        <Text>{supplierName}</Text>
      </HStack>
      <HStack>
        <Text fontWeight='bold'>Shipped On:</Text>
        <Text>{dayjs(createdAt).format('MM/DD/YYYY')}</Text>
      </HStack>
      <HStack>
        <Link
          color='blue.500'
          isExternal
          href={trackingUrl}
        >
          <Text fontWeight='bold'>
            Click here to track your shipment
          </Text>
        </Link>
      </HStack>
      <VStack
        spacing={4}
        alignItems='start'
        pt={4}
      >
        {shipmentsCartItems.map(shipmentItem => (
          <ShipmentItem key={shipmentItem.uuid} shipmentItem={shipmentItem} />
        ))}
      </VStack>
    </Box>
  )
}

Shipment.propTypes = {
  shipment: PropTypes.object,
  index: PropTypes.number
}

const ShipmentItem = ({ shipmentItem }) => {
  const {
    cartItem
  } = shipmentItem

  const {
    productVariant: {
      productTitle,
      product: {
        imageUrls,
        manufacturer
      }
    },
    quantity
  } = cartItem
  return (
    <HStack
      spacing={4} borderBottom='solid 1px'
      borderBottomColor='blackAlpha.300'
      _last={{ border: 'none', paddingBottom: 0 }}
      width='100%'
      pb={4}
    >
      <Image
        boxSize='50px'
        fit='contain'
        src={imageUrls[0]}
      />
      <VStack spacing={0} alignItems='start'>
        <Text
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {manufacturer} - {productTitle}
        </Text>
        <HStack spacing={2}>
          <Text fontWeight='bold'>Quantity:</Text>
          <Text>{quantity}</Text>
        </HStack>
      </VStack>
    </HStack>
  )
}

ShipmentItem.propTypes = {
  shipmentItem: PropTypes.object
}

export default Shipments
