import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { Banner } from '../common/Banner'
import { usePatientSession } from '../../context/use-patient-session'
import { usePrescriptionModal } from '../../context/use-prescription-modal'

const PrescriptionBannerComponent = (props) => {
  const { shouldPromptForPrescription, hasActiveInsurance } = usePatientSession()
  const prescriptionModal = usePrescriptionModal()

  if (prescriptionModal.isModalOpen) {
    return null
  }

  if (shouldPromptForPrescription() && hasActiveInsurance()) {
    return (
      <Banner
        data-test='prescription-banner'
        text='Insurance pricing has been unlocked. To ensure savings, please upload a copy of your prescription.'
        ctaText='Upload prescription'
        onCtaAction={() => prescriptionModal.handleOpenModal({ triggerSource: 'Banner' })}
        {...props}
      />
    )
  } else {
    return null
  }
}

PrescriptionBannerComponent.propTypes = {
  props: PropTypes.object
}

const DesktopBanner = () => {
  const desktopBreakpointValue = '(min-width: 62em)'
  const isDesktopBreakpoint = useMediaQuery({ query: desktopBreakpointValue })

  return isDesktopBreakpoint
    ? <PrescriptionBannerComponent />
    : null
}

const MobileBanner = () => {
  const mobileBreakpointValue = '(max-width: 62em)'
  const isMobileBreakpoint = useMediaQuery({ query: mobileBreakpointValue })

  return isMobileBreakpoint
    ? <PrescriptionBannerComponent />
    : null
}
export const PrescriptionBanner = {
  Desktop: DesktopBanner,
  Mobile: MobileBanner
}
