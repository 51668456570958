import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Text,
  VStack
} from '@chakra-ui/react'
import { ExcludeFromRecording } from '../../../services/RecordingService'

const InsuranceDetails = ({ patientInsurancePlan }) => {
  const {
    patientFullName,
    insuranceCarrierName,
    insuranceMemberId
  } = patientInsurancePlan

  return (
    <ExcludeFromRecording>
      <VStack data-test='insurance-details' align='left' spacing='5'>
        <Box>
          <Text mb='1' fontWeight='semibold'>
            Name
          </Text>
          <Text>
            {patientFullName}
          </Text>
        </Box>
        <Box>
          <Text mb='1' fontWeight='semibold'>
            Insurance Carrier
          </Text>
          <Text>
            {insuranceCarrierName}
          </Text>
        </Box>
        <Box>
          <Text mb='1' fontWeight='semibold'>
            Member ID
          </Text>
          <Text>
            {insuranceMemberId}
          </Text>
        </Box>
      </VStack>
    </ExcludeFromRecording>
  )
}

InsuranceDetails.propTypes = {
  patientInsurancePlan: PropTypes.object
}

export default InsuranceDetails
