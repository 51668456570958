import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  GridItem,
  Flex,
  Link,
  Select
} from '@chakra-ui/react'
import useRemoveFromCartMutation from '../Cart/useRemoveFromCartMutation'
import useEditFromCartMutation from '../Cart/useEditFromCartMutation'
import Mixpanel from '../../../services/Mixpanel'

const CartItemQuantity = ({ cartItem, ...props }) => {
  const removeFromCartMutation = useRemoveFromCartMutation()
  const editFromCartMutation = useEditFromCartMutation()

  const { uuid, quantity, productVariant } = cartItem
  const { product, maxQuantity } = productVariant
  const mutationsLoading = removeFromCartMutation.isLoading || editFromCartMutation.isLoading

  const handleRemoveClick = () => {
    removeFromCartMutation.mutate(uuid)

    Mixpanel.trackEventWithProduct('Remove product from cart', product)
  }

  const handleQuantityChange = (element) => {
    const newQuantity = element.target.value
    let event

    if (newQuantity > parseInt(quantity)) {
      event = 'Increment item count'
    } else {
      event = 'Decrement item count'
    }

    editFromCartMutation.mutate({
      cartItemUuid: uuid,
      quantity: element.target.value
    })

    Mixpanel.trackEventWithProduct(
      event,
      product,
      { count: parseInt(newQuantity) }
    )
  }

  return (
    <GridItem colSpan={2} {...props}>
      <Flex
        flexDirection={{ base: 'normal', lg: 'column' }}
        ml={{ lg: 'auto' }}
        justify={{ base: 'space-between' }}
      >
        <Box
          mb={4}
          width='64px'
        >
          <Select
            aria-label='Select quantity'
            maxW='16'
            size='sm'
            borderRadius='md'
            focusBorderColor='blue.500'
            bg='white'
            disabled={mutationsLoading}
            defaultValue={quantity}
            cursor='pointer'
            onChange={handleQuantityChange}
            data-test='cart-item-quantity-selector'
          >
            {[...Array(maxQuantity).keys()].map(i =>
              <option key={i}>
                {i + 1}
              </option>
            )}
          </Select>
        </Box>
        <Link
          color='blackAlpha.600'
          fontSize={12}
          fontWeight={500}
          textDecoration='underline'
          textAlign='center'
          onClick={handleRemoveClick}
          data-test='cart-remove-item'
        >
          Remove
        </Link>
      </Flex>
    </GridItem>
  )
}

CartItemQuantity.propTypes = {
  cartItem: PropTypes.object.isRequired
}

export default CartItemQuantity
