import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Grid,
  GridItem,
  Text,
  Link
} from '@chakra-ui/react'

import UsageDataIcon from './UsageDataIcon'

const ListItemCell = ({ children, ...props }) => {
  const borderProps = {
    borderBottom: 'solid 1px',
    borderBottomColor: 'providerDashboard.grayBorder'
  }

  return (
    <GridItem
      py={7}
      display='flex'
      height='100%'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='center'
      {...borderProps}
      {...props}
    >
      {children}
    </GridItem>
  )
}

ListItemCell.propTypes = {
  children: PropTypes.node.isRequired
}

const ListItem = ({
  fulfillment,
  ...props
}) => {
  const {
    createdAt,
    fulfillmentConfirmationNumber,
    orderShipmentUrl,
    patientName
  } = fulfillment

  return (
    <Grid
      templateColumns='repeat(12, 1fr)'
      fontSize={14}
      alignItems='center'
      {...props}
    >
      <ListItemCell
        colSpan={{ base: 2 }}
        pl={6}
      >
        <Text>
          {patientName}
        </Text>
      </ListItemCell>
      <ListItemCell
        colSpan={{ base: 2 }}
      >
        <Text>
          {fulfillmentConfirmationNumber}
        </Text>
      </ListItemCell>
      <ListItemCell
        colSpan={{ base: 2 }}
      >
        <Text>
          {dayjs(createdAt).format('MM/DD/YYYY')}
        </Text>
      </ListItemCell>
      <ListItemCell
        colSpan={{ base: 2 }}
      >
        <Link
          fontWeight='600'
          color='green.600'
          fontSize='md'
          isExternal
          href={orderShipmentUrl}
        >
          View
        </Link>
      </ListItemCell>
      <ListItemCell
        colSpan={{ base: 2 }}
      >
        CPAP Usage Data
      </ListItemCell>
      <ListItemCell
        colSpan={{ base: 2 }}
      >
        <UsageDataIcon fulfillment={fulfillment} />
      </ListItemCell>
    </Grid>
  )
}

ListItem.propTypes = {
  fulfillment: PropTypes.object.isRequired
}

const List = ({ fulfillments }) => (
  fulfillments.map((fulfillment) => (
    <ListItem
      key={fulfillment.fulfillmentConfirmationNumber}
      fulfillment={fulfillment}
    />))
)

List.propTypes = {
  fulfillments: PropTypes.array.isRequired
}

export default List
