import React from 'react'
import PropTypes from 'prop-types'
import { Flex, ThemeProvider } from '@chakra-ui/react'
import TopBar from './TopBar'
import Footer from './Footer'
import landingPageTheme from '../../theme/landing'
import { Outlet } from 'react-router-dom'

const Layout = ({ children, renderChildren = false }) => (
  <ThemeProvider theme={landingPageTheme}>
    <Flex direction='column' bg='white' minHeight='100vh' as='main'>
      <TopBar />
      {renderChildren ? children : <Outlet />}
      <Footer />
    </Flex>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node,
  renderChildren: PropTypes.bool
}

export default Layout
