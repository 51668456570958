import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { usePatientSession } from './use-patient-session'
import { useParams } from 'react-router-dom'

const productVariantContext = createContext()
// Provider component that wraps your app and returns the product variants ...
// ... available to any child component that calls useProductVariants().
export function ProductVariantProvider ({ children }) {
  const value = useProvideProductVariant()
  return (
    <productVariantContext.Provider value={value}>
      {children}
    </productVariantContext.Provider>
  )
}

ProductVariantProvider.propTypes = {
  children: PropTypes.node.isRequired
}
// Hook for child components to get the product variants object ...
// ... and re-render when it changes.
export const useProductVariant = () => {
  return useContext(productVariantContext)
}

function useProvideProductVariant (productVariantUuid) {
  const { uuid } = useParams()
  const patientSession = usePatientSession()
  const minQuantity = 1
  const productVariantCartItem = patientSession.getCartItem(uuid)

  const [quantity, setQuantity] = useState(
    productVariantCartItem?.quantity || minQuantity
  )

  return {
    quantity,
    setQuantity
  }
}
