import clockSvg from '../../../../assets/images/landing/clock.svg'
import moneySvg from '../../../../assets/images/landing/money.svg'
import phoneSvg from '../../../../assets/images/landing/phone.svg'
import checkmarkSvg from '../../../../assets/images/landing/checkmark.svg'

export const ASSETS = [
  {
    title: 'Speedy Distribution',
    description: 'Offering same day or next day delivery from local DME suppliers for patients - with no driving in.',
    src: clockSvg
  },
  {
    title: 'Cost Effective',
    description: 'Immediate application of benefits to eliminate unnecessary out-of-pocket costs.',
    src: moneySvg
  },
  {
    title: 'Efficient Communication',
    description: 'Reducing provider-to-supplier communication and eliminating the search for a perfect DME supplier.',
    src: phoneSvg
  },
  {
    title: 'Compliance Made Easy',
    description: 'Increasing patient compliance through a prescription-tailored online shopping experience.',
    src: checkmarkSvg
  }
]
