import { get, post, put } from './base'

export const orders = {
  create: (params) =>
    post('/api/orders', params),
  index: () =>
    get('/api/orders'),
  single: (orderConfirmationNumber) =>
    get(`/api/orders/${orderConfirmationNumber}`),
  edit: ({ orderConfirmationNumber, assistanceRequired }) =>
    put(
      `/api/orders/${orderConfirmationNumber}`,
      { order: { assistanceRequired } }
    )
}
