import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Button
} from '@chakra-ui/react'

const ShopButton = () => (
  <Box>
    <Button
      as={RouterLink}
      to='/shop'
      color='white'
      bg='green.700'
      _hover={{ background: 'green.500' }}
      data-test='order-history-shop-button'
    >
      Shop
    </Button>
  </Box>
)

export default ShopButton
