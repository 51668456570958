import React from 'react'
import { Flex, Button } from '@chakra-ui/react'
import { HashLink } from 'react-router-hash-link'
import { CtaButton } from './CtaButton'

export const CtaButtons = () => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align={{ base: 'center', md: 'left' }}
      mt='24px'
    >
      <CtaButton
        mb={{ base: '8px', md: '0' }}
        mr={{ base: '0', md: '16px  ' }}
        w={{ base: '250px', md: 'auto' }}
        fontSize='md'
        color='black'
        bg='white'
        _hover={{
          color: 'black',
          boxShadow: 'lg'
        }}
        data-test='landing-page-cta-primary-button'
      />
      <Button
        as={HashLink}
        to='#why-doorbell-section'
        w={{ base: '250px', md: 'auto' }}
        fontSize='md'
        size='lg'
        color='white'
        variant='outline'
        _hover={{
          bg: 'none',
          color: 'white',
          boxShadow: 'lg'
        }}
        data-test='landing-page-cta-secondary-button'
      >
        Learn More
      </Button>
    </Flex>
  )
}
