import React, { useEffect } from 'react'
import ForPatients from '../LandingPage/ForPatients'
import ForProviders from '../LandingPage/ForProviders'
import Team from '../LandingPage/Team'
import LandingPageLayout from '../LandingPage/Layout'
import WorkOrderList from '../WorkOrders/List'
import NewWorkOrder from '../WorkOrders/New'
import OrderDetail from '../Orders/Detail'
import PasswordRecovery from '../auth/PasswordRecovery'
import PasswordReset from '../auth/PasswordReset'
import PatientSignup from '../auth/PatientSignup'
import ProviderSignup from '../auth/ProviderSignup'
import ProviderSignupPrefill from '../auth/ProviderSignupPrefill'
import ShopLayout from '../Shop/Layout'
import ProductDetail from '../Shop/ProductDetail'
import ProductList from '../Shop/ProductList'
import SupplierFulfillments from '../Suppliers/Fulfillments'
import TrackingForm from '../Suppliers/Tracking/Form'
import TrackingSuccess from '../Suppliers/Tracking/Success'
import TrackingPageLayout from '../Suppliers/Tracking/Layout'
import AccountLayout from '../Patients/AccountLayout'
import OrderHistory from '../Patients/OrderHistory'
import InsuranceInformation from '../Patients/InsuranceInformation'
import AccountSettings from '../Patients/AccountSettings'
import ProtectedRoute from './ProtectedRoute'
import UnprotectedRoute from './UnprotectedRoute'
import WorkOrderDetail from '../WorkOrders/Detail'
import ReferralCodeGate from '../ReferralCodeGate/Gate'
import ShopRoute from './ShopRoute'
import SignIn from '../auth/SignIn'
import Cart from '../Shop/Cart'
import { useLocation, Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'
import { isAuthenticated, useAuth } from '../../context/use-auth'
import Analytics from '../../services/Analytics'
import * as RecordingService from '../../services/RecordingService'
import { useQueryParams } from '../../utils/useQueryParams'

const Routes = () => {
  RecordingService.useSetSessionId()
  const {
    isSupplier,
    isProvider
  } = useAuth()
  const location = useLocation()
  const isLoggedIn = isAuthenticated()

  useEffect(() => {
    Analytics.trackPageView()
  }, [location])

  const queryParams = useQueryParams()

  const IndexRoute = () => {
    if (isLoggedIn) {
      if (isSupplier) {
        return <SupplierFulfillments />
      } else if (isProvider) {
        return <WorkOrderList />
      }
    }

    // Logged In Patients are able to see the Landing Page
    return <ForPatients />
  }

  const CodeRoute = () => {
    if (queryParams.code !== undefined) {
      return (
        <Navigate
          to={{
            pathname: '/shop',
            search: `?code=${queryParams.code}`
          }} replace
        />
      )
    } else {
      return <ReferralCodeGate />
    }
  }

  return (
    <RouterRoutes>
      <Route index element={IndexRoute()} />
      <Route path='orders/:orderConfirmationNumber' element={<OrderDetail />} />

      <Route element={<LandingPageLayout />}>
        <Route path='code' element={CodeRoute()} />
        <Route path='providers' element={<ForProviders />} />
        <Route path='team' element={<Team />} />
        <Route element={<UnprotectedRoute />}>
          <Route path='auth/password_reset' element={<PasswordReset />} />
          <Route path='auth/password_recovery' element={<PasswordRecovery />} />
          <Route path='providers/signin' element={<SignIn signupEnabled />} />
          <Route path='suppliers/signin' element={<SignIn />} />
          <Route path='providers/signup' element={<ProviderSignup />} />
          <Route path='providers/signup/:code' element={<ProviderSignupPrefill />} />
        </Route>
      </Route>

      <Route element={<TrackingPageLayout />}>
        <Route path='tracking' element={<TrackingForm />} />
        <Route path='tracking/:fulfillmentConfirmationNumber' element={<TrackingSuccess />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path='work_orders/new' element={<NewWorkOrder />} />
        <Route path='work_orders/:uuid' element={<WorkOrderDetail />} />
      </Route>

      <Route element={<ShopRoute />}>
        <Route path='shop' element={<ProductList />} />
        <Route path='cart' element={<Cart />} />
        <Route path='products/:uuid' element={<ProductDetail />} />
        {window.location.hostname !== 'www.doorbellhealth.com' && (
          <Route element={<ProtectedRoute forPatients />}>
            <Route element={<AccountLayout />}>
              <Route path='order_history' element={<OrderHistory />} />
              <Route path='insurance_information' element={<InsuranceInformation />} />
              <Route path='account_settings' element={<AccountSettings />} />
            </Route>
          </Route>
        )}
      </Route>

      {window.location.hostname !== 'www.doorbellhealth.com' && (
        <Route element={<ShopLayout />}>
          <Route element={<UnprotectedRoute />}>
            <Route path='patients/password_reset' element={<PasswordReset />} />
            <Route path='patients/password_recovery' element={<PasswordRecovery />} />
            <Route path='patients/signin' element={<SignIn signupEnabled />} />
            <Route path='patients/signup' element={<PatientSignup />} />
          </Route>
        </Route>
      )}

      <Route path='*' element={<Navigate to='/' replace />} />
    </RouterRoutes>
  )
}

export default Routes
