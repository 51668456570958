import * as React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Box, HStack } from '@chakra-ui/react'

const DesktopNavItem = ({ icon, label, path, active, ...props }) => (
  <HStack
    as={RouterLink}
    to={path}
    aria-current={active ? 'page' : undefined}
    spacing='2'
    px='3'
    py='2'
    rounded='md'
    transition='all 0.2s'
    color='gray.600'
    cursor='pointer'
    _hover={{
      color: 'gray.800'
    }}
    {...props}
  >
    {icon && (
      <Box aria-hidden fontSize='md'>
        {icon}
      </Box>
    )}
    <Box fontWeight='medium'>{label}</Box>
  </HStack>
)

DesktopNavItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  active: PropTypes.bool
}

const MobileNavItem = ({ label, path, active, ...props }) => (
  <Box
    as={RouterLink}
    to={path}
    display='block'
    px='3'
    py='6'
    fontWeight='medium'
    borderBottom='solid 1px'
    borderBottomColor='gray.300'
    aria-current={active ? 'page' : undefined}
    cursor='pointer'
    _hover={{
      bg: 'whiteAlpha.200'
    }}
    _activeLink={{
      bg: 'blackAlpha.300',
      color: 'white'
    }}
    {...props}
  >
    {label}
  </Box>
)

MobileNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  active: PropTypes.bool
}

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem
}
