import React from 'react'
import PropTypes from 'prop-types'
import CardInsurancePrice from '../ProductCard/InsurancePrice'
import DetailInsurancePrice from '../ProductDetail/InsurancePrice'
import CardRetailPrice from '../ProductCard/RetailPrice'
import DetailRetailPrice from '../ProductDetail/RetailPrice'
import { Box } from '@chakra-ui/react'
import { usePatientSession } from '../../../context/use-patient-session'

const Price = ({ prices, product, designVariant, insuranceSavingsRef, ...props }) => {
  const { hasActiveInsurance } = usePatientSession()
  const isCashOnly = product.isCashOnly
  let insurancePrice, retailPrice

  switch (designVariant) {
    case 'card':
      insurancePrice = <CardInsurancePrice prices={prices} />
      retailPrice = (
        <CardRetailPrice
          insuranceSavingsRef={insuranceSavingsRef}
          prices={prices}
          showUnlockInsuranceLink={!isCashOnly}
        />
      )
      break
    case 'detail':
      insurancePrice = <DetailInsurancePrice prices={prices} />
      retailPrice = <DetailRetailPrice prices={prices} showUnlockInsuranceLink={!isCashOnly} />
  }

  return (
    <Box {...props}>
      {
        !isCashOnly && hasActiveInsurance() ? insurancePrice : retailPrice
      }
    </Box>
  )
}

Price.propTypes = {
  prices: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  designVariant: PropTypes.oneOf(['card', 'detail']),
  insuranceSavingsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])

}

export default Price
