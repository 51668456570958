import clockSvg from '../../../../assets/images/landing/clock.svg'
import truckSvg from '../../../../assets/images/landing/truck.svg'
import networkSvg from '../../../../assets/images/landing/network.svg'
import checkmarkSvg from '../../../../assets/images/landing/checkmark.svg'

export const ASSETS = [
  {
    title: 'Shop Online with Insurance',
    description: 'We custom-built this experience just for you and your prescription. Insurance benefits are applied immediately so you\'ll never wait for a reimbursement again.',
    src: clockSvg
  },
  {
    title: 'Delivered to Your Front Door',
    description: 'At Doorbell Health, we believe that convenience and supporting small businesses can go hand-in-hand. That\'s why we offer same-day* delivery from your local DME right to your front door.',
    src: truckSvg
  },
  {
    title: 'Your Favorite Brands and Products',
    description: 'Exactly what you\'re looking for all in one place. We have personally selected quality products from reputable brands - because we care.',
    src: networkSvg
  },
  {
    title: 'Compliance Made Easy',
    description: 'Consider all of your questions answered. Doorbell Health is your new favorite resource for getting the most out of your device.',
    src: checkmarkSvg
  }
]
