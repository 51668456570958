import * as React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'
import { IoMenuOutline, IoCloseOutline } from 'react-icons/io5'

export const MobileHamburgerMenu = (props) => {
  const { onClick, isOpen } = props
  return (
    <Box
      ms='-4'
      minW={{
        base: '12',
        lg: '76px'
      }}
      display={{
        lg: 'none'
      }}
      mr='3'
      data-test='hamburger-menu'
    >
      <Box
        as='button'
        onClick={onClick}
        p='2'
        fontSize='xl'
        data-test='hamburger-menu-button'
      >
        <Box
          aria-hidden
          as={isOpen ? IoCloseOutline : IoMenuOutline}
          color={isOpen ? 'white' : 'black'}
          h='32px'
          w='32px'
        />
        <Box srOnly>{isOpen ? 'Close menu' : 'Open menu'}</Box>
      </Box>
    </Box>
  )
}

MobileHamburgerMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}
