import React from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import {
  HStack,
  Text
} from '@chakra-ui/react'

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <HStack
      data-test='back-link'
      color='blackAlpha.600'
      cursor='pointer'
      fontSize='sm'
      spacing='1'
      onClick={() => navigate(-1)}
      _hover={{
        color: 'blackAlpha.700'
      }}
    >
      <IoArrowBack />
      <Text>
        Back
      </Text>
    </HStack>
  )
}

export default BackButton
