import React from 'react'
import isBoolean from 'lodash/isBoolean'
import Dashboard from '../../providers/Dashboard'
import ActionItems from './ActionItems'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { API } from '../../../services/Api'
import { Card } from '../../common/Card'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { ExcludeFromRecording } from '../../../services/RecordingService'

import {
  useToast,
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'

import {
  FormDivider
} from '../../common/FormElements'

const WorkOrderItem = ({ children, label, text }) => (
  <Box>
    <FormLabel fontSize='xs' fontWeight='semibold' color='gray.600' mb={3}>
      {label}
    </FormLabel>
    <Text>{text}</Text>
    {children}
  </Box>
)

WorkOrderItem.propTypes = {
  children: PropTypes.array,
  label: PropTypes.string.isRequired,
  text: PropTypes.string
}

const WorkOrderDetail = () => {
  const { uuid } = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const handleError = () => {
    navigate('/', { replace: true })

    toast({
      position: 'top-right',
      title: 'Invalid work order id',
      status: 'error',
      duration: null,
      isClosable: true
    })
  }

  const cardStyles = {
    fontSize: 'sm',
    py: '6',
    px: {
      base: '4',
      lg: '6'
    }
  }

  const fetchConsultation = async () => {
    const { data } = await API.consultations.single(uuid)

    return data
  }

  const { data } = useQuery(
    ['fetchConsultation', uuid],
    fetchConsultation,
    {
      retry: false,
      onError: handleError,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const stringifyPecos = (pecos) => (
    pecos ? 'Yes' : 'No'
  )

  let workOrder,
    referralCard,
    formattedReferralCode,
    unformattedReferralCode,
    productVerticalName,
    patientPacketTypes

  if (data) {
    const consultation = data
    const { consultationHcpcsCodes } = consultation
    productVerticalName = consultation.productVerticalName
    patientPacketTypes = consultation.patientPacketTypes

    const hcpcsGroupedByCategory = consultationHcpcsCodes.reduce((hash, consultationHcpcsCode) => {
      const productCategoryName = consultationHcpcsCode.hcpcsCode.productCategory.name

      return ({ ...hash, [productCategoryName]: (hash[productCategoryName] || []).concat(consultationHcpcsCode) })
    }, {})

    const categories = Object.keys(hcpcsGroupedByCategory)

    const formattedCreatedAt = (
      dayjs(consultation.createdAt).format('MM/DD/YYYY')
    )

    formattedReferralCode = (
      consultation.referralCode.replace(/(\S{3})(\S{3})/, '$1-$2')
    )

    unformattedReferralCode = data.referralCode

    referralCard = (
      <Card mt={{ base: '0', lg: '108px' }} {...cardStyles}>

        <Flex mb={4}>
          <Heading size='sm' fontWeight='medium' color='gray.800' py={2}>
            Referral Code
          </Heading>

          <Spacer />

          <ExcludeFromRecording>
            <Heading
              size='sm'
              fontWeight='semibold'
              color='blue.600'
              bg='gray.100'
              p={2}
              borderRadius={4}
              data-test='formatted-referral-code'
            >
              {formattedReferralCode}
            </Heading>
          </ExcludeFromRecording>
        </Flex>

        <VStack align='stretch' spacing={5}>
          <p>
            Share this unique referral code with your patient, and they can shop
            a customized experience at doorbellhealth.com.
          </p>
          <p>
            We’ll also reach out to your patient via email or text to shop
            Doorbell Health.
          </p>
          <p>
            Upon purchase, we assemble the complete treatment package for our DME
            supplier to fulfill the order and bill insurance, if necessary.
          </p>
        </VStack>
      </Card>
    )

    workOrder = (
      <Card mt={6} {...cardStyles}>
        <Heading size='md' fontWeight='bold' color='gray.800' mb='8px'>
          Work Order Details
        </Heading>

        <ExcludeFromRecording>
          <VStack align='stretch' spacing={8}>
            <Text
              fontWeight='medium'
              color='gray.600'
            >
              {`Submitted ${formattedCreatedAt}`}
            </Text>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              <WorkOrderItem
                label='Email Address'
                text={consultation.patientEmail || '--'}
              />
              <WorkOrderItem
                label='Phone'
                text={consultation.patientPhone || '--'}
              />
            </SimpleGrid>

            <FormDivider />

            {
              categories.map((category) => {
                const options = hcpcsGroupedByCategory[category]

                if (options === undefined) {
                  return false
                }

                return (
                  <WorkOrderItem label={category} key={category}>
                    {options.map((consultationHcpcs, index) => {
                      const icd10s = consultationHcpcs.consultationHcpcsCodeIcd10S
                      const hcpcsCode = consultationHcpcs.hcpcsCode

                      const fields = [
                        { value: consultationHcpcs.lengthOfNeed, label: 'Length of Need (months)' },
                        { value: consultationHcpcs.pressureSetting, label: 'Pressure Setting (cm H2O)' },
                        { value: consultationHcpcs.po2Percent, label: 'PO2 Level', withPercentSign: true },
                        { value: consultationHcpcs.arterialSaturationPercent, label: 'Arterial Saturation Level', withPercentSign: true }
                      ].filter(f => f.value)

                      return (
                        <VStack align='left' spacing={6} key={hcpcsCode.code} mt={index > 0 ? 6 : 0}>
                          <Text>
                            {`${hcpcsCode.code} ${hcpcsCode.alias}`}
                          </Text>

                          {fields.length > 0 && (
                            <>
                              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                                {fields.map(field => (
                                  <WorkOrderItem
                                    key={field.label}
                                    label={field.label}
                                    text={field.value + (field.withPercentSign ? ' %' : '')}
                                  />
                                ))}
                              </SimpleGrid>
                            </>
                          )}

                          <WorkOrderItem
                            label='Diagnosis'
                          >
                            {icd10s && icd10s.length > 0 && (
                              <Box>
                                {icd10s.map((consultationIcd10, index) => (
                                  <Text key={index}>{`${consultationIcd10.icd10.code}, ${consultationIcd10.icd10.description}.`}</Text>
                                ))}
                              </Box>
                            )}
                          </WorkOrderItem>

                          <FormDivider />
                        </VStack>
                      )
                    }
                    )}
                  </WorkOrderItem>
                )
              }
              )
            }

            {isBoolean(consultation.patientEligibleForMachine) && (
              <>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <WorkOrderItem
                    label='Does this patient currently have a machine or has had one in the last five years?'
                    text={consultation.patientEligibleForMachine ? 'No' : 'Yes'}
                  />
                </SimpleGrid>

                <FormDivider />
              </>
            )}

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              <WorkOrderItem
                label='Provider Name'
                text={
                  `${consultation.provider.firstName} ${consultation.provider.lastName}`
                }
              />

              <WorkOrderItem label='NPI' text={`${consultation.provider.npi}`} />
            </SimpleGrid>

            <WorkOrderItem
              label='PECOS'
              text={stringifyPecos(consultation.provider.pecos)}
            />

          </VStack>
        </ExcludeFromRecording>
      </Card>
    )
  }

  return (
    <Dashboard>
      <Grid templateColumns='repeat(8, 1fr)' gap={8} maxW='7xl'>
        <GridItem colSpan={{ base: 8, lg: 6 }}>
          <Heading
            fontSize={{ base: '20px', lg: '24px' }}
            fontWeight='700'
            color='gray.800'
            mb='2'
          >
            <HStack spacing={1}>
              <IoCheckmarkSharp />
              <Text>
                Order submitted
              </Text>
            </HStack>
          </Heading>
          <Text color='gray.600'>
            Your standard work order is now on file with us and will be shared with
            our DME supplier partner for order fulfillment and billing.
          </Text>
          <ActionItems
            cardStyles={cardStyles}
            formattedReferralCode={formattedReferralCode}
            unformattedReferralCode={unformattedReferralCode}
            productVerticalName={productVerticalName}
            patientPacketTypes={patientPacketTypes}
          />
          {workOrder}
        </GridItem>

        <GridItem colSpan={{ base: 8, lg: 2 }}>
          {referralCard}
        </GridItem>
      </Grid>
    </Dashboard>
  )
}

export default WorkOrderDetail
