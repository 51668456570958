/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Analytics from '../../services/Analytics'
import DatePicker from '../common/DatePicker'
import ReactSelect from '../common/ChakraReactSelect'
import useRouteMatching from '../common/useRouteMatching'
import Mixpanel from '../../services/Mixpanel'
import { ExcludeFromRecording } from '../../services/RecordingService'
import { Controller } from 'react-hook-form'
import { FormControl } from '../common/FormElements'

import {
  useBoolean,
  Box,
  Button,
  Center,
  chakra,
  Input,
  Link,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
export const notListedOption =
  { label: 'Other insurance carrier', value: 'otherInsuranceCarrier' }
export const InsuranceForm = ({
  onSubmit,
  errors,
  errorMessage = null,
  labels,
  control,
  isBreastPump,
  isLoading,
  register,
  reset,
  handleCarrierChange,
  onDoThisLaterClick,
  setSelectedInsuranceCarrier,
  hideInsuranceInputs,
  insuranceCarriers,
  submitDisabled,
  defaultFormData,
  isACoveredCaliforniaPlan,
  isUpdateModal
}) => {
  const [hasPressedAKey, setHasPressedAKey] = useBoolean()
  const [hasSetDefaultData, setHasSetDefaultData] = useBoolean()
  const { isShopRoute, isProductRoute, isCartRoute } = useRouteMatching()

  const displayFieldsStyle = hideInsuranceInputs ? 'none' : 'block'

  let insuranceOptions = [notListedOption]

  if (insuranceCarriers.data) {
    insuranceOptions = insuranceCarriers.data.data.map((option) => {
      return {
        value: option.payerId,
        label: option.name
      }
    }).concat(notListedOption)
  }

  useEffect(() => {
    if (defaultFormData && !hasSetDefaultData) {
      reset(defaultFormData)

      const defaultInsuranceCarrier = defaultFormData?.insuranceCarrier
      if (defaultInsuranceCarrier) {
        handleInsuranceCarrierChange(defaultInsuranceCarrier)
      }

      setHasSetDefaultData.on()
    }
  }, [defaultFormData, hasSetDefaultData])

  useEffect(() => {
    Mixpanel.trackEvent('Arrive at insurance details entry form')
  }, [])

  const trackInputInfo = () => {
    if (!hasPressedAKey) {
      setHasPressedAKey.on()

      let label

      if (isShopRoute) {
        label = 'product listing page'
      }

      if (isProductRoute) {
        label = 'product details page'
      }

      if (isCartRoute) {
        label = 'cart review page'
      }

      Analytics.trackEvent({
        category: 'insurance',
        action: 'input info',
        label
      })
    }
  }

  const handleKeyDown = () => {
    trackInputInfo()
  }

  const trackCarrierSelection = (insuranceCarrier) => {
    Analytics.trackEvent({
      category: 'insurance',
      action: 'select carrier',
      label: insuranceCarrier.label
    })
  }

  const handleInsuranceCarrierFieldChange = (field) => (insuranceCarrier) => {
    handleInsuranceCarrierChange(insuranceCarrier)

    return field.onChange(insuranceCarrier)
  }

  const handleInsuranceCarrierChange = (insuranceCarrier) => {
    trackCarrierSelection(insuranceCarrier)
    setSelectedInsuranceCarrier(insuranceCarrier)
    handleCarrierChange()
  }

  const closeButton = () => {
    if (hideInsuranceInputs) {
      return null
    } else {
      return (
        <Link
          color='green.600'
          onClick={onDoThisLaterClick}
          data-test='modal-ignore-prompt'
        >
          {isUpdateModal ? 'Close' : "No, I'll do this later"}
        </Link>
      )
    }
  }

  return (
    <>
      <Text fontSize='sm'>
        {isUpdateModal ? 'Update your information below.' : 'Get the best price on your equipment by using your insurance. Your details will remain secure.'}
      </Text>

      <chakra.form
        mt={4}
        onSubmit={onSubmit}
        w='100%'
        data-test='insurance-form'
      >
        <FormControl
          isRequired
          errorMessage={errors?.insuranceCarrier?.message}
          label={labels.insuranceCarrier}
          data-test='insurance-carrier-input'
        >
          <Controller
            control={control}
            name='insuranceCarrier'
            render={({ field }) => (
              <ReactSelect
                {...field}
                onChange={handleInsuranceCarrierFieldChange(field)}
                id='insurance-carrier-input'
                isInvalid={!!errors?.insuranceCarrier?.message}
                options={insuranceOptions}
                closeMenuOnSelect
                size='md'
                fontSize='sm'
                isDisabled={isACoveredCaliforniaPlan}
              />
            )}
          />
        </FormControl>

        <ExcludeFromRecording>
          <VStack spacing='3' mt='3'>
            <FormControl
              isRequired
              errorMessage={errors?.memberId?.message}
              label={labels.memberId}
              display={displayFieldsStyle}
            >
              <Input
                id='memberId'
                data-test='member-id-input'
                onKeyDown={handleKeyDown}
                fontSize='sm'
                {...register('memberId')}
              />
            </FormControl>

            <Box display={displayFieldsStyle} w='100%'>
              <SimpleGrid
                w='100%'
                columns={{ base: 1, md: 2 }}
                spacing={4}
              >
                <FormControl
                  isRequired
                  errorMessage={errors?.firstName?.message}
                  label={labels.firstName}
                >
                  <Input
                    data-test='first-name-input'
                    fontSize='sm'
                    {...register('firstName')}
                  />
                </FormControl>

                <FormControl
                  isRequired
                  errorMessage={errors?.lastName?.message}
                  label={labels.lastName}
                >
                  <Input
                    data-test='last-name-input'
                    fontSize='sm'
                    {...register('lastName')}
                  />
                </FormControl>

              </SimpleGrid>
            </Box>

            <Box display={displayFieldsStyle} w='100%'>
              <FormControl
                isRequired
                errorMessage={errors?.patientBirthDate?.message}
                label={labels.patientBirthDate}
              >
                <Controller
                  name='patientBirthDate'
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <DatePicker
                      id='patientBirthDate'
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      maxDate={new Date()}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </VStack>
        </ExcludeFromRecording>

        {errorMessage}

        {!hideInsuranceInputs &&
          <Center mt={6}>
            <Button
              width='full'
              colorScheme='green'
              type='submit'
              disabled={submitDisabled}
              data-test='insurance-modal-submit-button'
              isLoading={isLoading}
            >
              {isUpdateModal ? 'Update' : 'Continue'}
            </Button>
          </Center>}
      </chakra.form>

      <Center pt={4}>
        {closeButton()}
      </Center>
    </>
  )
}
InsuranceForm.propTypes = {
  errors: PropTypes.object.isRequired,
  errorMessage: PropTypes.node,
  labels: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  handleCarrierChange: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  isBreastPump: PropTypes.bool,
  isLoading: PropTypes.bool,
  insuranceCarriers: PropTypes.object.isRequired,
  onDoThisLaterClick: PropTypes.func.isRequired,
  setSelectedInsuranceCarrier: PropTypes.func.isRequired,
  hideInsuranceInputs: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  defaultFormData: PropTypes.object,
  isACoveredCaliforniaPlan: PropTypes.bool,
  isUpdateModal: PropTypes.bool
}
