import React from 'react'
import {
  useToast,
  Alert,
  AlertDescription,
  AlertTitle,
  chakra,
  CloseButton,
  Icon,
  LinkOverlay
} from '@chakra-ui/react'
import { IoCart } from 'react-icons/io5'
import { Link as RouterLink } from 'react-router-dom'
import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { API } from '../../../services/Api'
import Analytics from '../../../services/Analytics'
import { usePatientSession } from '../../../context/use-patient-session'
import useRouteMatching from '../../common/useRouteMatching'

export const useAddToCartMutation = () => {
  const patientSession = usePatientSession()
  const toast = useToast()
  const { isShopRoute, isProductRoute, isCartRoute } = useRouteMatching()

  const trackAddToCart = () => {
    let label

    if (isShopRoute) {
      label = 'product listing page'
    }

    if (isProductRoute) {
      label = 'product details page'
    }

    if (isCartRoute) {
      label = 'cart review page'
    }

    Analytics.trackEvent(
      {
        category: 'cart',
        action: 'add item',
        label
      })
  }

  const handleSuccess = (data, { quantity }) => {
    patientSession.setCart(data.data)

    const handleCartClick = () => {
      Analytics.trackEvent({
        category: 'cart',
        action: 'click review cart',
        label: 'product listing page'
      })
    }

    toast({
      position: 'top-right',
      duration: 2000,
      isClosable: true,
      render: ({ onClose }) => (
        <Alert
          status='success'
          variant='solid'
          alignItems='start'
          textAlign='start'
          borderRadius='md'
          boxShadow='lg'
          paddingEnd={8}
          width='auto'
        >
          <LinkOverlay
            as={RouterLink}
            to='/cart?trigger-source=toast'
            onClick={handleCartClick}
            display='flex'
          >
            <chakra.span pr={2}>
              <Icon as={IoCart} w={6} h={6} />
            </chakra.span>
            <chakra.div flex='1' maxWidth='100%'>
              <AlertTitle mr={2}>{`${pluralize('items', quantity, true)} added to your cart`}</AlertTitle>
              <AlertDescription display='block'>You can checkout at any time</AlertDescription>
            </chakra.div>
          </LinkOverlay>
          <CloseButton
            position='absolute'
            insetEnd={1}
            top={1}
            onClick={onClose}
            size='sm'
            data-test='toast-close-button'
          />
        </Alert>
      )
    })
  }

  const handleError = (error) => {
    let errorMessage

    if (error.response.status === 400) {
      errorMessage = error.response.data.error
    }

    toast({
      position: 'top-right',
      description: errorMessage,
      title: 'Something went wrong',
      status: 'error',
      isClosable: true
    })
  }

  const handleSettled = () => (
    trackAddToCart()
  )

  const mutation = useMutation(
    (createCartItem) => API.cartItems.create(createCartItem),
    {
      onSuccess: handleSuccess,
      onError: handleError,
      onSettled: handleSettled
    }
  )

  return mutation
}

export default useAddToCartMutation
