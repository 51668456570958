import React from 'react'

import {
  IoInformationOutline
} from 'react-icons/io5'

import {
  Circle
} from '@chakra-ui/react'

import Tooltip from '../../common/Tooltip'

const InsuranceInfo = () => {
  const label = 'If your price with insurance equals the full retail price,' +
  'this means you have yet to meet your annual deductible. Once you’ve met it,' +
  'you will see lower prices.'

  return (
    <sup>
      <Tooltip
        placement='right'
        hasArrow
        label={label}
        bg='shop.B4'
        fontSize='xs'
        color='blue.600'
        rounded='md'
        width='180px'
        p={3}
      >
        <Circle
          size='16px'
          color='shop.B1'
          bg='shop.B4'
          cursor='pointer'
        >
          <IoInformationOutline />
        </Circle>
      </Tooltip>
    </sup>
  )
}

export default InsuranceInfo
