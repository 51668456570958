import React from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import { usePatientSession } from '../../../context/use-patient-session'
import { useQuery } from 'react-query'
import { API } from '../../../services/Api'
import blueBlobSvg from '../../../../assets/images/onboarding/BlueBlob.svg'
import greenBlobSvg from '../../../../assets/images/onboarding/GreenBlob.svg'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'

export const OnboardingWizard = ({ isOpen, onClose }) => {
  const patientSession = usePatientSession()
  const currentStep = patientSession.getCurrentOnboardingStep()
  const cookieName = 'doorbellhealth_productVertical'
  const [cookies] = useCookies([cookieName])
  const productVerticalUuid = cookies.doorbellhealth_productVertical

  const setInitialProductVertical = (productVerticals) => {
    const selectedProductVertical = productVerticals.find(productVertical =>
      productVertical.uuid === productVerticalUuid
    )

    if (selectedProductVertical) {
      patientSession.setProductVertical(selectedProductVertical)
    }
  }

  const fetchProductVerticals = async () => {
    const { data } = await API.productVerticals.index()

    return data
  }

  const { data: productVerticals } = useQuery(
    'fetchProductVerticals',
    fetchProductVerticals,
    {
      enabled: (!patientSession.getProductVertical() || isOpen),
      onSuccess: setInitialProductVertical,
      keepPreviousData: true,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const onboardingPage = () => {
    switch (currentStep) {
      case 0:
        return <StepOne />
      case 1:
        return <StepTwo productVerticals={productVerticals} />
      case 2:
        return <StepThree />
      case 3:
        return <StepFour onClose={onClose} />
      default:
        return <StepOne />
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size='5xl'
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(5px)'
      />
      <ModalContent
        backgroundColor='#FFFBEB'
        minHeight='600px'
        overflow='hidden'
        data-test='onboarding-wizard'
      >
        {onboardingPage()}
        <Image
          src={blueBlobSvg}
          alt='blue blob'
          position='absolute'
          bottom='0'
          zIndex={-1}
          minW='700px'
        />
        <Image
          src={greenBlobSvg}
          alt='green blob'
          position='absolute'
          minW='700px'
          right='0'
          zIndex={-2}
        />
      </ModalContent>
    </Modal>
  )
}

OnboardingWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}
