import React from 'react'
import PropTypes from 'prop-types'
import Constants from '../../common/constants'
import { useMutation, useQueryClient } from 'react-query'
import { API } from '../../../services/Api'

import { Button } from '@chakra-ui/react'
import UsageDataDownloadIcon from './UsageDataDownloadIcon'

const UsageDataStatus = ({ fulfillment }) => {
  const {
    usageDataDocuments,
    usageDataStatus,
    fulfillmentConfirmationNumber
  } = fulfillment
  const queryClient = useQueryClient()

  const mutation = useMutation(
    params => API.fulfillments.edit(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('consultations')
      }
    }
  )

  const submitDataRequest = () => {
    mutation.mutate({
      fulfillmentConfirmationNumber,
      status: 'requested'
    })
  }

  if (usageDataStatus === Constants.usageDataStatuses.unrequested) {
    return (
      <Button
        colorScheme='blue'
        disabled={mutation.isLoading || mutation.isSuccess}
        size='xs'
        onClick={submitDataRequest}
      >
        REQUEST
      </Button>
    )
  } else if (usageDataStatus === Constants.usageDataStatuses.requested) {
    return (
      <Button size='xs' colorScheme='blue' disabled>
        REQUESTED
      </Button>
    )
  } else if (usageDataStatus === Constants.usageDataStatuses.uploaded) {
    return (
      <UsageDataDownloadIcon usageDataDocuments={usageDataDocuments} />
    )
  }
}

UsageDataStatus.propTypes = {
  fulfillment: PropTypes.object.isRequired
}

export default UsageDataStatus
