import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Flex, Center } from '@chakra-ui/react'
import { Section } from '../Section'

const TESTIMONIALS = [
  {
    text: '“I love having an online option to get my equipment - ordering was really easy, plus their customer service is amazing and helped me every step of the way.“',
    name: '-Tammy H. (Customer)'
  },
  {
    text: '“I was initially somewhat leery of trying out a newer company but I was extremely pleased with the customer service and no hassle process.“',
    name: '-Don C. (Customer)'
  },
  {
    text: '“Your customer service is incredible.“',
    name: '-Elaine W. (Customer)'
  }
]

const Testimonial = ({ text, name }) => (
  <Center fontWeight='500'>
    <Box>
      <Center minHeight={{ base: '125px', md: '150px' }}>
        <Text
          fontSize={{ base: 'xl', md: '2xl' }}
          color='landingPage.green'
          maxW='560px'
          margin='auto'
        >
          {text}
        </Text>
      </Center>
      <Text
        my={5}
      >{name}
      </Text>
    </Box>
  </Center>
)
Testimonial.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

const Indicators = ({ active, total, onClick }) => {
  const isActive = i => i === active

  return (
    <Flex justify='center'>
      {[...Array(total).keys()].map((index) => (
        <Box
          key={index}
          p='8px'
          cursor={isActive(index) ? 'unset' : 'pointer'}
          onClick={isActive(index) ? null : () => onClick(index)}
          role='group'
          data-test='landing-page-testimonials-indicator'
        >
          <Box
            borderRadius='full'
            w='16px'
            h='16px'
            bg={isActive(index)
              ? 'landingPage.purple'
              : 'landingPage.darkGray'}
            _groupHover={isActive(index)
              ? {}
              : {
                  bg: 'gray.500'
                }}
          />
        </Box>
      ))}
    </Flex>
  )
}
Indicators.propTypes = {
  active: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onClick: PropTypes.func
}

export const Testimonials = () => {
  const [activeTestimonial, setActiveTestimonial] = useState(0)

  const handleIndicatorClick = (index) => {
    setActiveTestimonial(index)
  }

  return (
    <Section
      bg='landingPage.gray'
      textAlign='center'
      data-test='landing-page-testimonials-section'
    >
      <Text
        size='md'
        color='landingPage.grayText'
        fontWeight='500'
        mb={4}
      >
        TESTIMONIALS
      </Text>
      <Testimonial
        {...TESTIMONIALS[activeTestimonial]}
      />
      <Indicators
        active={activeTestimonial}
        total={TESTIMONIALS.length}
        onClick={handleIndicatorClick}
      />
    </Section>
  )
}
