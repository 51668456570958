import { Text } from '@chakra-ui/layout'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Grid,
  GridItem,
  HStack
} from '@chakra-ui/react'
import CartItems from './CartItems'
import { CartSummary } from './CartSummary'
import pluralize from 'pluralize'
import { useBoolean } from '@chakra-ui/hooks'
import { usePatientSession } from '../../../context/use-patient-session'
import { usePrescriptionModal } from '../../../context/use-prescription-modal'
import { ShippingInformation } from './ShippingInformation'

export const CartList = ({ cart, quantity }) => {
  const quantityCopy = `(${pluralize('item', quantity, true)})`
  const [showSummary, setShowSummary] = useBoolean(true)
  const { shouldPromptForPrescription } = usePatientSession()
  const prescriptionModal = usePrescriptionModal()

  const showFreeCheckout = () => (
    !showSummary && (
      !shouldPromptForPrescription() || prescriptionModal.isUploadOptional
    )
  )

  return (
    <Container maxW='7xl' px={{ base: 0 }}>
      <HStack>
        <Text fontSize={20} fontWeight={700}>Cart </Text>
        <Text fontSize={20} data-test='cart-quantity'>{quantityCopy}</Text>
      </HStack>
      <Grid templateColumns='repeat(4, 1fr)' mt={4} gap={6}>

        {/** Main Content Area */}
        <GridItem
          as='main'
          colSpan={{
            base: 4,
            lg: 3
          }}
        >
          <CartItems cart={cart} />
        </GridItem>

        {/** Sidebar Area */}
        <GridItem
          pl={{ base: 0, lg: 6 }}
          as='nav'
          borderLeft={{ base: '', lg: 'solid 1px' }}
          borderLeftColor={{ lg: 'blackAlpha.300' }}
          colSpan={{
            base: 4,
            lg: 1
          }}
        >
          {
            showFreeCheckout()
              ? <ShippingInformation cart={cart} />
              : <CartSummary cart={cart} setShowSummary={setShowSummary} />
          }
        </GridItem>
      </Grid>
    </Container>
  )
}

CartList.propTypes = {
  cart: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired
}
