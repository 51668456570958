import { get, put } from './base'

export const fulfillments = {
  edit: ({ fulfillmentConfirmationNumber, status }) =>
    put(
      `/api/fulfillments/${fulfillmentConfirmationNumber}`,
      { fulfillment: { usageDataStatus: status } }
    ),
  index: () =>
    get('/api/fulfillments'),
  single: (uuid) =>
    get(`/api/fulfillments/${uuid}`)
}
