import React, { useState } from 'react'
import * as yup from 'yup'
import CardContainer from '../common/CardContainer'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
import ReactSelect from '../common/ChakraReactSelect'
import { API } from '../../services/Api'
import { ExcludeFromRecording } from '../../services/RecordingService'
import { US_STATES } from '../../utils/us_states'
import { passwordRegex, passwordErrorMessage } from './PasswordField'
import { phoneNumberRegex } from '../common/utils'
import { useAuth } from '../../context/use-auth'
import { useForm, Controller } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import { useQuery } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import Constants from '../common/constants'

import {
  FormControl,
  FormDivider
} from '../common/FormElements'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Grid,
  GridItem,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
  chakra,
  useColorModeValue as mode
} from '@chakra-ui/react'

const LABELS = {
  city: 'City',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  npi: 'NPI Number',
  password: 'Password',
  passwordConfirmation: 'Confirm Password',
  pecos: 'PECOS',
  phoneNumber: 'Phone Number',
  faxNumber: 'Fax Number',
  practiceName: 'Practice Name',
  providerType: 'Provider Type',
  state: 'State',
  streetLine1: 'Address Line 1',
  streetLine2: 'Address Line 2',
  zipcode: 'Zip code'
}

const schema = yup.object().shape({
  firstName: yup.string().label(LABELS.firstName).required(),
  lastName: yup.string().label(LABELS.lastName).required(),
  phoneNumber: yup.string().label(LABELS.phoneNumber).required().matches(
    phoneNumberRegex,
    'Phone Number must be a valid phone number'
  ).label(LABELS.phoneNumber),
  faxNumber: yup.string().label(LABELS.faxNumber).matches(
    phoneNumberRegex,
    {
      message: 'Fax Number must be a valid phone number',
      excludeEmptyString: true
    }
  ).label(LABELS.faxNumber),
  npi: yup.string().label(LABELS.npi).required().test(
    'length',
    'NPI Number must be exactly 10 digits', value => value.length === 10
  ),
  pecos: yup.object().label(LABELS.pecos).nullable().required(),
  providerType: yup.object().label(LABELS.providerType).nullable().required(),
  streetLine1: yup.string().label(LABELS.streetLine1).required(),
  city: yup.string().label(LABELS.city).required(),
  state: yup.object().label(LABELS.state).nullable().required(),
  zipcode: yup.string().label(LABELS.zipcode).required(),
  email: yup.string().email().nullable().label(LABELS.email).required(),
  password: yup.string().label(LABELS.password).required().matches(
    passwordRegex,
    passwordErrorMessage
  ),
  passwordConfirmation: yup
    .string()
    .label(LABELS.passwordConfirmation)
    .required()
    .when('password', {
      is: password => (!!(password && password.length > 0)),
      then: yup.string().oneOf([yup.ref('password')], "Password doesn't match")
    }),
  termsOfServiceAgreement: yup.bool().oneOf([true], 'Required field')
})

export default function ProviderSignup ({
  prefill,
  signupCode,
  welcomeMessage
}) {
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)
  const auth = useAuth()

  const { isSuccess, data: providerTypes } = useQuery(
    'fetchProviderTypes',
    API.providerTypes.index,
    {
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  let providerTypeOptions = []

  if (isSuccess && providerTypes.data) {
    providerTypeOptions = providerTypes.data.map((option) => ({
      value: option.id,
      label: option.name
    }))
  }

  const pecosOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  const stateOptions = () => (
    Object.keys(US_STATES).map((state) => (
      { value: state, label: state }
    ))
  )

  const defaultFormValues = () => {
    const selectedStateOption = stateOptions().filter(
      option => option.value === prefill?.state
    )[0]

    const selectedPecosOption = pecosOptions.filter(
      option => option.value === prefill?.pecos
    )[0]

    const selectedProviderTypeOption = providerTypeOptions.find(
      option => option.value === prefill?.providerTypeId
    )

    return {
      ...prefill,
      pecos: selectedPecosOption,
      providerType: selectedProviderTypeOption,
      state: selectedStateOption
    }
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: defaultFormValues(),
    resolver: yupResolver(schema)
  })

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onValidatedSubmit = ({
    city,
    email,
    password,
    passwordConfirmation,
    pecos,
    providerType,
    state,
    streetLine1,
    streetLine2,
    zipcode,
    termsOfServiceAgreement,
    ...params
  }) => {
    setServerState()
    setSubmitting(true)

    params.pecos = pecos.value
    params.providerTypeId = providerType.value

    params.addressAttributes = {
      city,
      zipcode,
      state: state.value,
      street_line_1: streetLine1,
      street_line_2: streetLine2
    }

    const userAttributes = {
      email,
      password,
      passwordConfirmation
    }

    API.providers.create({
      provider: params,
      user: userAttributes,
      signupCode
    })
      .then(response => {
        handleServerResponse(true, 'Logged In!')

        const user = {
          ...response.data,
          accessToken: response.headers.accessToken,
          client: response.headers.client
        }

        auth.signin(user)
      })
      .catch(error => {
        const status = error.response.status

        if (status === 400) {
          handleServerResponse(false, error.response.data.error)
        } else {
          handleServerResponse(false, 'Something went wrong')
        }
      })
      .finally(
        () => setSubmitting(false)
      )
  }

  const footer = (
    <Box textAlign='center' mt={4}>
      <Box
        as={RouterLink}
        color={mode('blue.600', 'blue.200')}
        fontWeight='semibold'
        fontSize='sm'
        to='/providers/signin'
      >
        Have an account? Sign in as a Provider
      </Box>
    </Box>
  )

  const termsLink = (
    <Link
      href={Constants.doorbell.termsOfServiceLink}
      color={mode('blue.600', 'blue.200')}
      isExternal
    >
      {Constants.doorbell.termsOfService}
    </Link>
  )

  return (
    <CardContainer
      heading='Provider Sign Up'
      footer={footer}
    >

      {welcomeMessage}
      <ExcludeFromRecording>
        <chakra.form onSubmit={handleSubmit(onValidatedSubmit)}>
          <Stack spacing='6'>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={4}
            >
              <FormControl
                isRequired
                schema={schema}
                errorMessage={errors?.firstName?.message}
                label={LABELS.firstName}
              >
                <Input
                  id='firstName'
                  fontSize='sm'
                  {...register('firstName')}
                />
              </FormControl>

              <FormControl
                isRequired
                errorMessage={errors?.lastName?.message}
                label={LABELS.lastName}
              >
                <Input
                  id='lastName'
                  fontSize='sm'
                  {...register('lastName')}
                />
              </FormControl>

            </SimpleGrid>

            <FormControl
              isRequired
              errorMessage={errors?.providerType?.message}
              label={LABELS.providerType}
            >
              <Controller
                control={control}
                name='providerType'
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    isClearable
                    id='providerType'
                    isInvalid={!!errors?.providerType?.message}
                    options={providerTypeOptions}
                    closeMenuOnSelect
                    size='md'
                    fontSize='sm'
                  />
                )}
              />
            </FormControl>

            <FormControl
              isRequired
              errorMessage={errors?.phoneNumber?.message}
              label={LABELS.phoneNumber}
            >
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputMask
                    mask='(999) 999-9999'
                    onChange={onChange}
                    value={value}
                  >
                    {(inputProps) => (
                      <Input
                        id='phoneNumber'
                        fontSize='sm'
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormControl>

            <FormControl
              errorMessage={errors?.faxNumber?.message}
              label={LABELS.faxNumber}
            >
              <Controller
                name='faxNumber'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputMask
                    mask='(999) 999-9999'
                    onChange={onChange}
                    value={value}
                  >
                    {(inputProps) => (
                      <Input
                        id='faxNumber'
                        fontSize='sm'
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormControl>

            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={4}
            >
              <FormControl
                isRequired
                errorMessage={errors?.npi?.message}
                label={LABELS.npi}
              >
                <Input
                  id='npi'
                  fontSize='sm'
                  {...register('npi')}
                />
              </FormControl>

              <FormControl
                isRequired
                errorMessage={errors?.pecos?.message}
                label={LABELS.pecos}
              >
                <Controller
                  control={control}
                  name='pecos'
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id='pecos'
                      isInvalid={!!errors?.pecos?.message}
                      options={pecosOptions}
                      closeMenuOnSelect
                      size='md'
                      fontSize='sm'
                    />
                  )}
                />
              </FormControl>
            </SimpleGrid>

            <FormDivider />

            <FormControl
              errorMessage={errors?.practiceName?.message}
              label={LABELS.practiceName}
            >
              <Input
                id='practiceName'
                fontSize='sm'
                {...register('practiceName')}
              />
            </FormControl>

            <Grid width='100%' templateColumns='repeat(3, 1fr)' gap={4}>
              <GridItem colSpan={{ base: 3, md: 2 }}>
                <FormControl
                  isRequired
                  errorMessage={errors?.streetLine1?.message}
                  label={LABELS.streetLine1}
                >
                  <Input
                    id='streetLine1'
                    fontSize='sm'
                    {...register('streetLine1')}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={{ base: 3, md: 1 }}>
                <FormControl
                  errorMessage={errors?.streetLine2?.message}
                  label={LABELS.streetLine2}
                >
                  <Input
                    id='streetLine2'
                    fontSize='sm'
                    {...register('streetLine2')}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              spacing={4}
            >

              <FormControl
                isRequired
                errorMessage={errors?.city?.message}
                label={LABELS.city}
              >
                <Input
                  id='city'
                  fontSize='sm'
                  {...register('city')}
                />
              </FormControl>

              <FormControl
                isRequired
                errorMessage={errors?.state?.message}
                label={LABELS.state}
              >
                <Controller
                  control={control}
                  name='state'
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id='state'
                      isInvalid={!!errors?.state?.message}
                      options={stateOptions()}
                      closeMenuOnSelect
                      size='md'
                      fontSize='sm'
                    />
                  )}
                />
              </FormControl>

              <FormControl
                isRequired
                errorMessage={errors?.zipcode?.message}
                label={LABELS.zipcode}
              >
                <Input
                  id='zipcode'
                  fontSize='sm'
                  {...register('zipcode')}
                />
              </FormControl>
            </SimpleGrid>

            <FormDivider />

            <FormControl
              isRequired
              errorMessage={errors?.email?.message}
              label={LABELS.email}
            >
              <Input
                id='email'
                autoComplete='email'
                fontSize='sm'
                {...register('email')}
              />
            </FormControl>

            <FormControl
              isRequired
              errorMessage={errors?.password?.message}
              label={LABELS.password}
            >
              <Input
                id='password'
                type='password'
                autoComplete='password'
                {...register('password')}
              />
            </FormControl>

            <FormControl
              isRequired
              errorMessage={errors?.passwordConfirmation?.message}
              label={LABELS.passwordConfirmation}
            >
              <Input
                id='passwordConfirmation'
                type='password'
                autoComplete='passwordConfirmation'
                {...register('passwordConfirmation')}
              />
            </FormControl>

            <Box>
              <Checkbox
                isInvalid={!!errors?.termsOfServiceAgreement?.message}
                alignItems='start'
                spacing={4}
                id='termsCheckbox'
                {...register('termsOfServiceAgreement')}
              >
                <Text
                  color='gray.600'
                  fontSize='sm'
                  mt='-4px'
                >
                  By checking this box, I agree to Doorbell Health's {termsLink}
                    &nbsp;and the Business Associate Agreement within it.
                </Text>
              </Checkbox>

              <Text fontSize='sm' ml={8} mt={2} color='red.500'>
                {errors?.termsOfServiceAgreement?.message}
              </Text>
            </Box>

            {serverState && !serverState.ok && (
              <Box mb={8}>
                <Alert status='error' borderRadius={4} fontSize='sm'>
                  <AlertIcon />
                  <AlertDescription>{serverState.msg}</AlertDescription>
                </Alert>
              </Box>
            )}

            <Button
              isLoading={submitting}
              type='submit'
              colorScheme='blue'
              size='lg'
              fontSize='md'
              disabled={submitting}
            >
              Sign Up
            </Button>
          </Stack>
        </chakra.form>
      </ExcludeFromRecording>
    </CardContainer>
  )
}

ProviderSignup.propTypes = {
  signupCode: PropTypes.string,
  welcomeMessage: PropTypes.element,
  prefill: PropTypes.shape({
    city: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    formalFullName: PropTypes.string,
    lastName: PropTypes.string,
    npi: PropTypes.string,
    pecos: PropTypes.bool,
    phoneNumber: PropTypes.string,
    faxNumber: PropTypes.string,
    practiceName: PropTypes.string,
    providerTypeId: PropTypes.number,
    state: PropTypes.string,
    streetLine1: PropTypes.string,
    streetLine2: PropTypes.string,
    zipcode: PropTypes.string
  })
}
