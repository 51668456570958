import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { Box, Flex, Heading as ChakraHeading, Text } from '@chakra-ui/react'
import { useProgress } from '../ProgressBar/useProgress'
import Filters from '../Filters'

const Heading = ({ itemsQuantity }) => {
  const { currentStep } = useProgress()

  const headingText = pluralize.plural(currentStep?.productCategory || '')

  const ProductsQuantityLabel = () => (
    <Text
      fontSize='xs'
      align={{ base: 'left', lg: 'right' }}
      color='blackAlpha.600'
      mb='15px'
      data-test='heading-products-quantity'
      whiteSpace='nowrap'
    >
      {`${pluralize('products', itemsQuantity, true)}`}
    </Text>
  )

  return (
    <Box>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        justify='space-between'
        alignItems='baseline'
        pb={{ base: '0', lg: '40px' }}
        pt={{ base: '20px', lg: '0' }}
      >
        <ChakraHeading
          as='h4'
          fontSize={{ base: 'xl', lg: '3xl' }}
          textAlign={{ base: 'center', lg: 'left' }}
          w='100%'
          data-test='heading-text'
        >
          {headingText}
        </ChakraHeading>
        <Filters.Mobile data-test='mobile-filters' />
        {itemsQuantity > 0 ? <ProductsQuantityLabel /> : null}
      </Flex>
    </Box>
  )
}
Heading.propTypes = {
  itemsQuantity: PropTypes.number.isRequired
}

export default Heading
