import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth, isAuthenticated } from '../../context/use-auth'

const UnprotectedRoute = () => {
  const loggedIn = isAuthenticated()
  const { isPatient } = useAuth()

  if (loggedIn) {
    return <Navigate to={isPatient ? '/shop' : '/'} replace />
  }

  return <Outlet />
}

export default UnprotectedRoute
