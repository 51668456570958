/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Center,
  Flex,
  Spacer,
  Text
} from '@chakra-ui/react'
import Mixpanel from '../../../services/Mixpanel'
import ProductCartButton from '../Product/CartButton'
import QuantityPicker from '../../common/QuantityPicker'
import { usePatientSession } from '../../../context/use-patient-session'
import { useProductVariant } from '../../../context/use-product-variant'

const BuyBox = ({ productVariant }) => {
  const { uuid, product, maxQuantity } = productVariant
  const { quantity, setQuantity } = useProductVariant()
  const patientSession = usePatientSession()
  const previousQuantityRef = useRef()
  const productVariantCartItem = patientSession.getCartItem(uuid)
  const minQuantity = 1

  const isQuantityPickerDisabled = !!productVariantCartItem

  useEffect(() => {
    // Only trigger on change, not on the initial render
    if (previousQuantityRef.current) {
      let event

      if (quantity > previousQuantityRef.current) {
        event = 'Increment item count'
      } else {
        event = 'Decrement item count'
      }

      Mixpanel.trackEventWithProduct(event, product, { count: quantity })
    }

    previousQuantityRef.current = quantity
  }, [quantity])

  return (
    <Box mb={{ base: '5', lg: '10' }}>
      <Text
        color='blackAlpha.600'
        fontSize='xs'
        mb={2}
      >
        Quantity
      </Text>

      <Flex maxW='440px' mb={{ base: '5', lg: '9' }}>
        <Center
          height={{ base: '40px', lg: '60px' }}
          width='150px'
        >
          <QuantityPicker
            rootProps={{
              w: '150px'
            }}
            value={quantity}
            onChange={setQuantity}
            min={minQuantity}
            max={maxQuantity}
            isDisabled={isQuantityPickerDisabled}
          />
        </Center>
        <Spacer minWidth='3' maxWidth='8' />
      </Flex>

      <ProductCartButton
        variant='large'
        productVariant={productVariant}
        quantity={quantity}
      />

      <Text color='blackAlpha.600' fontSize='xs' pt={6}>
        Ships to Los Angeles, CA. Shipping calculated at checkout.
      </Text>
    </Box>
  )
}

BuyBox.propTypes = {
  productVariant: PropTypes.object.isRequired
}

export default BuyBox
