/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useAuth, isAuthenticated } from '../context/use-auth'
import { useCookies } from 'react-cookie'
import { usePatientSession } from '../context/use-patient-session'
import * as FullStory from '@fullstory/browser'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

const RECORDING_ENABLED = process.env.FS_ORG != null

export const init = () => {
  if (!RECORDING_ENABLED) return

  FullStory.init({
    orgId: process.env.FS_ORG
  })
}

export const useSetSessionId = () => {
  const sessionIdCookie = 'doorbellhealth_sessionId'
  const [cookies] = useCookies([sessionIdCookie])
  const sessionId = cookies[sessionIdCookie]
  const { setSessionId } = usePatientSession()
  const isLoggedIn = isAuthenticated()
  const { isProvider } = useAuth()

  useEffect(() => {
    // Skip for providers, not applicable
    if (!(isLoggedIn && isProvider)) {
      setSessionId(sessionId)
    }

    if (!RECORDING_ENABLED) return

    FullStory.setUserVars({
      sessionId
    })
  }, [sessionId])
}

export const identify = (uuid, role) => {
  if (!RECORDING_ENABLED) return

  const userVars = { role }

  // NOTE: indentify() is limited to 12 calls per minute, with a burst limit of 5 calls per second
  FullStory.identify(
    uuid,
    userVars
  )
}

// Use this components to Exclude, Mask or Unmask a component from FullStory Recordings
// We have the "Private by Default" mode activated: All the app is Masked by default.
// See: https://help.fullstory.com/hc/en-us/articles/360044349073

export const ExcludeFromRecording = ({ children, ...props }) => (
  <Box className='fs-exclude' {...props}>{children}</Box>
)
ExcludeFromRecording.propTypes = {
  children: PropTypes.node
}

export const MaskFromRecording = ({ children, ...props }) => (
  <Box className='fs-mask' {...props}>{children}</Box>
)
MaskFromRecording.propTypes = {
  children: PropTypes.node
}

export const UnmaskFromRecording = ({ children, ...props }) => (
  <Box className='fs-unmask' {...props}>{children}</Box>
)
UnmaskFromRecording.propTypes = {
  children: PropTypes.node
}
