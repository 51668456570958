import { useNavigate, useSearchParams } from 'react-router-dom'
import QueryString from 'query-string'
import { useQueryParams } from '../../../utils/useQueryParams'
import useRouteMatching from '../../common/useRouteMatching'

export const useFilters = () => {
  const queryParams = useQueryParams()
  const { isShopRoute } = useRouteMatching()
  const { query, brands, priceFrom, priceTo, ...otherQueryParams } = queryParams
  const currentFilters = {
    query,
    brands,
    priceFrom,
    priceTo
  }
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const goToShop = (stringifiedParams) => {
    // Prevents pushing the same route twice
    const search = params.toString()

    if (isShopRoute && search === stringifiedParams) {
      return
    }

    navigate({
      pathname: '/shop',
      search: stringifiedParams ? `?${stringifiedParams}` : ''
    })
  }

  const filterPage = (newFilters) => {
    const stringifiedParams = QueryString.stringify(
      { ...newFilters, ...otherQueryParams },
      {
        skipEmptyString: true,
        arrayFormat: 'bracket'
      }
    )
    goToShop(stringifiedParams)
  }

  const reset = () => {
    const stringifiedParams = QueryString.stringify(
      otherQueryParams
    )
    goToShop(stringifiedParams)
  }

  const setSearch = (query) => {
    filterPage({ ...currentFilters, query })
  }

  const setBrands = (brands) => {
    filterPage({ ...currentFilters, brands })
  }

  const setPrices = (priceFrom, priceTo) => {
    filterPage({ ...currentFilters, priceFrom, priceTo })
  }

  return {
    setSearch,
    setBrands,
    setPrices,
    reset,
    currentFilters
  }
}
