import React from 'react'
import { useBoolean, Box, HStack } from '@chakra-ui/react'
import { useMobileMenuState } from '../../common/useMobileMenuState'
import { useLockBodyScroll } from '../../common/useLockBodyScroll'
import { MobileSearchFilterButton as SearchFilterButton } from './SearchFilter/MobileSearchFilterButton'
import { MobileSearchFilter as SearchFilter } from './SearchFilter/MobileSearchFilter'
import { MobileBrandFilterButton as BrandFilter } from './BrandFilter/MobileBrandFilterButton'
import { MobilePriceFilterButton as PriceFilter } from './PriceFilter/MobilePriceFilterButton'
import MobileSidebar from './MobileFiltersSidebar'

export const MobileFilters = (props) => {
  const [searchOpened, handleSearchOpened] = useBoolean()
  const {
    isMenuOpen: isSidebarOpen,
    toggle: toggleSidebar
  } = useMobileMenuState()

  useLockBodyScroll(isSidebarOpen)

  return (
    <Box
      pt='14px'
      pb='8px'
      w='100%'
      display={{
        lg: 'none'
      }}
      {...props}
    >
      <MobileSidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <HStack>
        {searchOpened
          ? <SearchFilter onCloseClick={handleSearchOpened.off} />
          : (
            <>
              <SearchFilterButton onClick={handleSearchOpened.on} />
              <BrandFilter onClick={toggleSidebar} />
              <PriceFilter onClick={toggleSidebar} />
            </>
            )}
      </HStack>
    </Box>
  )
}
