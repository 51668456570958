import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  useColorModeValue as mode,
  HStack,
  Text
} from '@chakra-ui/react'
import { useInsuranceModal } from '../../../context/use-insurance-modal'

const RetailPrice = ({ insuranceSavingsRef, prices, showUnlockInsuranceLink }) => {
  const modal = useInsuranceModal()

  return (
    <div>
      <HStack align='baseline' spacing={1} mb={1}>
        <Text fontWeight='medium' data-test='retail-price'>
          {prices.retailCost}
        </Text>
        <Text fontSize='sm'>Retail</Text>
      </HStack>
      {showUnlockInsuranceLink &&
        <Button
          color={mode('blue.600', 'blue.200')}
          fontSize='sm'
          fontWeight='medium'
          variant='link'
          onClick={event => {
          // This prevents the click event to propagate to the Product Card
            event.preventDefault()

            modal.handleOpenModal()
          }}
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
          ref={insuranceSavingsRef}
          textAlign='left'
          data-test='insurance-savings-link'
        >
          Insurance savings available
        </Button>}
    </div>
  )
}

RetailPrice.propTypes = {
  prices: PropTypes.object.isRequired,
  showUnlockInsuranceLink: PropTypes.bool.isRequired,
  insuranceSavingsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
}

export default RetailPrice
