/* eslint-disable camelcase */
import { useEffect } from 'react'
import Mixpanel from '../../../services/Mixpanel'
import { useQueryParams } from '../../../utils/useQueryParams'
import { useIdentifyInboundLeads } from '../../common/useIdentifyInboundLeads'

export const useTrackShopSite = () => {
  const {
    utm_source,
    utm_medium,
    utm_campaign
  } = useQueryParams()

  useIdentifyInboundLeads()

  useEffect(() => {
    Mixpanel.trackEvent('Arrive at /shop Site', {
      utm_source,
      utm_medium,
      utm_campaign
    })
    /* eslint-disable-next-line */
  }, [])
}
