import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Center,
  Circle as ChakraCircle,
  Link,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import Constants from '../../common/constants'
import { Card } from '../../common/Card'
import FaxCoverSheetCta from './FaxCoverSheetCta'

const Circle = ({ children }) => (
  <ChakraCircle
    size={{ base: '26px', lg: '38px' }}
    bg='green.600'
    color='white'
    marginX={{ lg: 'auto' }}
    mb={{ lg: 4 }}
    mr={{ base: 4 }}
  >
    <Text
      fontWeight={700}
      fontSize={{ base: 'xs', lg: 'lg' }}
    >
      {children}
    </Text>
  </ChakraCircle>
)

Circle.propTypes = {
  children: PropTypes.node.isRequired
}

const ViewWorkOrdersButton = ({ ...props }) => (
  <Center>
    <Button
      as={RouterLink}
      to='/'
      size='sm'
      colorScheme='green'
      bg='green.600'
      data-test='action-items-work-orders-button'
      {...props}
    >
      View Work Orders
    </Button>
  </Center>
)

const FirstAction = ({ productVerticalName }) => {
  const documentsCopy = productVerticalName === Constants.productVertical.breastPump
    ? 'paper prescription'
    : 'required documentation'

  return (
    <Box
      data-test='action-items-first-action'
    >
      <Box display={{ base: 'flex', lg: 'block' }}>
        <Circle>1</Circle>
        <Box>
          <Text mb={{ base: 6, lg: 8 }}>
            {`Upload the ${documentsCopy} directly via your work orders dashboard`}
          </Text>
          <ViewWorkOrdersButton display={{ base: 'none', lg: 'flex' }} />
        </Box>
      </Box>
      <ViewWorkOrdersButton display={{ base: 'flex', lg: 'none' }} />
    </Box>
  )
}

FirstAction.propTypes = {
  productVerticalName: PropTypes.string
}

const SecondAction = ({
  formattedReferralCode,
  unformattedReferralCode,
  patientPacketTypes
}) => (
  <Box
    display={{ base: 'flex', lg: 'block' }}
    data-test='action-items-second-action'
  >
    <Circle>2</Circle>
    <Box>
      <Text mb={3}>
        {'Send the following fax to '}
        <Link ml={1} href={Constants.doorbell.faxEmailMailTo} color='green.600'>
          {Constants.doorbell.faxEmail}
        </Link>
        {' or to '}
        <Text as='em' color='green.600' fontStyle='normal'>
          {Constants.doorbell.faxNumber.formatted}
        </Text>
        :
      </Text>
      <UnorderedList mb={3}>
        <ListItem>
          <FaxCoverSheetCta
            formattedReferralCode={formattedReferralCode}
            unformattedReferralCode={unformattedReferralCode}
          />
        </ListItem>
        {patientPacketTypes?.map(({ name }) => (
          <ListItem key={name}>
            {name}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  </Box>
)

SecondAction.propTypes = {
  formattedReferralCode: PropTypes.string,
  unformattedReferralCode: PropTypes.string,
  patientPacketTypes: PropTypes.array
}

const ActionItems = ({
  cardStyles,
  formattedReferralCode,
  productVerticalName,
  unformattedReferralCode,
  patientPacketTypes
}) => {
  return (
    <Card
      mt={6}
      title='Your next action items'
      border='1px solid'
      borderColor='green.600'
      {...cardStyles}
    >
      <Text mb={4}>
        Additionally, your office needs to share all necessary documentation and can do so in one of two ways:
      </Text>

      <Box
        display={{ base: 'block', lg: 'flex' }}
        justifyContent={{ lg: 'center' }}
      >
        <FirstAction
          productVerticalName={productVerticalName}
        />
        <Text
          mt={4}
          mb={4}
          minW='100px'
          fontSize={{ base: 'sm', lg: 'lg' }}
          fontWeight={700}
          textAlign='center'
        >
          or
        </Text>
        <SecondAction
          formattedReferralCode={formattedReferralCode}
          unformattedReferralCode={unformattedReferralCode}
          patientPacketTypes={patientPacketTypes}
        />
      </Box>

      <Text
        mt={8}
        fontSize='xs'
        fontStyle='italic'
      >
        If you have submitted documentation, please ignore this message
      </Text>
    </Card>
  )
}

ActionItems.propTypes = {
  cardStyles: PropTypes.object,
  formattedReferralCode: PropTypes.string,
  productVerticalName: PropTypes.string,
  unformattedReferralCode: PropTypes.string,
  patientPacketTypes: PropTypes.array
}

export default ActionItems
