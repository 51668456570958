import { API } from '../../services/Api'
import Constants from '../common/constants'

export const attachmentsModalConfig = ({
  modalType,
  modalData
}) => {
  const {
    patientPackets,
    insuranceCard,
    usageData
  } = Constants.attachmentModalTypes

  switch (modalType) {
    case patientPackets: {
      const { consultation, packetType } = modalData

      const patientPacketsList = consultation.patientPackets.filter(
        packet => (
          packet.packetType === packetType.type ||
          packet.packetType === Constants.packetTypes.faxBundle.type // The fax bundle is included in all types
        )
      )

      const patientPacketsConfig = {
        allowDeletion: true,
        allowMultipleUploads: true,
        attachments: patientPacketsList.map(patientPacket => patientPacket.packet),
        closeButtonCopy: 'Close',
        closeButtonStyles: {},
        createAttachmentRequest: (blobSignedIds) => (
          API.attachments.createPatientPacket({
            blobSignedIds,
            consultationUuid: consultation.uuid,
            packetType: packetType.type
          })
        ),
        headerStyles: {
          color: 'gray.600',
          paddingBottom: 4,
          paddingRight: 6
        },
        showCloseButton: false,
        showUploadedAttachments: true
      }

      return patientPacketsConfig
    }

    case insuranceCard: {
      return {
        allowDeletion: false,
        allowMultipleUploads: false,
        attachments: [],
        closeButtonCopy: 'Manually Enter Details',
        closeButtonStyles: { color: 'blue.500' },
        createAttachmentRequest: (blobSignedIds) => (
          API.attachments.createInsuranceCard({
            blobSignedId: blobSignedIds[0]
          })
        ),
        headerStyles: {
          color: 'gray.800',
          paddingBottom: 8,
          paddingRight: 12
        },
        showCloseButton: true,
        showUploadedAttachments: false
      }
    }

    case usageData: {
      const {
        usageDataDocuments,
        fulfillmentConfirmationNumber
      } = modalData.fulfillment

      return {
        attachments: usageDataDocuments,
        allowDeletion: false,
        allowMultipleUploads: true,
        closeButtonCopy: 'Close',
        closeButtonStyles: { },
        createAttachmentRequest: (blobSignedIds) => (
          API.attachments.createUsageData({
            blobSignedIds,
            fulfillmentConfirmationNumber
          })
        ),
        headerStyles: {
          color: 'gray.800'
        },
        showCloseButton: true,
        showUploadedAttachments: true
      }
    }

    default:
      throw new Error(`Unrecognized argument modalType: ${modalType}`)
  }
}
