/** groupByKey(list, key): Groups a list of objects by a key.
 * Example:
 * > groupByKey([{name:"Agus", age:1}, {name:"Agus", age: 2}, {name:"Jhon", age: 3}], "name")
 *
 * returns
 * {
 *   Agus: [{name: "Agus", age: 1},{name: "Agus", age: 2}],
 *   Jhon: [{name:"Jhon", age: 3}]
 * }
 */
export const groupByKey = (list, key) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj)
    }),
    {}
  )

// Validates URL formatting and protocol: http or https
// Uses javascript URL interface: https://developer.mozilla.org/en-US/docs/Web/API/URL
export const isValidUrl = (value) => {
  let url

  try {
    url = new URL(value)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

// Format: (444) 444-4444
export const phoneNumberRegex =
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

// Format: AAAA-AAA-AAAA or 1111-111-1111 (alphanumeric characters)
export const mbiRegex = /^\w{4}-\w{3}-\w{4}$/

export const hasANumber = (string) => /\d/.test(string)

// Returns 'a' or 'an' given a singular noun. Examples:
// with 'Horse' it returns 'a'
// with 'Oxygen Mask' it returns 'an'
export const getIndefiniteArticle = (singularNoun) => {
  // Convert the word to lowercase for easier comparison
  const lowerWord = singularNoun.toLowerCase()

  // Words that start with a vowel
  const vowels = ['a', 'e', 'i', 'o', 'u']

  // Check if the word starts with a vowel
  if (vowels.includes(lowerWord.charAt(0))) {
    return 'an'
  } else {
    return 'a'
  }
}

// Sorts an array of objects by a list of keys in a specified direction
export const sortArrayOfObjects = ({ array, direction, sortKeys }) => {
  return array.slice().sort((a, b) => {
    let displayValueA = ''
    let displayValueB = ''

    for (let i = 0; i < sortKeys.length; i++) {
      displayValueA = displayValueA || a[sortKeys[i]]
      displayValueB = displayValueB || b[sortKeys[i]]
    }

    if (direction === 'up') {
      return displayValueA.localeCompare(displayValueB)
    } else if (direction === 'down') {
      return displayValueB.localeCompare(displayValueA)
    } else {
      throw new Error('Invalid direction')
    }
  })
}
