import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import {
  useToast,
  Alert,
  AlertTitle,
  chakra,
  CloseButton,
  Icon,
  LinkOverlay
} from '@chakra-ui/react'
import { IoInformationCircle } from 'react-icons/io5'
import { usePatientSession } from '../../../context/use-patient-session'
import useRouteMatching from '../../common/useRouteMatching'
import { useProgress } from '../ProgressBar/useProgress'

const CustomToast = ({ handleToastClick }) => {
  // Hardcoded list of Matriarch Brace Products links
  const matriarchProductLinks = {
    // This uuid has to match with a product in production
    production: '/products/6ff4e720-8914-4c01-a572-52660436dab6',
    // This uuid comes from a seed product. Added in seeds/breast_pump_products.js
    stagingAndDevelopment: '/products/75e5939b-e38a-48b2-b5f3-28e38eb6c2df'
  }

  let productLink
  if (window.location.hostname === 'www.doorbellhealth.com') {
    productLink = matriarchProductLinks.production
  } else {
    productLink = matriarchProductLinks.stagingAndDevelopment
  }

  return (
    <Alert
      variant='solid'
      alignItems='start'
      textAlign='start'
      borderRadius='md'
      boxShadow='lg'
      paddingEnd={8}
      width='auto'
      data-test='matriarch-toast'
    >
      <LinkOverlay
        as={RouterLink}
        to={productLink}
        onClick={handleToastClick}
        display='flex'
      >
        <chakra.span pr={2}>
          <Icon as={IoInformationCircle} w={6} h={6} />
        </chakra.span>
        <chakra.div flex='1' maxWidth='100%'>
          <AlertTitle mr={2}>
            Note: The Matriarch Back Brace could be free with your insurance as well. Click here to learn more.
          </AlertTitle>
        </chakra.div>
      </LinkOverlay>
      <CloseButton
        position='absolute'
        insetEnd={1}
        top={1}
        onClick={handleToastClick}
        size='sm'
        data-test='matriarch-toast-close'
      />
    </Alert>
  )
}

CustomToast.propTypes = {
  handleToastClick: PropTypes.func.isRequired
}

const MatriarchToast = () => {
  const toast = useToast()
  const toastId = 'matriarch-toast'
  const {
    getProductVertical,
    hasActiveInsurance,
    setHasClosedMatriarchToast,
    patientSession
  } = usePatientSession()
  const { isShopRoute } = useRouteMatching()
  const isInsuranceActive = hasActiveInsurance()
  const hasClosedMatriarchToast = patientSession?.hasClosedMatriarchToast

  const currentProductVertical = getProductVertical()
  const productVerticalName = currentProductVertical?.name
  const isBreastPump = productVerticalName === 'Breast Pump'

  const { currentStep } = useProgress()
  const stepCategory = currentStep?.productCategory
  const isAccessories = stepCategory === 'Accessories'

  const handleToastClick = () => {
    setHasClosedMatriarchToast()
    closeToast()
  }

  const addToast = ({ cypressDisabled }) => (
    toast({
      id: toastId,
      containerStyle: cypressDisabled ? { display: 'none' } : {},
      position: 'top-right',
      status: 'info',
      isClosable: true,
      // Null duration: Never closes automatically
      duration: null,
      render: (props) => CustomToast(
        {
          handleToastClick,
          ...props
        }
      )
    })
  )

  const closeToast = () => (
    toast.close(toastId)
  )

  useEffect(() => {
    if (hasClosedMatriarchToast) return

    if (
      isShopRoute &&
      isBreastPump &&
      isAccessories &&
      isInsuranceActive &&
      !toast.isActive(toastId)
    ) {
      addToast({ cypressDisabled: window._cypressDisableMatriarchToast })
    } else {
      closeToast()
    }

    return () => closeToast()
    /* eslint-disable-next-line */
  }, [
    isShopRoute,
    isBreastPump,
    isAccessories,
    isInsuranceActive,
    hasClosedMatriarchToast
  ])

  return null
}

export default MatriarchToast
