import React from 'react'
import PropTypes from 'prop-types'
import { Center, Button, Heading } from '@chakra-ui/react'
import { useFilters } from '../useFilters'

export const TopHeader = ({ boxStyles }) => {
  const filters = useFilters()

  const handleClearFiltersClick = () => {
    filters.reset()
  }

  return (
    <Center
      h='50px'
      justifyContent='space-between'
      {...boxStyles}
    >
      <Heading as='h5' size='sm' p={4}>
        Filter
      </Heading>
      <Button
        onClick={handleClearFiltersClick}
        variant='ghost'
        size='sm'
        color='green.600'
        fontWeight='500'
      >
        Clear all
      </Button>
    </Center>
  )
}

TopHeader.propTypes = {
  boxStyles: PropTypes.object
}
