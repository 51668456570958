import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { NavItem } from './NavItem'
import { Footer } from '../Footer/Footer'
import { useLockBodyScroll } from '../../common/useLockBodyScroll'
import { isAuthenticated } from '../../../context/use-auth'
import { PatientAuth } from './PatientAuth'

const MobileNavMenu = (props) => {
  const { isOpen, topBannersHeight, toggleMenu } = props
  const isLoggedIn = isAuthenticated()

  useLockBodyScroll(isOpen)

  return (
    <Flex
      hidden={!isOpen}
      as='nav'
      direction='column'
      bg='white'
      position='fixed'
      height={`calc(100vh - ${topBannersHeight}px)`}
      top={`${topBannersHeight}px`}
      insetX='0'
      zIndex={10}
      w='full'
      data-test='mobile-nav-menu'
    >
      <Box px='4'>
        <NavItem.Mobile label='Shop' path='/shop' onClick={toggleMenu} />
        <NavItem.Mobile label='About' path='/' />
        {window.location.hostname !== 'www.doorbellhealth.com'
          ? isLoggedIn
            ? <PatientAuth.Mobile toggleMenu={toggleMenu} />
            : <NavItem.Mobile label='Sign In' path='/patients/signin' onClick={toggleMenu} />
          : null}
      </Box>
      <Footer.Mobile />
    </Flex>
  )
}
MobileNavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  topBannersHeight: PropTypes.number,
  toggleMenu: PropTypes.func
}

const DesktopNavMenu = () => {
  return (
    <HStack
      spacing='1'
      flex='1'
      display={{
        base: 'none',
        lg: 'flex'
      }}
      data-test='desktop-nav-menu'
    >
      <NavItem.Desktop label='Shop' path='/shop' />
      <NavItem.Desktop label='About' path='/' />
    </HStack>
  )
}

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu
}
