/* eslint-disable camelcase */
import { useEffect } from 'react'
import Mixpanel from '../../services/Mixpanel'
import { useQueryParams } from '../../utils/useQueryParams'
import { useIdentifyInboundLeads } from '../common/useIdentifyInboundLeads'

export const useTrackLandingPage = (siteType) => {
  const {
    utm_source,
    utm_medium,
    utm_campaign
  } = useQueryParams()

  useIdentifyInboundLeads()

  useEffect(() => {
    Mixpanel.trackEvent('Arrive at Marketing Site', {
      siteType,
      utm_source,
      utm_medium,
      utm_campaign
    })
    /* eslint-disable-next-line */
  }, [])
}
