import React, { useEffect } from 'react'
import { Card } from '../../common/Card'
import { Link as RouterLink, useParams } from 'react-router-dom'
import ShipmentCartItem from './ShipmentCartItem'
import useFulfillment from '../../common/useFulfillment'
import {
  Box,
  Button,
  Divider,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react'

const Success = () => {
  const { fulfillmentConfirmationNumber } = useParams()

  const {
    confirmationNumber,
    fetchFulfillment,
    shippedItems,
    unshippedItems
  } = useFulfillment()

  useEffect(() => {
    if (fulfillmentConfirmationNumber) {
      fetchFulfillment(fulfillmentConfirmationNumber)
    }
    /* eslint-disable-next-line */
  }, [fulfillmentConfirmationNumber])

  return (
    <>
      <Heading size='md' fontWeight='medium' color='gray.800' mb='2'>
        Order Tracking Form
      </Heading>
      <Text color='gray.600'>Fill out your order tracking details</Text>

      <Card mt={6} maxWidth='600px' fontSize='sm'>

        <VStack
          align='left'
          divider={<Divider />}
          spacing='8'
        >
          <Box>
            <Heading size='sm' fontWeight='medium' color='gray.600' pb='3'>
              Fulfillment Confirmation Number
            </Heading>

            <Text>
              {fulfillmentConfirmationNumber}
            </Text>
          </Box>

          {shippedItems.length > 0 && (
            <Box>
              <Heading size='sm' fontWeight='medium' color='gray.600' pb='3'>
                Shipped
              </Heading>

              <VStack spacing='5' align='left'>
                {
                  shippedItems.map((shipmentCartItem, index) => {
                    return (
                      <ShipmentCartItem
                        key={index}
                        shipmentCartItem={shipmentCartItem}
                      />
                    )
                  })
                }
              </VStack>
            </Box>
          )}

          {unshippedItems.length > 0 && (
            <Box>
              <Heading size='sm' fontWeight='medium' color='gray.600' pb='3'>
                Not Shipped
              </Heading>

              <VStack spacing='5' align='left'>
                {
                  unshippedItems.map((shipmentCartItem, index) => {
                    return (
                      <ShipmentCartItem
                        key={index}
                        shipmentCartItem={shipmentCartItem}
                      />
                    )
                  })
                }
              </VStack>
            </Box>
          )}

        </VStack>
      </Card>

      <Button
        type='submit'
        colorScheme='blue'
        as={RouterLink}
        to={`/tracking?fulfillment=${confirmationNumber}`}
        width={{ base: '100%', md: 'auto' }}
        data-test='tracking-form-success-add-another-button'
        mt='5'
      >
        Submit Another
      </Button>
    </>
  )
}

export default Success
