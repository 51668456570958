import React from 'react'
import Constants from '../common/constants'
import dayjs from 'dayjs'
import { API } from '../../services/Api'
import { Button, HStack, Link, Text } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import { useQuery, useMutation } from 'react-query'

const TermsBanner = ({ ...props }) => {
  const cookieName = 'doorbellhealth_showTermsPrompt'
  const [cookies, setCookie] = useCookies([cookieName])
  const queryEnabled = cookies.doorbellhealth_showTermsPrompt === undefined

  const fetchTermsOfService = async () => {
    const { data } = await API.termsOfServiceAgreements.single()

    return data
  }

  const setTermsCookie = (data) => {
    setCookie(cookieName, data.showTermsPrompt, {
      expires: dayjs().add(1, 'day').toDate(),
      path: '/'
    })
  }

  useQuery(
    'termsOfService',
    fetchTermsOfService,
    {
      enabled: queryEnabled,
      onSuccess: setTermsCookie,
      retry: 0,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const mutation = useMutation(
    editUser => API.termsOfServiceAgreements.edit(editUser),
    {
      onSuccess: (response) => setTermsCookie(response.data)
    }
  )

  const handleSubmit = () => (
    mutation.mutate({ termsAccepted: true })
  )

  if (cookies.doorbellhealth_showTermsPrompt === 'true') {
    return (
      <HStack justify='center' spacing='4' p='4' bg='gray.700' {...props}>
        <Text color='white' fontSize={{ base: 'sm', md: 'md' }}>
          Our Terms of Service have been updated. Review terms for more details.
        </Text>

        <Link
          isExternal
          color='white'
          fontSize={{ base: 'sm', md: 'md' }}
          href={Constants.doorbell.termsOfServiceLink}
          textDecoration='underline'
        >
          View terms
        </Link>

        <Button
          isLoading={mutation.isLoading}
          bg='white'
          color='black'
          _hover={{ bg: 'gray.100' }}
          size='sm'
          onClick={handleSubmit}
        >
          I agree
        </Button>
      </HStack>
    )
  } else {
    return (null)
  }
}

export default TermsBanner
