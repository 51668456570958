import React from 'react'
import PropTypes from 'prop-types'
import { IoNotifications } from 'react-icons/io5'
import { Box, HStack, Icon, Stack, Text, Button } from '@chakra-ui/react'

const CtaButton = (props) =>
  (
    <Button
      fontSize='sm'
      data-test='banner-cta-button'
      borderWidth='1px'
      h='37px'
      borderColor='whiteAlpha.400'
      fontWeight='medium'
      bg='none'
      _hover={{
        background: 'blue.600',
        color: 'white'
      }}
      _active={{
        bg: 'blue.600'
      }}
      {...props}
    />
  )

export const Banner = ({ text, ctaText, onCtaAction, ctaComponent, ...rest }) => {
  return (
    <Box
      as='section'
      {...rest}
    >
      <Stack
        direction={{ base: 'column', sm: 'row' }}
        justifyContent='center'
        alignItems='center'
        flexWrap='wrap'
        py='2'
        px={{ base: '3', md: '6', lg: '8' }}
        color='white'
        bg='blue.500'
      >
        <HStack spacing='3' mr='2'>
          <Icon as={IoNotifications} fontSize='2xl' h='10' />
          <Text
            marginEnd='2'
            ml='20px'
            fontSize={{ base: 'sm', lg: 'md' }}
          >
            {text}
          </Text>
        </HStack>
        {ctaComponent || <CtaButton onClick={onCtaAction}>{ctaText}</CtaButton>}
      </Stack>
    </Box>
  )
}

Banner.propTypes = {
  text: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  onCtaAction: PropTypes.func.isRequired,
  ctaComponent: PropTypes.object
}
