/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import Mixpanel from '../../services/Mixpanel'
import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react'

const baseStyle = {
  border: '1px dashed',
  p: 8,
  my: 8,
  borderColor: '#4A5568',
  backgroundColor: 'white'
}

const focusedStyle = {
  borderColor: 'blue.500'
}

const acceptStyle = {
  borderColor: 'green.500',
  backgroundColor: 'gray.100'
}

const rejectStyle = {
  borderColor: 'red.500',
  backgroundColor: 'red.100'
}

const AttachmentsUploader = ({
  isMobile,
  isUploading,
  pendingAttachments,
  setPendingAttachments,
  allowMultipleUploads
}) => {
  const onDrop = useCallback(newAttachments => {
    setPendingAttachments(previousPendingAttachments => {
      let pendingAttachments

      if (allowMultipleUploads) {
        pendingAttachments = previousPendingAttachments.concat(newAttachments)
      } else {
        pendingAttachments = newAttachments
      }

      Mixpanel.trackEvent('Attachments Added', {
        documentCount: pendingAttachments.length
      })

      return pendingAttachments
    }
    )
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragReject,
    isDragAccept,
    isFocused
  } = useDropzone({
    onDrop,
    useFsAccessApi: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/heic': ['.heic', '.heif'],
      'image/heif': ['.heic', '.heif'],
      'application/pdf': ['.pdf']
    },
    disabled: isUploading,
    multiple: allowMultipleUploads
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ])

  const dropAreaContent = useMemo(() => {
    const filesCopy = allowMultipleUploads ? 'files' : 'file'

    if (isDragAccept) {
      return <Heading size='sm'>{`Drop ${filesCopy} here...`}</Heading>
    } else if (isDragReject) {
      return <Heading size='sm'>Invalid file selection</Heading>
    } else {
      return (
        <VStack spacing='6'>
          {!isMobile &&
          (
            <>
              <Heading
                data-test='attachments-instructions'
                size='sm'
              >
                {`Drag and drop ${filesCopy} here`}
              </Heading>
              <Text fontSize='sm'>or</Text>
            </>
          )}
          <Button
            data-test='attachments-button'
            fontSize='sm'
            color='white'
            bg='green.600'
            variant='solid'
            h='8'
            w='126px'
            _hover={{
              color: 'white',
              background: 'green.700'
            }}
          >
            Browse files
          </Button>
        </VStack>
      )
    }
  }, [
    isDragAccept,
    isDragReject,
    isMobile,
    allowMultipleUploads
  ])

  return (
    <>
      <Box
        {...getRootProps(style)}
      >
        <input {...getInputProps()} data-test='attachments-input' />

        <Center h={isMobile ? '' : '150px'}>
          {dropAreaContent}
        </Center>
      </Box>

      <Box minH='58px'>
        {pendingAttachments.length > 0 && (
          <>
            <Text fontSize='sm' fontWeight='700'>Ready for Upload</Text>

            <VStack
              data-test='pending-attachments'
              align='left'
              fontSize='sm'
              fontWeight='500'
              mt={4}
            >
              {pendingAttachments.map((attachment, index) =>
                <div key={index}>{attachment.name}</div>
              )}
            </VStack>
          </>
        )}
      </Box>
    </>
  )
}

AttachmentsUploader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  pendingAttachments: PropTypes.array.isRequired,
  setPendingAttachments: PropTypes.func.isRequired,
  allowMultipleUploads: PropTypes.bool.isRequired
}

export default AttachmentsUploader
