import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

export const Wrapper = ({ children }) => (
  <Box
    data-test='progress-navigation'
    position='fixed'
    bottom={{
      base: '0',
      lg: '25px'
    }}
    right={{
      base: '0',
      lg: '72px'
    }}
    bg='white'
    boxShadow='0px 4px 12px rgb(0 0 0 / 25%)'
    rounded={{
      base: 'none',
      lg: 'sm'
    }}
    padding={{
      base: '12px 16px',
      lg: '24px'
    }}
    w={{
      base: '100%',
      lg: '394px'
    }}
    zIndex={5}
  >
    {children}
  </Box>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
}
