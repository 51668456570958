import React from 'react'
import PropTypes from 'prop-types'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { useProductVariants } from '../../../context/use-product-variants'
import { ProductListLayout } from '../Layout/ProductListLayout'
import ProductCard from '../ProductCard'
import Heading from '../Heading'
import { NoResults } from './NoResults'
import { useTrackShopSite } from './useTrackShopSite'

const ProductsGrid = ({ products }) => (
  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={6} maxWidth='894'>
    {products.map((product) =>
      <ProductCard
        key={product.uuid}
        product={product}
      />
    )}
  </SimpleGrid>
)
ProductsGrid.propTypes = {
  products: PropTypes.array.isRequired
}

const ProductList = () => {
  useTrackShopSite()

  const {
    filteredProductVariants,
    groupByProduct,
    isFetching
  } = useProductVariants()

  const products = groupByProduct(filteredProductVariants)

  return (
    <ProductListLayout>
      <Box bg='white' px={{ base: '0', lg: '9' }} pt='0' align='center' minH='400px'>
        <Heading itemsQuantity={products.length} />
        {!isFetching && filteredProductVariants.length === 0
          ? <NoResults />
          : <ProductsGrid products={products} />}
      </Box>
    </ProductListLayout>
  )
}

export default ProductList
