import { post, put } from './base'

export const patients = {
  contactRequest: (params) =>
    post('/api/patients/contact_request', params),
  create: (params) =>
    post('/api/patients', params),
  update: (params) =>
    put('/api/patients/update', { patient: params })
}
