import heic2any from 'heic2any'
import Airbrake from './Airbrake'

// Converts a .heic/.heif to .jpeg
// Docs: https://github.com/alexcorvi/heic2any/blob/master/docs/getting-started.md
export const convertHeicToJpeg = async (heicFile) => {
  try {
    const blobResults = await heic2any({
      blob: heicFile,
      toType: 'image/jpeg',
      // Adds support for `heic` files that are composed of multiple images
      // This option causes heic2any to return an array of blobs instead of a single blob
      multiple: true
    })

    const fileNameWithoutExtension = heicFile.name.replace(/\.[^/.]+$/, '')

    // heic2any does not support keeping file metadata, this is creating
    // a new File object which contains the original metadata
    const jpegFile = new File(
      // Uses the first result because it might return more than one
      // when the .heic file is composed of multiple images
      [blobResults[0]],
      `${fileNameWithoutExtension}.jpeg`,
      {
        type: 'image/jpeg',
        lastModified: heicFile.lastModified
      }
    )

    return jpegFile
  } catch (error) {
    // Possible errors: https://github.com/alexcorvi/heic2any/blob/master/docs/errors.md
    Airbrake.notify(error)
    throw new Error('Error during .heic conversion to .jpeg')
  }
}
