import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridItem, Image, Text, Circle, Box } from '@chakra-ui/react'
import { groupByKey } from '../../common/utils'
import { CartTotalPrice } from '../../Shop/Cart/CartTotalPrice'

const QuantityCircle = ({ quantity }) => (
  <Circle
    size='20px'
    bg='gray.900'
    border='solid 1px white'
    color='white'
    position='absolute'
    top='-3px'
    right='-7px'
  >
    <Text fontSize='xs'>{quantity}</Text>
  </Circle>
)
QuantityCircle.propTypes = {
  quantity: PropTypes.number
}

const OrderItem = ({ lineItem, insuranceActive }) => {
  const {
    quantity,
    description,
    productImageUrl,
    amountTotalFormatted,
    retailCostFormatted,
    productIsCashOnly
  } = lineItem

  return (
    <Grid
      templateColumns='repeat(4, 1fr)'
      gap={2}
      py={4}
      borderBottom='solid 1px'
      borderBottomColor='blackAlpha.300'
      alignItems='center'
      _last={{ border: 'none', paddingBottom: 0 }}
    >
      <GridItem
        colSpan={1}
        position='relative'
      >
        <QuantityCircle quantity={quantity} />
        <Image
          boxSize='100px'
          fit='contain'
          src={productImageUrl}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Text
          fontSize={14}
          fontWeight={600}
        >
          {description}
        </Text>
      </GridItem>
      <GridItem
        colSpan={1}
        textAlign='right'
        colStart={0}
      >
        <CartTotalPrice
          patientCost={amountTotalFormatted}
          retailCost={retailCostFormatted}
          showInsurancePricing={!productIsCashOnly && insuranceActive}
        />
      </GridItem>
    </Grid>
  )
}
OrderItem.propTypes = {
  lineItem: PropTypes.object,
  insuranceActive: PropTypes.bool
}

const ItemSection = ({ supplierName, children }) => (
  <Box
    p={3}
    mb={5}
    borderWidth='1px'
    borderColor='blackAlpha.300'
    borderRadius='md'
    _last={{ mb: 0 }}
  >
    <Text
      mb={1}
      data-test='item-section-supplier'
    >
      {`Fulfilled by ${supplierName}:`}
    </Text>
    {children}
  </Box>
)
ItemSection.propTypes = {
  supplierName: PropTypes.string,
  children: PropTypes.node
}

export const OrderItems = ({ order }) => {
  const itemsBySupplierName = groupByKey(order.lineItems, 'supplierName')

  return (
    <Box data-test='order-items'>
      {
        Object.entries(itemsBySupplierName)
          .map(([supplierName, items]) => (
            <ItemSection
              key={supplierName}
              supplierName={supplierName}
            >
              {items.map((lineItem) =>
                <OrderItem
                  key={lineItem.productVariantId}
                  lineItem={lineItem}
                  insuranceActive={order.insuranceActive}
                />
              )}
            </ItemSection>
          ))
      }
    </Box>
  )
}
OrderItems.propTypes = {
  order: PropTypes.object
}
