import React from 'react'
import PropTypes from 'prop-types'
import { Circle, HStack, Center } from '@chakra-ui/react'
import { usePatientSession } from '../../../context/use-patient-session'
import Mixpanel from '../../../services/Mixpanel'

const ProgressIndicator = ({ step }) => {
  const patientSession = usePatientSession()
  const currentStep = patientSession.getCurrentOnboardingStep()

  const handleClick = () => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Click Progress Indicator',
      { fromStep: currentStep, toStep: step }
    )

    if (step < currentStep) {
      patientSession.setOnboardingStep({ step })
    }
  }

  const cursor = () => {
    if (step < currentStep) {
      return 'pointer'
    } else {
      return 'not-allowed'
    }
  }

  const indicatorColor = () => {
    if (step === currentStep) {
      return 'green.400'
    } else {
      return 'blackAlpha.500'
    }
  }

  return (
    <Circle
      size='10px'
      data-step={step}
      bg={indicatorColor()}
      data-test={`progress-indicator-${step}`}
      onClick={handleClick}
      cursor={cursor()}
    />
  )
}

ProgressIndicator.propTypes = {
  step: PropTypes.number
}

const ProgressIndicators = ({ amount }) => {
  return (
    <Center>
      <HStack>
        {
          Array.from(Array(amount).keys()).map((step) => (
            <ProgressIndicator step={step} key={step} />
          ))
        }
      </HStack>
    </Center>
  )
}

ProgressIndicators.propTypes = {
  amount: PropTypes.number
}

export default ProgressIndicators
