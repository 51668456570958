import React from 'react'
import Layout from '../Layout'
import { Cta } from '../Sections/Cta'
import { WhyDoorbell } from '../Sections/WhyDoorbell'
import { ASSETS as howItWorksAssets } from './howItWorksAssets'
import { Testimonials } from '../Sections/Testimonials'
import { HowItWorks } from '../Sections/HowItWorks'
import womanPictureSvg from '../../../../assets/images/landing/woman-picture.svg'
import { ASSETS as features } from './features'
import { useTrackLandingPage } from '../useTrackLandingPage'

const ForPatients = () => {
  useTrackLandingPage('Patients')

  return (
    <Layout renderChildren>
      <Cta
        color='landingPage.green'
        illustration={womanPictureSvg}
        headingCopy='Your new best friend for finding DME'
        bodyCopy='Meet Doorbell Health, the first online marketplace
        for insurance-covered medical equipment (DME). Never
        worry about the hassle of driving into the DME store again.'
      />
      <WhyDoorbell
        features={features}
        disclaimerMessage='*Delivery timing is subject to change based on location and availability.'
      />
      <HowItWorks
        assets={howItWorksAssets}
        bottomMessage='That’s it. Seriously.'
      />
      <Testimonials />
    </Layout>
  )
}

export default ForPatients
