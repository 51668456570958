import React from 'react'
import { Box, Center } from '@chakra-ui/react'
import { SvgLogo as Logo } from '../../common/SvgLogo'

export const EmptyTopBar = (props) => {
  return (
    <Box
      w='100%'
      bg='white'
      borderBottom={{ base: 'none', md: '1px' }}
      borderBottomColor={{ base: 'none', md: 'gray.300' }}
      {...props}
    >
      <Center h='80px'>
        <Logo h='32px' />
      </Center>
    </Box>
  )
}
