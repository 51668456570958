import React from 'react'
import PropTypes from 'prop-types'
import Constants from '../common/constants'
import { Button, Text } from '@chakra-ui/react'
import { usePatientSession } from '../../context/use-patient-session'

export const ContactSuccess = ({ handleCloseModal }) => {
  const { hasPatientPhone, hasPatientEmail } = usePatientSession()

  const supportMessageInitialPart = hasPatientPhone() && !hasPatientEmail()
    ? `Our support team will reach out to you within a business day from ${Constants.doorbell.phoneNumber.formatted}.`
    : 'Our support team will be in touch soon.'

  const supportMessage = supportMessageInitialPart + ' In the meantime, you are welcome to continue browsing the site.'

  return (
    <>
      <Text
        mt='1rem'
        mb='2rem'
        data-test='insurance-modal-contact-support-message'
      >
        {supportMessage}
      </Text>
      <Button
        width='full'
        colorScheme='green'
        onClick={handleCloseModal}
        data-test='insurance-modal-contact-browse-button'
      >
        Browse Store
      </Button>
    </>
  )
}

ContactSuccess.propTypes = {
  handleCloseModal: PropTypes.func.isRequired
}

export default ContactSuccess
