import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import {
  Tooltip as ChakraTooltip,
  Box,
  useBoolean
} from '@chakra-ui/react'

const Tooltip = ({ children, ...props }) => {
  const [mobileTooltipOpen, setMobileTooltipOpen] = useBoolean(false)
  const mobileBreakpointValue = '(max-width: 62em)'
  const isMobile = useMediaQuery({ query: mobileBreakpointValue })

  useEffect(() => {
    // Closes the tooltip if it was opened and the user resizes the window
    setMobileTooltipOpen.off()
    /* eslint-disable-next-line */
  }, [isMobile])

  if (isMobile) {
    return (
      <ChakraTooltip
        isOpen={mobileTooltipOpen}
        {...props}
      >
        {/** Controls the visiblity of the Mobile Tooltip.
         * Otherwise, the tooltip does not work on Mobile (Chrome for Android).
         */}
        <Box
          onClick={() => {
            setMobileTooltipOpen.toggle()
          }}
          onMouseLeave={() => {
            setMobileTooltipOpen.off()
          }}
          data-test='mobile-tooltip'
        >
          {children}
        </Box>
      </ChakraTooltip>
    )
  }

  return (
    <ChakraTooltip
      {...props}
    >
      <Box
        data-test='desktop-tooltip'
      >
        {children}
      </Box>
    </ChakraTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired
}

export default Tooltip
