const ANALYTICS_ENABLED = process.env.GOOGLE_ANALYTICS_ID != null

// List of query parameters that we don't want to send to Analytics
const EXCLUDED_QUERY_PARAMETERS = ['referral_code']

const identify = (resourceUuid, resourceType, isTestAccount) => {
  if (!ANALYTICS_ENABLED) return

  // https://developers.google.com/analytics/devguides/collection/ga4/user-properties
  window.gtag('set', 'user_properties', {
    resource_type: resourceType,
    resource_uuid: resourceUuid,
    is_test_account: isTestAccount
  })
}

const trackEvent = ({ action, category, label, value, customParams = {} }) => {
  if (!ANALYTICS_ENABLED) return

  const optionalParams = {
    ...(category ? { event_category: category } : {}),
    ...(label ? { event_label: label } : {}),
    ...(value ? { value } : {}),
    ...customParams
  }

  window.gtag('event', action, optionalParams)
}

const trackPageView = () => {
  if (!ANALYTICS_ENABLED) return

  const currentUrl = new URL(window.location.href)

  EXCLUDED_QUERY_PARAMETERS.forEach(queryParam => {
    if (currentUrl.searchParams.has(queryParam)) {
      currentUrl.searchParams.set(queryParam, 'EXCLUDED')
    }
  })

  window.gtag('event', 'page_view', {
    page_title: window.document.title,
    page_location: currentUrl.href,
    page_path: currentUrl.pathname,
    send_to: process.env.GOOGLE_ANALYTICS_ID
  })
}

const Analytics = {
  identify,
  trackEvent,
  trackPageView
}

export default Analytics
