import React from 'react'
import BuyBox from './BuyBox'
import Price from '../Product/Price'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { usePatientSession } from '../../../context/use-patient-session'
import { Link as RouterLink } from 'react-router-dom'

import {
  Button,
  Divider,
  Heading,
  Wrap,
  WrapItem,
  Text
} from '@chakra-ui/react'

const VariantPicker = ({ productVariant, selected }) => (
  <Button
    key={productVariant.uuid}
    as={RouterLink}
    to={`/products/${productVariant.uuid}`}
    replace
    fontWeight='medium'
    variant='outline'
    colorScheme='blue'
    color={selected ? 'blue.500' : 'gray.600'}
    background={selected ? 'blue.50' : 'white'}
    border='none'
    outline={selected ? 'solid 2px' : 'solid 1px'}
    outlineColor={selected ? 'blue.500' : 'gray.200'}
    _focus={{ boxShadow: 'none' }}
    _focusVisible={{ boxShadow: 'outline' }}
    data-test='variant-picker'
    margin='2px'
    whiteSpace='normal'
  >
    {productVariant.title}
  </Button>
)

VariantPicker.propTypes = {
  productVariant: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired
}

const Aside = ({ productVariant, productVariants }) => {
  const { product } = productVariant
  const session = usePatientSession()
  const description = parse(product.description)
  const hasOptions = productVariants.length > 1
  const cartItem = session.getCartItem(productVariant.uuid)
  const prices = (cartItem || productVariant).formattedPrices
  const title = hasOptions ? product.name : productVariant.productTitle

  return (
    <>
      <Heading size='lg' mb={{ base: '1', lg: '4' }}>
        {title}
      </Heading>

      <Text color='blackAlpha.600' mb={{ base: '2', lg: '5' }}>
        {product.manufacturer}
      </Text>

      <Price
        prices={prices}
        product={product}
        designVariant='detail'
        mb={{ base: '5', lg: '9' }}
      />

      {hasOptions && (
        <Wrap mb={6} spacingX={3} spacingY={2}>
          {productVariants.map((variant) => (
            <WrapItem key={variant.uuid}>
              <VariantPicker
                productVariant={variant}
                selected={variant.uuid === productVariant.uuid}
              />
            </WrapItem>
          ))}
        </Wrap>
      )}
      <BuyBox
        // Key forces a state reset, so that BuyBox state is not shared
        // across different product variants. Each should be isolated.
        key={productVariant.uuid}
        productVariant={productVariant}
      />

      <Divider color='gray.200' mb={{ base: '5', lg: '8' }} />

      <Text
        as='span'
        fontSize='sm'
        color='gray.800'
        data-test='product-description'
      >
        {description}
      </Text>
    </>
  )
}

Aside.propTypes = {
  productVariant: PropTypes.object.isRequired,
  productVariants: PropTypes.array.isRequired
}

export default Aside
