import React from 'react'
import PropTypes from 'prop-types'
import Constants from '../../common/constants'
import { useMatch } from 'react-router-dom'
import { Box, Flex, HStack, VStack } from '@chakra-ui/react'
import { useLockBodyScroll } from '../../common/useLockBodyScroll'
import { FooterLinks } from '../Footer'
import { NavItem } from './NavItem'
import { useCtaConfig } from '../Sections/CtaButton'
import { isAuthenticated } from '../../../context/use-auth'

const MobileNavMenu = (props) => {
  const { isOpen, topBarHeight, toggleMenu } = props
  const { CtaText, CtaDestination } = useCtaConfig()
  const isLoggedIn = isAuthenticated()
  const isProviderLandingPage = useMatch('/providers')
  const showSignIn = isProviderLandingPage && !isLoggedIn

  useLockBodyScroll(isOpen)

  return (
    <Flex
      hidden={!isOpen}
      as='nav'
      direction='column'
      bg='white'
      position='fixed'
      height={`calc(100vh - ${topBarHeight}px)`}
      top={`${topBarHeight}px`}
      insetX='0'
      zIndex={10}
      w='full'
      data-test='mobile-nav-menu'
    >
      <Box px='4'>
        <NavItem.Mobile label='For Providers' path='/providers' onClick={toggleMenu} />
        <NavItem.Mobile label='About' path='/' onClick={toggleMenu} />
        <NavItem.Mobile label='Our Team' path='/team' onClick={toggleMenu} />
        <NavItem.Mobile label='Help' asHref href={Constants.doorbell.supportEmailMailTo} onClick={toggleMenu} isExternal />
        <NavItem.Mobile label={CtaText} path={CtaDestination} />
        {showSignIn &&
          <NavItem.Mobile label='Sign In' path='/providers/signin' />}
      </Box>
      <VStack
        w='100%'
        spacing={4}
        px={6}
        mt={6}
        align='left'
      >
        <FooterLinks
          color='blackAlpha.600'
          fontSize='xs'
          _hover={{
            color: 'blackAlpha.800'
          }}
        />
      </VStack>
    </Flex>
  )
}
MobileNavMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  topBarHeight: PropTypes.number,
  toggleMenu: PropTypes.func
}

const DesktopNavMenu = () => (
  <HStack
    spacing='1'
    flex='1'
    display={{
      base: 'none',
      md: 'flex'
    }}
    data-test='desktop-nav-menu'
  >
    <NavItem.Desktop
      label='For Providers'
      path='/providers'
      data-test='nav-for-providers'
    />
    <NavItem.Desktop label='About' path='/' data-test='nav-about' />
    <NavItem.Desktop label='Our Team' path='/team' data-test='nav-team' />
    <NavItem.Desktop label='Help' asHref href={Constants.doorbell.supportEmailMailTo} isExternal />
  </HStack>
)

const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu
}

export default NavMenu
