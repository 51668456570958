import * as React from 'react'
import PropTypes from 'prop-types'
import { chakra, useTheme } from '@chakra-ui/react'

export const IconLogo = (props) => {
  const theme = useTheme()
  const { color = theme.colors.green[600] } = props

  return (
    <chakra.svg
      aria-hidden
      viewBox='0 0 25 33'
      fill='none'
      h='33px'
      flexShrink={0}
      data-test='logo'
      {...props}
    >
      <path d='M16.7198 5.24886H14.4693C14.0626 3.78043 13.1196 2.5462 11.8016 1.76725C10.4518 0.96461 8.86702 0.738291 7.3456 1.12514C5.29063 1.6462 3.68205 3.2199 3.12208 5.2515H0.871647C0.39092 5.2515 0 5.64097 0 6.11993V27.6465C0 28.1254 0.39092 28.5149 0.871647 28.5149H3.11944C3.52621 29.9833 4.46917 31.2176 5.78721 31.9965C6.70904 32.5439 7.73917 32.8228 8.78514 32.8228C9.27115 32.8228 9.76244 32.7623 10.2458 32.6386C12.3008 32.1176 13.9094 30.5439 14.4693 28.5123H16.7171C17.1979 28.5123 17.5888 28.1228 17.5888 27.6438V6.11729C17.5888 5.63834 17.2005 5.24886 16.7198 5.24886ZM15.8481 6.98572V26.778H13.7667C13.3362 26.778 12.9638 27.0991 12.903 27.5228C12.5861 29.7781 10.4862 31.3623 8.22253 31.0439C6.39736 30.7912 4.94461 29.3412 4.6884 27.5201C4.62501 27.0886 4.2605 26.7754 3.82468 26.7754H1.74329V6.98572H3.82468C4.25522 6.98572 4.62765 6.66467 4.6884 6.24098C5.00536 3.98569 7.09996 2.40147 9.36888 2.71989C11.1941 2.97253 12.6468 4.42254 12.903 6.24098C12.9638 6.66467 13.3336 6.98572 13.7667 6.98572H15.8481Z' fill={color} />
      <path d='M10.9564 23.678H6.63515C6.23631 23.678 5.91406 23.999 5.91406 24.3964C5.91406 24.7938 6.23631 25.1148 6.63515 25.1148H10.9564C11.3553 25.1148 11.6775 24.7938 11.6775 24.3964C11.6775 23.999 11.3553 23.678 10.9564 23.678Z' fill={color} />
      <path d='M8.47079 10.97H4.63818V21.1359H8.47079C10.1692 21.1359 11.4951 20.6964 12.4434 19.8201C13.3916 18.9411 13.8644 17.6858 13.8644 16.0542C13.8644 14.4226 13.389 13.1674 12.4434 12.2884C11.4925 11.4094 10.1692 10.97 8.47079 10.97ZM11.1095 18.77C10.502 19.4122 9.62242 19.7332 8.47079 19.7332H6.39733V12.37H8.47079C9.62242 12.37 10.502 12.691 11.1095 13.3332C11.7197 13.9753 12.0234 14.8805 12.0234 16.0516C12.0234 17.2227 11.7197 18.1279 11.1095 18.77Z' fill={color} />

    </chakra.svg>
  )
}
IconLogo.propTypes = {
  color: PropTypes.string
}
