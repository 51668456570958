import React, { useEffect, useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import ReactSelect from '../common/ChakraReactSelect'
import {
  Text,
  Box,
  VStack,
  Input,
  InputGroup,
  InputRightElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  CloseButton,
  Button
} from '@chakra-ui/react'
import {
  FormSection,
  FormControl
} from '../common/FormElements'
import { hasANumber } from '../common/utils'

const HcpcsCodeForm = ({
  form,
  handleRemove,
  handleAdd
}) => {
  const { hcpcsCode, hcpcsLabel, hcpcsDescription, hcpcsCodeIcd10S } = form
  const primaryDxCodes = hcpcsCodeIcd10S.filter(hcpcsIcd10 => hcpcsIcd10.isPrimaryDx)?.map(hcpcsIcd10 => hcpcsIcd10.icd10.code)
  const secondaryDxCodes = hcpcsCodeIcd10S.filter(hcpcsIcd10 => hcpcsIcd10.isSecondaryDx)?.map(hcpcsIcd10 => hcpcsIcd10.icd10.code)
  const [index, setIndex] = useState(0)
  const [added, setAdded] = useState(false)

  const isCPAP = hcpcsCode.isCpap
  const isOxygenCore = hcpcsCode.isOxygenCore

  const LABELS = {
    diagnosis: 'Add one or more diagnosis codes',
    lengthOfNeed: 'Length of Need',
    pressureSetting: 'Pressure Setting',
    po2Percent: 'PO2 Level',
    arterialSaturationPercent: 'Arterial Saturation Level'
  }

  const validateRequiredDxCodes = (values) => {
    if (!values.diagnosis || values.diagnosis.length === 0) {
      return
    }

    const diagnosisCodes = values.diagnosis.map(diagnosis => diagnosis.value)

    if (secondaryDxCodes.length > 0) {
      const missingSecondaryDxCodes = secondaryDxCodes.filter(code => !diagnosisCodes.includes(code))

      if (missingSecondaryDxCodes.length > 0) {
        return new yup.ValidationError(
          'Please select the required ICD10 code' + (missingSecondaryDxCodes.length === 1 ? '' : 's') + ': ' + missingSecondaryDxCodes.join(', ') + ' in order to submit this order.',
          null,
          'diagnosis'
        )
      }
    }

    const primaryDxCodeSelected = diagnosisCodes.some(code => primaryDxCodes.includes(code))
    if (!primaryDxCodeSelected) {
      return new yup.ValidationError(
        'Please select an ICD10 that supports the core device',
        null,
        'diagnosis'
      )
    }

    return true
  }

  const validateOxygenFields = (values) => {
    if (!isOxygenCore) {
      return true
    }

    if (!values.po2Percent && !values.arterialSaturationPercent) {
      return new yup.ValidationError(
        "Please provide either the Patient's PO2 or Arterial Saturation Level to proceed.",
        null,
        'dataError'
      )
    }

    return true
  }

  const baseSchema = yup.object().shape({
    diagnosis: yup.array()
      .of(yup.object())
      .required('Add one or more diagnosis codes').nullable().label(LABELS.diagnosis)
  })
    .test(validateRequiredDxCodes)

  const cpapSchema = baseSchema.shape({
    lengthOfNeed: yup.string()
      .required()
      .label(LABELS.lengthOfNeed)
      .test(
        'is-valid-length-of-need',
        'Length Of Need must contain a number',
        hasANumber
      ),
    pressureSetting: yup.string()
      .required()
      .label(LABELS.pressureSetting)
      .test(
        'is-valid-pressure-setting',
        'Pressure Setting must contain a number',
        hasANumber
      )
  })

  const oxygenCoreSchema = baseSchema.shape({
    po2Percent: yup.string()
      .label(LABELS.po2Percent)
      .test(
        'is-valid-po2',
        'PO2 level must contain a number',
        value => (value ? hasANumber(value) : true)
      ),
    arterialSaturationPercent: yup.string()
      .label(LABELS.arterialSaturationPercent)
      .test(
        'validate-oxygen-fields',
        'Arterial Saturation must contain a number',
        value => (value ? hasANumber(value) : true)
      )
  }).test(validateOxygenFields)

  let schema = baseSchema
  if (isCPAP) {
    schema = cpapSchema
  } else if (isOxygenCore) {
    schema = oxygenCoreSchema
  }

  const showRequiredDataSection = schema !== baseSchema

  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  const onValidatedSubmit = ({
    diagnosis,
    pressureSetting,
    lengthOfNeed,
    po2Percent,
    arterialSaturationPercent,
    ...params
  }) => {
    params.diagnosis = diagnosis.value

    setIndex(1)
    setAdded(true)
    handleAdd(
      form,
      {
        pressureSetting,
        lengthOfNeed,
        po2Percent,
        arterialSaturationPercent,
        icd10_codes: diagnosis.map(diag => diag.value)
      }
    )
  }

  const icd10Options = hcpcsCodeIcd10S.map(hcpcsIcd10 => ({
    value: hcpcsIcd10.icd10.code,
    label: `${hcpcsIcd10.icd10.code} ${hcpcsIcd10.icd10.description}`
  }))

  const po2PercentWatcher = watch('po2Percent')

  useEffect(() => {
    validatePo2(po2PercentWatcher)
  }, [po2PercentWatcher])

  const validatePo2 = useCallback(
    debounce(value => {
      if (value && parseInt(value) > 55) {
        const message = "The patient's PO2 levels do not meet CMS requirements for the device prescribed. If you proceed it will most likely not be covered by insurance."
        setError('po2Error', { type: 'custom', message })
      } else {
        setError('po2Error', null)
      }
    }, 500),
    []
  )

  const arterialPercentWatcher = watch('arterialSaturationPercent')

  useEffect(() => {
    validateArterialSaturation(arterialPercentWatcher)
  }, [arterialPercentWatcher])

  const validateArterialSaturation = useCallback(
    debounce(value => {
      if (value && parseInt(value) < 88) {
        const message = "The patient's Arterial Saturation levels do not meet CMS requirements for the device prescribed. If you proceed it will most likely not be covered by insurance."
        setError('arterialSaturationError', { type: 'custom', message })
      } else {
        setError('arterialSaturationError', null)
      }
    }, 500),
    []
  )

  return (
    <Accordion index={index} onChange={setIndex} allowToggle mt={4}>
      <AccordionItem border='solid 1px #E2E8F0' borderRadius='5px'>

        <h2>
          <AccordionButton
            as='div' cursor='pointer' borderTopRightRadius='5px' borderTopLeftRadius='5px'
            color='gray.800' _expanded={{ bg: '#2F855A', color: 'white' }}
          >
            <AccordionIcon />
            <Box as='span' flex='1' textAlign='left'>
              <Text fontWeight='bold' ml={6}>{hcpcsLabel}</Text>
            </Box>
            <CloseButton size='md' p={4} onClick={() => handleRemove(form)} />
          </AccordionButton>
        </h2>

        <form id='hcpcsForm' onSubmit={handleSubmit(onValidatedSubmit)}>
          <AccordionPanel p={6} overflow='initial'>
            <VStack
              align='stretch'
              spacing={8}
            >
              <Box as='span' textAlign='left'>
                <Text color='gray.500'>{hcpcsDescription}</Text>
              </Box>
              <FormSection heading='Diagnosis'>
                <VStack
                  align='stretch'
                  mt={4}
                >
                  <FormControl
                    errorMessage={errors?.diagnosis?.message}
                    label={LABELS.diagnosis}
                  >
                    <Controller
                      control={control}
                      name='diagnosis'
                      render={({ field }) => (
                        <ReactSelect
                          {...field}
                          id='diagnosis'
                          isMulti
                          isClearable
                          isDisabled={added}
                          isInvalid={!!errors?.diagnosis?.message}
                          options={icd10Options}
                          placeholder='Type ICD-10 code or diagnosis description'
                          closeMenuOnSelect
                          size='md'
                          mt={0}
                          fontSize='sm'
                          zIndex='50'
                          onChange={(event) => {
                            return field.onChange(event)
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </VStack>
              </FormSection>

              {showRequiredDataSection && (
                <FormSection heading='Required Data'>
                  <VStack
                    align='stretch'
                    mt={4}
                    spacing={6}
                  >
                    {isCPAP && (
                      <>
                        <Box>
                          <FormControl
                            errorMessage={errors?.lengthOfNeed?.message}
                            label='Length of Need (months)'
                          >
                            <Input
                              id='lengthOfNeed'
                              type='text'
                              defaultValue='99'
                              fontSize='sm'
                              disabled={added}
                              {...register('lengthOfNeed')}
                            />
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl
                            errorMessage={errors?.pressureSetting?.message}
                            label='Pressure Setting (cm H2O)'
                          >
                            <Input
                              id='pressureSetting'
                              type='text'
                              defaultValue='10'
                              fontSize='sm'
                              disabled={added}
                              {...register('pressureSetting')}
                            />
                          </FormControl>
                        </Box>
                      </>
                    )}

                    {isOxygenCore && (
                      <>
                        <Box>
                          <FormControl
                            errorMessage={errors?.po2Percent?.message}
                            label={LABELS.po2Percent}
                          >
                            <InputGroup maxW='280px'>
                              <Input
                                id='po2Percent'
                                type='number'
                                inputMode='numeric'
                                placeholder="Enter patient's PO2"
                                fontSize='sm'
                                disabled={added}
                                {...register('po2Percent')}
                              />
                              <InputRightElement>
                                <Text fontSize='22px' color='gray.500'>%</Text>
                              </InputRightElement>
                            </InputGroup>
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl
                            errorMessage={errors?.arterialSaturationPercent?.message}
                            label={LABELS.arterialSaturationPercent}
                          >
                            <InputGroup maxW='280px'>
                              <Input
                                id='arterialSaturationPercent'
                                type='number'
                                inputMode='numeric'
                                fontSize='sm'
                                placeholder="Enter patient's Arterial Saturation"
                                disabled={added}
                                {...register('arterialSaturationPercent')}
                              />
                              <InputRightElement>
                                <Text fontSize='22px' color='gray.500'>%</Text>
                              </InputRightElement>
                            </InputGroup>
                          </FormControl>
                        </Box>
                      </>
                    )}
                  </VStack>
                </FormSection>
              )}

              {!added && (
                <Box>
                  {errors?.dataError !== '' && (
                    <Text color='red.500' mb={4}>
                      {errors?.dataError?.message}
                    </Text>
                  )}
                  {errors?.po2Error !== '' && (
                    <Text color='red.500' mb={4}>
                      {errors?.po2Error?.message}
                    </Text>
                  )}
                  {errors?.arterialSaturationError !== '' && (
                    <Text color='red.500' mb={4}>
                      {errors?.arterialSaturationError?.message}
                    </Text>
                  )}
                  <Button
                    id='add-hcpcs-code'
                    type='submit'
                    colorScheme='blue'
                    width={{ base: '100%', md: 'auto' }}
                  >
                    Save
                  </Button>
                </Box>
              )}

            </VStack>
          </AccordionPanel>
        </form>
      </AccordionItem>
    </Accordion>
  )
}

HcpcsCodeForm.propTypes = {
  form: PropTypes.object,
  handleRemove: PropTypes.func,
  handleAdd: PropTypes.func
}

export default HcpcsCodeForm
