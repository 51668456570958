import React from 'react'
import { useQuery } from 'react-query'
import { Outlet } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { Spinner } from '../common/Spinner'
import ShopLayout from '../Shop/Layout'
import { useCode } from '../ReferralCodeGate/useCode'
import { useAuth } from '../../context/use-auth'
import { usePatientSession } from '../../context/use-patient-session'
import { API } from '../../services/Api'

const ShopRoute = () => {
  const { referralCodeParam } = useCode()
  const { isPatient, user } = useAuth()
  const patientSession = usePatientSession()
  const signedInPatientId = user?.uid

  const hasCart = !!patientSession.patientSession.cart

  const setCart = ({ data: cart }) => {
    patientSession.setCart(cart)
  }

  // This forces a cart refetch to prevent local session cart from becoming stale.
  // Runs on page load for patients that have carts in their session
  // And after a patient has signed-in
  useQuery(
    ['fetchCart', signedInPatientId],
    API.cart.single,
    {
      enabled: isPatient || hasCart,
      onSuccess: setCart,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  if (referralCodeParam) {
    return (
      <ShopLayout renderChildren>
        <Box h='100%'>
          <Spinner labelText='Assembling your personalized treatment plan' />
        </Box>
      </ShopLayout>
    )
  } else {
    return (
      <ShopLayout fullLayout renderChildren>
        <Outlet />
      </ShopLayout>
    )
  }
}

export default ShopRoute
