import { extendTheme } from '@chakra-ui/react'
import colors from './colors'

const theme = extendTheme({
  colors,
  fonts: {
    heading: 'Inter',
    body: 'Inter'
  }
})
export default theme
