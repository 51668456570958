import { Notifier } from '@airbrake/browser'

const AIRBRAKE_ENABLED = process.env.AIRBRAKE_PROJECT_ID != null

const initialize = () => {
  if (!AIRBRAKE_ENABLED) return

  if (window._airbrake === undefined) {
    window._airbrake = new Notifier({
      projectId: process.env.AIRBRAKE_PROJECT_ID,
      projectKey: process.env.AIRBRAKE_API_KEY
    })
  }

  return window._airbrake
}

const notify = () => {
  if (!AIRBRAKE_ENABLED) return

  return initialize().notify
}

const Airbrake = {
  notify
}

export default Airbrake
