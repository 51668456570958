import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { useDisclosure, Flex } from '@chakra-ui/react'
import { useCookies } from 'react-cookie'
import { InsuranceModalProvider } from '../../../context/use-insurance-modal'
import { usePatientSession } from '../../../context/use-patient-session'
import { ProductVariantsProvider } from '../../../context/use-product-variants'
import { InsuranceModal } from '../../Insurance/InsuranceModal'
import { PrescriptionModal } from '../../Prescription/PrescriptionModal'
import { OnboardingWizard } from '../../Shop/OnboardingWizard'
import { Footer } from '../Footer/Footer'
import TopBanners from '../TopBanners'

const Layout = (
  {
    children,
    fullLayout = false,
    renderChildren = false
  }
) => {
  const { patientSession } = usePatientSession()

  const productVerticalCookieName = 'doorbellhealth_productVertical'
  const [cookies] = useCookies([productVerticalCookieName])
  const productVerticalCookie = cookies.doorbellhealth_productVertical

  const hideOnboardingModal = () => (
    (patientSession?.consultation ||
      patientSession?.onboardingModal?.hasCompletedOnboarding) &&
      productVerticalCookie
  )

  const { isOpen, onClose } = useDisclosure(
    { defaultIsOpen: !hideOnboardingModal() }
  )

  useEffect(() => {
    if (fullLayout && patientSession.consultation && isOpen) {
      onClose()
    }
    /* eslint-disable-next-line */
  }, [fullLayout, patientSession?.consultation])

  const childrenComponent = renderChildren ? children : <Outlet />

  return (
    <Flex direction='column' bg='white' minHeight='100vh'>
      {fullLayout
        ? (
          <InsuranceModalProvider>
            <ProductVariantsProvider>
              <TopBanners />

              <InsuranceModal />
              <PrescriptionModal />
              <OnboardingWizard isOpen={isOpen} onClose={onClose} />

              {childrenComponent}
              <Footer.Desktop />
            </ProductVariantsProvider>
          </InsuranceModalProvider>
          )
        : (
          <>
            <TopBanners showTopBarOnly />
            {childrenComponent}
            <Footer.Desktop />
          </>
          )}
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  fullLayout: PropTypes.bool,
  renderChildren: PropTypes.bool
}

export default Layout
