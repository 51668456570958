import { useMatch } from 'react-router-dom'

const useRouteMatching = () => {
  const isShopRoute = !!useMatch('/shop')
  const isPatientSignInRoute = !!useMatch('/patients/signin')
  const isPatientPasswordResetRoute = !!useMatch('/patients/password_reset')
  const isPatientPasswordRecoveryRoute = !!useMatch('/patients/password_recovery')
  const isCartRoute = !!useMatch('/cart')
  const isProductRoute = !!useMatch('/products/:uuid')
  const isOrderHistoryRoute = !!useMatch('/order_history')
  const isInsuranceInformationRoute = !!useMatch('/insurance_information')
  const isAccountSettingsRoute = !!useMatch('/account_settings')

  return {
    isAccountSettingsRoute,
    isInsuranceInformationRoute,
    isOrderHistoryRoute,
    isShopRoute,
    isPatientPasswordRecoveryRoute,
    isPatientPasswordResetRoute,
    isPatientSignInRoute,
    isProductRoute,
    isCartRoute
  }
}

export default useRouteMatching
