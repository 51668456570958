import PropTypes from 'prop-types'
import {
  Center,
  Flex,
  FormControl,
  IconButton,
  Text,
  useControllableState
} from '@chakra-ui/react'
import * as React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'

const QuantityPickerButton = (props) => (
  <IconButton
    size='sm'
    fontSize='md'
    _focus={{
      boxShadow: 'none'
    }}
    _focusVisible={{
      boxShadow: 'outline'
    }}
    {...props}
  />
)

const QuantityPicker = ({
  isDisabled = false,
  min,
  max,
  rootProps,
  ...rest
}) => {
  const [value, setValue] = useControllableState(rest)

  const handleDecrement = () => setValue(value === min ? value : value - 1)

  const handleIncrement = () => setValue(value === max ? value : value + 1)

  return (
    <FormControl {...rootProps}>
      <Flex
        borderRadius='base'
        px='2'
        py='0.4375rem' p
        borderWidth='1px'
        justifyContent='space-between'
      >
        <QuantityPickerButton
          onClick={handleDecrement}
          icon={<FiMinus />}
          isDisabled={value === min || isDisabled}
          aria-label='Decrement'
          data-test='quantity-picker-decrement'
        />
        <Center minW='8'>
          <Text
            as='span'
            fontWeight='semibold'
            userSelect='none'
            data-test='quantity-picker-value'
          >
            {value}
          </Text>
        </Center>
        <QuantityPickerButton
          onClick={handleIncrement}
          icon={<FiPlus />}
          isDisabled={value === max || isDisabled}
          aria-label='Increment'
          data-test='quantity-picker-increment'
        />
      </Flex>
    </FormControl>
  )
}

QuantityPicker.propTypes = {
  isDisabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  rootProps: PropTypes.object
}

export default QuantityPicker
