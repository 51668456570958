import React from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import { usePatientSession } from '../../context/use-patient-session'
import { usePrescriptionModal } from '../../context/use-prescription-modal'
import { ExcludeFromRecording } from '../../services/RecordingService'
import { FormControl, FormSection } from '../common/FormElements'
import { chakra, Input, Button, Center, Text, VStack, FormHelperText } from '@chakra-ui/react'
import { phoneNumberRegex } from '../common/utils'
import { API } from '../../services/Api'
import InputMask from 'react-input-mask'
import Mixpanel from '../../services/Mixpanel'

const LABELS = {
  email: 'Email',
  phoneNumber: 'Phone Number'
}

export const PrescriptionForm = ({ handleCloseModal }) => {
  const modal = usePrescriptionModal()
  const { setConsultation, getEstimatedDueDate, setCart } = usePatientSession()

  const schema = yup.object().shape({
    email: yup.string().email('Must be a valid email address').label(LABELS.email).required(),
    phoneNumber: yup.string().label(LABELS.phoneNumber).matches(
      phoneNumberRegex,
      {
        message: 'Must be a valid phone number',
        excludeEmptyString: true
      }).required()
  })

  const { handleSubmit, control, register, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(schema)
  })

  const handleSuccess = async ({ data: consultation }) => {
    setConsultation(consultation)

    // We are making an extra cart request to show updated prices.
    // During testing we saw a "merge patients" case were we found that the patient purchased a machine
    // in the past (order with status = Completed) then that information causes prices with insurance to change.
    const { data: cart } = await API.cart.single()
    await setCart(cart)

    if (modal.state.triggeredByCheckout && consultation.inboundLead === false) {
      modal.state.callback()
      handleCloseModal()
    }
  }

  const mutation = useMutation(
    consultation => API.consultations.findOrCreate(consultation),
    {
      onSuccess: handleSuccess
    }
  )

  const onSubmit = (values) => {
    const { email, phoneNumber } = values

    Mixpanel.trackEvent('Click Verify CTA')

    mutation.mutate({
      consultation: {
        patientEmail: email,
        patientPhone: phoneNumber,
        estimatedDueDate: getEstimatedDueDate()
      }
    })
  }

  const error500 = 'Sorry, we could not process your request. Please try again later.'
  const errorMessage = () => {
    if (mutation.isError) {
      if (mutation.error.response.data.status === 500) {
        return error500
      }
      return mutation.error.response.data.error
    }
  }

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} w='100%'>
      <ExcludeFromRecording>
        <FormSection heading=''>
          <VStack
            mt={5}
            spacing={6}
          >
            <FormControl
              errorMessage={errors?.email?.message}
              isRequired
              label={LABELS.email}
            >
              <Input
                data-test='email-input'
                id='email'
                fontSize='sm'
                {...register('email')}
              />
              <FormHelperText>Please use the email on file with your provider.</FormHelperText>
            </FormControl>

            <FormControl
              errorMessage={errors?.phoneNumber?.message}
              label={LABELS.phoneNumber}
              isRequired
            >
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputMask
                    mask='(999) 999-9999'
                    onChange={onChange}
                    value={value}
                  >
                    {(inputProps) => (
                      <Input
                        data-test='phone-number-input'
                        id='phoneNumber'
                        fontSize='sm'
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </FormControl>
          </VStack>
          <Text color='red.500' mt={5} fontSize='sm' h='21px'>
            {errorMessage()}
          </Text>
        </FormSection>
      </ExcludeFromRecording>

      <Center mt={5}>
        <Button
          type='submit'
          colorScheme='green'
          disabled={isSubmitting || mutation.isLoading}
          isLoading={mutation.isLoading}
          data-test='prescription-form-submit-button'
        >
          Verify
        </Button>
      </Center>
    </chakra.form>
  )
}

PrescriptionForm.propTypes = {
  handleCloseModal: PropTypes.func.isRequired
}
