import React from 'react'
import CardContainer from '../common/CardContainer'
import ProviderSignup from './ProviderSignup'
import { API } from '../../services/Api'
import { Box, Spinner, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export default function ProviderSignupPrefill () {
  const { code } = useParams()

  const { isSuccess: fetchedProviderTypes } = useQuery(
    'fetchProviderTypes',
    API.providerTypes.index,
    {
      retry: 0,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  const fetchProviderRegistration = async () => {
    const { data } = await API.providerRegistrations.single(code)

    return data
  }

  const { error, data } = useQuery(
    'fetchProviderRegistration',
    fetchProviderRegistration,
    {
      retry: 0,
      enabled: fetchedProviderTypes,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  if (error && error.request.status === 404) {
    const welcomeMessage = (
      <Box
        bg='yellow.100'
        borderRadius={4}
        padding='3'
        fontSize='sm'
        mb='10'
      >
        <Text fontWeight='medium' mb='2'>
          Signup code not found
        </Text>

        Please enter your information below to get started.
      </Box>
    )

    return (
      <ProviderSignup
        welcomeMessage={welcomeMessage}
      />
    )
  }

  if (data) {
    const prefill = data

    const welcomeMessage = (
      <Box
        bg='green.100'
        borderRadius={4}
        padding='3'
        fontSize='sm'
        mb='10'
      >
        <Text fontWeight='medium' mb='2'>
          Welcome to Doorbell Health, {prefill.formalFullName}!
        </Text>

        Please verify the information below, then enter your email and
        a new password to get started.
      </Box>
    )

    return (
      <ProviderSignup
        prefill={prefill}
        signupCode={code}
        welcomeMessage={welcomeMessage}
      />
    )
  } else {
    return (
      <CardContainer
        heading='Provider Sign Up'
      >
        <Box textAlign='center'>
          <Spinner />
        </Box>
      </CardContainer>
    )
  }
}
