import React from 'react'
import { Button, Text } from '@chakra-ui/react'
import Analytics from '../../../services/Analytics'
import { useProgress } from '../ProgressBar/useProgress'

export const NextButton = () => {
  const {
    currentStep,
    progressSteps,
    goToStep,
    currentStepIndex,
    nextStep,
    stepHasProductSelection
  } = useProgress()

  const isLastStep = progressSteps.length === currentStepIndex + 1

  const label = isLastStep ? 'Review Cart' : 'Next'

  let isDisabled

  if (stepHasProductSelection(nextStep) || isLastStep) {
    isDisabled = false
  } else {
    isDisabled = !stepHasProductSelection(currentStep)
  }

  const handleClick = () => {
    if (isLastStep) {
      Analytics.trackEvent({
        category: 'cart',
        action: 'click review cart',
        label: 'review cart button'
      })
    }
    goToStep(currentStepIndex + 1, { force: true })
  }

  return (
    <Button
      data-test='progress-navigation-next-button'
      bg='green.600'
      color='white'
      size='md'
      px='7'
      _hover={{ background: 'green.500' }}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <Text
        fontSize='md'
        fontWeight='600'
      >
        {label}
      </Text>
    </Button>
  )
}
