export default {
  shop: {
    B1: '#99B0C2',
    B4: '#E6EBF0',
    bgDark: '#90A99E',
    bgLight: '#EEF1EF',
    grayOyster: '#60646f'
  },
  landingPage: {
    black: '#252525',
    blue: '#3846C3',
    darkGray: '#BFBFBF',
    gray: '#F8F8F8',
    grayText: '#6D6D6D',
    green: '#0C6958',
    purple: '#6372FF'
  },
  providerDashboard: {
    bg: '#F0F0F0',
    grayBorder: '#E2E8F0',
    grayHeader: '#F8FAFC'
  }
}
