import { get, post, put } from './base'

export const auth = {
  signIn: (params) =>
    post('/auth/sign_in', params),
  signOut: () =>
    get('/auth/sign_out'),
  passwordReset: (params) =>
    post('/auth/password', params),
  passwordUpdate: (params, config) =>
    put('/auth/password', params, config)
}
