import React from 'react'
import {
  Grid,
  GridItem,
  Image,
  Link,
  Text
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useInsuranceModal } from '../../../context/use-insurance-modal'
import { usePatientSession } from '../../../context/use-patient-session'
import { CartTotalPrice } from './CartTotalPrice'
import CartItemQuantity from './CartItemQuantity'

const CartItem = ({ cartItem }) => {
  const modal = useInsuranceModal()
  const hasActiveInsurance = usePatientSession().hasActiveInsurance()
  const { productVariant } = cartItem
  const isCashOnly = productVariant.product.isCashOnly

  return (
    <Grid
      templateColumns='repeat(12, 1fr)'
      gap={4}
      borderBottom='solid 1px'
      borderBottomColor='blackAlpha.300'
      fontSize={14}
      py={6}
      data-test='cart-item-row'
    >
      <GridItem colSpan={{ base: 4, lg: 2 }}>
        <RouterLink to={`/products/${productVariant.uuid}`}>
          <Image
            data-test='cart-item-image'
            boxSize='133px'
            fit='contain'
            src={productVariant.product.imageUrls[0]}
          />
        </RouterLink>
      </GridItem>

      <GridItem colSpan={{ base: 5, lg: 6 }}>
        <Text
          as={RouterLink}
          data-test='cart-item-title'
          fontSize={14}
          fontWeight={700}
          to={`/products/${productVariant.uuid}`}
        >
          {productVariant.productTitle}
        </Text>

        <Text
          fontSize={14}
          color='blackAlpha.600'
        >
          {productVariant.product.manufacturer}
        </Text>
        <Text
          fontSize={14}
          color='blackAlpha.600'
        >
          Ships to Los Angeles
        </Text>

        {!isCashOnly && !hasActiveInsurance &&
          <Link
            color='blue.600'
            fontSize='sm'
            fontWeight='medium'
            onClick={modal.handleOpenModal}
            data-test='insurance-savings-link'
          >
            Insurance savings available
          </Link>}
      </GridItem>
      <CartItemQuantity
        cartItem={cartItem}
        flexDirection='column'
        display={{ base: 'none', lg: 'flex' }}
      />

      <GridItem colSpan={{ base: 3, lg: 2 }} textAlign='right'>
        <CartTotalPrice
          patientCost={cartItem.formattedPrices.patientCost}
          retailCost={cartItem.formattedPrices.totalRetailCost}
          showInsurancePricing={!isCashOnly && hasActiveInsurance}
        />
      </GridItem>
      <CartItemQuantity
        cartItem={cartItem}
        flexDirection='column'
        justifyContent='space-between'
        display={{ base: 'flex', lg: 'none' }}
        colStart={5}
        colEnd={13}
      />
    </Grid>
  )
}

CartItem.propTypes = {
  cartItem: PropTypes.object.isRequired
}

const CartItemsHeader = () => {
  return (
    <Grid
      templateColumns='repeat(12, 1fr)'
      gap={4}
      borderBottom='solid 1px'
      fontSize={12}
      fontWeight={700}
      borderBottomColor='blackAlpha.300'
      display={{ base: 'none', lg: 'grid' }}
    >
      <GridItem colSpan={2} h='8' />
      <GridItem colSpan={6} h='8'>
        <Text>Item</Text>
      </GridItem>
      <GridItem colSpan={2} h='8' textAlign='right'>
        <Text>Quantity</Text>
      </GridItem>
      <GridItem colSpan={2} h='8' textAlign='right'>
        <Text>Total Price</Text>
      </GridItem>
    </Grid>
  )
}

const CartItems = ({ cart }) => {
  return (
    <>
      <CartItemsHeader />
      {
        cart.cartItems.map((cartItem) =>
          <CartItem cartItem={cartItem} key={cartItem.uuid} />
        )
      }
    </>
  )
}

CartItems.propTypes = {
  cart: PropTypes.object.isRequired
}

export default CartItems
