/* eslint-disable camelcase, react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Mixpanel from '../../services/Mixpanel'
import { useQueryParams } from '../../utils/useQueryParams'
import { usePatientSession } from '../../context/use-patient-session'

export const useIdentifyInboundLeads = () => {
  const { getConsultation } = usePatientSession()
  const consultation = getConsultation()
  const { utm_source } = useQueryParams()
  const cookieName = 'doorbellhealth_providerName'
  const [cookies] = useCookies([cookieName])
  const providerName = cookies.doorbellhealth_providerName

  useEffect(() => {
    let userProperties

    const hasAReferralConsultation = consultation?.inboundLead === false
    if (hasAReferralConsultation) {
      const provider = consultation.provider

      userProperties = {
        inboundLead: false,
        ...(provider && {
          providerUuid: provider.uuid,
          providerFullName: provider.formalFullName
        })
      }
    } else {
      userProperties = {
        inboundLead: true,
        ...(utm_source && { providerUuid: utm_source.replace('provider-', '') }),
        ...(providerName && { providerFullName: window.decodeURIComponent(providerName) })
      }
    }

    Mixpanel.identify({
      userProperties
    })
  }, [])
}
