import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import useLocalStorageState from 'use-local-storage-state'
import { API } from '../services/Api'
import Mixpanel from '../services/Mixpanel'
import Analytics from '../services/Analytics'
import * as RecordingService from '../services/RecordingService'
import * as CustomerSupportService from '../services/CustomerSupportService'
import { useCookies } from 'react-cookie'

const authContext = createContext()

export function isAuthenticated () {
  const auth = useAuth() // eslint-disable-line react-hooks/rules-of-hooks
  return auth?.user !== null
}
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider ({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}
// Provider hook that creates auth object and handles state
function useProvideAuth () {
  const [user, setUser] = useLocalStorageState(
    'user',
    { defaultValue: null, storageSync: false }
  )

  const termsCookie = 'doorbellhealth_showTermsPrompt'
  const [,, removeCookie] = useCookies([termsCookie])

  const resourceType = user?.role?.resourceType
  const isSupplier = resourceType === 'Supplier'
  const isPatient = resourceType === 'Patient'
  const isProvider = resourceType === 'Provider'
  const isEhrProvider = user?.role?.isEhrProvider

  const signin = (user) => {
    const { role } = user

    removeCookie(termsCookie)

    Mixpanel.identify({
      uniqueId: role.resourceUuid,
      userProperties: {
        resourceType: role.resourceType,
        isTestAccount: role.isTestAccount
      }
    })

    Analytics.identify(
      role.resourceUuid,
      role.resourceType,
      role.isTestAccount
    )

    RecordingService.identify(
      role.resourceUuid,
      role.resourceType
    )

    CustomerSupportService.identify(
      {
        name: user.fullName,
        email: user.email,
        resourceType: role.resourceType,
        uuid: role.resourceUuid
      }
    )

    updateUser(user)
  }

  const signout = () => {
    clearUser()
    Mixpanel.reset()
    CustomerSupportService.resetWidget()
    return API.auth.signOut()
  }

  const clearUser = () => {
    setUser(null)
  }

  const updateUser = (user) => {
    setUser(user)
  }

  return {
    isSupplier,
    isPatient,
    isProvider,
    isEhrProvider,
    user,
    clearUser,
    updateUser,
    signin,
    signout
  }
}
