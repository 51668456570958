import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/react'

const ShippingAddress = ({ order, ...props }) => {
  const { customerDetails, shippingAddress } = order

  return (
    <Text
      fontWeight='600'
      // whiteSpace='pre-line' splits a string that contains '/n' into multiple lines
      whiteSpace='pre-line'
      {...props}
    >
      {`${customerDetails.name}
      ${shippingAddress}`}
    </Text>
  )
}

ShippingAddress.propTypes = {
  order: PropTypes.object
}

export default ShippingAddress
