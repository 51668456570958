import React from 'react'
import PropTypes from 'prop-types'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { DesktopFilters } from '../DesktopFilters'
import { Wrapper } from './Wrapper'
import { CloseIcon } from './CloseIcon'
import { ViewItemsButton } from './ViewItemsButton'
import { TopHeader } from './TopHeader'

const Background = () => (
  <Box
    bg='black'
    opacity='0.8'
    h='full'
    w='full'
  />
)

const MobileFiltersSidebar = ({ isOpen, onClose }) => {
  const theme = useTheme()

  const grayBorder = `solid 1px ${theme.colors.blackAlpha['300']}`

  return (
    <Wrapper isOpen={isOpen}>
      <Background />
      <Flex
        direction='column'
        position='relative'
        bg='white'
        h='full'
        w='full'
        minW='319px'
        textAlign='left'
      >
        <CloseIcon
          onClose={onClose}
        />
        <TopHeader
          boxStyles={{ borderBottom: grayBorder }}
        />
        <DesktopFilters
          hideSearchFilter
          overflow='auto'
          flex='1'
          pl={4}
          pr={4}
        />
        <ViewItemsButton
          onClick={onClose}
          boxStyles={{ borderTop: grayBorder }}
        />
      </Flex>
    </Wrapper>
  )
}
MobileFiltersSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default MobileFiltersSidebar
