import PropTypes from 'prop-types'

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useColorModeValue as mode
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import * as React from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { ExcludeFromRecording } from '../../services/RecordingService'
import useRouteMatching from '../common/useRouteMatching'

export const passwordRegex =
  /(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[[!@#$%^&*()\-_=+{};:,<.>])/

export const passwordErrorMessage =
  'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special case character'

export const PasswordField = React.forwardRef((props, ref) => {
  const { isOpen, onToggle } = useDisclosure()
  const { errors, registerRef, isHidden = false } = props
  const { isPatientSignInRoute } = useRouteMatching()

  return (
    <FormControl
      id='password'
      isInvalid={!!errors?.password?.message}
      errortext={errors?.password?.message}
      hidden={isHidden}
    >
      <Flex justify='space-between'>
        <FormLabel>Password</FormLabel>
        <Box
          as={RouterLink}
          color={mode('blue.600', 'blue.200')}
          fontWeight='semibold'
          fontSize='sm'
          to={isPatientSignInRoute ? '/patients/password_recovery' : '/auth/password_recovery'}
        >
          Forgot Password?
        </Box>
      </Flex>
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg='transparent !important'
            variant='ghost'
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onToggle}
          />
        </InputRightElement>
        <ExcludeFromRecording w='full'>
          <Input
            {...registerRef}
            name='password'
            type={isOpen ? 'text' : 'password'}
            autoComplete='current-password'
          />
        </ExcludeFromRecording>
      </InputGroup>
      <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
    </FormControl>
  )
})

PasswordField.propTypes = {
  errors: PropTypes.shape({
    password: PropTypes.shape({
      message: PropTypes.string
    })
  }),
  registerRef: PropTypes.object.isRequired,
  isHidden: PropTypes.bool
}

PasswordField.displayName = 'PasswordField'
