import React, { useState, useEffect } from 'react'
import camelcaseKeys from 'camelcase-keys'
import { useQueryParams } from '../../utils/useQueryParams'
import PropTypes from 'prop-types'
import CardContainer from '../common/CardContainer'
import useRouteMatching from '../common/useRouteMatching'
import * as yup from 'yup'
import { API } from '../../services/Api'
import { PasswordField } from './PasswordField'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '../../context/use-auth'
import { ExcludeFromRecording } from '../../services/RecordingService'

import {
  useBoolean,
  useToast,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Text,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  VStack,
  useColorModeValue as mode
} from '@chakra-ui/react'

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  otpAttempt: yup.string()
})

const SignIn = ({
  signupEnabled
}) => {
  const { user_data: userData } = useQueryParams()
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [otpPromptOpen, setOtpPromptOpen] = useBoolean(false)

  const toast = useToast()

  const auth = useAuth()

  const { isPatientSignInRoute } = useRouteMatching()

  const { register, reset, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(schema)
  })

  const otpAttemptValue = watch('otpAttempt')

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const handleOtpResponse = (response) => {
    if (response === 'prompt') {
      setOtpPromptOpen.on()
    } else if (response === 'invalid') {
      handleServerResponse(false, 'Invalid code, please try again.')
    }
  }

  const handleGoBackClick = () => {
    reset({
      email: '',
      password: '',
      otpAttempt: ''
    })

    setServerState()

    setOtpPromptOpen.off()
  }

  const onValidatedSubmit = (values) => {
    setServerState()
    setSubmitting(true)

    API.auth.signIn(values)
      .then(response => {
        const otpResponse = response.data?.otp

        if (otpResponse) {
          return handleOtpResponse(otpResponse)
        }

        handleServerResponse(true, 'Logged In!')

        const user = {
          ...response.data,
          accessToken: response.headers.accessToken,
          client: response.headers.client
        }

        auth.signin(user)
      })
      .catch(error => {
        const { status, data } = error.response

        if (status === 401) {
          const ACCOUNT_LOCKED_ERROR = 'Your account has been locked due to an excessive number of unsuccessful sign in attempts.'

          if (data?.errors[0] === ACCOUNT_LOCKED_ERROR) {
            handleServerResponse(false, `${ACCOUNT_LOCKED_ERROR} Please try again later.`)
          } else {
            handleServerResponse(false, 'Invalid email or password')
          }
        } else {
          handleServerResponse(false, 'Something went wrong')
        }
      })
      .finally(
        () => setSubmitting(false)
      )
  }

  // This use-effect handles successfull authentication from the omniauth controller
  useEffect(() => {
    if (userData) {
      const userFromOmniauth = camelcaseKeys(
        JSON.parse(window.atob(userData)),
        { deep: true }
      )

      const user = {
        ...userFromOmniauth.user,
        accessToken: userFromOmniauth.accessToken,
        client: userFromOmniauth.client
      }

      auth.signin(user)
    }
  }, [userData])

  const signInFooter = signupEnabled && (
    <Box textAlign='center' mt={4}>
      <Box
        as={RouterLink}
        color={mode('blue.600', 'blue.200')}
        fontWeight='semibold'
        fontSize='sm'
        to={
          isPatientSignInRoute
            ? '/patients/signup'
            : '/providers/signup'
        }
      >
        {`No account? Signup as a ${isPatientSignInRoute ? 'Patient' : 'Provider'}`}
      </Box>
    </Box>
  )

  const otpFooter = signupEnabled && (
    <VStack spacing={4}>
      <Box textAlign='center' mt={4}>
        <Box
          as={Button}
          color='blue.600'
          fontWeight='semibold'
          fontSize='md'
          variant='link'
          onClick={handleSubmit(() => {
            reset({
              otpAttempt: ''
            })

            toast({
              title: "We've sent you a new code",
              status: 'info',
              position: 'top-right',
              isClosable: true
            })

            return onValidatedSubmit
          })}
        >
          Didn't receive the email? Click here to resend.
        </Box>
      </Box>
      <Box textAlign='left'>
        <Box
          as={Button}
          color='gray.500'
          fontSize='sm'
          variant='link'
          onClick={handleGoBackClick}
        >
          Go Back
        </Box>
      </Box>
    </VStack>
  )

  return (
    <CardContainer
      heading={otpPromptOpen ? 'Check your email' : 'Sign in to your account'}
      footer={otpPromptOpen ? otpFooter : signInFooter}
    >
      <chakra.form onSubmit={handleSubmit(onValidatedSubmit)}>

        {serverState && !serverState.ok && (
          <Box mb={8}>
            <Alert status='error' borderRadius={4}>
              <AlertIcon />
              <AlertDescription>{serverState.msg}</AlertDescription>
            </Alert>
          </Box>
        )}
        {otpPromptOpen && (
          <Text fontSize='md'>
            We have sent a One-time password to your email. Please enter the code here to sign in.
          </Text>
        )}
        <Stack spacing={otpPromptOpen ? 5 : 6}>
          <FormControl
            id='email'
            isInvalid={!!errors?.email?.message}
            errortext='errors?.email?.message'
            hidden={otpPromptOpen}
          >
            <FormLabel>Email address</FormLabel>
            <ExcludeFromRecording>
              <Input name='email' autoComplete='email' {...register('email')} />
            </ExcludeFromRecording>
          </FormControl>
          <PasswordField
            registerRef={register('password')}
            errors={errors}
            isHidden={otpPromptOpen}
          />
          <FormControl
            id='otpAttempt'
            hidden={!otpPromptOpen}
          >
            <FormLabel>Verification Code</FormLabel>
            <ExcludeFromRecording>
              <Input
                name='otpAttempt'
                size='lg'
                placeholder='Enter verification code'
                {...register('otpAttempt')}
              />
            </ExcludeFromRecording>
            <FormErrorMessage>{errors?.otpAttempt?.message}</FormErrorMessage>
          </FormControl>
          <Button
            isLoading={submitting}
            type='submit'
            colorScheme='blue'
            size='lg'
            fontSize='md'
            disabled={submitting || !!errors?.email || !!errors?.password || (otpPromptOpen && !otpAttemptValue)}
          >
            Sign In
          </Button>
        </Stack>
      </chakra.form>
    </CardContainer>
  )
}

SignIn.propTypes = {
  signupEnabled: PropTypes.bool
}

export default SignIn
