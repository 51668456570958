import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'
import { FaFilePrescription } from 'react-icons/fa'
import { usePatientSession } from '../../../context/use-patient-session'
import { usePrescriptionModal } from '../../../context/use-prescription-modal'

export const PrescriptionButton = ({ whiteColor }) => {
  const {
    hasActiveInsurance,
    hasPrescription,
    hasInboundLeadConsultation,
    getConsultation
  } = usePatientSession()
  const prescriptionModal = usePrescriptionModal()
  const patientConsultation = getConsultation()

  if (
    !hasActiveInsurance() ||
      (patientConsultation && !hasInboundLeadConsultation())
  ) {
    return null
  }

  const statusColor = hasPrescription() ? 'green.700' : 'red.700'
  const color = whiteColor ? 'white' : statusColor

  return (
    <Button
      onClick={() => prescriptionModal.handleOpenModal({ triggerSource: 'Rx Icon' })}
      colorScheme='white'
      aria-label='Upload prescription'
      cursor='pointer'
      fontSize='26px'
      transition='none'
      data-test='prescription-button'
      position='relative'
      color={color}
      flexShrink={0}
      padding={2}
    >
      <FaFilePrescription />
    </Button>
  )
}

PrescriptionButton.propTypes = {
  whiteColor: PropTypes.bool.isRequired
}
