import React from 'react'
import PropTypes from 'prop-types'
import { Button, Text, Box } from '@chakra-ui/react'

export const ViewItemsButton = ({ onClick, boxStyles = {} }) => (
  <Box px={4} py={2} {...boxStyles}>
    <Button
      onClick={onClick}
      bg='green.700'
      color='white'
      size='md'
      w='full'
      data-test='mobile-filters-view-items-button'
    >
      <Text
        fontSize='md'
        fontWeight='600'
      >
        Apply
      </Text>
    </Button>
  </Box>
)
ViewItemsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  boxStyles: PropTypes.object
}
