import React from 'react'
import PropTypes from 'prop-types'
import { IoInformationCircleOutline } from 'react-icons/io5'
import { useTheme, Box, HStack, Text, SimpleGrid, Icon, Divider } from '@chakra-ui/react'
import Tooltip from '../../common/Tooltip'

const OrderSummary = ({ order, ...props }) => {
  const { insuranceActive, formattedPrices } = order
  const {
    insuranceSavings: orderInsuranceSavings,
    subtotal,
    patientServiceFee,
    tax,
    amountPatientPaid
  } = formattedPrices

  const theme = useTheme()
  const insuranceLabel =
    'Shipping price determined by the participating retailer ' +
    'shipping to your location and not by Doorbell Health.'

  let insuranceSavings

  if (insuranceActive) {
    insuranceSavings = (
      <Text
        color='blue.600'
        fontWeight='500'
        data-test='cart-savings'
      >
        -{orderInsuranceSavings}
      </Text>
    )
  }

  return (
    <Box data-test='order-summary'>
      <SimpleGrid
        columns={2}
        fontSize={14}
        spacing={4}
        px={0}
        {...props}
      >
        <Text>Subtotal</Text>
        <Text
          align='right'
          data-test='cart-subtotal'
        >
          {subtotal}
        </Text>
        <Text>Service Fee</Text>
        <Text
          align='right'
          data-test='service-fee'
        >
          {patientServiceFee}
        </Text>
        <HStack spacing={0}>
          <Text as='span'>
            Shipping
          </Text>
          <Tooltip
            arrowShadowColor={theme.colors.gray['200']}
            backgroundColor='white'
            bg='white'
            borderWidth={1}
            color='blackAlpha.600'
            hasArrow
            label={insuranceLabel}
            p={6}
            placement='bottom-start'
          >
            <Box>
              <Icon as={IoInformationCircleOutline} pl={1} />
            </Box>
          </Tooltip>

        </HStack>
        <Text align='right'>Free</Text>
        <Text>Tax</Text>
        <Text align='right' data-test='cart-tax'>{tax}</Text>
        {insuranceSavings
          ? (
            <>
              <Text>Insurance Savings</Text>
              <Box display='flex' justifyContent='flex-end'>
                {orderInsuranceSavings}
              </Box>
            </>
            )
          : null}
      </SimpleGrid>
      <Divider />
      <SimpleGrid
        columns={2}
        fontSize={14}
        spacing={4}
        pt={2}
      >
        <Text fontWeight={700}>Total</Text>
        <Text align='right' fontWeight={700}>
          {amountPatientPaid}
        </Text>
      </SimpleGrid>
    </Box>
  )
}
OrderSummary.propTypes = {
  order: PropTypes.object
}

export default OrderSummary
