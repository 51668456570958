import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import Mixpanel from '../../services/Mixpanel'
import Analytics from '../../services/Analytics'
import { API } from '../../services/Api'
import * as RecordingService from '../../services/RecordingService'
import * as CustomerSupportService from '../../services/CustomerSupportService'
import {
  useToast,
  useBoolean
} from '@chakra-ui/react'

function useFulfillment () {
  const toast = useToast()
  const navigate = useNavigate()
  const [fulfillment, setFulfillment] = useState()
  const [confirmationNumber, setConfirmationNumber] = useState()
  const [hasConfirmationNumber, setHasConfirmationNumber] = useBoolean()

  const handleError = () => {
    navigate('/')

    toast({
      position: 'top-right',
      title: 'Invalid fulfillment link',
      status: 'error',
      duration: null,
      isClosable: true
    })
  }

  const identifySupplierForAnalytics = (fulfillment) => {
    const { uuid, name, email } = fulfillment.supplier
    const resourceType = 'Supplier'

    Mixpanel.identify(uuid, resourceType)

    Analytics.identify(uuid, resourceType)

    RecordingService.identify(uuid, resourceType)

    CustomerSupportService.identify(
      { name, email, resourceType, uuid }
    )
  }

  const handleSuccess = (fulfillment) => {
    setFulfillment(fulfillment)
    identifySupplierForAnalytics(fulfillment)
  }

  const shipmentsCartItems = fulfillment?.shipmentsCartItems || []

  const unshippedItems = shipmentsCartItems.filter((shipmentsCartItem) => (
    shipmentsCartItem.status === 'unshipped'
  ))

  const shippedItems = shipmentsCartItems.filter((shipmentsCartItem) => (
    shipmentsCartItem.status === 'shipped'
  ))

  const fetchFulfillment = (confirmationNumber) => {
    setConfirmationNumber(confirmationNumber)
    setHasConfirmationNumber.on()
  }

  useQuery(
    ['fetchFulfillment', confirmationNumber],
    async () => {
      const { data } = await API.fulfillments.single(confirmationNumber)

      return data
    },
    {
      enabled: hasConfirmationNumber,
      onError: handleError,
      onSuccess: handleSuccess,
      retry: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )

  return {
    confirmationNumber,
    fulfillment,
    fetchFulfillment,
    setFulfillment,
    shipmentsCartItems,
    shippedItems,
    unshippedItems
  }
}

export default useFulfillment
