import { useLocation, useNavigate } from 'react-router-dom'
import QueryString from 'query-string'

const QUERY_STRING_OPTIONS = { arrayFormat: 'bracket' }

// Parses query string to get URL query parameters.
// Example:
// queryParams = useQueryParams()
// queryParams.code
export const useQueryParams = () => {
  return QueryString.parse(useLocation().search, QUERY_STRING_OPTIONS)
}

export const useRemoveQueryParam = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = useQueryParams()

  const removeQueryParam = (queryParam) => {
    delete queryParams[queryParam]

    const newLocation = {
      ...location,
      search: QueryString.stringify(queryParams, QUERY_STRING_OPTIONS)
    }

    navigate(newLocation, { replace: true })
  }

  return removeQueryParam
}
