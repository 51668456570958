import React from 'react'
import PropTypes from 'prop-types'
import { Button, Text } from '@chakra-ui/react'
import Mixpanel from '../../../services/Mixpanel'

const ProgressButton = ({
  buttonAction,
  buttonCopy,
  buttonIsDisabled,
  currentStep
}) => {
  const clickHandler = () => {
    Mixpanel.trackEvent(
      'Onboarding Wizard: Click Next CTA',
      { currentStep, buttonCopy }
    )

    buttonAction()
  }
  return (
    <Button
      data-test='onboarding-progress-button'
      bg='green.600'
      color='white'
      size='md'
      px='7'
      onClick={clickHandler}
      isDisabled={buttonIsDisabled}
      _hover={{ background: 'green.500' }}
    >
      <Text
        fontSize='md'
        fontWeight='600'
      >
        {buttonCopy}
      </Text>
    </Button>
  )
}

ProgressButton.propTypes = {
  buttonAction: PropTypes.func,
  buttonCopy: PropTypes.string.isRequired,
  buttonIsDisabled: PropTypes.bool,
  currentStep: PropTypes.number.isRequired
}

export default ProgressButton
