import Compressor from 'compressorjs'
import Airbrake from './Airbrake'

export const compressImage = (imageFile) => new Promise(
  (resolve) => {
    const onError = (error) => {
      Airbrake.notify(error)
      // Note that the promise resolves the uncompressed image on error (fails silently)
      resolve(imageFile)
    }

    const onSuccess = (compressedImage) => {
      resolve(compressedImage)
    }

    // Docs: https://github.com/fengyuanchen/compressorjs
    // By default, png images with size > 5MB are converted to jpg
    new Compressor(imageFile, { // eslint-disable-line no-new
      quality: 0.8, // Compression ratio of 46.41%
      maxWidth: 4000, // 4000px
      maxHeight: 4000, // 4000px
      success: onSuccess,
      error: onError
    })
  }
)
