import React, { useEffect } from 'react'
import { Center, Text, Box } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { useProgress } from './useProgress'
import { Step } from './Step'

const ProgressBar = () => {
  const location = useLocation()

  const {
    progressSteps,
    goToStep,
    goToTheLatestIncompleteStep,
    currentStepIndex,
    stepHasProductSelection,
    stepMessage
  } = useProgress()

  const handleStepClick = (progressStep) => {
    goToStep(progressStep.stepIndex)
  }

  useEffect(() => {
    if (location.state?.stepIndex !== undefined) {
      goToStep(location.state.stepIndex)
    } else {
      goToTheLatestIncompleteStep()
    }
    /* eslint-disable-next-line */
  }, [])

  return (
    <Box m={{ base: 'none', lg: '70px' }}>
      <Center
        h='87px'
        bg={{ base: 'blackAlpha.50', lg: 'none' }}
      >
        <Box paddingTop='20px' alignItems='baseline' px='40px' h='100%' display='flex' overflowY={{ base: 'hidden' }} overflowX={{ base: 'auto', lg: 'hidden' }}>
          {progressSteps
            ? progressSteps.map((progressStep) =>
              <Step
                key={progressStep.stepIndex}
                label={progressStep.productCategory}
                isClickeable={
                  currentStepIndex !== progressStep.stepIndex && stepHasProductSelection(progressStep)
                }
                onClick={() => handleStepClick(progressStep)}
                isActive={currentStepIndex === progressStep.stepIndex}
                isSelected={stepHasProductSelection(progressStep)}
                isLast={progressStep.stepIndex === (progressSteps.length - 1)}
              />)
            : null}
        </Box>
      </Center>
      {stepMessage ? <Text data-test='progress-message' fontSize='xs' color='red.500' align='center'>{stepMessage}</Text> : null}
    </Box>
  )
}

export default ProgressBar
