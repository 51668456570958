import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import {
  Button,
  HStack,
  Icon,
  Text
} from '@chakra-ui/react'

import {
  IoBagHandleOutline,
  IoInformationCircleOutline,
  IoPersonOutline
} from 'react-icons/io5'

import useRouteMatching from '../common/useRouteMatching'
import RoundedBox from '../common/RoundedBox'
import PatientInfo from './PatientInfo'

const NavButton = (props) => {
  const { icon, label, ...buttonProps } = props
  return (
    <Button
      as={RouterLink}
      to=''
      fontSize='sm'
      py='10'
      pl='6'
      fontWeight='500'
      variant='ghost'
      _hover={{ borderRadius: 'none' }}
      _active={{
        background: 'gray.100',
        borderRadius: 'none'
      }}
      justifyContent='start'
      {...buttonProps}
    >
      <HStack spacing='3'>
        <Icon as={icon} boxSize='6' color='subtle' />
        <Text>{label}</Text>
      </HStack>
    </Button>
  )
}

NavButton.propTypes = {
  icon: PropTypes.elementType,
  label: PropTypes.string
}

const DesktopSidebar = () => {
  const {
    isAccountSettingsRoute,
    isInsuranceInformationRoute,
    isOrderHistoryRoute
  } = useRouteMatching()

  return (
    <RoundedBox>
      <PatientInfo />
      <NavButton
        label='Order History'
        icon={IoBagHandleOutline}
        to='/order_history'
        isActive={isOrderHistoryRoute}
      />
      <NavButton
        label='Insurance Information'
        icon={IoInformationCircleOutline}
        to='/insurance_information'
        isActive={isInsuranceInformationRoute}
      />
      <NavButton
        label='Account Settings'
        icon={IoPersonOutline}
        to='/account_settings'
        isActive={isAccountSettingsRoute}
      />
    </RoundedBox>
  )
}

export default DesktopSidebar
