import React from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from '@chakra-ui/react'

export const Section = ({ children, withPaddingTop = true, ...props }) => (
  <Box
    as='section'
    {...props}
  >
    <Container
      h='full'
      maxW='5xl'
      p={5}
      pb={8}
      pt={withPaddingTop ? { base: 8, md: 10 } : {}}
    >
      {children}
    </Container>
  </Box>
)
Section.propTypes = {
  children: PropTypes.node,
  withPaddingTop: PropTypes.bool
}
