import React from 'react'
import PropTypes from 'prop-types'
import { API } from '../../services/Api'
import { useMutation } from 'react-query'
import Mixpanel from '../../services/Mixpanel'

import {
  Button,
  Flex,
  Link,
  Spacer
} from '@chakra-ui/react'

const AttachmentItem = ({
  afterAttachmentsChange,
  allowDeletion,
  attachment
}) => {
  const { filename } = attachment

  const mutation = useMutation(
    () => API.attachments.remove(attachment.uuid),
    {
      onSuccess: (data) => {
        afterAttachmentsChange(data)
      }
    }
  )

  const downloadUrlMutation = useMutation(
    params => API.attachments.getDownloadUrl(params),
    {
      onSuccess: (data) => {
        window.open(data.data.downloadUrl, '_blank')
      }
    }
  )

  const handleFileClick = () => (
    downloadUrlMutation.mutate({ uuid: attachment.uuid })
  )

  const isDeleting = mutation.isLoading || mutation.isSuccess

  const deleteAttachment = () => {
    if (!isDeleting) {
      Mixpanel.trackEvent('Click Delete Attachment')
      mutation.mutate()
    }
  }

  return (
    <Flex>
      <Link color='blue.500' onClick={handleFileClick}>{filename}</Link>
      <Spacer />
      {allowDeletion && (
        <Button
          data-test='delete-attachment'
          color='red.500'
          fontSize='sm'
          fontWeight='500'
          variant='link'
          isLoading={isDeleting}
          onClick={deleteAttachment}
        >
          Delete
        </Button>
      )}

    </Flex>
  )
}

AttachmentItem.propTypes = {
  afterAttachmentsChange: PropTypes.func.isRequired,
  allowDeletion: PropTypes.bool.isRequired,
  attachment: PropTypes.object.isRequired
}

export default AttachmentItem
