import React, { useState } from 'react'
import * as yup from 'yup'
import CardContainer from '../common/CardContainer'
import { API } from '../../services/Api'
import { ExcludeFromRecording } from '../../services/RecordingService'
import { passwordRegex, passwordErrorMessage } from './PasswordField'
import { useAuth } from '../../context/use-auth'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import Constants from '../common/constants'
import { FormControl } from '../common/FormElements'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Input,
  Link,
  Stack,
  Text,
  chakra,
  useToast,
  useColorModeValue as mode
} from '@chakra-ui/react'

const LABELS = {
  email: 'Email',
  password: 'Password',
  passwordConfirmation: 'Confirm Password'
}

const schema = yup.object().shape({
  email: yup.string().email().nullable().label(LABELS.email).required(),
  password: yup.string().label(LABELS.password).required().matches(
    passwordRegex,
    passwordErrorMessage
  ),
  passwordConfirmation: yup
    .string()
    .label(LABELS.passwordConfirmation)
    .required()
    .when('password', {
      is: password => (!!(password && password.length > 0)),
      then: yup.string().oneOf([yup.ref('password')], "Password doesn't match")
    }),
  termsOfServiceAgreement: yup.bool().oneOf([true], 'Required field')
})

export default function PatientSignup () {
  const [serverState, setServerState] = useState()
  const [submitting, setSubmitting] = useState(false)
  const auth = useAuth()

  const toast = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) })

  const handleServerResponse = (ok, msg) => {
    setServerState({ ok, msg })
  }

  const onValidatedSubmit = ({
    email,
    password,
    passwordConfirmation
  }) => {
    setServerState()
    setSubmitting(true)

    const userAttributes = {
      email,
      password,
      passwordConfirmation
    }

    API.patients.create({
      user: userAttributes
    })
      .then(response => {
        handleServerResponse(true, 'Logged In!')

        toast({
          position: 'top-right',
          title: 'You have successfully signed up.',
          status: 'success',
          isClosable: true
        })

        const user = {
          ...response.data,
          accessToken: response.headers.accessToken,
          client: response.headers.client
        }

        auth.signin(user)
      })
      .catch(error => {
        const status = error.response.status

        if (status === 400) {
          handleServerResponse(false, error.response.data.error)
        } else {
          handleServerResponse(false, 'Something went wrong')
        }
      })
      .finally(
        () => setSubmitting(false)
      )
  }

  const footer = (
    <Box textAlign='center' mt={4}>
      <Box
        as={RouterLink}
        color={mode('blue.600', 'blue.200')}
        fontWeight='semibold'
        fontSize='sm'
        to='/patients/signin'
      >
        Have an account? Sign in as a Patient
      </Box>
    </Box>
  )

  const termsLink = (
    <Link
      href={Constants.doorbell.termsOfServiceLink}
      color={mode('blue.600', 'blue.200')}
      isExternal
    >
      {Constants.doorbell.termsOfService}
    </Link>
  )

  return (
    <CardContainer
      heading='Patient Sign Up'
      footer={footer}
    >
      <ExcludeFromRecording>
        <chakra.form onSubmit={handleSubmit(onValidatedSubmit)}>
          <Stack spacing='6'>
            <FormControl
              isRequired
              errorMessage={errors?.email?.message}
              label={LABELS.email}
            >
              <Input
                id='email'
                autoComplete='email'
                fontSize='sm'
                {...register('email')}
              />
            </FormControl>

            <FormControl
              isRequired
              errorMessage={errors?.password?.message}
              label={LABELS.password}
            >
              <Input
                id='password'
                type='password'
                autoComplete='password'
                {...register('password')}
              />
            </FormControl>

            <FormControl
              isRequired
              errorMessage={errors?.passwordConfirmation?.message}
              label={LABELS.passwordConfirmation}
            >
              <Input
                id='passwordConfirmation'
                type='password'
                autoComplete='passwordConfirmation'
                {...register('passwordConfirmation')}
              />
            </FormControl>

            <Box>
              <Checkbox
                isInvalid={!!errors?.termsOfServiceAgreement?.message}
                alignItems='start'
                spacing={4}
                id='termsCheckbox'
                {...register('termsOfServiceAgreement')}
              >
                <Text
                  color='gray.600'
                  fontSize='sm'
                  mt='-4px'
                >
                  By checking this box, I agree to Doorbell Health's {termsLink}.
                </Text>
              </Checkbox>

              <Text fontSize='sm' ml={8} mt={2} color='red.500'>
                {errors?.termsOfServiceAgreement?.message}
              </Text>
            </Box>

            {serverState && !serverState.ok && (
              <Box mb={8}>
                <Alert status='error' borderRadius={4} fontSize='sm'>
                  <AlertIcon />
                  <AlertDescription>{serverState.msg}</AlertDescription>
                </Alert>
              </Box>
            )}

            <Button
              isLoading={submitting}
              type='submit'
              colorScheme='blue'
              size='lg'
              fontSize='md'
              disabled={submitting}
            >
              Sign Up
            </Button>
          </Stack>
        </chakra.form>
      </ExcludeFromRecording>
    </CardContainer>
  )
}
